import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MessageIcon from '@mui/icons-material/Message';
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from "../../components/drawer";
import { getDateformatUsingSeconds, getFixedValue } from '../../utils/constant';
import { getBrandPosts } from './action';
import { updateUser } from './userSlice';

const BrandPosts = ({ setfilterState, filterState, brandName }) => {
    const { brandsPosts, brandPostlimit } = useSelector((state) => state.userSlice);
    const dispatch = useDispatch();
    const loaderRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && brandPostlimit <= brandsPosts.length) {
                dispatch(updateUser({ brandPostlimit: brandPostlimit + 10 }));
                dispatch(getBrandPosts(brandName.brandId, brandName.id));
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [brandPostlimit, brandName, dispatch, brandsPosts.length]);


    useEffect(() => {
        dispatch(getBrandPosts(brandName.brandId, brandName.id));
    }, [dispatch, brandName.id, brandName.brandId])

    return (
        <Drawer
            state={filterState}
            setState={setfilterState}
            className="filterDrawer"
            bgColor={"white"}
        >
            <Grid container p={2} bgcolor={"white"}>
                <Grid item md={12} lg={12} sm={12} xs={12} pl={1}>
                    <Typography variant='h4' sx={{ position: "relative", bottom: "30px" }}>
                        {brandName.name}
                    </Typography>
                    <Typography variant='body2'>
                        {brandName.postCount} posts
                    </Typography>
                </Grid>
                {brandsPosts?.map((v) => (
                    <Grid item key={v.id} md={4} lg={4} sm={12} xs={12}>
                        <div
                            style={{ marginLeft: "10px", marginTop: "10px" }}
                            onClick={() => {
                                if (v.shortcode) {
                                    window.open(v.shortcode);
                                }
                            }}
                        >
                            <div className="brandBlocks">
                                <Card sx={{ maxWidth: '100%', cursor: 'pointer' }}>
                                    <CardMedia
                                        component="img"
                                        alt="img"
                                        height="140"
                                        image={v.thumbnailSrc}
                                    />
                                    <CardContent
                                        className="wishDate w-100"
                                        style={{
                                            padding: '0px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            component="p"
                                            className="mb-0"
                                            style={{ fontSize: '14px', color: '#6C7692' }}
                                        >
                                            <FavoriteBorderOutlinedIcon /> {getFixedValue(v.previewLikeCount)} &nbsp;
                                            <MessageIcon fontSize="small" /> {getFixedValue(v.commentCount)}
                                        </Typography>
                                        <Typography
                                            component="p"
                                            className="mb-0"
                                            style={{ color: '#6C7692', fontSize: '14px' }}
                                        >
                                            {getDateformatUsingSeconds(v.timestamp)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </Grid>
                ))}
                <div ref={loaderRef} style={{ height: '20px', margin: '10px' }} />
            </Grid>
        </Drawer>
    );
};

export default BrandPosts;
