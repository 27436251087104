import {
  Search
} from "@mui/icons-material";
import {
  Box,
  Chip,
  FormControlLabel,
  InputAdornment,
  Pagination,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import useDebounce from "../../../hooks/use.debounce";
import { getUserDetails } from "../../../utils/common";
import { getAllBrandForDropDown } from "../../brands/action";
import { checkUserCreateCredit, getAllCompanyUserforListing, updateUserStatus } from "../action";
import { resetCompany, updateCompany } from "./../companySlice";

function TableHeading(props) {
  const { order, orderBy } = props;


  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      type: "",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
      type: "",
    },
    {
      id: "contact_number",
      numeric: false,
      disablePadding: true,
      label: "Phone Number",
      type: "",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Action",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className="hideActionSort">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={() => null}
              //onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}



export default function Users() {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [status, setStatus] = useState({ id: 0, status: false, name: "", open: false });

  const dispatch = useDispatch();
  const {
    limit,
    page,
    sort_key,
    totalCount,
    totalPages,
    filters,
    listing,
  } = useSelector((state) => state.companySlice);


  const debouncedSearch = useDebounce(filters.search, 500);



  const handleChangePerPage = (event, value) => {
    dispatch(updateCompany({ limit: event.target.value, page: 0 }));
    dispatch(getAllCompanyUserforListing());
  };


  // useEffect(() => {
  //   socket.on(SOCKET_EVENT.COMPANY_CREATED, (data) => {
  //     dispatch(getAllCompanyUserforListing());
  //   });
  // }, [socket]);






  const handleChange = (event, value) => {
    dispatch(updateCompany({ page: value - 1 }));
    dispatch(getAllCompanyUserforListing());
  };

  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateCompany({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );
    dispatch(getAllBrandForDropDown());
  };

  useEffect(() => {
    if (debouncedSearch || filters.search == "")
      dispatch(getAllCompanyUserforListing())
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(getAllCompanyUserforListing())
    return () => dispatch(resetCompany())
  }, []);

  const handleSearch = (event) => {
    //if (event.target.value)
    dispatch(updateCompany({ filters: { ...filters, search: event.target.value } }));
  };

  const handleSearchClear = () => {
    dispatch(updateCompany({ filters: { ...filters, search: "" } }));
    dispatch(getAllCompanyUserforListing());
  };

  const userDetails = getUserDetails();

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1"> Manage Users</Typography>
          <button className="btnPrimary"
            disabled={!states?.permissions?.company?.create}
            onClick={() => {
              dispatch(checkUserCreateCredit(navigate));
            }}
          >
            Create User
          </button>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            // overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            <div className='allFilter cmpyMgmt '>
              <div className="d-flex align-items-center gap-2" style={{ marginRight: 0 }}>
                <SearchInput
                  className="inputSearch sContrl p-0"
                  value={filters.search}
                  placeholder={"Search here..."}
                  handleSearch={handleSearch}
                  clearSearch={handleSearchClear}
                  startAdornment={
                    <InputAdornment className="overIcon" position="start"
                      sx={{
                        maxHeight: 'none',
                        height: '38px',
                        minWidth: '38px',
                        marginRight: '0px'
                      }}
                    >
                      <Search />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </div>


          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader aria-label="sticky table"
            >
              <TableHeading
                // numSelected={selected.length}

                order={order}
                orderBy={sort_key}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                //rowCount={rows.length}
                numSelected={0}
                onSelectAllClick={function (

                ) {
                  throw new Error("Function not implemented.");
                }}
              //order={"desc"}
              />
              <TableBody>
                {listing && listing?.length ? (
                  listing?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        className={"tableRow"}
                        style={{ userSelect: "text" }}
                      >

                        <TableCell component="td" scope="row">
                          {row?.contactPersonName || "N/A"}&emsp;{row.isPrimary == 1 && <Chip label="primary" color="primary" />}

                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row?.email || "N/A"}
                        </TableCell>

                        <TableCell align="left">
                          {row?.phoneNumber || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {listing[0]?.id == userDetails?.id ? (row.isPrimary != 1 ? <Stack direction="row" alignItems="center" >
                            <span className="editIcon">Inactive</span>
                            <FormControlLabel
                              className="toogleBtn"

                              control={
                                <Switch
                                  checked={row.isActive}
                                  onClick={() => setStatus({ id: row.id, status: !row.isActive, name: row?.contactPersonName, open: true })}
                                  color="success"
                                />
                              }
                              label=""
                            />
                            <span className="editIcon" style={{ position: "relative", right: 16 }}>Active</span>

                          </Stack> : <span>Active (by default)</span>) : row.isActive ? "Active" : "Inactive"}
                        </TableCell>

                        <TableCell sx={{ cursor: "pointer" }}>



                          <Stack direction="row" alignItems="center" >

                            <div className="actBtnGrps">
                              <span className="editIcon" onClick={() => navigate(`/company/user/edit/${row.id}`, { state: row })}>Edit</span>
                            </div>
                          </Stack>



                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        alignItems={"center"}
                        component="h3"
                      >
                        No Data found!
                      </Typography>

                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </TableContainer>
          <hr />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{
              background: 'transparent',
            }}
          >
            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
              <Box className="d-flex align-items-center rowPerPage" gap={0}>
                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
              </Box>
              <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                  {listing && listing.length > 0
                    ? page === 0
                      ? `0 - ${listing.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <Pagination
                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                  defaultPage={1} siblingCount={0} boundaryCount={1}
                />
              </Box>
            </Box>
          </Stack >

          <CommonModel
            open={status.open}
            handleDialogClose={() => setStatus((prev) => ({ ...prev, open: false }))}
            dialogTitle={""}
            dialogPara={`Do you want to ${status ? "inactive" : "active"} this user “${status.name}”? `}
            nvYes={"Confirm"}
            nvCancel={"Cancel"}
            yesHandle={() => {
              dispatch(updateUserStatus({ id: status.id, isActive: status.status, roleId: "1" }));
              setStatus({ id: 0, status: false, name: "", open: false });
            }}
          />
        </Box>




      </Stack>

    </>
  );
}
