import { Box, Checkbox, FormGroup, FormLabel, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Editor from 'react-simple-wysiwyg';
import AutoSuggestion from "../../../components/autocomplete";
import NormalInput from "../../../components/inputs/normalInput";
import BasicSelect from "../../../components/select";
import Schema from "../../../schema";
import { getUserType } from "../../../utils/common";
import { ADMIN, AGE_GROUP, CAMPAIGN_TYPES, COMPANY, FOLLOWERS_ARRAY, GENDER, INFLUENCER_NICHE, citiesInIndia } from "../../../utils/constant";
import { endpoints } from "../../../utils/endpoints";
import { addCampaignInfo } from "../action";
import { updateCampaign } from "../campaignSlice";
import Steppers from './Stepper';
const BudgetTarget = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let initialValues = {
        location: [],
        creatorLocation: [],
        budget: "",
        noOfCreators: "",
        gender: "Male",
        influncerType: [],
        selectedCategories: [],
        campaignApplyPref: [],
        audioPrefGender: "",
        ageGroup: "18-25",
        contactDetails: "",
        campaignTNC: "",
        budgetbudgetPerInfluencer: "",
        type: "",
        demand: "",
        campaignId: ""
    }
    const [value, setValue] = useState(initialValues);
    const [categories, setCategories] = useState([]);
    const { formData, step } = useSelector((state) => state.campaignSlice);
    const { paramMasterList } = useSelector((state) => state.commonSlice);

    useEffect(() => {

        if (formData?.budget !== undefined) {
            setValue({
                location: formData.location,
                creatorLocation: formData.creatorLocation,
                budget: formData.budget,
                noOfCreators: formData?.noOfCreators,
                gender: formData.gender,
                influncerType: formData.influncerType,
                campaignApplyPref: formData.campaignApplyPref,
                audioPrefGender: formData.audioPrefGender,
                ageGroup: formData.ageGroup,
                contactDetails: formData.contactDetails,
                campaignTNC: formData.campaignTNC,
                selectedCategories: formData.selectedCategories,
                budgetbudgetPerInfluencer: formData.budgetbudgetPerInfluencer,
                type: formData.type,
                demand: formData.demand,
                campaignId: formData.campaignId
            });
        } else {
            setValue((prev) => ({ ...prev, type: formData.type, demand: formData.demand, campaignId: formData.campaignId }));
        }

        setCategories(paramMasterList?.length > 0 ? JSON.parse(paramMasterList?.find((v) => v?.parameterkey === INFLUENCER_NICHE)?.value) : []);

        if (formData?.type === undefined && getUserType() === ADMIN) {
            navigate("/campaigns");
        }
        if (formData?.type === undefined && getUserType() === COMPANY) {
            navigate("/my-campaigns");
        }


        window.scrollTo(0, 0);
        return () => null;

    }, [formData, paramMasterList]);
    // 

    return <>
        <Stack direction={"column"} gap={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography className="page-title" variant="h1">Create New Campaign</Typography>
            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}>
                <Grid container>
                    <Grid className="formLevels" item xs={12} lg={12} md={12} sm={12} p={5}>
                        <Steppers step={2} />
                    </Grid>
                    <hr />
                    <Formik
                        initialValues={value}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validationSchema={Schema.Step4}
                        onSubmit={(values, { setSubmitting }) => {
                            values.budget = values.budget || 0;
                            dispatch(updateCampaign({ formData: { ...formData, ...values }, step: 5 }));
                            dispatch(addCampaignInfo(values, endpoints.createCampaignBudget));
                            navigate("/campaign/dates")
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {

                            return (
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    // if (Object.keys(errors).length > 0) {
                                    //     console.log("Object.keys(errors)", Object.keys(errors))
                                    //     document.getElementsByName(Object.keys(errors)[0])[0].focus();
                                    // }
                                    handleSubmit();
                                }}>
                                    <Grid container xs={12} lg={12} md={12} sm={12} p={5}>
                                        <Grid item xs={8} lg={8} md={8} sm={8} rowGap={2} className='stepAcc pe-xxl-5 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-3 pe-3' style={{ borderRight: '1px solid rgba(170, 170, 170, 0.3)' }}>

                                            <Grid container rowGap={3} >
                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} style={{ borderRadius: '10px' }} >

                                                    {formData.type === CAMPAIGN_TYPES.PAID && <>
                                                        <Typography mb={3} variant="h4" component="h4">Campaign Budget</Typography>
                                                        <FormLabel required>What is your overall budget for this campaign?</FormLabel>
                                                        <NormalInput
                                                            name={"budget"}
                                                            type={"text"}
                                                            isRequired={true}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.budget}
                                                            placeholder={"Ex. 50000"}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.budget) && errors.budget}
                                                            helperText={touched.budget && errors.budget}
                                                        />
                                                        <Typography mb={2} ></Typography>
                                                    </>}

                                                    <FormLabel required>What is the total collaboration value per influencer for this campaign?</FormLabel>
                                                    <NormalInput
                                                        name={"budgetbudgetPerInfluencer"}
                                                        type={"text"}
                                                        isRequired={true}
                                                        focused={false}
                                                        label={("")}
                                                        sizeval="medium"
                                                        values={values.budgetbudgetPerInfluencer}
                                                        placeholder={"Ex. 50000"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.budgetbudgetPerInfluencer) && errors.budgetbudgetPerInfluencer}
                                                        helperText={touched.budgetbudgetPerInfluencer && errors.budgetbudgetPerInfluencer}
                                                    />
                                                    <Typography mb={2} ></Typography>
                                                    <FormLabel required>No. of Creators</FormLabel>
                                                    <NormalInput
                                                        name={"noOfCreators"}
                                                        type={"text"}
                                                        isRequired={true}
                                                        focused={false}
                                                        label={("")}
                                                        sizeval="medium"
                                                        values={values.noOfCreators}
                                                        placeholder={"Ex. 50000"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.noOfCreators) && errors.noOfCreators}
                                                        helperText={touched.noOfCreators && errors.noOfCreators}
                                                    />
                                                </Grid>

                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10">
                                                    <Typography mb={3} variant="h4" component="h4">Creator Preferences</Typography>
                                                    <FormControl>
                                                        <Typography mb={2} className="fs-6" variant="h6" component="h6">Creator Gender</Typography>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="gender"
                                                            value={values.gender}
                                                            onChange={handleChange}
                                                        >
                                                            {GENDER.map((v, i) => {
                                                                return <FormControlLabel key={i} value={v} control={<Radio />} label={v} />
                                                            })}

                                                        </RadioGroup>
                                                    </FormControl>

                                                    <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Creator Location <span className="impAstric">*</span></Typography>
                                                    {/* <AutoSuggestion
                                                        multiple={true}
                                                        disableClearable={true}
                                                        values={values?.creatorLocation}
                                                        className="auto-chips-cover"
                                                        label={""}
                                                        onChange={(_e, newValue) => {
                                                            handleChange({
                                                                target: { name: "creatorLocation", value: newValue },
                                                            });
                                                        }}
                                                        placeholder="Ex. Mumbai, Delhi"
                                                        defaultValue={values?.creatorLocation || []}
                                                        id={`users-${values?.creatorLocation?.length}`}
                                                        key={step + values?.creatorLocation?.length > 0}
                                                        size="small"
                                                        name="creatorLocation"
                                                        error={Boolean(touched.creatorLocation) && errors.creatorLocation}
                                                        helperText={touched.creatorLocation && errors.creatorLocation}
                                                        options={citiesInIndia}
                                                        isOptionEqualToValue={(option, value) => {
                                                            if (
                                                                value === "" ||
                                                                option === "" ||
                                                                value === option
                                                            )
                                                                return option;
                                                        }}
                                                        getOptionLabel={(option) =>
                                                            option || "no_option_found"
                                                        }
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <Box
                                                                    component="li"
                                                                    sx={{
                                                                        "& > img": { mr: 2, flexShrink: 0 },
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    {option}
                                                                </Box>
                                                            );
                                                        }}
                                                    /> */}
                                                    <AutoSuggestion
                                                        multiple={true}
                                                        disableClearable={true}
                                                        values={values?.creatorLocation}
                                                        className="auto-chips-cover"
                                                        label={""}
                                                        onChange={(_e, newValue) => {
                                                            handleChange({
                                                                target: { name: "creatorLocation", value: newValue },
                                                            });
                                                        }}
                                                        placeholder="Ex. Mumbai, Delhi"
                                                        key={step + values?.creatorLocation?.length || formData.mode}
                                                        defaultValue={values?.creatorLocation || []}
                                                        id={`users-${values?.creatorLocation?.length}`}
                                                        onModalClose={() => { }}
                                                        size="small"
                                                        name="creatorLocation"
                                                        error={Boolean(touched.creatorLocation) && errors.creatorLocation}
                                                        helperText={touched.creatorLocation && errors.creatorLocation}
                                                        options={citiesInIndia}
                                                        isOptionEqualToValue={(option, value) => {
                                                            if (
                                                                value === "" ||
                                                                option === "" ||
                                                                value === option
                                                            )
                                                                return option;
                                                        }}
                                                        getOptionLabel={(option) =>
                                                            option || "no_option_found"
                                                        }
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <Box
                                                                    component="li"
                                                                    sx={{
                                                                        "& > img": { mr: 2, flexShrink: 0 },
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    {option}
                                                                </Box>
                                                            );
                                                        }}
                                                    />
                                                    <Typography mb={2} mt={3} className="fs-6" variant="h6" component="h6">Influencer Type <span className="impAstric">*</span></Typography>
                                                    <FormGroup style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)' }}>
                                                        {FOLLOWERS_ARRAY.map((v, i) => {
                                                            return <FormControlLabel style={{ marginBottom: '10px' }} key={i} control={<Checkbox name="influncerType" value={v.value} checked={values.influncerType?.includes(v.value)} onChange={handleChange} />} label={v.label} />
                                                        })}
                                                    </FormGroup>
                                                    {errors.influncerType && touched.influncerType && (
                                                        <p
                                                            className="error"
                                                        >
                                                            {`${errors?.influncerType}`}
                                                        </p>
                                                    )}

                                                    <Grid item mt={1} mb={2} xs={12} lg={12} md={12} sm={12}></Grid>
                                                    <Grid item mt={1} mb={2} xs={12} lg={12} md={12} sm={12}>
                                                        <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Content Categories</Typography>
                                                        <Stack direction="row" spacing={6}>
                                                            <Box width={"40%"} >
                                                                <Typography className="fs-6 mb-1 d-block" variant="contained" component="body2" >Available Categories <span className="impAstric">*</span></Typography>
                                                                <List dense={true} className="multiSelect css-h4y409-MuiList-root1" >
                                                                    {categories?.length > 0 && categories?.map((v, i) => {
                                                                        return <ListItem key={i} sx={{ cursor: "pointer", borderBottom: '1px solid #AAAAAA59', background: values.selectedCategories?.includes(v.option) ? "#AAAAAA59" : "" }} >
                                                                            <ListItemText
                                                                                name={"selectedCategories"}
                                                                                value={v.option}
                                                                                onClick={() => {
                                                                                    const selectedCategoryIndex = values.selectedCategories?.indexOf(v.option);
                                                                                    if (selectedCategoryIndex === -1) {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "selectedCategories",
                                                                                                value: [...values?.selectedCategories, v.option]
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        const updatedSelectedCategories = values?.selectedCategories ? [...values?.selectedCategories] : [];
                                                                                        updatedSelectedCategories.splice(selectedCategoryIndex, 1);
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "selectedCategories",
                                                                                                value: updatedSelectedCategories
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                primary={v.option}
                                                                                secondary={''}
                                                                            />

                                                                        </ListItem>
                                                                    })}

                                                                </List>

                                                                <p></p>
                                                                {errors.selectedCategories && touched.selectedCategories && (
                                                                    <p
                                                                        className="error"
                                                                    >
                                                                        {`${errors?.selectedCategories}`}
                                                                    </p>
                                                                )}
                                                            </Box>
                                                            <Box width={"40%"} >
                                                                <Typography variant="contained" component="body2" >Selected Categories <span className="impAstric">*</span></Typography>
                                                                <List dense={true} className="multiSelect css-h4y409-MuiList-root1" >
                                                                    {values?.selectedCategories?.map((v, i) => {
                                                                        return <ListItem key={i} sx={{ borderBottom: '1px solid #AAAAAA59', background: "#AAAAAA59" }} >
                                                                            <ListItemText
                                                                                primary={v}
                                                                                secondary={''}
                                                                            />

                                                                        </ListItem>
                                                                    })}



                                                                </List>
                                                            </Box>
                                                        </Stack>

                                                    </Grid>

                                                </Grid>
                                                <Grid item pl={2} pb={2} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10">
                                                    <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Campaign Applying Preferences</Typography>
                                                    <FormGroup style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)' }}>
                                                        <FormControlLabel control={<Checkbox name="campaignApplyPref" checked={values.campaignApplyPref?.includes("campaignApplyPref")} value="campaignApplyPref" onChange={handleChange} />} label="Apply by Everyone" />

                                                    </FormGroup>
                                                </Grid>




                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10" >
                                                    <Typography variant="h4" component="h6" mb={3}>Audience Preferences</Typography>

                                                    <FormControl>
                                                        <Typography id="demo-row-radio-buttons-group-label" mb={2} className="fs-6" variant="h6" component="h6">Gender</Typography>
                                                        <RadioGroup
                                                            style={{ paddingBottom: '16px' }}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="audioPrefGender"
                                                            value={values.audioPrefGender}
                                                            onChange={handleChange}
                                                        >
                                                            {GENDER.map((v, i) => {
                                                                return <FormControlLabel key={i} value={v} control={<Radio />} label={v} />
                                                            })}
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <Grid item mt={1} xs={12} lg={12} md={12} sm={12}></Grid>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                                            <FormLabel>Age Group</FormLabel>
                                                            <BasicSelect
                                                                required={false}
                                                                name={`ageGroup`}
                                                                label={""}
                                                                values={values.ageGroup || "18-25"}
                                                                handleChange={handleChange}
                                                                options={AGE_GROUP.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item} key={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    );
                                                                })}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                                            <FormLabel required>Location</FormLabel>
                                                            <AutoSuggestion
                                                                multiple={true}
                                                                disableClearable={true}
                                                                values={values?.location}
                                                                className="auto-chips-cover"
                                                                label={""}
                                                                onChange={(_e, newValue) => {
                                                                    handleChange({
                                                                        target: { name: "location", value: newValue },
                                                                    });
                                                                }}
                                                                key={step + values?.location?.length}
                                                                defaultValue={values?.location || []}
                                                                id={`users-${values?.location?.length}`}
                                                                onModalClose={() => { }}
                                                                size="small"
                                                                name="location"
                                                                error={Boolean(touched.location) && errors.location}
                                                                helperText={touched.location && errors.location}
                                                                options={citiesInIndia}
                                                                isOptionEqualToValue={(option, value) => {
                                                                    if (
                                                                        value === "" ||
                                                                        option === "" ||
                                                                        value === option
                                                                    )
                                                                        return option;
                                                                }}
                                                                getOptionLabel={(option) =>
                                                                    option || "no_option_found"
                                                                }
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <Box
                                                                            component="li"
                                                                            sx={{
                                                                                "& > img": { mr: 2, flexShrink: 0 },
                                                                                fontFamily: "Poppins",
                                                                            }}
                                                                            {...props}
                                                                        >
                                                                            {option}
                                                                        </Box>
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10" >
                                                    <Typography variant="h4" component="h6" mb={2}>Contact Details</Typography>
                                                    <FormLabel required>Sharing your phone number will help us reach you faster, we won't spam you.</FormLabel>
                                                    <NormalInput
                                                        name={"contactDetails"}
                                                        type={"text"}
                                                        isRequired={true}
                                                        focused={false}
                                                        label={("")}
                                                        sizeval="medium"
                                                        values={values.contactDetails}
                                                        placeholder={"Enter your phone number"}
                                                        onChange={(e) => {
                                                            if (e.target.value.length <= 10) {
                                                                handleChange(e);
                                                            } else {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        // onKeyDown={(e) => {
                                                        //     console.log("eeee", e.target.value)
                                                        // }}

                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.contactDetails) && errors.contactDetails}
                                                        helperText={touched.contactDetails && errors.contactDetails}
                                                    />
                                                </Grid>
                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12}  >
                                                    <Typography variant="h4" component="h6" mb={2}>Campaign Terms & Conditions</Typography>
                                                    <div style={{ overflow: 'auto', overflowY: 'auto', width: '100%' }}>
                                                        <Editor value={values.campaignTNC} onChange={(e) => {
                                                            handleChange({
                                                                target: {
                                                                    name: "campaignTNC",
                                                                    value: e.target.value
                                                                }
                                                            })
                                                        }} />
                                                    </div>

                                                </Grid>
                                            </Grid>


                                        </Grid>
                                        <Grid className='ps-xl-5 ps-lg-5 ps-md-5 ps-sm-3 ps-3' item xs={4} lg={4} md={4} sm={4}  >
                                            <Typography variant="h4" component="h4" style={{ textAlign: 'center' }}
                                            >XXX</Typography>

                                            <Typography variant="caption" className='fs-6 mb-3' component="h6" py={1} px={5} style={{ textAlign: 'center', overflow: "auto" }}>
                                                This section is a representation of how the influencers will see your campaign
                                            </Typography>
                                            <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="" style={{ border: '1px solid #AAAAAA', borderRadius: '10px' }} >
                                                <Typography variant="h4" component="h4" className="mb-3">Campaign Recommendation</Typography>
                                                <List className="listView">
                                                    <ListItem m={0} style={{ padding: '7px 0px' }} >
                                                        <ListItemText primary="No. of Influencers:" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText primary="N/A" style={{ textAlign: 'right' }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem m={0} style={{ padding: '7px 0px' }}>
                                                        <ListItemText primary="Avg. Reach:" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText primary="N/A" style={{ textAlign: 'right' }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem m={0} style={{ padding: '7px 0px' }}>
                                                        <ListItemText primary="Total:" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText primary="N/A" style={{ textAlign: 'right' }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <ListItem m={0} style={{ padding: '7px 0px' }}>
                                                        <ListItemText primary="CPV (Cost per view):" />
                                                        <ListItemSecondaryAction>
                                                            <ListItemText primary="N/A" style={{ textAlign: 'right' }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </List>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid item p={3} xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">
                                        <Stack direction={"row"} spacing={1}>
                                            <button
                                                className="btnSecondary"
                                                onClick={() => {
                                                    dispatch(updateCampaign({ step: 3 }));
                                                    navigate("/campaign/info")
                                                }}
                                            >
                                                Back
                                            </button>
                                            <button
                                                className="btnPrimary"
                                                type="submit"
                                            // disabled={!(isValid && dirty)}
                                            >
                                                Set Budget & Targeting
                                            </button>
                                        </Stack>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid >
            </Box>
        </Stack>
    </>
}
export default BudgetTarget;