import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthTokenFromLS } from './utils/common';

const PublicRoute = ({ element }) => {
    const accessToken = getAuthTokenFromLS();
    console.log("accessToken", accessToken);
    return accessToken ? <Navigate to="/" replace /> : element;
};

export default PublicRoute;