import {
    Box,
    Button,
    Checkbox,
    Chip,
    Stack,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addRemoveGroupFromNotification } from "../action";
import { updateNotification } from "../notificationSlice";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isSticky, listing, selectedRows } = props;
    const dispatch = useDispatch();
    const {
        allRemoveCheckbox
    } = useSelector((state) => state.notificationSlice);
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Group Name",
            type: "",
        },
        {
            id: "description",
            numeric: false,
            disablePadding: true,
            label: "Group Description",
            type: "",
        },
        {
            id: "noofUsers",
            numeric: false,
            disablePadding: true,
            label: "No. of Users",
            type: "",
        },

        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Actions",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort ${isSticky} `}>
            <TableRow>
                <TableCell>
                    <Checkbox
                        //checked={selectedCheckbox.length === listing?.length}
                        checked={allRemoveCheckbox}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(updateNotification({ selectedCheckbox: listing.map((v) => v.id), allRemoveCheckbox: true }))
                            } else {
                                dispatch(updateNotification({ selectedCheckbox: [], allRemoveCheckbox: false }))
                            }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </TableCell>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function SelectedGroupTable() {
    const navigate = useNavigate();
    const states = useSelector((state) => state.app);
    const { id } = useParams();
    const [order, setOrder] = useState("asc");
    const [ids, setIds] = useState([]);
    const dispatch = useDispatch();
    const {
        sort_key,
        selectedRows,
        selectedCheckbox
    } = useSelector((state) => state.notificationSlice);
    console.log("selectedRows", selectedRows)

    return (
        <>
            <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                <div className='allFilter campMgmt'></div>
                <div className='allFilter campMgmt'>
                    {selectedCheckbox.length > 1 &&
                        <Button variant="contained" color="error" onClick={() => {
                            dispatch(addRemoveGroupFromNotification(id, "remove", selectedRows.filter((v) => !selectedCheckbox.includes(v.id))));
                            dispatch(updateNotification({ selectedRows: selectedRows.filter((v) => !selectedCheckbox.includes(v.id)), selectedCheckbox: [], allRemoveCheckbox: false }));

                        }}>Remove Users</Button>}
                </div>
            </div>
            <TableContainer sx={{ maxHeight: 550 }} >
                <Table
                    stickyHeader aria-label="sticky table"
                >
                    <EnhancedTableHead
                        // numSelected={selected.length}

                        order={order}
                        orderBy={sort_key}
                        listing={selectedRows}
                        setIds={setIds}
                        ids={ids}
                        selectedRows={selectedRows?.map((v) => v.id)}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={() => { }}
                        //rowCount={rows.length}
                        numSelected={0}
                        onSelectAllClick={function (

                        ) {
                            throw new Error("Function not implemented.");
                        }}
                    //order={"desc"}
                    />
                    <TableBody>
                        {selectedRows && selectedRows?.length ? (
                            selectedRows?.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row?.id}
                                        className={"tableRow"}
                                        style={{ userSelect: "text" }}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedCheckbox?.includes(row.id)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        dispatch(updateNotification({ selectedCheckbox: [...selectedCheckbox, row.id] })) //selectedRows: [...(selectedRows || []), row]
                                                    } else {
                                                        dispatch(updateNotification({ selectedCheckbox: selectedCheckbox.filter((v) => v !== row.id) })) //, selectedRows: selectedRows.filter((v) => v.id !== row.id)
                                                    }

                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />

                                        </TableCell>

                                        <TableCell component="td" scope="row">
                                            {row?.name || "N/A"}

                                        </TableCell>

                                        <TableCell align="left">
                                            {row?.description || "N/A"}

                                        </TableCell>
                                        <TableCell align="left">
                                            {row.noOfUsers || 0}
                                        </TableCell>
                                        <TableCell sx={{ cursor: "pointer" }}>
                                            <Stack direction={"row"} gap={2}>
                                                <div className="actBtnGrps">
                                                    <Chip size="small" label="Remove" onClick={() => {
                                                        dispatch(updateNotification({ selectedRows: selectedRows.filter((v) => v.id !== row.id) }));
                                                        dispatch(addRemoveGroupFromNotification(id, "remove", [row.id]));
                                                    }} color="error" variant="outlined" />

                                                </div>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow colSpan={10}>
                                <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                    <Typography
                                        color="inherit"
                                        variant="subtitle1"
                                        alignItems={"center"}
                                        component="h3"
                                    >
                                        No Data found!
                                    </Typography>

                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

            </TableContainer>
        </>
    );
}
