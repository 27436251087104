import React from 'react'; // Import React
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeadingSection = ({ heading, backBtn = true }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={3}>
      <Typography variant="h4">{heading}</Typography>
      {backBtn && (
        <Button
          onClick={handleNavigate}
          disableRipple
          startIcon={
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                stroke="#0077FF"
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
              />
              <path
                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                stroke="#0077FF"
                strokeWidth="1.5" 
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        >
          Back
        </Button>
      )}
    </Stack>
  );
};

export default HeadingSection;
