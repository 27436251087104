import { FormLabel, Grid } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Formik } from 'formik';
import React from 'react';
import Image from 'react-bootstrap/Image';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logoIcon from '../assets/images/logoIcon.svg';
import NormalInput from '../components/inputs/normalInput';
import Toaster from '../components/toaster/toaster';
import Schema from '../schema';
import { forgotPassword } from './company/action';

const Forgot = () => {

    const initialValues = {
        email: "",
    }

    const dispatch = useDispatch()
    const navigate = useNavigate();

    return (
        <>
            <Toaster />
            <div className='loginPage signupbg wrap'>
                <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-start">
                            <div className="leftText">
                                <div className="maintagline">
                                    <span>Register to get</span>More with Inflyx
                                </div>
                                <ul className="reg_pointer">
                                    <li>🧠 Intelligent Matchmaking</li>
                                    <li>🕓 Historical Insights</li>
                                    <li>📝 Post Campaign Analysis</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center justify-content-start">
                            <div className="formSide w-100 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
                                <div className="formcvr">
                                    <div className="logoIcon text-center mb-3">
                                        <Image src={logoIcon} alt="Logo Icon" />
                                    </div>
                                    <h1 className="text-center mb-5">
                                        Forgot Your <span className="textGradient">Password</span>
                                    </h1>


                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={Schema.ForgotPassword}
                                        enableReinitialize={true}
                                        onSubmit={(values, { resetForm }) => {

                                            dispatch(forgotPassword(values.email, navigate))

                                            //resetForm()
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            touched,
                                            values,
                                            setFieldValue,
                                            isValid,
                                            setFieldError,
                                            dirty,
                                        }) => {

                                            return (
                                                <Form>
                                                    <Grid className="frm" container>
                                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                                            <FormLabel required focused={false}>Email ID</FormLabel>
                                                            <NormalInput
                                                                name={"email"}
                                                                type={"text"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.email) && errors.email}
                                                                helperText={touched.email && errors.email}
                                                            />
                                                        </Grid>
                                                        <button className="btnPrimary mt-3 w-100" type="submit">
                                                            Submit
                                                        </button>
                                                    </Grid>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Forgot