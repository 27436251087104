import { Search, Visibility } from "@mui/icons-material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../components/customTable";
import { useGetAllMyCampaignsQuery } from "../redux/api";
import { ROUTES } from "../utils/routes";


const MyCampaign = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState({});
  const [campaignData, setCampaignData] = React.useState({});
  const [duration, setDuration] = React.useState(dayjs());
  const [pagination, setPagination] = React.useState({ rows: 5, page: 0 });
  const [count, setCount] = React.useState(0);
  const { data } = useGetAllMyCampaignsQuery({
    offset: pagination.page * pagination.rows,
    limit: pagination.rows,
    filter: JSON.stringify(filter),
  });

  React.useEffect(() => {
    setCampaignData(data?.result.data);
    setCount(data?.result.count);
  });
  const filterOptions = {
    brands: ["demo1", "demo2"],
    postedOn: ["demo1", "demo2"],
    gender: ["Male", "Female", "LGBTQ", "Prefer not to say"],
    type: ["demo1", "demo2"],
    status: ["demo1", "demo2"],
  };

  const cols = [
    "name",
    "brand",
    "type",
    "duration",
    "product_name",
    "posted_on",
    "gender",
    "followers",
    "status",
  ];

  const handleSearch = (e) => {
    if (e.target.value) {
      setFilter({ ...filter, search: e.target.value });
    } else {
      setFilter({ ...filter, search: "" });
    }
  };

  const handleChange = (e) => {

    if (e.target.value == "All") {
      const updatedFilter = { ...filter };
      delete updatedFilter[e.target.name];
      setFilter(updatedFilter);
    } else {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    }
  };

  const handleResetFilter = () => {
    setFilter({});
  };

  const handlePageChange = (data) => {
    setPagination(data);
  };

  const tableData = [];
  if (campaignData && campaignData.length) {
    for (let key of campaignData) {
      tableData.push({
        name: key.name,
        brand: key.brandName,
        type: key.type,
        duration: key.duration,
        product_name: key.name,
        posted_on: key.postedOn,
        gender: key.gender,
        followers: key.followers,
        status: key.status,
        id: key.id
      })
    }
  }

  const Action = (row) => {
    const { id } = row;
    return (
      <>
        <IconButton
          sx={{ p: "4px" }}
          color="primary"
          onClick={() => navigate(`${ROUTES.EDIT_CAMPAIGN}`)}
        >
          <Visibility />
        </IconButton>
      </>
    );
  };
  return (
    <Stack direction={"column"} gap={3}>
      {/* <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} /> */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography className="page-title" variant="h1">
          My Campaigns
        </Typography>

        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(ROUTES.CREATE_CAMPAIGN)}
          >
            Create Campaign
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {/* Create component for filter */}
        <FormControl variant="outlined" sx={{ gap: 0.3 }}>
          <OutlinedInput
            placeholder="Search"
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
        <Stack direction={"row"} gap={2}>
          <Select
            value={filter.brand}
            name="brand"
            onChange={handleChange}
            displayEmpty
            defaultValue={""}
          >
            <MenuItem value="" disabled hidden>
              Brands
            </MenuItem>
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {filterOptions.brands.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filter.postedOn}
            name="postedOn"
            onChange={handleChange}
            displayEmpty
            defaultValue={""}
          >
            <MenuItem value="" disabled hidden>
              Posted on
            </MenuItem>
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {filterOptions.postedOn.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filter.type}
            name="type"
            onChange={handleChange}
            displayEmpty
            defaultValue={""}
          >
            <MenuItem value="" disabled hidden>
              Type
            </MenuItem>
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {filterOptions.type.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filter.gender}
            name="gender"
            onChange={handleChange}
            displayEmpty
            defaultValue={""}
          >
            <MenuItem value="" disabled hidden>
              Gender
            </MenuItem>
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {filterOptions.gender.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filter.status}
            name="status"
            onChange={handleChange}
            displayEmpty
            defaultValue={""}
          >
            <MenuItem value="" disabled hidden>
              Status
            </MenuItem>
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {filterOptions.status.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Duration"
                value={duration}
                name="duration"
                onChange={(newValue) => setDuration(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button
            onClick={handleResetFilter}
            sx={{
              padding: "0px",
              minWidth: "0px",
              borderRadius: "0px",
              border: "2px solid transparent",
              background: "transparent",
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <RotateLeftIcon />
          </Button>
        </Stack>
      </Stack>
      <CustomTable
        cols={cols}
        onPageChange={handlePageChange}
        data={tableData}
        actionComp={Action}
        count={count}
      />
    </Stack>
  );
};

export default MyCampaign;
