import { Box } from '@mui/material';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';



const PaymentStatus = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    console.log("11", searchParams.get("status"))
    const mandate_status = searchParams.get("mandate_status")
    console.log("testest", mandate_status)
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bgcolor="#f0f0f0"
        >

            <div className='callBackMsg'>

                {searchParams.get("status") == "CHARGED" ? <img src="/success2.png" alt="success" /> : <img src="/failed.png" alt="failed" />}
                <h4 className='capitalize-first-letter1'>
                    {searchParams.get("status") == "CHARGED" ? "Your payment is successful" : "Failed"}
                </h4>
                {/* <p>Thank you for your payment.</p> */}
                {/* <Typography variant="body1" color="textSecondary" align="center" paragraph>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text.
            </Typography> */}
                <button className='btnPrimary' onClick={() => {
                    if (mandate_status == null) {
                        navigate("/wallet");
                    } else {
                        navigate("/subscription/active");
                    }

                }}>
                    Back
                </button>
            </div>
        </Box>
    );
}
export default PaymentStatus;