import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "../components/Form";
import CustomizedSnackbars from "../components/snackbar";
import { useCreateInfluencerMutation } from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { ROUTES } from "../utils/routes";

const CreateInfluencer = () => {
  const dispatch = useDispatch();
  const [createInfluenecer, { data, isLoading }] =
    useCreateInfluencerMutation();
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const handleSubmit = (data) => {
    createInfluenecer(data);
  };
  React.useEffect(() => {
    if (data?.status == "SUCCESS") {

      setTimeout(() => {
        dispatch(updateCommonData({
          message: "User successfully created!",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        navigate(ROUTES.USER_MANAGEMENT);
      }, 1);
    } else {
      if (data?.status == "FAILURE") {
        dispatch(updateCommonData({
          message: data?.message || "Api error",
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
        // setSnackbar({
        //   message: data?.message,
        //   severity: "error",
        //   open: true,
        // });
      }
    }
  }, [isLoading]);
  return (
    <Box>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Create New Influencer
          </Typography>
          <Stack
            className="backText"
            direction={"row"}
            gap={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={handleClick}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography variant="h5" color={"primary"}>
              Back
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}
      >
        <Form onSubmit={handleSubmit} />
      </Box>
    </Box>
  );
};

export default CreateInfluencer;
