import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { Alert } from "../../utils/Alert";
import { api } from "../../utils/api";
import { encodedURI, removeEmptyValues } from "../../utils/common";
import { endpoints } from "../../utils/endpoints";
import { updateDashboard } from "./dashboardSlice";


export const getInfluencerListFordashboard = () => {
    return (dispatch, getState) => {
        const { limit, page, filters, search } = getState().dashboardSlice;
        const filter = encodedURI(removeEmptyValues(filters))
        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.dashboardInfluencerList,
            `?limit=${limit}&offset=${page * limit}&filter=${filter}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === "FAILURE") {
                    dispatch(
                        updateDashboard({
                            list: [], totalCount: 0, totalPages: 0
                        })
                    );
                    dispatch(updateCommonData({
                        message: respData.data.result.message,
                        title: "Insufficient Credits",
                        img: "/sub_exp.png",
                        status: true,
                        getPlanButton: true
                    }));
                } else {
                    dispatch(
                        updateDashboard({
                            list: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count, exceedMsg: ""
                        })
                    );
                }

            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}

export const checkCreditOnDashboard = (navigate) => {
    return (dispatch, getState) => {
        const { filters } = getState().dashboardSlice;
        const filter = encodedURI(removeEmptyValues(filters))
        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.dashboardInfluencerList,
            `?limit=10&offset=0&filter=${filter}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === "FAILURE") {
                    dispatch(updateCommonData({
                        message: respData.data.result.message,
                        title: "Insufficient Credits",
                        img: "/sub_exp.png",
                        status: true,
                        getPlanButton: true
                    }));

                } else {
                    dispatch(
                        updateDashboard({
                            list: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count, exceedMsg: ""
                        })
                    );
                    navigate("/dashboard/listing")
                }

            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}