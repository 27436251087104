import InfoIcon from '@mui/icons-material/Info';
import { Box, FormControl, FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, Typography, styled } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import Editor from 'react-simple-wysiwyg';
import AutoSuggestion from "../../components/autocomplete";
import NormalInput from "../../components/inputs/normalInput";
import NumberInput from "../../components/inputs/normalInput/number";
import CustomTextArea from "../../components/inputs/textarea";
import BasicSelect from "../../components/select";
import Schema from "../../schema";
import { COMPANY_QUE_FLOW, getUserType, setCompanyName } from "../../utils/common";
import { COMPANY, FONT_PREFERENCES, INFLUENCER_NICHE, TWO_FACTOR_AUTH_VALUE, citiesInIndia } from "../../utils/constant";
import { createCompany } from "./action";
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const AddEditCompany = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { paramMasterList } = useSelector((state) => state.commonSlice);
    const [data, setData] = useState([]);


    const [fontReference, setFontReference] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    useEffect(() => {

        if (paramMasterList?.length > 0) {
            let para = paramMasterList;
            setData(JSON.parse(para?.find((v) => v.parameterkey === INFLUENCER_NICHE)?.value));
            setFontReference(JSON.parse(para?.find((v) => v.parameterkey === FONT_PREFERENCES)?.value));
        }

    }, [paramMasterList])


    let brandMsg = state?.brandValueAndMessaging || {};

    const initialValues = {
        companyName: state?.companyName || '',
        contactPersonName: state?.contactPersonName || '',
        phoneNumber: state?.phoneNumber || '',
        email: state?.email || '',
        instagram: state?.socialMedia?.instagram?.length > 0 ? ["instagram"] : [],
        instagramInfo: state?.socialMedia?.instagram || [],
        linkedin: state?.socialMedia?.linkedin?.length > 0 ? ["linkedin"] : [],
        linkedinInfo: state?.socialMedia?.linkedin || [],
        youtube: state?.socialMedia?.youtube?.length > 0 ? ["youtube"] : [],
        youtubeInfo: state?.socialMedia?.youtube || [],
        facebook: state?.socialMedia?.facebook?.length > 0 ? ["facebook"] : [],
        facebookInfo: state?.socialMedia?.facebook || [],
        geoPreferences: state?.targetAudience?.geoPreferences || [],
        niche: state?.brandBasics?.niche?.length > 0 ? state?.brandBasics?.niche?.map((v) => {
            return { "option": v }
        }) : [],
        idealDescription: state?.targetAudience?.description || '',
        description: state?.brandBasics?.description || '',
        primaryColor: state?.branding?.primaryColor || '',
        secondaryColor: state?.branding?.secondaryColor || '',
        font: state?.branding?.font || '',
        logo: state?.logo || "",
        messagesKey: brandMsg?.messages || '',
        toneOfVoice: brandMsg?.toneOfVoice || '',
        additionalInformation: brandMsg?.additionalInformation || '',
        password: '',
        confirmPassword: '',
        id: state?.userId || undefined,
        comingFrom: state?.comingFrom || "",
        twoFactorAuthType: state?.twoFactorAuthType || "",
        keyMessages: state?.keyMessages || "",
        brandTone: state?.brandTone || "",
        influencersCampaign: state?.influencersCampaign || "",
        lastMonthInfluencers: state?.lastMonthInfluencers || "",
        support: state?.support || ""
    }
    const handleKeyDown = (event) => {
        // Prevent form submission when Enter key is pressed
        if (event.key === 'Enter' && event.target.type !== 'button') {
            event.preventDefault();
        }
    };
    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    {!state?.comingFrom ? <>
                        <Typography variant="h1" className="page-title">
                            {state?.userId ? "Edit" : "Create"} Company
                        </Typography>

                        <Stack
                            className="backText"
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => { }}
                        >
                            <svg
                                width="16"
                                height="12"
                                viewBox="0 0 16 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                    stroke="#0077FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                    stroke="#0077FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <Typography variant="h5" color={"primary"} onClick={() => navigate("/companies")}>
                                Back
                            </Typography>
                        </Stack></> : <Typography variant="h1" className="page-title">
                        Company Profile
                    </Typography>}
                </Stack>
            </Stack>

            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={Schema.Company}
                    enableReinitialize
                    onSubmit={(values, { resetForm }) => {

                        if (state?.userId !== undefined) {
                            values.userId = state?.userId;
                        }
                        if (state?.comingFrom) {
                            setCompanyName(values.companyName);
                        }

                        dispatch(createCompany(values, navigate));
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        setFieldError,
                        dirty,
                    }) => {
                        console.log({ errors })
                        return (

                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                if (Object.keys(errors).length > 0) {

                                    document?.getElementsByName(Object?.keys(errors)[0])[0]?.focus();
                                }
                                handleSubmit();
                            }} onKeyDown={handleKeyDown}>
                                <Grid container spacing={2} p={3} bgcolor={"white"}>
                                    {/* <Grid item xs={3} lg={3} md={3} sm={3}>
                                <ImageUpload />
                            </Grid> */}

                                    <Grid item xs={12} lg={4} md={4} sm={6}>
                                        <FormLabel required>Company Name</FormLabel>

                                        <NormalInput
                                            name={"companyName"}
                                            isRequired={true}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.companyName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.companyName) && errors.companyName}
                                            helperText={touched.companyName && errors.companyName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={6}>
                                        <FormLabel required>Contact Person Name</FormLabel>
                                        <NormalInput
                                            name={"contactPersonName"}
                                            isRequired={true}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.contactPersonName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.contactPersonName) && errors.contactPersonName}
                                            helperText={touched.contactPersonName && errors.contactPersonName}
                                        />
                                    </Grid>


                                    <Grid item xs={12} lg={4} md={4} sm={6}>
                                        <FormLabel required>Email</FormLabel>
                                        <NormalInput
                                            name={"email"}
                                            isRequired={true}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.email) && errors.email}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={6}>
                                        <FormLabel required>Phone Number</FormLabel>
                                        <NumberInput
                                            name={"phoneNumber"}
                                            isRequired={true}
                                            type={"number"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.phoneNumber}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 10) {
                                                    handleChange(e);
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.phoneNumber) && errors.phoneNumber}
                                            helperText={touched.phoneNumber && errors.phoneNumber}
                                        />
                                    </Grid>

                                    {getUserType() == COMPANY && <Grid item xs={12} lg={4} md={4} sm={6}>
                                        <Box display="flex" alignItems="center" >
                                            <FormLabel>2-Factor Authentication &nbsp;<Tooltip title="Select if you want to enable 2FA and how you want to receive the OTPs" arrow>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip></FormLabel>

                                        </Box>
                                        <BasicSelect
                                            required={false}
                                            name={`twoFactorAuthType`}
                                            label={("")}
                                            values={values.twoFactorAuthType}
                                            handleChange={handleChange}
                                            options={TWO_FACTOR_AUTH_VALUE?.map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                            handleBlur={handleBlur}
                                        />

                                    </Grid>}

                                    {!state?.userId && <>
                                        <Grid item xs={12} lg={4} md={4} sm={6}>
                                            <FormLabel required>Password</FormLabel>
                                            <NormalInput
                                                name={"password"}
                                                isRequired={true}
                                                type={showPassword ? "text" : "password"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                showPassword={showPassword}
                                                error={Boolean(touched.password) && errors.password}
                                                helperText={touched.password && errors.password}
                                                handleViewPassword={() => setShowPassword((prev) => !prev)}
                                                isEndAdornment={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={4} sm={6}>
                                            <FormLabel required>Confirm Password</FormLabel>
                                            <NormalInput
                                                name={"confirmPassword"}
                                                isRequired={true}
                                                type={showConfirmPassword ? "text" : "password"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.confirmPassword) && errors.confirmPassword}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                handleViewPassword={() => setShowConfirmPassword((prev) => !prev)}
                                                isEndAdornment={true}
                                                showPassword={showConfirmPassword}
                                            />
                                        </Grid>
                                    </>
                                    }
                                </Grid >
                                <hr />
                                <Grid container spacing={3} p={3}>
                                    <Grid item xs={12} p={0} lg={12} md={12} sm={12}>
                                        <Typography variant="h4">
                                            Company Basics
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                        <FormLabel required>Industry/Niche</FormLabel>
                                        <AutoSuggestion
                                            multiple={true}
                                            required={true}
                                            disableClearable={true}
                                            values={values?.niche}
                                            className="auto-chips-cover"
                                            label={""}
                                            onChange={(_e, newValue) => {
                                                handleChange({
                                                    target: { name: "niche", value: newValue },
                                                });
                                            }}
                                            placeholder="Select Industry/Niche"
                                            defaultValue={values?.niche}
                                            id={`users-${values?.niche?.length}`}
                                            size="small"
                                            name="niche"
                                            error={Boolean(touched.niche) && errors.niche}
                                            helperText={touched.niche && errors.niche}
                                            options={data} //data & JSON.parse(data)
                                            isOptionEqualToValue={(option, value) => {
                                                if (
                                                    value === "" ||
                                                    option === "" ||
                                                    value === option
                                                )
                                                    return option;
                                            }}
                                            getOptionLabel={(option) =>
                                                option.option || "no_option_found"
                                            }
                                            renderOption={(props, option) => {
                                                return (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            "& > img": { mr: 2, flexShrink: 0 },
                                                            fontFamily: "Poppins",
                                                        }}
                                                        {...props}
                                                    >
                                                        {option.option}
                                                    </Box>
                                                );
                                            }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                        <FormLabel>Brief Description of Products/Services</FormLabel>
                                        <CustomTextArea

                                            name={"description"}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.description}
                                            onChange={handleChange}
                                            placeholder=" "
                                            multiline={true}
                                            style={{ m: 0, p: 0, height: '90px' }}
                                        />
                                        {errors.description && touched.description && (
                                            <p
                                                className="error"
                                            >
                                                {`${errors?.description}`}
                                            </p>
                                        )}
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container spacing={3} p={3}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4">
                                            Target Audience
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                        <FormLabel required>Geographic Preferences (Regions/Countries):</FormLabel>
                                        {/* <StateCityDropdown /> */}

                                        <AutoSuggestion
                                            multiple={true}
                                            //disabled={values?.geoPreferences.includes(" Pan India")}
                                            disableClearable={true}
                                            values={values?.geoPreferences}
                                            className="auto-chips-cover"
                                            label={""}
                                            onChange={(_e, newValue) => {
                                                handleChange({
                                                    target: { name: "geoPreferences", value: newValue },
                                                });
                                            }}
                                            placeholder="Ex. Mumbai, Delhi"
                                            defaultValue={values?.geoPreferences}
                                            id={`users-${values?.geoPreferences?.length}`}
                                            size="small"
                                            name="geoPreferences"
                                            error={Boolean(touched.geoPreferences) && errors.geoPreferences}
                                            helperText={touched.geoPreferences && errors.geoPreferences}
                                            options={citiesInIndia}
                                            isOptionEqualToValue={(option, value) => {
                                                if (
                                                    value === "" ||
                                                    option === "" ||
                                                    value === option
                                                )
                                                    return option;
                                            }}
                                            getOptionLabel={(option) =>
                                                option || "no_option_found"
                                            }
                                            renderOption={(props, option) => {
                                                return (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            "& > img": { mr: 2, flexShrink: 0 },
                                                            fontFamily: "Poppins",
                                                        }}
                                                        {...props}
                                                    >
                                                        {option}
                                                    </Box>
                                                );
                                            }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={12}>
                                        <FormLabel>Describe Your Ideal Customer:</FormLabel>
                                        <Editor value={values.idealDescription} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "idealDescription",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container spacing={3} p={3}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4">
                                            Visual Company
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel>Logo or Visual Assets</FormLabel>

                                        {/* <Typography width={"200px"}  >

                                            <ImageUpload name="logo" handleFile={(event) => {
                                                handleChange({
                                                    target: {
                                                        name: "logo",
                                                        value: event
                                                    }
                                                })
                                            }} label="" fileUrl={state?.logo || ""} fileObject={values.logo} />
                                        </Typography>
                                        <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 180x180 and 300x300.</small>



                                        {/* 
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            sx={{ borderRadius: 0, background: "#00000080" }}
                                            borderRadius={0}
                                        >
                                            Upload file
                                            <VisuallyHiddenInput type="file" onChange={(e) => {
                                                handleChange({
                                                    target: {
                                                        name: "logo",
                                                        value: e.target.files[0]
                                                    }
                                                })
                                            }} />

                                        </Button> */}

                                        <div
                                            style={{
                                                border: '1px solid #aaa',
                                                borderRadius: '8px',
                                                padding: '3px',
                                                height: '38px'
                                            }}
                                        >
                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <Button
                                                    component="label"

                                                    variant="contained"
                                                    tabIndex={-1}
                                                    style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.3)", color: " rgba(0, 0, 0, 0.5)", fontSize: '13px', fontWeight: '500', lineHeight: '17.2px' }}
                                                >
                                                    Choose File
                                                </Button>
                                                <input
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        cursor: 'pointer',
                                                    }}
                                                    type="file"
                                                    onChange={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: "logo",
                                                                value: e.target.files[0]
                                                            }
                                                        })
                                                    }}
                                                />

                                                {values.logo?.name && <p className="m-0">{values.logo?.name}</p>}
                                            </div>
                                        </div>
                                        <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 180x180 and 300x300. Image formats like JPEG and PNG</small>

                                        {errors.logo && touched.logo && (
                                            <p
                                                className="error m-0"
                                            >
                                                {`${errors?.logo}`}
                                            </p>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel required>Primary Color</FormLabel>
                                        <NormalInput
                                            isRequired={true}
                                            name={"primaryColor"}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.primaryColor}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.primaryColor) && errors.primaryColor}
                                            helperText={touched.primaryColor && errors.primaryColor}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel>Secondary Color</FormLabel>
                                        <NormalInput
                                            name={"secondaryColor"}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.secondaryColor}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.secondaryColor) && errors.secondaryColor}
                                            helperText={touched.secondaryColor && errors.secondaryColor}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel required>Font Preferences</FormLabel>
                                        <BasicSelect
                                            required={false}
                                            name={`font`}
                                            label={("")}
                                            values={values.font}
                                            handleChange={handleChange}
                                            options={fontReference?.map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                            handleBlur={handleBlur}
                                        />
                                        {errors.font && touched.font && (
                                            <p
                                                className="error"

                                            >
                                                {`${errors?.font}`}
                                            </p>
                                        )}
                                    </Grid>
                                </Grid>
                                <hr />
                                <Box p={3}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Social Media Presence
                                        </Typography>

                                    </Grid>
                                    <Grid container pb={2} pl={2} width={"70%"} rowGap={1} borderRadius={2} columnGap={2} bgcolor={"#EEEEEE"} >

                                        <Grid item xs={12} lg={12} md={12} sm={12} mt={2} >
                                            <Typography variant="h6" gutterBottom
                                                style={{
                                                    color: 'rgba(0,0,0,0.7)',
                                                    fontSize: '13px'
                                                }}
                                            >
                                                Add Platform URL's  Existing Social Media Profiles (Please provide links):
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={values.instagram.includes("instagram")} value={"instagram"} onChange={(e) => {
                                                        handleChange(e);
                                                        if (e.target.checked) {
                                                            handleChange({
                                                                target: {
                                                                    name: "instagramInfo",
                                                                    value: [{ userName: "", url: "" }]
                                                                }
                                                            });
                                                        } else {
                                                            handleChange({
                                                                target: {
                                                                    name: "instagramInfo",
                                                                    value: []
                                                                }
                                                            });
                                                        }
                                                    }} name="instagram" />
                                                }
                                                label="Instagram"
                                            />
                                        </Grid>

                                        {values.instagram.length > 0 &&
                                            <FieldArray
                                                name={`instagramInfo`}
                                                render={(array) => (
                                                    <>{values?.instagramInfo?.map(
                                                        (post, index) => (
                                                            <>
                                                                <Grid item xs={12} lg={3} md={3} sm={3}>
                                                                    <FormLabel required>Username  {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`instagramInfo.[${index}].userName`}
                                                                        type={"text"}
                                                                        isRequired={true}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.instagramInfo[index].userName
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}

                                                                    />

                                                                    <Field
                                                                        name={`instagramInfo.[${index}].userName`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `instagramInfo.[${index}].userName`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `instagramInfo.[${index}].userName`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={12} lg={7.5} md={7.5} sm={7.5}>
                                                                    <FormLabel required>URL  {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`instagramInfo.[${index}].url`}
                                                                        type={"text"}
                                                                        isRequired={true}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.instagramInfo[index].url
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}

                                                                    />
                                                                    <Field
                                                                        name={`instagramInfo.[${index}].url`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `instagramInfo.[${index}].url`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `instagramInfo.[${index}].url`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={0.5} mt={3} lg={0.5} md={0.5} sm={0.5} className="d-flex" gap={1}>
                                                                    {index === 0 ? <img src="/plus.png" onClick={() => {
                                                                        array.push({
                                                                            userName: "",
                                                                            url: "",
                                                                        });
                                                                    }} alt="plus" style={{ width: '28px', height: '28px' }} /> : <img src="/minus.png"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            array.remove(index);
                                                                        }} alt="minus" style={{ width: '28px', height: '28px' }} />}</Grid>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            />}
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Box
                                                mt={1}
                                                mb={1}
                                                sx={{ borderBottom: '1px solid #D9D9D9' }}
                                            >
                                            </Box>
                                        </Grid>
                                        {false && <>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={values.linkedin.includes("linkedin")} value={"linkedin"} onChange={(e) => {
                                                            handleChange(e);
                                                            if (e.target.checked) {
                                                                handleChange({
                                                                    target: {
                                                                        name: "linkedinInfo",
                                                                        value: [{ userName: "", url: "" }]
                                                                    }
                                                                });
                                                            } else {
                                                                handleChange({
                                                                    target: {
                                                                        name: "linkedinInfo",
                                                                        value: []
                                                                    }
                                                                });
                                                            }
                                                        }} name="linkedin" />
                                                    }
                                                    label="Linkedin"
                                                />
                                            </Grid>
                                            {values.linkedin.length > 0 && <FieldArray
                                                name={`linkedinInfo`}
                                                render={(array) => (
                                                    <>{values?.linkedinInfo?.map(
                                                        (post, index) => (
                                                            <>
                                                                <Grid item xs={12} lg={3} md={3} sm={3}>
                                                                    <FormLabel required>Username  {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`linkedinInfo.[${index}].userName`}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.linkedinInfo[index].userName
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}

                                                                    />

                                                                    <Field
                                                                        name={`linkedinInfo.[${index}].userName`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `linkedinInfo.[${index}].userName`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `linkedinInfo.[${index}].userName`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={12} lg={7.5} md={7.5} sm={7.5}>
                                                                    <FormLabel required >URL  {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`linkedinInfo.[${index}].url`}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.linkedinInfo[index].url
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}

                                                                    />
                                                                    <Field
                                                                        name={`linkedinInfo.[${index}].url`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `linkedinInfo.[${index}].url`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `linkedinInfo.[${index}].url`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={0.5} mt={4} lg={0.5} md={0.5} sm={0.5} className="d-flex" gap={1}>
                                                                    {index === 0 ? <img src="/plus.png" onClick={() => {
                                                                        array.push({
                                                                            userName: "",
                                                                            url: "",
                                                                        });
                                                                    }} alt="plus" style={{ width: '28px', height: '28px' }} /> : <img src="/minus.png"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            array.remove(index);
                                                                        }} alt="minus" style={{ width: '28px', height: '28px' }} />}</Grid>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            />}

                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <Box
                                                    mt={1}
                                                    mb={1}
                                                    sx={{ borderBottom: '1px solid #D9D9D9' }}
                                                >
                                                </Box>
                                            </Grid>
                                        </>}


                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={values.youtube.includes("youtube")} value={"youtube"} onChange={(e) => {
                                                        handleChange(e);
                                                        if (e.target.checked) {
                                                            handleChange({
                                                                target: {
                                                                    name: "youtubeInfo",
                                                                    value: [{ userName: "", url: "" }]
                                                                }
                                                            });
                                                        } else {
                                                            handleChange({
                                                                target: {
                                                                    name: "youtubeInfo",
                                                                    value: []
                                                                }
                                                            });
                                                        }
                                                    }} name="youtube" />
                                                }
                                                label="Youtube"
                                            />
                                        </Grid>
                                        <FieldArray
                                            name={`youtubeInfo`}
                                            render={(array) => (
                                                <>{values?.youtubeInfo?.map(
                                                    (post, index) => (
                                                        <>
                                                            <Grid item xs={12} lg={3} md={3} sm={3}>
                                                                <FormLabel required>Username  {index + 1}</FormLabel>
                                                                <NormalInput
                                                                    name={`youtubeInfo.[${index}].userName`}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    label={("")}
                                                                    sizeval="medium"
                                                                    isRequired={true}
                                                                    values={
                                                                        values.youtubeInfo[index].userName
                                                                    }
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}

                                                                />

                                                                <Field
                                                                    name={`youtubeInfo.[${index}].userName`}
                                                                    render={({ form }) => {
                                                                        const error = getIn(
                                                                            form.errors,
                                                                            `youtubeInfo.[${index}].userName`,
                                                                        );
                                                                        const touch = getIn(
                                                                            form.touched,
                                                                            `youtubeInfo.[${index}].userName`,
                                                                        );
                                                                        return touch && error ? (
                                                                            <p className="error">
                                                                                {error}
                                                                            </p>
                                                                        ) : null;
                                                                    }}
                                                                />

                                                            </Grid>

                                                            <Grid item xs={12} lg={7.5} md={7.5} sm={7.5}>
                                                                <FormLabel required >URL  {index + 1}</FormLabel>
                                                                <NormalInput
                                                                    name={`youtubeInfo.[${index}].url`}
                                                                    type={"text"}
                                                                    focused={false}
                                                                    label={("")}
                                                                    isRequired={true}
                                                                    sizeval="medium"
                                                                    values={
                                                                        values.youtubeInfo[index].url
                                                                    }
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}

                                                                />
                                                                <Field
                                                                    name={`youtubeInfo.[${index}].url`}
                                                                    render={({ form }) => {
                                                                        const error = getIn(
                                                                            form.errors,
                                                                            `youtubeInfo.[${index}].url`,
                                                                        );
                                                                        const touch = getIn(
                                                                            form.touched,
                                                                            `youtubeInfo.[${index}].url`,
                                                                        );
                                                                        return touch && error ? (
                                                                            <p className="error">
                                                                                {error}
                                                                            </p>
                                                                        ) : null;
                                                                    }}
                                                                />

                                                            </Grid>

                                                            <Grid item xs={0.5} mt={3} lg={0.5} md={0.5} sm={0.5} className="d-flex" gap={1}>
                                                                {index === 0 ? <img src="/plus.png" onClick={() => {
                                                                    array.push({
                                                                        userName: "",
                                                                        url: "",
                                                                    });
                                                                }} alt="plus" style={{ width: '28px', height: '28px' }} /> : <img src="/minus.png"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        array.remove(index);
                                                                    }} alt="minus" style={{ width: '28px', height: '28px' }} />}</Grid>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        />
                                        {false && <>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <Box
                                                    mt={1}
                                                    mb={1}
                                                    sx={{ borderBottom: '1px solid #D9D9D9' }}
                                                >
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={values.facebook.includes("facebook")} value={"facebook"} onChange={(e) => {
                                                            handleChange(e);
                                                            if (e.target.checked) {
                                                                handleChange({
                                                                    target: {
                                                                        name: "facebookInfo",
                                                                        value: [{ userName: "", url: "" }]
                                                                    }
                                                                });
                                                            } else {
                                                                handleChange({
                                                                    target: {
                                                                        name: "facebookInfo",
                                                                        value: []
                                                                    }
                                                                });
                                                            }
                                                        }} name="facebook" />
                                                    }
                                                    label="Facebook"
                                                />
                                            </Grid>
                                            <FieldArray
                                                name={`facebookInfo`}
                                                render={(array) => (
                                                    <>{values?.facebookInfo?.map(
                                                        (post, index) => (
                                                            <>
                                                                <Grid item xs={12} lg={3} md={3} sm={3}>
                                                                    <FormLabel required>Username  {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`facebookInfo.[${index}].userName`}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.facebookInfo[index].userName
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}

                                                                    />

                                                                    <Field
                                                                        name={`facebookInfo.[${index}].userName`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `facebookInfo.[${index}].userName`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `facebookInfo.[${index}].userName`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={12} lg={7.5} md={7.5} sm={7.5}>
                                                                    <FormLabel required >URL  {index + 1}</FormLabel>
                                                                    <NormalInput
                                                                        name={`facebookInfo.[${index}].url`}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={
                                                                            values.facebookInfo[index].url
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}

                                                                    />
                                                                    <Field
                                                                        name={`facebookInfo.[${index}].url`}
                                                                        render={({ form }) => {
                                                                            const error = getIn(
                                                                                form.errors,
                                                                                `facebookInfo.[${index}].url`,
                                                                            );
                                                                            const touch = getIn(
                                                                                form.touched,
                                                                                `facebookInfo.[${index}].url`,
                                                                            );
                                                                            return touch && error ? (
                                                                                <p className="error">
                                                                                    {error}
                                                                                </p>
                                                                            ) : null;
                                                                        }}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={0.5} mt={4} lg={0.5} md={0.5} sm={0.5}>
                                                                    {index === 0 ? <img src="/plus.png" onClick={() => {
                                                                        array.push({
                                                                            userName: "",
                                                                            url: "",
                                                                        });
                                                                    }} alt="plus" style={{ width: '20px', height: '20px' }} /> : <img src="/minus.png"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            array.remove(index);
                                                                        }} alt="minus" style={{ width: '20px', height: '20px' }} />}</Grid>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            />
                                        </>}
                                    </Grid>
                                </Box>
                                <hr />
                                <Grid container spacing={3} p={3}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Company Values and Messaging
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel >Key Messages or Company Talking Points</FormLabel>
                                        <Editor value={values.messagesKey} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "messagesKey",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel>Tone of Voice and Communication Style</FormLabel>
                                        <Editor value={values.toneOfVoice} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "toneOfVoice",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container spacing={3} p={3}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4">
                                            Additional Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <FormLabel >Describe Your Ideal Customer</FormLabel>
                                        <Editor value={values.additionalInformation} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "additionalInformation",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                    </Grid>
                                </Grid>

                                {values.comingFrom === "profile" && <Grid container spacing={3} p={3}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4">
                                            Company Questions and Answers
                                        </Typography>
                                    </Grid>
                                    {COMPANY_QUE_FLOW.map((v, i) => {
                                        return <Grid item key={v.name} xs={6} lg={6} md={6} sm={6}>
                                            <FormLabel >{v.question}</FormLabel>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name={v.name}
                                                    onChange={handleChange}
                                                    value={values[v.name]}
                                                >
                                                    {v.answer.map((v, i) => <FormControlLabel key={v} value={v} control={<Radio />} label={v} />)}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    })}


                                </Grid>}

                                <Grid item xs={12} lg={12} md={12} sm={12} p={3} className="pt-0" container justifyContent="flex-end">
                                    <Stack direction={"row"} spacing={1}>
                                        {/* {state?.comingFrom ? <></>
                                            : <button className="btnSecondary" onClick={() => navigate("/companies")}>Cancel</button>} */}

                                        <button className="btnSecondary" onClick={(e) => {
                                            e.preventDefault();
                                            if (state?.comingFrom) {
                                                navigate(-1)
                                            } else {
                                                navigate("/companies")
                                            }
                                        }}>Cancel</button>
                                        <button
                                            className="btnPrimary"
                                            type="submit"
                                        // disabled={!(isValid && dirty)}
                                        >
                                            {state?.userId ? "Update" : "Submit"}
                                        </button>
                                    </Stack>
                                </Grid>

                            </Form >
                        );
                    }}
                </Formik >
            </Box >
        </Box >

    );

}
export default AddEditCompany;