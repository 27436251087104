import * as React from "react";
import Avatar from "@mui/material/Avatar";

export default function AvatarComponent(props) {
    const {image, width, height} = props;
  return (
    <Avatar
      alt="Remy Sharp"
      src={image}
      sx={{ width, height }}
    />
  );
}
