import { Box, FormLabel, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Editor from 'react-simple-wysiwyg';
import NormalInput from "../../../components/inputs/normalInput";
import VerticalLinearStepper from "../../../components/stepper/vertical";
import Schema from "../../../schema";
import { formatDate, getUserType } from "../../../utils/common";
import { ADMIN, CAMPAIGN_TYPES, COMPANY } from "../../../utils/constant";
import { endpoints } from "../../../utils/endpoints";
import { addCampaignInfo } from "../action";
import { updateCampaign } from "../campaignSlice";
import Steppers from './Stepper';
const CampaignDates = () => {

    const navigate = useNavigate();
    const { formData } = useSelector((state) => state.campaignSlice);

    let initialValues = {
        conceptScriptDate: "",
        contentSubmissionDate: "",
        contentPublishDate: "",
        customizeDescription: null,
        productShipmentDate: "",
        type: "",
        demand: "",
        campaignId: ""
    }
    const [value, setValue] = useState(initialValues);
    const dispatch = useDispatch();

    useEffect(() => {
        if (formData?.conceptScriptDate !== undefined) {
            setValue({
                conceptScriptDate: formData?.conceptScriptDate,
                contentSubmissionDate: formData?.contentSubmissionDate,
                contentPublishDate: formData?.contentPublishDate,
                customizeDescription: formData?.customizeDescription,
                productShipmentDate: formData?.productShipmentDate,
                type: formData.type,
                demand: formData.demand,
                campaignId: formData.campaignId
            });
        }
        setValue((prev) => ({ ...prev, type: formData.type, demand: formData.demand, campaignId: formData.campaignId }));
        if (formData?.type === undefined) {
            navigate("/campaigns");
        }


        if (formData.mode === "add") {
            setValue((prev) => ({ ...prev, productShipmentDate: formData.productShipmentDate ? formData.productShipmentDate : "", conceptScriptDate: formData.conceptScriptDate, contentSubmissionDate: formData.contentSubmissionDate, contentPublishDate: formData.contentPublishDate }));

        }

        if (formData.mode === "edit" && formData?.editStartDate !== formData.startDate) {
            setValue((prev) => ({ ...prev, productShipmentDate: formData.startDate, conceptScriptDate: formData.startDate, contentSubmissionDate: formData.startDate, contentPublishDate: formData.startDate }));
        }

        if (formData.mode === "edit" && (formData?.conceptScriptDate == null || formData?.conceptScriptDate === undefined)) {
            setValue((prev) => ({ ...prev, productShipmentDate: formData.startDate, conceptScriptDate: formData.startDate, contentSubmissionDate: formData.startDate, contentPublishDate: formData.startDate }));
        }

        if (formData?.type === undefined && getUserType() === ADMIN) {
            navigate("/campaigns");
        }
        if (formData?.type === undefined && getUserType() === COMPANY) {
            navigate("/my-campaigns");
        }

    }, [formData])

    return <>
        <Stack direction={"column"} gap={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography className="page-title" variant="h1">Create New Campaign</Typography>
            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}>
                <Formik
                    initialValues={value}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={Schema.Step5}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(updateCampaign({ formData: { ...formData, ...values }, step: 6, sugType: "create", listType: 0 }));
                        dispatch(addCampaignInfo(values, endpoints.createCampaignDate));
                        navigate("/campaign/profile-screener")
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        dirty,
                    }) => {

                        return (
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                // if (Object.keys(errors).length > 0) {

                                //     document.getElementsByName(Object.keys(errors)[0])[0].focus();
                                // }
                                handleSubmit();
                            }}>
                                <Grid container>
                                    <Grid className="formLevels" item xs={12} lg={12} md={12} sm={12} p={5}>
                                        <Steppers step={3} />
                                    </Grid>
                                    <hr />
                                    <Grid container xs={12} lg={12} md={12} sm={12} p={5}>
                                        <Grid className='stepAcc pe-xxl-5 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-3 pe-3' style={{ borderRight: '1px solid rgba(170, 170, 170, 0.3)' }} item xs={8} lg={8} md={8} sm={8}>
                                            <Grid container rowGap={3}>
                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10" >
                                                    <Grid container spacing={2} >
                                                        {CAMPAIGN_TYPES.BARTER === formData.type && <Grid item xs={6} lg={6} md={6} sm={6}>
                                                            <FormLabel>Product Shipment Date</FormLabel>
                                                            <NormalInput
                                                                name={"productShipmentDate"}
                                                                type={"date"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.productShipmentDate}
                                                                placeholder={"10/01/2024"}
                                                                min={formData.startDate}
                                                                max={formData.endDate}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    setFieldValue("conceptScriptDate", formatDate(e.target.value))
                                                                }}
                                                                error={Boolean(touched.productShipmentDate) && errors.productShipmentDate}
                                                                helperText={touched.productShipmentDate && errors.productShipmentDate}
                                                            />
                                                        </Grid>}
                                                        <Grid item xs={6} lg={6} md={6} sm={6} >
                                                            <FormLabel required>Concept Script Date</FormLabel>
                                                            <NormalInput
                                                                name={"conceptScriptDate"}
                                                                type={"date"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.conceptScriptDate}
                                                                placeholder={"10/01/2024"}
                                                                min={values.productShipmentDate || formatDate(formData.startDate)}
                                                                // min={"08/05/2024"}
                                                                max={formData.endDate}
                                                                //onChange={handleChange}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    setFieldValue("contentSubmissionDate", formatDate(e.target.value))
                                                                }}
                                                                error={Boolean(touched.conceptScriptDate) && errors.conceptScriptDate}
                                                                helperText={touched.conceptScriptDate && errors.conceptScriptDate}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                                            <FormLabel required>Content Submission Date</FormLabel>
                                                            <NormalInput
                                                                name={"contentSubmissionDate"}
                                                                type={"date"}
                                                                focused={false}
                                                                label={("")}
                                                                isDisabled={values.conceptScriptDate === ""}
                                                                sizeval="medium"
                                                                values={values.contentSubmissionDate}
                                                                placeholder={"17/01/2024"}
                                                                //onChange={handleChange}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    setFieldValue("contentPublishDate", formatDate(e.target.value))
                                                                }}
                                                                min={values.conceptScriptDate}
                                                                max={formData.endDate}
                                                                error={Boolean(touched.contentSubmissionDate) && errors.contentSubmissionDate}
                                                                helperText={touched.contentSubmissionDate && errors.contentSubmissionDate}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                                            <FormLabel required>Content Publishing Date</FormLabel>
                                                            <NormalInput
                                                                name={"contentPublishDate"}
                                                                type={"date"}
                                                                focused={false}
                                                                label={("")}
                                                                isDisabled={values.contentSubmissionDate === ""}
                                                                sizeval="medium"
                                                                values={values.contentPublishDate}
                                                                placeholder={"17/01/2024"}
                                                                onChange={handleChange}
                                                                max={formData.endDate}
                                                                min={values.contentSubmissionDate}
                                                                error={Boolean(touched.contentPublishDate) && errors.contentPublishDate}
                                                                helperText={touched.contentPublishDate && errors.contentPublishDate}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10" >
                                                    <Typography mb={2} variant="h4" component="h4">Customize Description</Typography>
                                                    <Editor value={values.customizeDescription} onChange={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: "customizeDescription",
                                                                value: e.target.value
                                                            }
                                                        })
                                                    }} />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid className='ps-xl-5 ps-lg-5 ps-md-5 ps-sm-3 ps-3' item xs={4} lg={4} md={4} sm={4} >
                                            <Typography variant="h4" component="h4" style={{ textAlign: 'center' }}>
                                                Campaign Preview
                                            </Typography>
                                            <Typography className='fs-6 mb-3' component="h6" py={1} px={4} style={{ textAlign: 'center', overflow: "auto" }}>
                                                This section is a representation of how the influencers will see your campaign
                                            </Typography>

                                            <Grid item p={3} bgcolor={"#f7f7f7"} xs={12} lg={12} md={12} sm={12} className="radius10" style={{ border: '1px solid rgba(170, 170, 170, 0.3)' }}  >
                                                <Typography variant="h4" component="h4" className="mb-2">Before you start</Typography>
                                                <Typography variant="caption" className="fs-6 mb-3" component="h6"  >
                                                    Know about the process
                                                </Typography>
                                                <VerticalLinearStepper />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid item p={3} xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">
                                    <Stack direction={"row"} spacing={1}>
                                        <button
                                            className="btnSecondary"
                                            onClick={() => {
                                                dispatch(updateCampaign({ step: 4 }));
                                                navigate("/campaign/budget-target")
                                            }}
                                        >
                                            Back
                                        </button>
                                        <button
                                            className="btnPrimary"
                                            type="submit"
                                        >
                                            Set Campaign Dates
                                        </button>
                                    </Stack>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Stack>
    </>
}
export default CampaignDates;