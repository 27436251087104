import { CloseSharp } from "@mui/icons-material";
import { Button, FormLabel, Grid, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { subscriptionPayment } from "../../pages/subscriptions/action";
import Schema from "../../schema";
import NumberInput from "../inputs/normalInput/number";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddMoney(props) {
    const { open, setOpen } = props;
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        Add Money
                    </Typography>
                    <div className="cursorPointer" onClick={handleClose}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                <DialogContent className='p-4'>
                    <Formik
                        initialValues={{ amount: "", description: "" }}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validationSchema={Schema.BidNegotiate}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(subscriptionPayment({ amount: String(values.amount), type: "" }));
                            setOpen(false);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>Enter Amount(₹)</FormLabel>
                                            <NumberInput
                                                name={"amount"}
                                                type={"number"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.amount}
                                                placeholder={"Enter Amount(₹)"}
                                                onChange={handleChange}
                                                //onBlur={handleBlur}
                                                error={Boolean(touched.amount) && errors.amount}
                                                helperText={touched.amount && errors.amount}

                                            />
                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        <Stack direction={"row"} gap={2} mt={4}>
                                            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                                            <Button variant="contained" disabled={!(isValid && dirty)} type="submit">Proceed</Button>
                                        </Stack>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik >

                </DialogContent>

            </BootstrapDialog>

        </>
    );
}
