import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  totalPages: 0,
  listing: [],
  campaigns: [],
  influencers: [],
  graphData: [],
  filters: {},
  userListing: [],
  removeUsers: false
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    updateKyc: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetKyc: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateKyc, resetKyc } =
  kycSlice.actions;

export default kycSlice.reducer;
