import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";


//const type = "top" | "left" | "bottom" | "right";

export default function Drawer({
  state,
  setState,
  children,
  className,
  togalDrawrr,
  width = "50%",
  bgColor = "#E2EAF3"
}) {

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(isOpen);
  };
  const list = () => (
    <Box className={`drawer_wrap ${className}`} bgcolor={bgColor} role="presentation">

      <Button
        variant="contained"
        color="action"
        sx={{ float: "right" }}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <CloseIcon />
      </Button>
      {children}

    </Box>
  );

  return (
    <React.Fragment key={"right"}>
      <SwipeableDrawer
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // disableTouch={true}
        style={{ userSelect: "text" }}
        sx={{
          '& .MuiDrawer-paper': {
            width: width, // Set your desired width here
          },
        }}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
