import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormHelperText,
  FormLabel,
  IconButton, InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarComponent from "../components/avatar";
import CustomizedSnackbars from "../components/snackbar";
import { useAddAdminUsersMutation, useGetRolesQuery } from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { ROUTES } from "../utils/routes";
import { adminUserValidationSchema } from "../utils/schema";
const AdminAddUser = () => {
  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });
  const defaultValue = { name: "", email: "", roleId: "", password: "", photoUrl: "" };
  const [formData, setFormData] = useState(defaultValue);

  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: adminUserValidationSchema,
    onSubmit: async (values) => {
      // Create a new FormData object
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Send FormData instead of values
      await addAdminUsers(formData).then((res) => {
        if (res.data.status === "SUCCESS") {
          dispatch(updateCommonData({
            message: "Admin user successfully created!",
            title: "Success",
            img: "/success.png",
            status: true
          }));
          navigate(ROUTES.ADMIN_USERS);
        } else {
          dispatch(updateCommonData({
            message: res.data.message || "API error",
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
        }
      });
    },
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();

  const [addAdminUsers, { isLoading, isSuccess, data: userData, error }] =
    useAddAdminUsersMutation();

  const { data: roleDatadb } = useGetRolesQuery();

  useEffect(() => {
    if (roleDatadb?.status === "SUCCESS") {
      setRoleData(roleDatadb.result.data);
    }
  });


  return (
    <Box>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack direction={"column"} gap={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Add User
          </Typography>
        </Stack>
        <Box
          p={3}
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={3} justifyContent={"center"}>
              <Stack direction={"row"} alignItems={"center"} gap={1.25}>
                <AvatarComponent
                  width={28}
                  height={28}
                  sx={{
                    color: '#000',
                  }}
                />
                <Typography variant="h4" className="subtitle1">User Detail</Typography>
              </Stack>
              <div className="row flex-column gap-3">
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel required>Name</FormLabel>
                  <OutlinedInput
                    className="w-100"
                    placeholder="Enter name of the user"
                    value={formik.values.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  {formik.touched.name && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.name}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel required>Email</FormLabel>
                  <OutlinedInput
                    className="w-100"
                    placeholder="Enter user email"
                    value={formik.values.email}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  {formik.touched.email && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel>Upload Profile Image</FormLabel>


                  <div
                    style={{
                      border: '1px solid #aaa',
                      borderRadius: '8px',
                      padding: '3px',
                      height: '38px'
                    }}
                  >
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Button
                        component="label"

                        variant="contained"
                        tabIndex={-1}
                        style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.3)", color: " rgba(0, 0, 0, 0.5)", fontSize: '13px', fontWeight: '500', lineHeight: '17.2px' }}
                      >
                        Choose File
                      </Button>
                      <input
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                        type="file"
                        onChange={(e) => {
                          formik.handleChange({
                            target: {
                              name: "photoUrl",
                              value: e.target.files[0]
                            }
                          })
                        }}
                      />

                      {formik?.values?.photoUrl?.name && <p className="m-0">{formik?.values?.photoUrl?.name}</p>}
                    </div>
                  </div>
                  <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 80x80 and 100x100. Image formats like JPEG and PNG</small>


                  {/* <div
                    style={{
                      border: '1px solid #aaa',
                      borderRadius: '8px',
                      padding: '3px',
                      height: '45px'
                    }}
                  >
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Button
                        component="label"
                        variant="contained"
                        tabIndex={-1}
                        style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.3)", cursor: 'pointers', color: " rgba(0, 0, 0, 1)", fontSize: '15px', fontWeight: '500', lineHeight: '23px' }}
                      >
                        Choose File
                      </Button>
                      <input
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                        type="file"
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: "logo",
                              value: e.target.files[0]
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                  <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 80x80 and 100x100.</small> */}

                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel required>Password</FormLabel>
                  <OutlinedInput
                    className="w-100"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter user password"
                    value={formik.values.password}
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={formik.touched.password && formik.errors.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}

                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.password && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                  <FormLabel required>Roles</FormLabel>
                  <Select
                    className="w-100"
                    value={formik.values.roleId}
                    name="roleId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                    helperText={formik.touched.roleId && formik.errors.roleId}
                  >
                    {roleData?.map((item, index) => (
                      <MenuItem key={index} value={item.roleId}>
                        {item.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.roleId && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.roleId}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 gap-2 d-flex justify-content-end">
                  <button type="submit" className="btnSecondary" onClick={() => navigate(-1)}>Cancel</button>
                  <button type="submit" className="btnPrimary">Create User</button>
                </div>
              </div>
            </Stack>
          </form>
        </Box>
      </Stack>

    </Box>
  );
};

export default AdminAddUser;
