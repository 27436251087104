import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MessageIcon from '@mui/icons-material/Message';
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDateformatUsingSeconds, getFixedValue } from '../../utils/constant';
import { getUserSocialContentsById } from './action';
import { resetUser, updateUser } from './userSlice';

const Contents = () => {
    const { id, user_id } = useParams();
    const dispatch = useDispatch();
    const [tab, setTab] = useState("topMost");
    const tableRef = useRef();
    const { userContents, limit } = useSelector((state) => state.userSlice);

    useEffect(() => {
        dispatch(getUserSocialContentsById(id, tab));
        return () => {
            dispatch(resetUser({ userContents: [] }));
        }
    }, [dispatch, id, tab]);

    useEffect(() => {
        const tableContainer = tableRef.current;
        const handleScroll = () => {
            if (tableContainer) {
                const { scrollTop, clientHeight, scrollHeight } = tableContainer;
                if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
                    if (limit <= userContents.length) {
                        dispatch(updateUser({ limit: limit + 10 }));
                        dispatch(getUserSocialContentsById(id, tab));
                    }

                }
            }
        };

        tableContainer.addEventListener('scroll', handleScroll);

        return () => {
            tableContainer.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <Grid container bgcolor={"white"} p={2} rowGap={2} ref={tableRef} style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <Grid item md={10} lg={10} sm={10} xs={10}>
                <Typography variant='h5' sx={{ width: "70%" }}>
                    Contents &nbsp;<a href={`/userManagement/details/${user_id}`} style={{ textDecoration: "none", fontSize: 14 }} >
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1" stroke="#0077FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665" stroke="#0077FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>&nbsp;Back
                    </a>
                </Typography>
            </Grid>

            <Grid item md={2} lg={2} sm={2} xs={2} justifyContent="flex-end">
                <Stack direction="row" spacing={2} alignItems="flex-end">
                    <Typography variant='subtitle2' onClick={() => setTab("topMost")} sx={{ cursor: "pointer" }} className={`${tab === "topMost" ? "content-tab" : ""} showHandCursor content-active-tab`}>
                        Top Posts
                    </Typography>
                    <Typography variant='subtitle2' onClick={() => setTab("topRecent")} sx={{ cursor: "pointer" }} className={`${tab === "topRecent" ? "content-tab" : ""} showHandCursor content-active-tab`}>
                        Recent Posts
                    </Typography>
                </Stack>
            </Grid>

            {!userContents.length && (
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Typography variant='h5'>Loading...</Typography>
                </Grid>
            )}

            {userContents?.map((v) => (
                <Grid item md={3} lg={3} sm={3} xs={3} key={v.id}>
                    <div style={{ marginLeft: "10px" }} onClick={() => {
                        if (v.shortcode) {
                            window.open(v.shortcode)
                        } else {
                            return
                        }
                    }}>
                        <div className="brandBlocks">
                            <Card sx={{ maxWidth: '100%', cursor: 'pointer' }}>
                                <CardMedia
                                    component="img"
                                    alt="img"
                                    height="140"
                                    image={v.thumbnailSrc || v.shortcode}
                                />
                                <CardContent
                                    className="wishDate w-100"
                                    style={{
                                        padding: '0px',
                                        textAlign: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography component="p" className="mb-0" style={{ fontSize: '14px', color: '#6C7692' }}>
                                        <FavoriteBorderOutlinedIcon /> {getFixedValue(v.previewLikeCount)} &nbsp;
                                        <MessageIcon fontSize="small" /> {getFixedValue(v.commentCount)}
                                    </Typography>
                                    <Typography component="p" className="mb-0" style={{ color: '#6C7692', fontSize: '14px' }}>
                                        {getDateformatUsingSeconds(v.timestamp)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};

export default Contents;
