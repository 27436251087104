import { FormLabel, Grid } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logoIcon from '../assets/images/logoIcon.svg';
import NormalInput from '../components/inputs/normalInput';
import Schema from '../schema';
import { resetPassword } from './company/action';

const ResetPassword = () => {

    const { token } = useParams();
    const navigate = useNavigate();
    const initialValues = {
        password: "",
        confirmPassword: ""
    }
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch()
    return (
        <div className='loginPage signupbg wrap'>
            <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-start">
                        <div className="leftText">
                            <div className="maintagline">
                                <span>Register to get</span>More with Inflyx
                            </div>
                            <ul className="reg_pointer">
                                <li>🧠 Intelligent Matchmaking</li>
                                <li>🕓 Historical Insights</li>
                                <li>📝 Post Campaign Analysis</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center justify-content-start">
                        <div className="formSide w-100 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
                            <div className="formcvr">
                                <div className="logoIcon text-center mb-3">
                                    <Image src={logoIcon} alt="Logo Icon" />
                                </div>
                                <h1 className="text-center mb-5">
                                    Reset Your <span className="textGradient">Password</span>
                                </h1>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={Schema.ResetPassword}
                                    enableReinitialize={true}
                                    onSubmit={(values, { resetForm }) => {
                                        const data = {
                                            password: values.password,
                                            confirmPassword: values.confirmPassword,
                                            token
                                        }
                                        dispatch(resetPassword(data, navigate))
                                        // dispatch(createCompany(values, navigate, 1));
                                        // resetForm()
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched,
                                        values,
                                        setFieldValue,
                                        isValid,
                                        setFieldError,
                                        dirty,
                                    }) => {

                                        return (
                                            <Form className='frmp pt-3'>
                                                <Grid item xs={12} className="frm" lg={6} md={6} sm={6}>
                                                    <FormLabel>Password</FormLabel>
                                                    <NormalInput
                                                        name={"password"}
                                                        type={showPassword ? "text" : "password"}
                                                        focused={false}
                                                        label={("")}
                                                        sizeval="medium"
                                                        values={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        showPassword={showPassword}
                                                        error={Boolean(touched.password) && errors.password}
                                                        helperText={touched.password && errors.password}
                                                        handleViewPassword={() => setShowPassword((prev) => !prev)}
                                                        isEndAdornment={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="frm" lg={6} md={6} sm={6} mt={2}>
                                                    <FormLabel>Confirm Password</FormLabel>
                                                    <NormalInput
                                                        name={"confirmPassword"}
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        focused={false}
                                                        label={("")}
                                                        sizeval="medium"
                                                        values={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.confirmPassword) && errors.confirmPassword}
                                                        helperText={touched.confirmPassword && errors.confirmPassword}
                                                        handleViewPassword={() => setShowConfirmPassword((prev) => !prev)}
                                                        isEndAdornment={true}
                                                        showPassword={showConfirmPassword}
                                                    />
                                                </Grid>
                                                <br></br>
                                                <Button variant="primary w-100 mt-2" type="submit" mt={2}>
                                                    Submit
                                                </Button>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ResetPassword