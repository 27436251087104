import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  totalPages: 0,
  brandsDropdown: [],
  listing: [],
  filters: {},
  companyDrodown: [],
  lastCampaignStartdate: "",
  lastCampaignEnddate: "",
  renewalCampaignStartdate: "",
  renewalCampaignEnddate: "",
  modalOpen: false,
  modalMessage: "",
  message: "",
  title: "",
  img: "",
  status: false,
  buttonText: "",
  redirect: ""
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompany: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetCompany: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCompany, resetCompany } =
  companySlice.actions;

export default companySlice.reducer;
