import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Link from '@mui/material/Link';
import * as React from "react";
import { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import socialIcons from '../../src/assets/images/icons/instagram.svg';
import influencerProfImgs from '../../src/assets/images/userProf.png';
import CommonModel from '../components/dialog/Common';
import { usePushNotificationMutation } from "../redux/firebaseApi";
import { getCompanyid, getFormatedDate, getUserType } from '../utils/common';
import { COMPANY } from '../utils/constant';
import { getAllBrandForDropDown } from './brands/action';
import { getMyCampaigns } from './campaigns/action';
import { updateCampaign } from './campaigns/campaignSlice';
import SearchData from "./dashboard/search";
import { updateUser } from './users/userSlice';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Dashboard = () => {
  const [pushNotification] = usePushNotificationMutation();
  const [active, setActive] = useState(1);

  const {
    myCampaigns,

  } = useSelector((state) => state.campaignSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modelNewOpen, setModelOpen] = useState(false);

  const { modelOpen, brandCount } = useSelector((state) => state.userSlice)
  const { brandsDropdown } = useSelector((state) => state.brandSlice);
  useEffect(() => {
    const token = localStorage.getItem("notificationToken");

    if (token) {
      const payload = {
        token,
        title: "Welcome",
        message: "This is demo notification",
      };
      pushNotification(payload)
        .then((res) => {
          console.log(res, "------res");
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
    dispatch(updateCampaign({ page: 0, limit: 10, myCampaignsFilters: {} }));
    dispatch(getMyCampaigns());
    if (getUserType() === COMPANY) {
      dispatch(getAllBrandForDropDown(getCompanyid()));
    }

    return () => dispatch(updateUser({ modelOpen: false }));
  }, []);

  return (
    <>
      <Stack direction={"column"} gap={2} >
        <Stack
          display={'none'}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Dashboard
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#EFEEF4',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',

          }}>
          <SearchData />
          <div className='px-4 pt-4 pb-5'>
            <div className='dashPgeTbs'>
              <div className='viewDetTabs dashboardPage mx-auto'>
                <button className={active === 1 ? "active" : ""}
                  onClick={() => setActive(1)}
                >
                  All
                </button>
                <button className={active === 2 ? "active" : ""}
                  onClick={() => setActive(2)}
                >
                  My Campaigns
                </button>
                <button className={active === 3 ? "active" : ""}
                  onClick={() => setActive(3)}
                >
                  Communities
                </button>
                <button className={active === 4 ? "active" : ""}
                  onClick={() => setActive(4)}
                >
                  Demo Campaigns
                </button>
                <button className={active === 5 ? "active" : ""}
                  onClick={() => setActive(5)}
                >
                  Analytics
                </button>
              </div>
            </div>
          </div>

          {getUserType() === COMPANY && <div className='row'>
            <div className='col-12'>
              <div className='d-flex align-items-center justify-content-between px-xxl-5 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-3 mb-4'>
                <Typography className="fs-4 d-flex text-nowrap" variant="h4" component="h4">My Campaigns 🎉</Typography>
                {myCampaigns.length > 0 && <Link className="text-decoration-underline" href="#" onClick={(e) => { e.preventDefault(); navigate("/my-campaigns") }} style={{ fontSize: '14px', fontWeight: '500' }}>View All</Link>}
              </div>
              <div className='myCampSliders crtecmp px-xxl-5 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-3'>
                {myCampaigns.length > 0 ? <Carousel
                  responsive={responsive}
                  itemClass="carousel-item-padding-40-px"

                >
                  <div>
                    <Card
                      className="createNew dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem',
                        minHeight: '201.08px'
                      }}
                      onClick={() => {
                        if (brandsDropdown.length > 0) {
                          dispatch(updateCampaign({ formData: {}, affliateSection: 0, deliverablesSection: 0, addtionalSection: 0, detailsSection: 0, searchedInfluencers: [], searchedInfluencersCheckboxIds: [], searchedInfluencersInvitedIds: [], suggestedInfluencers: [] }))
                          navigate("/campaign/type")
                        } else {
                          setModelOpen(true);
                        }
                      }}
                    >
                      <Stack gap={2} direction={'row'} >
                        <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <AddCircleIcon />
                          <Typography className="mb-1 fw-400" variant="h4" component="h4" >Create<br />New</Typography>
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                  {myCampaigns.length > 0 ? myCampaigns.map((v, i) => {
                    return <div key={v.id + myCampaigns.length}>
                      <Card
                        className="dashCard"
                        borderRadius={12}
                        sx={{
                          height: 'auto',
                          position: 'relative',
                          cursor: 'pointer',
                          borderRadius: '12px',
                          padding: '1rem'
                        }}
                        key={v.id + myCampaigns.length}
                        onClick={() => { navigate("/campaign/partial/details/" + v.id) }}
                      >
                        <Stack className="d-flex" gap={2} direction={'row'}>
                          <Image src={v?.bannerImage.length > 0 ? v?.bannerImage.toString() : "/profile.png"} className='img-fluid d-block rounded-circle' alt="My campaign" style={{ width: '50px', height: '50px' }} />
                          <Stack >
                            <Typography className="mb-1" variant="h4" component="h4"><i>{v.name}</i></Typography>
                            <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: {v.status}</Typography>
                          </Stack>
                        </Stack>
                        <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>{getFormatedDate(v.createdDate, 'DD MMMM YYYY h:mm A')}</span></Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={5.5} xl={5.5} lg={5.5} md={5.5} sm={5.5} >
                            <Stack
                              mb={0.5}
                              flex={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              flexDirection={'row'}
                              sx={{
                                display: 'flex'
                              }}
                            >
                              <Typography className="fs-6" component="div">Views</Typography>
                              <Typography className="fs-6" variant="h4" component="h6">-</Typography>
                            </Stack>
                            <Stack
                              flex={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              flexDirection={'row'}
                              sx={{
                                display: 'flex'
                              }}
                            >
                              <Typography className="fs-6" component="div">Total Posts </Typography>
                              <Typography className="fs-6" variant="h4" component="h6">-</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={1} xl={1} lg={1} md={1} sm={1} >
                            <Box
                              sx={{
                                borderRight: '1px solid #AAAAAA', // Set the border width and color as needed
                                height: '8vh', // Adjust height as needed
                                width: '100%', // Adjust width as needed
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >

                            </Box>
                          </Grid>
                          <Grid item xs={5.5} xl={5.5} lg={5.5} md={5.5} sm={5.5} onClick={() => { navigate("/campaign/partial/details/" + v.id) }}>
                            <Stack
                              mb={0.5}
                              flex={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              flexDirection={'row'}
                              sx={{
                                display: 'flex'
                              }}
                            >
                              <Typography className="fs-6" component="div">Total Reach</Typography>
                              <Typography className="fs-6" variant="h4" component="h6">-</Typography>
                            </Stack>
                            <Stack
                              flex={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              flexDirection={'row'}
                              sx={{
                                display: 'flex'
                              }}
                            >
                              <Typography className="fs-6" component="div">CPR</Typography>
                              <Typography className="fs-6" variant="h4" component="h6">-</Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                  }) : <p className='card fs-1 mb-0 py-5 d-flex align-items-center justify-content-center'>Coming Soon</p>}
                </Carousel> : <div style={{ width: "30%" }}>
                  <Card
                    className="createNew dashCard"
                    borderRadius={12}
                    sx={{
                      height: 'auto',
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '12px',
                      padding: '1rem',
                      minHeight: '222px'
                    }}
                    onClick={() => {
                      if (brandsDropdown.length > 0) {
                        navigate("/campaign/type")
                      } else {
                        setModelOpen(true);
                      }
                    }}
                  >
                    <Stack gap={2} direction={'row'} >
                      <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <AddCircleIcon />
                        <Typography className="mb-1 fw-400" variant="h4" component="h4" >Create New</Typography>
                      </Stack>
                    </Stack>
                  </Card>
                </div>}
              </div>
            </div>
          </div>}
          <div className='row mt-5'>
            <div className='col-12'>
              <div className='d-flex align-items-center justify-content-between px-xxl-5 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-3 mb-4'>
                <Typography className="fs-4 d-flex text-nowrap" variant="h4" component="h4">Communities 👥</Typography>
                {/* <Link className="text-decoration-underline" href="#/" style={{ fontSize: '14px', fontWeight: '500' }}>Explore All</Link> */}
              </div>
              <div className='dashCommunities communityBlock px-xxl-5 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-3'>
                <p className='card fs-1 mb-0 py-5 d-flex align-items-center justify-content-center'>Coming Soon</p>
                {false && <Carousel
                  responsive={responsive}
                  itemClass="carousel-item-padding-40-px"
                >
                  <div>
                    <Card
                      className="commItem"
                      borderRadius={12}
                      sx={{
                        height: '172px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                        <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                        <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                          <Typography component="div" className="brandProfImg">
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                          </Typography>
                          <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="commItem"
                      borderRadius={12}
                      sx={{
                        height: '172px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                        <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                        <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                          <Typography component="div" className="brandProfImg">
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                          </Typography>
                          <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="commItem"
                      borderRadius={12}
                      sx={{
                        height: '172px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                        <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                        <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                          <Typography component="div" className="brandProfImg">
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                          </Typography>
                          <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="commItem"
                      borderRadius={12}
                      sx={{
                        height: '172px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                        <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                        <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                          <Typography component="div" className="brandProfImg">
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                          </Typography>
                          <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="commItem"
                      borderRadius={12}
                      sx={{
                        height: '172px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                        <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                        <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                          <Typography component="div" className="brandProfImg">
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                          </Typography>
                          <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="commItem"
                      borderRadius={12}
                      sx={{
                        height: '172px',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                        <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                        <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                          <Typography component="div" className="brandProfImg">
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                            <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                          </Typography>
                          <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                        </Stack>
                      </Stack>
                    </Card>
                  </div>
                </Carousel>}
              </div>
            </div>
          </div>
          <div className='row mt-5 mb-5'>
            <div className='col-12'>
              <div className='d-flex align-items-center justify-content-between px-xxl-5 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-3 mb-4'>
                <Typography className="fs-4 d-flex text-nowrap" variant="h4" component="h4">Competitor recent Campaigns 📢</Typography>
                {/* <Link className="text-decoration-underline" href="#/" style={{ fontSize: '14px', fontWeight: '500' }}>View All</Link> */}
              </div>
              <div className='  communityBlock competitorBlock px-xxl-5 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-3'>
                <p className='card fs-1 mb-0 py-5 d-flex align-items-center justify-content-center'>Coming Soon</p>
                {false && <Carousel
                  responsive={responsive}
                  itemClass="carousel-item-padding-40-px"
                >
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className="dashCard"
                      borderRadius={12}
                      sx={{
                        height: 'auto',
                        position: 'relative',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        padding: '1rem'
                      }}
                    >
                      <Stack className="d-flex" gap={2} direction={'row'}>
                        <Image src={influencerProfImgs} className='img-fluid d-block rounded-circle' alt="Influencer Profile" style={{ width: '50px', height: '50px' }} />
                        <Stack >
                          <Typography className="mb-1" variant="h4" component="h4"><i>Drivig_Naari</i></Typography>
                          <Typography variant="h6" component="h6" style={{ fontWeight: 'normal', fontSize: '14px', color: '#aaa' }}>Status: Closed</Typography>
                        </Stack>
                      </Stack>
                      <Typography className="d-flex" variant="h6" component="h6" py={3} style={{ lineHeight: 'normal', gap: '10px' }}><span className="label">Created on:</span> <span style={{ fontSize: '14px' }}>01 April 2024 2:05 PM</span></Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Reach</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">CPR</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                          <Stack
                            mb={0.5}
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Views</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">500K</Typography>
                          </Stack>
                          <Stack
                            flex={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexDirection={'row'}
                            sx={{
                              display: 'flex'
                            }}
                          >
                            <Typography className="fs-6" component="div">Total Posts</Typography>
                            <Typography className="fs-6" variant="h4" component="h6">5</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                </Carousel>}
              </div>
            </div>
          </div>
        </Box >
      </Stack >
      <CommonModel
        open={getUserType() == COMPANY && !brandCount ? modelOpen : false}
        handleDialogClose={() => dispatch(updateUser({ modelOpen: false }))}
        dialogTitle={""}
        dialogPara={`No brands are linked to your company yet. Please create at least one brand to continue.`}
        nvCancel={""}
        yesHandle={() => {
          dispatch(updateUser({ modelOpen: false }))
        }}
      />
      <CommonModel
        open={modelNewOpen}
        handleDialogClose={() => setModelOpen(false)}
        dialogTitle={""}
        dialogPara={`This company is not affiliated with any brands.`}
        nvCancel={""}
        yesHandle={() => {
          setModelOpen(false)
        }}
      />
    </>
  );
};

export default React.memo(Dashboard);