import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  totalPages: 0,
  brandsDropdown: [],
  listing: [],
  filters: {},
  brand: {}
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    updateBrand: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetBrand: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateBrand, resetBrand } =
  brandSlice.actions;

export default brandSlice.reducer;
