import { StepIcon } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { memo } from "react";

const Steppers = ({ step }) => {
    const steps = [
        'Campaign Info',
        'Budget & Targeting',
        'Campaign Dates',
        'Profile Screener',
        'Review & Create',
    ];

    return <Stepper activeStep={step - 1} alternativeLabel>
        {steps.map((label, idx) => (
            <Step key={idx} >
                <StepLabel StepIconComponent={(props) => (
                    <StepIcon
                        {...props}
                        icon={props.icon}
                        active={props.active || props.completed}
                        completed={false}
                    />
                )}  >{label}</StepLabel>
            </Step>
        ))}
    </Stepper>
}
export default memo(Steppers);