import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    Box,
    Pagination,
    Stack,
    Typography
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoSuggestion from "../../components/autocomplete";
import DateRangePickerComponent from "../../components/date/DateRangePickerComponent";
import PaginationSelect from "../../components/select/pagination";
import { formatPrice, getFormatedDate, getUserType } from "../../utils/common";
import { ADMIN } from "../../utils/constant";
import { getAllBrandForDropDown } from "../brands/action";
import { getAllCompanyforDropdown } from "../subscriptions/action";
import { getDepositHistory } from "./action";
import { updateWallet } from "./walletSlice";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isSticky } = props;
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "dateTime",
            numeric: false,
            disablePadding: true,
            label: "Date & Time",
            type: "",
        },
        {
            id: "company",
            numeric: false,
            disablePadding: true,
            label: "Company",
            type: "",
        },
        {
            id: "amount",
            numeric: false,
            disablePadding: true,
            label: "Transaction ID",
            type: "",
        },

        {
            id: "amount",
            numeric: false,
            disablePadding: true,
            label: "Amount",
            type: "",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            type: "",
        }
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort ${isSticky} `}>
            <TableRow>
                {headCells?.filter((v) => v.id !== getUserType())?.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function DepositHistory() {
    const navigate = useNavigate();
    const states = useSelector((state) => state.app);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [open, setOpen] = useState(false);

    const [status, setStatus] = useState({ name: "", status: "", id: "" });
    const dispatch = useDispatch();
    const {
        companyListing
    } = useSelector((state) => state.subscriptionSlice);
    const {
        limit,
        page,
        sort_key,
        totalCount,
        totalPages,
        filters,
        depositHistory,
        companyIds,
        startDate,
        endDate,
        pageRefresh
    } = useSelector((state) => state.walletSlice);

    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateWallet({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllBrandForDropDown());
    };


    useEffect(() => {
        dispatch(getDepositHistory())
        dispatch(getAllCompanyforDropdown());
        // dispatch(getAllCompanyforDropdown());
        return () => dispatch(updateWallet({
            depositHistory: [],
            startDate: "",
            endDate: "",
            companyIds: []
        }))
    }, [dispatch, navigate,]);
    const handleChangePerPage = (event, value) => {
        dispatch(updateWallet({ limit: event.target.value, page: 0 }));
        dispatch(getDepositHistory());
    };
    const handleChange = (event, value) => {
        dispatch(updateWallet({ page: value - 1 }));
        dispatch(getDepositHistory());
    };

    const tableRef = useRef();
    // useEffect(() => {
    //   const tableContainer = tableRef.current;
    //   const handleScroll = () => {

    //     if (tableContainer) {
    //       const { scrollTop, clientHeight, scrollHeight } = tableContainer;
    //       console.log("scroll", Math.ceil(scrollTop + clientHeight), scrollHeight - 1);
    //       if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
    //         dispatch(updateWallet({ limit: limit + 10, page: 0 }));
    //         dispatch(getDepositHistory())
    //       }
    //     }

    //   };

    //   tableContainer.addEventListener('scroll', handleScroll);

    //   return () => {
    //     tableContainer.removeEventListener('scroll', handleScroll);
    //   };
    // });
    // const isSticky = () => {
    //   const scrollTop = window.scrollY;
    //   const stickyClass = scrollTop >= 150 ? "is-sticky" : "";
    //   setSticky(stickyClass);
    //   console.log("stickyClass", stickyClass, scrollTop);
    // };
    // useEffect(() => {
    //   console.log("hello");
    //   window.addEventListener("scroll", isSticky);
    //   return () => {
    //     window.removeEventListener("scroll", isSticky);
    //   };
    // }, []);
    const filter = startDate ? { startDate: startDate } : {}
    return (
        <div className='card'>

            <div className="myCmpny d-flex flex-wrap align-items-center justify-content-end searcFilter p-3">
                <div className='allFilter cmpyMgmt '>
                    <div className="d-flex flex-wrap  gap-1" >
                        <span style={{ background: "white", borderRadius: 10 }}>
                            <DateRangePickerComponent text="Date Range" getSelectedDate={(dates) => {

                                if (dates?.filter((v) => v)?.length === 2) {
                                    dispatch(updateWallet({ startDate: getFormatedDate(dates[0]), endDate: getFormatedDate(dates[1]) }));
                                    dispatch(getDepositHistory());
                                }

                            }} dateReset={filter} />
                        </span>
                        {getUserType() === ADMIN &&
                            <Box sx={{ width: 400 }}>
                                <AutoSuggestion
                                    multiple={true}
                                    required={false}
                                    disableClearable={true}
                                    value={companyIds || []}
                                    className="auto-chips-cover"
                                    label={""}
                                    onChange={(e, newValue) => {
                                        dispatch(updateWallet({ companyIds: newValue }));
                                        dispatch(getDepositHistory());
                                    }}
                                    limitTags={2}
                                    key={pageRefresh}
                                    placeholder="Company"
                                    defaultValue={companyIds || []}
                                    id={`users-`}
                                    size="small"
                                    name="companyId"
                                    options={companyListing}
                                    isOptionEqualToValue={(option, value) => {
                                        if (
                                            value === "" ||
                                            option === "" ||
                                            value === option
                                        )
                                            return option;
                                    }}
                                    getOptionLabel={(option) =>
                                        option.companyName || ""
                                    }
                                    renderOption={(props, option) => {
                                        return (

                                            option.companyName && <Box
                                                key={option.companyName}
                                                component="li"
                                                sx={{
                                                    "& > img": { mr: 2, flexShrink: 0 },
                                                    fontFamily: "Poppins",
                                                }}
                                                {...props}
                                            >
                                                {option.companyName}
                                            </Box>

                                        );
                                    }}
                                />

                            </Box>}

                        <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', mt: 1.5 }}
                            onClick={() => {
                                dispatch(updateWallet({ startDate: "", endDate: "", pageRefresh: Math.random(), companyIds: [] }));
                                dispatch(getDepositHistory());
                            }}
                        />

                    </div>
                </div>


            </div>
            <div>
                <TableContainer sx={{ maxHeight: 550 }} ref={tableRef}>
                    <Table
                        stickyHeader aria-label="sticky table"
                    >
                        <EnhancedTableHead
                            // numSelected={selected.length}

                            order={order}
                            orderBy={sort_key}
                            //onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            //rowCount={rows.length}
                            numSelected={0}
                            onSelectAllClick={function (

                            ) {
                                throw new Error("Function not implemented.");
                            }}
                        //order={"desc"}
                        />
                        <TableBody>
                            {depositHistory && depositHistory?.length ? (
                                depositHistory?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row?.id}
                                            className={"tableRow"}
                                            style={{ userSelect: "text" }}
                                        >
                                            <TableCell component="td" scope="row">
                                                {row?.createdAt ? getFormatedDate(row?.createdAt?.toLocaleString(), "DD MMM YYYY hh:mm A") : "N/A"}

                                            </TableCell>
                                            {getUserType() === ADMIN &&
                                                <TableCell component="td" scope="row">
                                                    {row?.companyName || "N/A"}
                                                </TableCell>
                                            }
                                            <TableCell component="td" scope="row">
                                                {row?.txId || row?.orderId || "N/A"}
                                            </TableCell>

                                            <TableCell align="left">
                                                <div className="paymentDue">
                                                    ₹{formatPrice(row?.settlementAmount) || 0.0}
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow colSpan={10}>
                                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                        <Typography
                                            color="inherit"
                                            variant="subtitle1"
                                            alignItems={"center"}
                                            component="h3"
                                        >
                                            No Data found!
                                        </Typography>

                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>
                <Stack
                    sx={{
                        background: 'transparent',
                    }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                        <Box className="d-flex align-items-center rowPerPage" gap={0}>
                            <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                            <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                        </Box>
                        <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                            <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                                {depositHistory && depositHistory.length > 0
                                    ? page === 0
                                        ? `0 - ${depositHistory.length}`
                                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + depositHistory.length
                                        } `
                                    : "0 - 0"}{" "}
                                &nbsp;of {totalCount} items
                            </Typography>
                            <Pagination
                                count={Math.ceil(totalPages / limit) || 0}
                                variant="outlined"
                                onChange={handleChange}
                                defaultPage={1} siblingCount={0} boundaryCount={1}
                            />
                        </Box>
                    </Box>
                </Stack >
            </div>
        </div>
    );
}
