import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { Alert } from "../../utils/Alert";
import { api } from "../../utils/api";
import { endpoints } from "../../utils/endpoints";
import { updateBrand } from "./brandSlice";

export const getAllBrandForDropDown = (id) => {
  return (dispatch, getState) => {
    let query = id ? `?companyId=${id}` : ``;
    api.getApiCall(
      endpoints.brands,
      query,
      (respData) => {
        dispatch(updateBrand({ brandsDropdown: respData.data.result.data, }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getAllBrandsforListing = () => {
  return (dispatch, getState) => {
    const { limit, page } = getState().brandSlice;
    api.getApiCall(
      endpoints.brands,
      `?limit=${limit}&offset=${page * limit}`,
      (respData) => {
        dispatch(updateBrand({ listing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
export const getAllBrands = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.brands,
      "",
      (respData) => {
        dispatch(updateBrand({ brandsDropdown: respData.data.result.data, }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};




export const getBrandById = (id) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getBrandById + '/' + id,
      (respData) => {
        dispatch(updateBrand({ brand: respData.data.result.data }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const createBrand = (postData) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.createBrand,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        dispatch(updateCommonData({
          message: "Brand Successfully created!",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        dispatch(getAllBrandsforListing());
        //Alert(1, "Brand Successfully created!"); //res.message
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        Alert(2, data?.msg);
      }
    );
  };
};

export const updateBrandbyId = (postData, id) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateBrand + '/' + id,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(getAllBrands());
        let res = respData.data;
        dispatch(updateCommonData({
          message: "Brand Successfully updated!",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        // Alert(1, res.message);
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        Alert(2, data?.msg);
      }
    );
  };
};

export const getCompanyById = (id) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getCompanyById + '/' + id,
      ``,
      (respData) => {
        dispatch(updateBrand({ brand: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
