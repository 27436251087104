import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    Box,
    Tooltip,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangePickerComponent from "../../../components/date/DateRangePickerComponent";
import { formatPrice, getFormatedDate } from "../../../utils/common";
import { getAllBrandForDropDown } from "../../brands/action";
import { getSubscriptionHistory } from "../action";
import { resetSubscription, updateSubscription } from "../subscriptionSlice";
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Plan Name",
            type: "",
        },
        {
            id: "type",
            numeric: false,
            disablePadding: true,
            label: "Plan Type",
            type: "",
        },
        {
            id: "price",
            numeric: false,
            disablePadding: true,
            label: "Price (₹)",
            type: "",
        },
        {
            id: "date",
            numeric: false,
            disablePadding: true,
            label: "Purchase Date",
            type: "",
        },
        {
            id: "startDate",
            numeric: false,
            disablePadding: true,
            label: "Start Date",
            type: "",
        },
        {
            id: "endData",
            numeric: false,
            disablePadding: true,
            label: "End Date",
            type: "",
        }
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort `}>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                // IconComponent={() => headCell.id === "type" ? false : null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


const SubscriptionHistory = () => {

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const dispatch = useDispatch();
    const {
        sort_key,
        history: listing, startDate
    } = useSelector((state) => state.subscriptionSlice);
    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateSubscription({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllBrandForDropDown());
    };

    useEffect(() => {
        dispatch(getSubscriptionHistory())
        return () => dispatch(resetSubscription())
    }, []);

    let dataFilter = startDate ? { startDate: startDate } : {};
    return <>
        <div className="pageTitle">
            <div className="row d-flex align-items-center" style={{ minHeight: '42px' }}>
                <div className="col-lg-4 col-md-5 col-sm-4 col-12">
                    <Typography className="page-title" variant="h1">Subscription History</Typography>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-8 col-12 d-flex justify-content-end">

                </div>
            </div>
        </div>
        <div className='card mt-20'>
            <div className="myCmpny  align-items-center justify-content-end searcFilter p-3">
                <div className="d-flex  gap-1" >
                    <span style={{ background: "white", borderRadius: 10 }}>
                        <DateRangePickerComponent text="Purchase Date Range" getSelectedDate={(dates) => {
                            console.log("dates", dates)
                            if (dates?.filter((v) => v)?.length === 2) {
                                dispatch(updateSubscription({ startDate: getFormatedDate(dates[0]), endDate: getFormatedDate(dates[1]) }));
                                dispatch(getSubscriptionHistory());
                            }

                        }} dateReset={dataFilter} />
                    </span>
                    <Tooltip title="Refresh listing">
                        <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', mt: 1.5 }}
                            onClick={() => {
                                dispatch(updateSubscription({ startDate: "", endDate: "" }));
                                dispatch(getSubscriptionHistory());
                            }} />
                    </Tooltip>
                </div>

            </div>
            <TableContainer sx={{ maxHeight: 450 }}>
                <Table
                    stickyHeader aria-label="sticky table"
                >
                    <EnhancedTableHead
                        // numSelected={selected.length}
                        order={order}
                        orderBy={sort_key}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        //rowCount={rows.length}
                        numSelected={0}
                        onSelectAllClick={function (

                        ) {
                            throw new Error("Function not implemented.");
                        }}
                    //order={"desc"}
                    />
                    <TableBody>
                        {listing && listing?.length ? (
                            listing?.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row?.id}
                                        className={"tableRow"}
                                        style={{ userSelect: "text" }}
                                    >
                                        <TableCell component="td" scope="row" >
                                            {row?.name}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {row?.planType}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {formatPrice(row.price)}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.createdDate ? getFormatedDate(row?.createdDate?.toLocaleString(), "DD MMM YYYY") : "N/A"}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.startDate ? getFormatedDate(row?.startDate?.toLocaleString(), "DD MMM YYYY") : "N/A"}
                                        </TableCell>
                                        <TableCell align="left">

                                            {row?.endDate ? getFormatedDate(row?.endDate?.toLocaleString(), "DD MMM YYYY") : "N/A"}

                                        </TableCell>

                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow colSpan={10}>
                                <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                    <Typography
                                        color="inherit"
                                        variant="subtitle1"
                                        alignItems={"center"}
                                        component="h3"
                                    >
                                        No Data found!
                                    </Typography>

                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

            </TableContainer>
        </div>

    </>
}

export default SubscriptionHistory