import {
  Search
} from "@mui/icons-material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  Avatar,
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import DateRangePickerComponent from "../../../components/date/DateRangePickerComponent";

//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../hooks/use.debounce";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { CustomModal } from "../../../components/campaignModal";
import CommonModel from "../../../components/dialog/Common";
import NormalInput from "../../../components/inputs/normalInput";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import { capitalizeFirstLetter, DELIVERABLES_DATA, formatDate, formatPrice, getFormatedDate, SELECTED_DELI, SELECTED_DELI_FOR_YOUTUBE } from "../../../utils/common";
import { BASE_URL, CAMPAIGN_STATUS, CAMPAIGN_TYPES, DRAFT, GENDER, LIVE, PENDING, REJECTED, RUPEES_SYMBOL, SOCKET_EVENT } from "../../../utils/constant";
import { endpoints } from "../../../utils/endpoints";
import { getAllBrandsforListing } from "../../brands/action";
import { getAllCompanyforDropdown } from "../../company/action";
import { deleteCampaign, getAllCampaigns, getCampaignByIdForDetailsPage } from "../action";
import { updateCampaign } from "../campaignSlice";

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Campaign Name",
      type: "",
    },
    {
      id: "brand",
      numeric: false,
      disablePadding: true,
      label: "Company",
      type: "",
    },
    {
      id: "start_date",
      numeric: false,
      disablePadding: true,
      label: "Start Date",
      type: "",
    },
    {
      id: "end_date",
      numeric: false,
      disablePadding: true,
      label: "End Date",
      type: "",
    },
    {
      id: "goalOfCampaign",
      numeric: false,
      disablePadding: true,
      label: "Goal of the Campaign",
      type: "",
    },
    {
      id: "Budget ",
      numeric: false,
      disablePadding: true,
      label: "Budget",
      type: "",
    },
    {
      id: "influencer_type",
      numeric: false,
      disablePadding: true,
      label: "Influencer Type",
      type: "",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Action",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className="hideActionSort">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={() => null}
              //onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const socket = io(BASE_URL + endpoints.ims, {
  path: '/stream',
  transports: ['websocket']
});

export default function Listing() {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [deleteOpen, setDeleteOpen] = React.useState(0);
  const tableRef = React.useRef();

  const dispatch = useDispatch();
  const {
    limit,
    page,
    search,
    sort_key,
    totalCount,
    totalPages,
    listing: campaignListing,
    filters,
    routing
  } = useSelector((state) => state.campaignSlice);
  const debouncedSearch = useDebounce(filters.search, 500);


  const {
    companyDrodown: companyListing
  } = useSelector((state) => state.companySlice);

  const {
    brandsDropdown,
  } = useSelector((state) => state.brandSlice);

  const {
    deliverablesPlatform
  } = useSelector((state) => state.commonSlice);

  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState({
    heading: "",
    submitButton: "",
    isError: false,
  });


  const handleChangePerPage = (event, value) => {
    dispatch(updateCampaign({ limit: event.target.value, page: 0 }));
    dispatch(getAllCampaigns());
  };

  const handleChange = (event, value) => {
    dispatch(updateCampaign({ page: value - 1 }));
    dispatch(getAllCampaigns());
  };


  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateCampaign({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );
    dispatch(getAllCampaigns());
  };

  useEffect(() => {
    if (debouncedSearch || filters.search == "")
      dispatch(getAllCampaigns());

  }, [debouncedSearch]);



  useEffect(() => {
    if (routing == "audit") {
      dispatch(updateCampaign({ limit: 10, page: 0, filters: { ...filters }, routing: "" }));
    } else {
      dispatch(updateCampaign({ limit: 10, page: 0, filters: {} }));
    }

    dispatch(getAllBrandsforListing())
    dispatch(getAllCompanyforDropdown())
    dispatch(getAllCampaigns());
    //dispatch(resetCampaign());
  }, []);

  const handleSearch = (event, value) => {
    // if (event.target.value)
    dispatch(updateCampaign({ filters: { ...filters, search: event.target.value }, page: 0 }));
  };

  useEffect(() => {
    const handleCampaignCreated = (data) => {
      return dispatch(getAllCampaigns());
    };

    const handleCampaignApproved = (data) => {
      return dispatch(getAllCampaigns());
    };

    socket.on(SOCKET_EVENT.CAMPAIGN_CREATED, handleCampaignCreated);
    socket.on(SOCKET_EVENT.CAMPAIGN_APPROVED, handleCampaignApproved);

    // Cleanup the event listeners on component unmount
    return () => {
      socket.off(SOCKET_EVENT.CAMPAIGN_CREATED, handleCampaignCreated);
      socket.off(SOCKET_EVENT.CAMPAIGN_APPROVED, handleCampaignApproved);
    };
  }, []);



  const handleSearchClear = (e) => {

    dispatch(updateCampaign({ filters: { ...filters, search: "" } }));
    dispatch(getAllCampaigns());
  };

  const handleSubmit = ({ companyId }) => {
    dispatch(updateCampaign({ invitedIds: [], invitedInfluencers: [] }));
    navigate("/campaign/type", { state: companyId })
  }

  const approveFunction = () => {
    setOpen(true);
    setModal({
      heading: "Company Name",
      submitButton: "Next",
    });
  };

  useEffect(() => {
    const tableContainer = tableRef.current;
    const handleScroll = () => {
      if (tableContainer) {
        const { scrollTop, clientHeight, scrollHeight } = tableContainer;
        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
          //dispatch(updateCampaign({ limit: limit + 10, page: 0 }));
          //dispatch(getAllCampaigns())
        }
      }
    };
    tableContainer.addEventListener('scroll', handleScroll);
    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  });


  const updateFormData = (row) => {
    dispatch(getCampaignByIdForDetailsPage(row.id, (data) => {
      dispatch(updateCampaign({ campaignAppliedCount: data?.campaignContent?.filter((v) => v?.campaignContentJourney?.Script != null) }));
      delete data.campaignContent;
      delete data.applied;
      delete row.campaignContent;
      delete row.applied;
      let rowData = data;
      rowData.campaignId = row.id;

      const selectedDeliverables = rowData?.deliverables?.length > 0 ? (rowData?.deliverables[0]?.plateform == "youtube" ? SELECTED_DELI_FOR_YOUTUBE : SELECTED_DELI).map((vv) => {
        let val = data?.deliverables?.map((v) => v.deliverables)[0]?.find((v) => v?.type == vv?.type);
        if (val) {
          return { ...val };
        } else {
          return vv;
        }
      }) : [];
      // deliverables

      rowData.platform = rowData?.deliverables?.length > 0 ? rowData?.deliverables[0]?.plateform : "";
      rowData.selectedDeliverables = rowData?.deliverables?.length > 0 ? selectedDeliverables : [];
      rowData.post = rowData?.deliverables?.length > 0 ? data?.deliverables[0]?.post : [];
      rowData.deliverables = rowData?.deliverables?.length > 0 ? DELIVERABLES_DATA.find((v) => v[`${rowData.deliverables[0].plateform}`])[`${rowData.deliverables[0].plateform}`] : [];



      rowData.brandId = row.brand.id || '';
      //rowData.bannerImage = rowData?.bannerImage.toString();
      // rowData.editStartDate = rowData.startDate ? formatDate(rowData.startDate) : null;
      rowData.additionalRequirement = rowData.additionalRequirement !== "null" ? rowData.additionalRequirement : "";

      rowData.startDate = rowData.startDate ? formatDate(rowData.startDate) : null; //formatDate(rowData.startDate, 'DD-MM-YYYY');
      rowData.endDate = rowData.endDate ? formatDate(rowData.endDate) : null;
      rowData.budgetbudgetPerInfluencer = rowData.budgetPerInfluencer;

      rowData.bannerImage = row?.bannerImage?.toString() || '';


      rowData.gender = rowData?.creator?.gender || "";
      rowData.creatorLocation = rowData?.creator?.creatorLocation || '';
      rowData.influncerType = rowData?.creator?.influncerType || "";
      rowData.selectedCategories = rowData?.creator?.selectedCategories || [];
      rowData.campaignApplyingPreferences = rowData?.creator?.campaignApplyingPreferences ? ['campaignApplyingPreferences'] : [];

      rowData.noOfCreators = String(rowData?.noOfCreators || "");
      rowData.brandLogo = rowData.brandLogo || '';

      // Audionce preference
      // rowData.audioPrefGender = rowData?.audience?.audioPrefGender || "";
      // rowData.ageGroup = rowData?.audience?.ageGroup || "";
      // rowData.location = rowData?.audience?.location || [];
      rowData.shipmentDetails = rowData.shipmentDetails ? ['shipmentDetails'] : []

      /// Dates
      rowData.productShipmentDate = rowData.productShipmentDate ? formatDate(rowData.productShipmentDate) : "";
      rowData.conceptScriptDate = rowData?.conceptScriptDate ? formatDate(rowData?.conceptScriptDate) : null;
      rowData.contentSubmissionDate = rowData?.contentSubmissionDate ? formatDate(rowData?.contentSubmissionDate) : null
      rowData.contentPublishDate = rowData?.contentPublishDate ? formatDate(rowData?.contentPublishDate) : null;
      rowData.mode = "edit";
      rowData.productDropdown = brandsDropdown?.find((v) => v.id === rowData.brandId)?.product?.map((v) => v?.name) || [];
      dispatch(updateCampaign({ affliateSection: 0, deliverablesSection: 0, addtionalSection: 0, detailsSection: 0, formData: rowData, searchedInfluencers: [], searchedInfluencersCheckboxIds: [], searchedInfluencersInvitedIds: [], suggestedInfluencers: [] }));
      navigate(`/campaign/info`)
    }));
  }

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle  flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1">Campaign Management</Typography>
          <button className="btnPrimary"
            disabled={!states?.permissions?.campaign?.create}
            onClick={() => {
              dispatch(updateCampaign({ formData: {}, affliateSection: 0, deliverablesSection: 0, addtionalSection: 0, detailsSection: 0, searchedInfluencers: [], searchedInfluencersCheckboxIds: [], searchedInfluencersInvitedIds: [], suggestedInfluencers: [] }))
              approveFunction();
            }}
          >
            Create Campaign
          </button>

        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'none',
          }}>
          <CustomModal
            open={open}
            onClose={() => setOpen(!open)}
            modal={modal}
            approveFn={handleSubmit}
            companyData={companyListing}
          />

          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            {/* <PaginationSelect handleChange={handleChangePerPage} page={limit} /> */}
            <FormControl className="sContrl" variant="outlined" sx={{ marginRight: 'auto', height: '40px', borderRadius: '5px' }}>
              <SearchInput
                className="inputSearch sContrl p-0"
                value={filters.search}
                placeholder={"Search here..."}
                handleSearch={handleSearch}
                clearSearch={handleSearchClear}
                startAdornment={
                  <InputAdornment className="overIcon" position="start"
                    sx={{
                      maxHeight: 'none',
                      height: '38px',
                      minWidth: '38px',
                      marginRight: '0px'
                    }}
                  >
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>

            <div className='allFilter campMgmt'>
              <DateRangePickerComponent text="Date Range" getSelectedDate={(dates) => {
                if (dates?.filter((v) => v)?.length === 2) {
                  dispatch(updateCampaign({ filters: { ...filters, startDate: getFormatedDate(dates[0]), endDate: getFormatedDate(dates[1]) } }));
                  dispatch(getAllCampaigns());
                }

              }} dateReset={filters} startDate1={filters?.startDate} endDate1={filters?.endDate} />
              <Select
                value={filters?.status || ''}
                name="status"
                onChange={(e) => {
                  dispatch(updateCampaign({ filters: { ...filters, status: e.target.value } }));
                  dispatch(getAllCampaigns());
                }} displayEmpty
                defaultValue={filters?.status || ''}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="" hidden>
                  Status
                </MenuItem>
                <MenuItem value=" " >
                  Status (All)
                </MenuItem>
                {CAMPAIGN_STATUS.map((v) => <MenuItem key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>
              {false && <Select
                value={filters?.deliverables || ""}
                name="deliverables"
                onChange={(e) => {

                  dispatch(updateCampaign({ filters: { ...filters, deliverables: e.target.value || "" } }));
                  dispatch(getAllCampaigns());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Deliverables
                </MenuItem>

                {deliverablesPlatform && Object.keys(deliverablesPlatform)?.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v?.toUpperCase()}
                </MenuItem>)}
              </Select>}
              <Select
                value={filters?.type || ""}
                name="type"
                onChange={(e) => {
                  dispatch(updateCampaign({ filters: { ...filters, type: e.target.value } }))
                  dispatch(getAllCampaigns());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="" hidden>
                  Type
                </MenuItem >

                <MenuItem value=" " >
                  Type (All)
                </MenuItem>

                {Object.values(CAMPAIGN_TYPES).map((v) => <MenuItem className="upperCase" key={v} value={capitalizeFirstLetter(v)} >
                  {capitalizeFirstLetter(v)}
                </MenuItem>)}
              </Select>
              {false && <Select
                value={filters?.gender || ""}
                name="gender"
                onChange={(e) => {
                  dispatch(updateCampaign({ filters: { ...filters, gender: e.target.value } }))
                  dispatch(getAllCampaigns());
                }}
                displayEmpty
                defaultValue={""}
                justifyContent={"flex-end"}
                className="upperCase"
              >
                <MenuItem disabled value="">
                  Gender
                </MenuItem >

                {GENDER.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                  {v}
                </MenuItem>)}
              </Select>}
              {false && <><NormalInput
                name={"startDate"}
                type={"date"}
                focused={false}
                label={"Duration(Start Date)"}
                sizeval="medium"
                values={filters.startDate}
                placeholder={"From Date"}
                onChange={(e) => {
                  dispatch(updateCampaign({ filters: { ...filters, startDate: e.target.value, endDate: e.target.value } }));
                  dispatch(getAllCampaigns());
                }}
                style={{ width: "10%" }}
                isShrink={true}
              />
                <NormalInput
                  name={"endDate"}
                  type={"date"}
                  focused={false}
                  label={"Duration(End Date)"}
                  sizeval="medium"
                  style={{ width: "10%" }}
                  min={filters.startDate}
                  values={filters.endDate}
                  placeholder={"End Date"}
                  onChange={(e) => {
                    dispatch(updateCampaign({ filters: { ...filters, endDate: e.target.value } }))
                    dispatch(getAllCampaigns());
                  }}
                  isShrink={true}
                /></>}
              <Tooltip title="Refresh listing">
                <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692' }}
                  onClick={() => {
                    dispatch(updateCampaign({ filters: {} }))
                    dispatch(getAllCampaigns());
                  }} />
              </Tooltip>
            </div>
          </div>

          <Box className="search_bar">
          </Box>
          <TableContainer className="campListTables tableHeightFix" stickyHeader ref={tableRef}>
            <Table
              stickyHeader aria-label="sticky table"
            >
              <EnhancedTableHead
                // numSelected={selected.length}

                order={order}
                orderBy={sort_key}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                //rowCount={rows.length}
                numSelected={0}
                onSelectAllClick={function (

                ) {
                  throw new Error("Function not implemented.");
                }}
              //order={"desc"}
              />
              <TableBody>
                {campaignListing && campaignListing?.length ? (
                  campaignListing?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        className={"tableRow"}
                        style={{ userSelect: "text" }}
                      >

                        <TableCell component="th" scope="row" sx={{ minHeight: "auto" }} className=" align-items-center" padding="none">
                          <Stack direction={"row"} >
                            <Avatar
                              alt={row?.name}
                              src={row.bannerImage.length > 0 ? row?.bannerImage?.toString() : ""}
                              sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                            />
                            <Link className="text-decoration-none text-capitalize" style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px', marginLeft: '8px', marginTop: 4 }} onClick={(e) => {
                              e.preventDefault();
                              navigate(`/campaign/details/${row.id}`, { state: row });
                            }} >{row?.name || "N/A"}</Link>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row?.brand?.companyName || "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row?.startDate ? getFormatedDate(row?.startDate, "MM/DD/YYYY") : "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.endDate ? getFormatedDate(row?.endDate, "MM/DD/YYYY") : "N/A"}
                        </TableCell >
                        <TableCell component="th" scope="row" padding="none">
                          {row?.demand || "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {RUPEES_SYMBOL + (row?.budget ? formatPrice(row?.budget) : 0)}
                        </TableCell>


                        <TableCell align="left" className="single-line">
                          {row?.creator?.influncerType?.toString() || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                        </TableCell>
                        <TableCell>
                          <div className="actBtnGrps">
                            {///&& ADMIN === row.createdByType
                            }
                            {
                              [DRAFT, PENDING, REJECTED, LIVE].includes(row.status) && states?.permissions?.campaign?.edit === 1 &&
                              < span className="editIcon" onClick={() => {
                                updateFormData(row);
                              }}>Edit</span>
                            }
                            <span className="viewIcon" onClick={() => {
                              navigate(`/campaign/partial/details/${row.id}`, { state: row.type });
                            }}>
                              View
                            </span>
                            {![LIVE].includes(row.status) && states?.permissions?.campaign?.delete == 1 &&
                              <span className="viewIcon" style={{ color: "red" }} onClick={() => {
                                setDeleteOpen(row.id)
                              }}>
                                Delete
                              </span>}
                            {/* <RemoveRedEyeIcon sx={{ ml: 1 }} className="viewIcon" size="small" onClick={() => {
                              navigate(`/campaign/details/${row.id}`, { state: row });
                            }} /> */}
                          </div>
                        </TableCell>
                      </TableRow >
                    );
                  })
                ) : (
                  <TableRow colSpan={11}>
                    <TableCell colSpan={11} style={{ textAlign: 'center', height: "250px" }}>

                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        alignItems={"center"}
                        component="h3"
                      // sx={{ minHeight: 300 }}
                      >
                        No Data found!
                      </Typography>

                    </TableCell>
                  </TableRow>
                )}
              </TableBody >
            </Table >
          </TableContainer >
          <hr />
          <Stack
            sx={{
              background: 'transparent',
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
              <Box className="d-flex align-items-center rowPerPage" gap={0}>
                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
              </Box>
              <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                  {campaignListing && campaignListing.length > 0
                    ? page === 0
                      ? `0 - ${campaignListing.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + campaignListing.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <Pagination

                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                  defaultPage={1} siblingCount={0} boundaryCount={1}
                />
              </Box>
            </Box>
          </Stack >
        </Box >
      </Stack >
      <CommonModel
        open={deleteOpen}
        handleDialogClose={() => setDeleteOpen(false)}
        dialogTitle={"Delete Campaign"}
        className={"btnDanger"}
        dialogPara={`Are you sure you want to delete this campaign? `}
        nvYes={"Delete"}
        nvCancel={"Cancel"}
        yesHandle={() => {
          dispatch(deleteCampaign(deleteOpen));
          setDeleteOpen(false);
        }}
      />

    </>
  );
}
