import { api } from "../../utils/api";
import { encodedURI, removeEmptyValues } from "../../utils/common";
import { endpoints } from "../../utils/endpoints";
import { updateAudit } from "./auditSlice";

export const getCampaignAndinfluencersData = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getUserAndCampainInsights,
      ``,
      (respData) => {
        dispatch(updateAudit({ graphData: respData.data.result }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getAllAuditListing = () => {
  return (dispatch, getState) => {
    const { limit, page, filters } = getState().auditSlice;
    const { userIds, ...result } = filters;
    const filterData = encodedURI(removeEmptyValues(result));
    api.getApiCall(
      endpoints.getaudittrail,
      `?limit=${limit}&offset=${page * limit}&filter=${filterData}`,
      (respData) => {
        dispatch(updateAudit({ listing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAdminUserListing = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.adminUserList,
      `?limit=10000&offset=0`,
      (respData) => {
        dispatch(updateAudit({ userListing: respData.data.result.data }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};