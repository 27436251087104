import { CloseSharp } from "@mui/icons-material";

import {
  Box,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonModel from "../components/dialog/Common";
import { updateCampaign } from "../pages/campaigns/campaignSlice";

export const CustomModal = ({
  creating = true,
  open,
  onClose,
  approveFn,
  modal = {},
  companyData
}) => {
  const dispatch = useDispatch();
  const {
    heading,
    inputLabel = "Please select company name from dropdown",
    submitButton = "Next",
  } = modal;

  const {
    formData
  } = useSelector((state) => state.campaignSlice);

  const [selectedOption, setSelectedOption] = React.useState("");

  const [brands, setBrands] = React.useState(0);

  const [modelOpen, setModelOpen] = React.useState(false);

  // useEffect(() => {
  //   if (companyData?.length) {
  //     setSelectedOption(companyData[0].id)
  //   }
  // }, [companyData])


  const handleChange = (event) => {
    let brandsCount = companyData?.find((v) => v.userId === event.target.value)?.brands?.length;
    if (brandsCount === 0) {
      setModelOpen(true);
    } else {

      //  dispatch(updateCampaign({ formData: { ...formData, companyId: event.target.value, companyName: companyData?.find((v) => v.userId)?.companyName } }))
      setModelOpen(false);
    }
    setBrands(brandsCount);

    setSelectedOption(event.target.value);
  };




  const handleApprove = async (data) => {
    await approveFn(data);
  };


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 260, md: 400, lg: 550 },
    bgcolor: "background.paper",
    borderRadius: "10px",
    padding: "0px",
    zIndex: '9999'
  };


  return (
    <>
      <CssBaseline />
      <Modal open={open} onClose={onClose}>
        <Box sx={style} className="p-0">
          <Stack
            className="px-3 py-3"
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant={"h4"}
              sx={{ color: creating == false && "error" }}
            >
              {heading}
            </Typography>
            <div className="cursorPointer" onClick={() => onClose()}>
              <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
            </div>
          </Stack>
          <hr />
          <Stack direction={"column"} className="px-3 py-3" gap={0.5}>
            <Select
              value={selectedOption}
              name={"companyId"}
              onChange={handleChange}
              displayEmpty
              defaultValue={selectedOption}
              justifyContent={"flex-end"}
              className="upperCase"
            >
              <MenuItem className="upperCase" value="" disabled>
                Choose Company
              </MenuItem>
              {companyData?.map((company, i) => {
                return (
                  <MenuItem value={company.userId} key={company.userId}>
                    {company.companyName}
                  </MenuItem>
                );
              })}
            </Select>


            {/* <Typography variant="label" component="label">{inputLabel}</Typography> */}
            {/* <BasicSelect
              defaultValue={""}
              required={false}
              name={"companyId"}
              label={("Please select company")}
              value={selectedOption}
              handleChange={handleChange}
              options={companyData?.map((company, i) => {
                return (
                  <MenuItem value={company.id} key={company.id}>
                    {company.companyName}
                  </MenuItem>
                );
              })}
            /> */}
          </Stack>

          <Stack direction={"row"} className="px-3 py-3" justifyContent={"end"} gap={1} spacing={1}>
            <button
              className="btnPrimary"
              color={creating == false ? "error" : "primary"}
              onClick={() => {
                if (!selectedOption) return
                dispatch(updateCampaign({ formData: { ...formData, companyName: companyData?.find((v) => v.userId === selectedOption)?.companyName, userId: selectedOption } }))
                handleApprove({
                  status: submitButton,
                  companyId: selectedOption,
                  companyName: companyData?.find((v) => v.userId === selectedOption)?.companyName
                });
                onClose();
              }}
              disabled={brands == 0}
            >
              {submitButton}
            </button>

          </Stack>
        </Box>
      </Modal>
      <CommonModel
        open={modelOpen}
        handleDialogClose={() => setModelOpen(false)}
        dialogTitle={""}
        dialogPara={`This company is not affiliated with any brands`}
        nvCancel={""}
        yesHandle={() => {
          setModelOpen(false)
        }}
      />
    </>
  );
};




export const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Typography className="px-4 py-3" variant="h4" component="h4">{"Delete User?"}</Typography>
      <hr />
      <DialogContent className="px-4 py-3">
        <Typography component="p">{message || 'Are you sure ?'}</Typography>
      </DialogContent>
      <hr />
      <DialogActions className="px-3 pb-3 pt-3">
        <button onClick={onClose} className="btnSecondary">
          No
        </button>
        <button onClick={onConfirm} className="btnPrimary" autoFocus>
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

