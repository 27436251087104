import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPostAffiliateInsights, getPostCampaign } from "../action";
import PostCampaign from "./postCampaignAnalysis";

const CampainInfluencers = () => {
    const { id, infid } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    console.log("infid", infid, state)
    useEffect(() => {

        if (state?.type !== "affiliate") {
            dispatch(getPostCampaign(id, infid));
        } else {
            dispatch(getPostAffiliateInsights(id, infid));
        }

    }, [])
    console.log("state", state);
    return <>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={3} mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" component="h1" className="capitalize-first-letter">{state?.name} (Campaign metrics for {state?.infname})</Typography>
                <Stack
                    className="backText"
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/my-campaigns/approved-influencer/" + id)}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"}>
                        Back
                    </Typography>
                </Stack>

            </Stack></Stack>
        <PostCampaign />

    </>
}

export default CampainInfluencers;