import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { api } from "../../utils/api";
import { encodedURI, formatDate, getCompanyid, removeEmptyValues, setCompanyFlowStatus } from "../../utils/common";
import { capitalizeFirstLetter, GROUP_TYPE_ENUM } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";
import { ROUTES } from "../../utils/routes";
import { updateUser } from "../users/userSlice";
import { updateNotification } from "./notificationSlice";


export const createGroup = (values, navigate) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("type", values.type);
    if (values.type === GROUP_TYPE_ENUM.COMMUNITY) {
      formData.append("shortDescription", values.shortDescription);
      if (values.image)
        formData.append("image", values.image);
    }
    formData.append("description", values.description);
    if (values.id) {
      api.patchApiCall(
        endpoints.groupNotificationUpdate + `/${values.id}`,
        formData,
        (respData) => {
          dispatch(updateGlobalLoader(false));
          if (respData.data.status !== "SUCCESS") {
            dispatch(updateCommonData({
              message: respData.data.message,
              title: "Error",
              img: "/failed.png",
              status: true
            }));
          } else {
            dispatch(updateCommonData({
              message: "Group has been successfuly updated!",
              title: capitalizeFirstLetter(respData.data.message),
              img: "/success.png",
              status: true
            }));
            navigate(`/notification/group`);
          }
        },
        (error) => {
          dispatch(updateGlobalLoader(false));
          let { data } = error;
          dispatch(updateCommonData({
            message: "The email ID provided is already registered. Please try another.",
            title: "Error",
            img: "/failed.png",
            status: true
          }));
        }
      );
    } else {
      api.postApiCall(
        endpoints.groupNotificationCreate,
        formData,
        (respData) => {
          dispatch(updateGlobalLoader(false));
          if (respData.data.status === "SUCCESS") {
            dispatch(updateCommonData({
              message: "Group has been successfuly added!",
              title: capitalizeFirstLetter(respData.data.message),
              img: "/success.png",
              status: true
            }));
            navigate(`/notification/group`);
          } else {
            dispatch(updateCommonData({
              message: respData.data.message,
              title: "Failed",
              img: "/failed.png",
              status: true
            }));
          }
        },
        (error) => {
          dispatch(updateGlobalLoader(false));
          let { data } = error;
          dispatch(updateCommonData({
            message: data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
      );
    }
  }
}


export const deleteGroup = (id) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.deleteApiCall(
      endpoints.groupDetails + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateCommonData({
          message: "Group deleted successfully",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        dispatch(getAllGroups());
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );
  };
};

export const getAllCompany = () => {
  return (dispatch, getState) => {

    api.getApiCall(
      endpoints.brands,
      "",
      (respData) => {
        dispatch(updateNotification({ brandsDropdown: respData.data.result.data, }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllSelectedInfluencers = (id) => {
  return (dispatch, getState) => {

    api.getApiCall(
      endpoints.groupmemberSelected + "/" + id,
      "",
      (respData) => {
        dispatch(updateUser({ selectedRows: respData.data.result }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllGroups = () => {
  return (dispatch, getState) => {
    const { limit, page, filters, selectedAllUserCheckbox, sortType, sortKey } = getState().notificationSlice;
    const filterData = encodedURI(removeEmptyValues(filters));
    api.getApiCall(
      endpoints.groupList,
      `?limit=${limit}&offset=${page * limit}&filter=${filterData}&sortType=${sortType}`,
      (respData) => {

        dispatch(updateNotification({ listing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count, selectedRows: respData.data.result.data.filter((v) => selectedAllUserCheckbox.includes(v.id)) }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getNotificationList = () => {
  return (dispatch, getState) => {
    const { nLimit, nPage, nFilters, sortType, sortKey } = getState().notificationSlice;
    const filterData = encodedURI(removeEmptyValues(nFilters));
    api.getApiCall(
      endpoints.notificationList,
      `?limit=${nLimit}&offset=${nPage * nLimit}&filter=${filterData}&sortType=${sortType}&sortKey=${sortKey}`,
      (respData) => {
        dispatch(updateNotification({ notificationListing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const groupDetails = (id) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.groupDetails + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateNotification({ details: respData.data.result }));
        // dispatch(updateUser({ selectedRows: listing.filter((v) => respData.data.result.influencerIds.includes(v.id)) }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const eventList = (setEvent) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.eventList,
      ``,
      (respData) => {
        setEvent(respData.data.result);
        dispatch(updateNotification({ eventListing: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const eventListForEmail1 = (setEvent, setEventData) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.emailEvent,
      ``,
      (respData) => {
        setEvent(respData.data.result);
        dispatch(updateNotification({ eventListForEmail: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const notificationDetailsAPI = (id, setValue) => {
  return (dispatch, getState) => {
    const { listing } = getState().notificationSlice;
    api.getApiCall(
      endpoints.notificationDelete + `/${id}`,
      ``,
      (respData) => {
        let data = respData.data.result;

        let updatedData = {
          id: data.id,
          type: data.type,
          title: data.title,
          image: data.image,
          message: data.message,
          frequency: data.frequency || "oneTime",
          startDate: formatDate(data.startDate),
          endDate: formatDate(data.endDate),
          neverEnd: data.neverEnd ? ['neverEnd'] : [],
          time: data.time || null,
          repeat: data.repeat,
          weekDays: data.weekDays || [],
          monthlyDate: data.monthlyDate || [],
          groupIds: JSON.stringify(data?.groupIds || [])
        }
        setValue(updatedData)
        dispatch(updateNotification({ notificationDetails: respData.data.result, selectedAllUserCheckbox: data?.groupIds, selectedRows: listing.filter(v => data.groupIds.includes(v.id)) }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllCompanyforDropdown = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getAllCompanies,
      ``,
      (respData) => {
        dispatch(updateNotification({ companyListing: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
export const getActiveDefaultPlan = () => {
  return (dispatch, getState) => {
    const { activeFilters } = getState().subscriptionSlice;
    const filterData = encodedURI(removeEmptyValues(activeFilters));
    api.getApiCall(
      endpoints.getAllSubscription,
      `?filter=${filterData}`,
      (respData) => {
        dispatch(updateNotification({ defaultActivePlan: respData.data.result.length }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllActivePlans = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getAllActivePlans,
      ``,
      (respData) => {
        dispatch(updateNotification({ activePlans: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getCompanyDetails = (id, navigate) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getCompanyById + `/${id}`,
      ``,
      (respData) => {
        respData.data.result.comingFrom = "profile";
        navigate("/company/profile/" + id, { state: respData.data.result })
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};



export const createSubscription = (values, navigate) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.addSubscription,
      values,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "SUCCESS") {
          dispatch(updateCommonData({
            message: `Your plan has been ${values.id ? "updated" : 'created'} successfully `,
            title: "Success",
            img: "/success2.png",
            status: true
          }));
          navigate(ROUTES.SUBSCRIPTION_MGT)
        } else {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }


}

export const addRemoveGroupFromNotification = (id, action = "add", selecetedRows) => {
  return (dispatch, getState) => {
    //const { selectedRows } = getState().notificationSlice;
    const postData = { groupIds: selecetedRows, notificationId: id, action: action };
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.notificationRemove,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status !== "SUCCESS") {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }


}

export const updateNotificationQandA = (values, hideModal) => {
  return (dispatch, getState) => {
    //dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateNotificationQandA + `/${getCompanyid()}`,
      values,
      (respData) => {
        // dispatch(updateGlobalLoader(false));
        hideModal(false);
        setCompanyFlowStatus(0);
      },
      (error) => {
        //dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );


  }
}


export const addNotification = (values, navigate) => {
  return (dispatch, getState) => {
    const postData = values;//removeEmptyValues(values);
    const formNewData = new FormData();
    Object.entries(postData).forEach(([key, value]) => {
      //if (value !== null) {
      formNewData.append(key, value);
      // }
    });
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.notificationAdd,
      formNewData,
      (respData) => {
        dispatch(updateGlobalLoader(false));

        if (respData.data.status == "SUCCESS") {
          dispatch(updateCommonData({
            message: ``,
            title: "Success",
            img: "/success2.png",
            status: true
          }));

          navigate("/notification/group");
        } else {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
        //dispatch(getAllGroups());
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );
  };
};





export const updateNotificationStatus = (id, status) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.subscriptionStatusUpdate + `/${id}`,
      { status: status },
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status !== "SUCCESS") {
          dispatch(updateCommonData({
            message: "something went to wrong",
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
          return;
        }
        dispatch(getAllGroups());
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );
  };
};



export const updateUserStatus = (postData) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateUserStatus,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        dispatch(updateCommonData({
          message: "Status successfully updated",
          title: "Success",
          img: "/success.png",
          status: true
        }));
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data?.msg || "Api error",
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
        //Alert(2, data?.msg);
      }
    );
  };
};


export const deleteNotification = (id, hideModal) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.deleteApiCall(
      endpoints.notificationDelete + "/" + id,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        hideModal(false);
        dispatch(getNotificationList())
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );


  }
}

export const updateNotificationEditor = (values, setEvent) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });
    api.postApiCall(
      endpoints.updateNotification,
      formData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "SUCCESS") {
          dispatch(updateCommonData({
            message: "Push Notification has been saved successfully",
            title: capitalizeFirstLetter(respData.data.message),
            img: "/success.png",
            status: true
          }));
          dispatch(eventList(setEvent));
        } else {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }
}

export const updateEmailEditor = (values) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      var data = Array.isArray(value) === true ? JSON.stringify(value) : value || "";
      console.log("valuevaluevaluevalue", Array.isArray(value), data);
      formData.append(key, data);
    });
    api.postApiCall(
      endpoints.updateEmailNotification,
      formData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "SUCCESS") {
          dispatch(updateCommonData({
            message: "Email has been saved successfully",
            title: capitalizeFirstLetter(respData.data.message),
            img: "/success.png",
            status: true
          }));
        } else {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }
}