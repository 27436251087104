import { CloseSharp } from "@mui/icons-material";
import { Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CommonModel(props) {
    const { open, dialogTitle, dialogPara, nvYes, nvCancel, yesHandle, handleDialogClose, className, flag = false } = props;
    return (
        <>
            <BootstrapDialog
                onClose={handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                size="md"
                fullWidth
            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        {dialogTitle}
                    </Typography>
                    <div className="cursorPointer" onClick={handleDialogClose}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                <DialogContent className='p-4' sx={{ margin: flag ? "0px !important" : "", padding: flag ? "0px !important" : "" }}>
                    <Typography variant='contained' component="p" style={{ textAlign: !nvCancel ? 'center' : "" }} gutterBottom>
                        {dialogPara}
                    </Typography>

                </DialogContent>
                <DialogActions className='border-0 px-4 py-4'>
                    {nvCancel && <button onClick={handleDialogClose} className='btnSecondary'>{nvCancel}</button>}
                    {nvYes && <button onClick={yesHandle} className={className ? className : 'btnPrimary'}> {nvYes}</button>}
                </DialogActions>
            </BootstrapDialog >

        </>
    );
}
