import {
    Search
} from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    InputAdornment,
    Pagination,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import useDebounce from "../../../hooks/use.debounce";
import { getFormatedDate } from "../../../utils/common";
import { getAllBrandForDropDown } from "../../brands/action";
import { resetSubscription } from "../../subscriptions/subscriptionSlice";
import { addRemoveGroupFromNotification, getAllGroups, notificationDetailsAPI } from "../action";
import { updateNotification } from "../notificationSlice";
import SelectedGroupTable from "./selectedGroupTable";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, isSticky, listing, selectedRows } = props;
    const dispatch = useDispatch();
    const {
        allcheckbox
    } = useSelector((state) => state.notificationSlice);
    const createSortHandler =
        (property) => (event) => {
            if (property === "action") {
                return;
            }
            onRequestSort(event, property);
        };

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Group Name",
            type: "",
        },
        {
            id: "type",
            numeric: false,
            disablePadding: true,
            label: "Group Type",
            type: "",
        },
        {
            id: "description",
            numeric: false,
            disablePadding: true,
            label: "Group Description",
            type: "",
        },
        {
            id: "noofUsers",
            numeric: false,
            disablePadding: true,
            label: "No. of Users",
            type: "",
        },
        {
            id: "last",
            numeric: false,
            disablePadding: true,
            label: "Last Updated",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Actions",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className={`hideActionSort ${isSticky} `}>
            <TableRow>
                <TableCell>
                    <Checkbox
                        //checked={selectedAllUserCheckbox.length === listing.filter((v) => !selectedRows.includes(v.id))?.length && selectedRows?.length !== listing?.length}
                        checked={allcheckbox}
                        //disabled={listing.length === selectedRows.length}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(updateNotification({ selectedAllUserCheckbox: listing.filter((v) => !selectedRows?.includes(v.id)).map((v) => v.id), allcheckbox: true }))
                            } else {
                                dispatch(updateNotification({ selectedAllUserCheckbox: [], allcheckbox: false }))
                            }

                        }}
                        dis
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </TableCell>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={null}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function GroupTable({ setValue }) {
    const navigate = useNavigate();

    const { id } = useParams();

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [open, setOpen] = useState(false);
    const [ids, setIds] = useState([]);
    const [sticky, setSticky] = useState("");
    const [listType, setListType] = useState(1);

    const dispatch = useDispatch();
    const {
        limit,
        page,
        sortType,
        totalCount,
        totalPages,
        filters,
        listing,
        selectedAllUserCheckbox,
        selectedRows,
        allcheckbox,
        notificationDetails
    } = useSelector((state) => state.notificationSlice);
    const debouncedSearch = useDebounce(filters.name, 500);
    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateNotification({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllBrandForDropDown());
    };

    useEffect(() => {
        if (debouncedSearch || filters.name == "")
            dispatch(getAllGroups())
    }, [debouncedSearch]);

    useEffect(() => {
        dispatch(getAllGroups())
        return () => dispatch(resetSubscription())
    }, []);

    const handleSearch = (event) => {
        //if (event.target.value)
        dispatch(updateNotification({ filters: { ...filters, name: event.target.value } }));
    };

    const handleSearchClear = () => {
        dispatch(updateNotification({ filters: { ...filters, name: "" } }));
        dispatch(getAllGroups());
    };
    const handleChangePerPage = (event, value) => {
        dispatch(updateNotification({ limit: event.target.value, page: 0 }));
        dispatch(getAllGroups());
    };

    const handleChange = (event, value) => {
        dispatch(updateNotification({ page: value - 1 }));
        dispatch(getAllGroups());
    };

    useEffect(() => {
        if (id && listing.length > 0) {
            dispatch(notificationDetailsAPI(id, setValue));
        }
    }, [id, listing])

    return (
        <>
            <Stack direction={"column"} gap={2}>
                <Stack
                    className="mainTitle  flex-wrap"
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">
                        Notification Groups
                    </Typography>
                    <Stack direction="row" gap={2} className="viewDetTabs mt-0 campaignDetails" bgcolor={"#F7F7F7"}>
                        <button className={listType == 1 ? "active" : ""} onClick={() => setListType(1)}>
                            All Groups
                        </button>
                        <button className={(listType == 2 ? "active" : "") + " d-flex align-items-center"} onClick={() => setListType(2)}>
                            Selected Groups {selectedRows.length > 0 && (listType === 1 ? <span>({selectedRows.length})</span> : <span>{selectedRows.length}</span>)}
                        </button>


                    </Stack>
                </Stack>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        // overflow: 'hidden',
                    }}>
                    {listType == 1 && <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                        <div className='allFilter cmpyMgmt '>
                            <SearchInput
                                className="inputSearch sContrl p-0"
                                value={filters.name}
                                placeholder={"Search here..."}
                                handleSearch={handleSearch}
                                clearSearch={handleSearchClear}
                                startAdornment={
                                    <InputAdornment className="overIcon" position="start"
                                        sx={{
                                            maxHeight: 'none',
                                            height: '38px',
                                            minWidth: '38px',
                                            marginRight: '0px'
                                        }}
                                    >
                                        <Search />
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div className='allFilter cmpyMgmt '>
                            <div className="d-flex align-items-center gap-2" style={{ marginRight: 0 }}>
                                {(allcheckbox || selectedAllUserCheckbox.length > 1) &&
                                    <Button variant="contained" onClick={() => {
                                        dispatch(updateNotification({ allcheckbox: false, selectedRows: [...(selectedRows || []), ...listing.filter((v) => selectedAllUserCheckbox.includes(v.id))] }))
                                        dispatch(addRemoveGroupFromNotification(id, "add", [...(selectedRows || []), ...listing.filter((v) => selectedAllUserCheckbox.includes(v.id))].map((v => v.id))));
                                    }}>Add Groups</Button>}
                            </div>
                        </div>
                        
                    </div>}

                    <Paper sx={{ width: '100%', height: "100%", }}>
                        {listType == 1 ? <TableContainer sx={{ maxHeight: 550 }} >
                            <Table
                                stickyHeader aria-label="sticky table"
                            >
                                <EnhancedTableHead
                                    // numSelected={selected.length}
                                    isSticky={sticky}
                                    order={order}
                                    orderBy={sortType}
                                    listing={listing}
                                    setIds={setIds}
                                    ids={ids}
                                    selectedRows={selectedRows?.map((v) => v.id)}
                                    //onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    //rowCount={rows.length}
                                    numSelected={0}
                                    onSelectAllClick={function (

                                    ) {
                                        throw new Error("Function not implemented.");
                                    }}
                                //order={"desc"}
                                />
                                <TableBody>
                                    {listing && listing?.length ? (
                                        listing?.map((row, index) => {
                                            const rowsSelected = selectedRows.map((v) => v.id);
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row?.id}
                                                    className={"tableRow"}
                                                    style={{ userSelect: "text" }}
                                                >
                                                    <TableCell>
                                                        <Checkbox
                                                            disabled={rowsSelected.includes(row.id)}
                                                            checked={selectedAllUserCheckbox?.includes(row.id)}
                                                            onChange={(e) => {
                                                                if (rowsSelected.includes(row.id)) return;
                                                                if (e.target.checked) {
                                                                    dispatch(updateNotification({ selectedAllUserCheckbox: [...selectedAllUserCheckbox, row.id] })) //selectedRows: [...(selectedRows || []), row]
                                                                } else {
                                                                    dispatch(updateNotification({ selectedAllUserCheckbox: selectedAllUserCheckbox.filter((v) => v !== row.id) })) //, selectedRows: selectedRows.filter((v) => v.id !== row.id)
                                                                }
                                                            }}
                                                            sx={{ cursor: rowsSelected.includes(row.id) ? 'not-allowed' : "" }}
                                                            // sx={{ cursor: rowsSelected.includes(row.id) ? 'not-allowed' : "" }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />

                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {row?.name || "N/A"}

                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {row?.type || "N/A"}

                                                    </TableCell>



                                                    <TableCell align="left">
                                                        {row?.description || "N/A"}

                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.noOfUsers || 0}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {getFormatedDate(row.updatedDate) || 0}
                                                    </TableCell>
                                                    <TableCell sx={{ cursor: "pointer" }}>
                                                        <Stack direction={"row"} gap={2}>
                                                            <div className="actBtnGrps">

                                                                <Chip size="small" label="Add" onClick={() => {
                                                                    dispatch(updateNotification({ selectedRows: [...(selectedRows || []), row], selectedAllUserCheckbox: selectedAllUserCheckbox.filter((v) => v !== row.id) }))
                                                                    dispatch(addRemoveGroupFromNotification(id, "add", [row.id]));
                                                                }} color="primary" disabled={rowsSelected.includes(row.id)} variant="outlined" />
                                                                <Chip label="View" size="small" onClick={() => window.open("/notification/group/details/" + row.id, "_blank")} color="success" variant="outlined" />
                                                            </div>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow colSpan={10}>
                                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                                <Typography
                                                    color="inherit"
                                                    variant="subtitle1"
                                                    alignItems={"center"}
                                                    component="h3"
                                                >
                                                    No Data found!
                                                </Typography>

                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                        </TableContainer> : <SelectedGroupTable />}
                        <hr />
                        {listType == 1 && <Stack
                            sx={{
                                background: 'transparent',
                            }}
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                                <Box className="d-flex align-items-center rowPerPage" gap={0}>
                                    <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                                    <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                                </Box>
                                <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                                    <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                                        {listing && listing.length > 0
                                            ? page === 0
                                                ? `0 - ${listing.length}`
                                                : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                                } `
                                            : "0 - 0"}{" "}
                                        &nbsp;of {totalCount} items
                                    </Typography>
                                    <Pagination
                                        count={Math.ceil(totalPages / limit) || 0}
                                        variant="outlined"
                                        onChange={handleChange}
                                        defaultPage={1} siblingCount={0} boundaryCount={1}
                                    />
                                </Box>
                            </Box>
                        </Stack >}
                    </Paper>
                    <CommonModel
                        open={open}
                        handleDialogClose={() => setOpen(false)}
                        dialogTitle={"Delete Group"}
                        dialogPara={`Are you sure, you want to delete this group?`}
                        nvYes={"Yes"}
                        className={"btnDanger"}
                        nvCancel={"Cancel"}
                        yesHandle={() => {
                            setOpen(false);
                        }}
                    />
                </Box>

                {/* <NotificationType
                    setOpen={setOpen1}
                    open={open1}
                /> */}


            </Stack >

        </>
    );
}
