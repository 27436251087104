import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  totalPages: 0,
  brandsDropdown: [],
  companyListing: [],
  listing: [],
  filters: {},
  details: {},
  defaultActivePlan: 0,
  activeFilters: {},
  activePlans: [],
  planDetails: {},
  history: [],
  startDate: "",
  endDate: "",
  recurringEndDate: false,
  currentActivePlan: {}
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateSubscription: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetSubscription: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSubscription, resetSubscription } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
