import { Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserAllBrandsById } from './action';
import BrandPosts from './brandPosts';
import { resetUser, updateUser } from './userSlice';

const AllBrands = () => {
    const { id, user_id } = useParams();
    const dispatch = useDispatch();
    const tableRef = useRef();
    const { allbrands, limit } = useSelector((state) => state.userSlice);
    const [filterState, setfilterState] = useState(false);
    const [brandName, setBrandName] = useState({ id: "", name: "", postCount: 0, brandId: "" });

    useEffect(() => {
        dispatch(getUserAllBrandsById(id));
        return () => {
            dispatch(resetUser({ allbrands: [] }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        const tableContainer = tableRef.current;
        const handleScroll = () => {
            if (tableContainer) {
                const { scrollTop, clientHeight, scrollHeight } = tableContainer;
                if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
                    if (limit <= allbrands.length) {
                        dispatch(updateUser({ limit: limit + 10 }));
                        dispatch(getUserAllBrandsById(id));
                    }

                }
            }
        };
        tableContainer.addEventListener('scroll', handleScroll);

        return () => {
            tableContainer.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <Grid container bgcolor={"white"} p={2} rowGap={2} ref={tableRef} style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <Grid item md={12} lg={12} sm={12} xs={12}>
                <Typography variant='h5' sx={{ width: "70%" }}>
                    Brands &nbsp;<a href={`/userManagement/details/${user_id}`} style={{ textDecoration: "none", fontSize: 14 }} >
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1" stroke="#0077FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665" stroke="#0077FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>&nbsp;Back
                    </a>
                </Typography>
            </Grid>

            {!allbrands.length && (
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Typography variant='h5'>Loading...</Typography>
                </Grid>
            )}

            {allbrands?.map((v) => (
                <Grid item md={3} lg={3} sm={3} xs={3} key={v.id}>
                    <div  >
                        <div className='brandBlocks'>
                            <Card sx={{ maxWidth: '96%' }}>
                                <div className='imgCver'>
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        height="120"
                                        image={v.image}
                                    />
                                </div>
                                <CardContent style={{ textAlign: 'center' }}>
                                    <Typography variant="h5" component="h5" className='fs-5' style={{ fontWeight: '600' }}>{v.name}</Typography>
                                    <Typography component="p" className='mb-0 fs-6' style={{ color: '#6C7692' }}>{v.socialHandleName}</Typography>
                                </CardContent>
                                <Stack className='postCount'>
                                    <a href='/' onClick={(e) => {
                                        e.preventDefault();
                                        setBrandName({ id: id, name: v.name, postCount: v.media_count, brandId: v.brandId })
                                        setfilterState(true)
                                        // dispatch(getBrandPosts(v.brandId, id))
                                    }}>
                                        {v.media_count} Posts
                                    </a>
                                </Stack>
                            </Card>
                        </div>
                    </div>


                </Grid>
            ))}

            {brandName.id && <BrandPosts filterState={filterState} setfilterState={setfilterState} brandName={brandName} />}
        </Grid>
    );
};

export default AllBrands;
