import {
    Search
} from "@mui/icons-material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useRef, useState } from "react";

//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/use.debounce";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AutoSuggestion from "../../components/autocomplete";
import CommonModel from "../../components/dialog/Common";
import SearchInput from "../../components/inputs/searchInput";
import PaginationSelect from "../../components/select/pagination";
import { getFormatedDate } from "../../utils/common";
import { CREATOR_CATEGORY, CREATOR_GENDER, CREATOR_SOURCE, FOLLOWERS_ARRAY, getFixedValue, PENDING, SOCIAL_PLATFORM, USER_STATUS_FILTER } from "../../utils/constant";
import { ROUTES } from "../../utils/routes";
import { deleteCreator, getAllCreator, getStateAndCity } from "./action";
import { updateUser } from "./userSlice";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    console.log("orderBy1232123", orderBy)
    const createSortHandler =
        (property) => (event) => {
            if (property === "date") {
                onRequestSort(event, property);
            }
            return;
        };
    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
            type: "",
        },
        {
            id: "username",
            numeric: false,
            disablePadding: true,
            label: "Username",
            type: "",
        },
        {
            id: "niches",
            numeric: false,
            disablePadding: true,
            label: "Niches",
            type: "",
        },
        {
            id: "followers",
            numeric: false,
            disablePadding: true,
            label: "Followers",
            type: "",
        },
        {
            id: "state",
            numeric: false,
            disablePadding: true,
            label: "State",
            type: "",
        },
        {
            id: "city",
            numeric: false,
            disablePadding: true,
            label: "City",
            type: "",
        },
        {
            id: "date",
            numeric: false,
            disablePadding: true,
            label: "Created Date-Time",
            type: "",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy == headCell.id ? order : false}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                            // IconComponent={headCell.id === "date" ? ArrowDownwardIcon : null}
                            // onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function CreatorListing() {
    const navigate = useNavigate();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("date");
    const [deleteOpen, setDeleteOpen] = useState(0);
    const states = useSelector((state) => state.app);


    const [openTooltipIndexes, setOpenTooltipIndexes] = useState([]);
    const tooltipRefs = useRef([]);

    const handleTooltipToggle = (cellIndex) => {
        setOpenTooltipIndexes(openTooltipIndexes.includes(cellIndex) ? [] : [cellIndex]);
    };
    const tableRef = useRef();


    const handleDocumentClick = (event) => {
        const clickedOutsideAllTooltips = tooltipRefs.current.every(ref => ref && !ref.contains(event.target));
        if (clickedOutsideAllTooltips) {
            setOpenTooltipIndexes([]);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);


    const dispatch = useDispatch();
    const {
        limit,
        page,
        search,
        sort_key,
        totalCount,
        totalPages,
        listing,
        filters,
        loader,
        cityStateList
    } = useSelector((state) => state.userSlice);
    const debouncedSearch = useDebounce(filters.search, 500);

    const handleChangePerPage = (event, value) => {
        dispatch(updateUser({ limit: event.target.value, page: 0 }));
        dispatch(getAllCreator());
    };

    const handleChange = (event, value) => {
        dispatch(updateUser({ page: value - 1 }));
        dispatch(getAllCreator());
    };

    // useEffect(() => {
    //     const tableContainer = tableRef.current;
    //     const handleScroll = () => {
    //         if (tableContainer) {
    //             const { scrollTop, clientHeight, scrollHeight } = tableContainer;
    //             if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
    //                 dispatch(updateUser({ limit: limit + 10, page: 0 }));
    //                 dispatch(getAllCreator())
    //             }
    //         }
    //     };
    //     tableContainer.addEventListener('scroll', handleScroll);
    //     return () => {
    //         tableContainer.removeEventListener('scroll', handleScroll);
    //     };
    // });



    const handleRequestSort = (
        event,
        property
    ) => {
        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // if (property === "status") return;
        dispatch(
            updateUser({
                sort_type: isAsc ? "asc" : "desc",
                sort_key: property,
            }),
        );
        dispatch(getAllCreator());
    };

    useEffect(() => {
        if (debouncedSearch || filters.search == "")
            dispatch(getAllCreator());

    }, [debouncedSearch]);

    useEffect(() => {
        dispatch(getAllCreator());
        dispatch(getStateAndCity());
    }, []);

    const handleSearch = (event, value) => {
        // if (event.target.value)
        dispatch(updateUser({ page: 0, filters: { ...filters, search: event.target.value } }));
    };




    const handleSearchClear = (e) => {

        dispatch(updateUser({ filters: { ...filters, search: "" } }));
        dispatch(getAllCreator());
    };



    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack direction={"column"} gap={2}>
                <Stack
                    className="mainTitle  flex-wrap"
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">Creator Management
                    </Typography>
                    <button className="btnPrimary"
                        disabled={!states?.permissions?.users?.create}
                        onClick={() => navigate(ROUTES.CREATE_INFLUENCER)}
                    >
                        Create User
                    </button>

                </Stack>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'none',
                    }}>

                    <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                        {/* <PaginationSelect handleChange={handleChangePerPage} page={limit} /> */}
                        <FormControl className="sContrl" variant="outlined" sx={{ marginRight: 'auto' }}>
                            <SearchInput
                                className="inputSearch sContrl p-0"
                                value={filters.search}
                                placeholder={"Search here..."}
                                handleSearch={handleSearch}
                                clearSearch={handleSearchClear}
                                startAdornment={
                                    <InputAdornment className="overIcon " position="start"
                                        sx={{
                                            maxHeight: 'none',
                                            height: '38px',
                                            minWidth: '38px',
                                            marginRight: '0px'
                                        }}
                                    >
                                        <Search />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <div className='allFilter campMgmt'>
                            <Box className="multiselectFilter" style={{ width: '200px' }}>
                                <AutoSuggestion
                                    multiple={true}
                                    required={false}
                                    disableClearable={true}
                                    value={filters?.location || []}
                                    className="auto-chips-cover"
                                    label={""}
                                    onChange={(e, newValue) => {
                                        dispatch(updateUser({ filters: { ...filters, location: newValue }, page: 0 }));
                                        dispatch(getAllCreator());
                                    }}
                                    limitTags={2}
                                    // key={pageRefresh}
                                    placeholder="State/City"
                                    defaultValue={filters?.location || []}
                                    id={`users-`}
                                    size="small"
                                    name="location"
                                    options={cityStateList}
                                    // onInputChange={(e) => {
                                    //     console.log("eee", e.target.value);
                                    // }}
                                    isOptionEqualToValue={(option, value) => {
                                        if (
                                            value === "" ||
                                            option === "" ||
                                            value === option
                                        )
                                            return option;
                                    }}
                                    getOptionLabel={(option) =>
                                        option || ""
                                    }
                                    renderOption={(props, option) => {
                                        return (

                                            <Box
                                                key={option}
                                                component="li"
                                                sx={{
                                                    "& > img": { mr: 2, flexShrink: 0 },
                                                    fontFamily: "Poppins",
                                                }}
                                                {...props}
                                            >
                                                {option}
                                            </Box>

                                        );
                                    }}
                                />
                            </Box>
                            <Select
                                value={filters.followers || ''}
                                name="followers"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, followers: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                                defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Followers
                                </MenuItem>

                                <MenuItem value=" " >
                                    Followers (All)
                                </MenuItem>

                                {FOLLOWERS_ARRAY.map((row) => (
                                    <MenuItem key={row.value} value={row.value}>
                                        {row.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={filters.platform || ""}
                                name="platform"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, platform: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                            // defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Platform
                                </MenuItem>
                                <MenuItem value=" " >
                                    Platform (All)
                                </MenuItem>
                                {SOCIAL_PLATFORM.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={filters?.status || ''}
                                name="status"
                                onChange={(e) => {

                                    dispatch(updateUser({ filters: { ...filters, status: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }} displayEmpty
                                defaultValue={filters?.status || ''}
                                justifyContent={"flex-end"}
                                className="upperCase"
                            >
                                <MenuItem value="" disabled hidden>
                                    Status
                                </MenuItem>

                                <MenuItem value=" " >
                                    Status (All)
                                </MenuItem>

                                {USER_STATUS_FILTER.map((v) => <MenuItem key={v} value={v} >
                                    {v}
                                </MenuItem>)}
                            </Select>

                            <Select
                                value={filters.gender || ''}
                                name="gender"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, gender: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                                defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Gender
                                </MenuItem>

                                <MenuItem value=" " >
                                    Gender (All)
                                </MenuItem>

                                {CREATOR_GENDER.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={filters.creatorCategory || ''}
                                name="creatorCategory"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, creatorCategory: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                                defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Category
                                </MenuItem>

                                <MenuItem value=" " >
                                    Category (All)
                                </MenuItem>

                                {CREATOR_CATEGORY.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {false && <Select
                                value={filters.creatorCategory || ''}
                                name="creatorCategory"
                                onChange={(e) => {
                                    dispatch(updateUser({ filters: { ...filters, source: e.target.value }, page: 0 }));
                                    dispatch(getAllCreator());
                                }}
                                displayEmpty
                                defaultValue={""}
                            >
                                <MenuItem value="" disabled hidden>
                                    Source
                                </MenuItem>

                                {CREATOR_SOURCE.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>}


                            <Tooltip title="Refresh listing">
                                <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692' }}
                                    onClick={() => {
                                        dispatch(updateUser({ filters: {} }))
                                        dispatch(getAllCreator());
                                    }} />
                            </Tooltip>
                        </div>
                    </div>


                    <TableContainer className="campListTables tableHeightFix" stickyHeader ref={tableRef}>
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                // numSelected={selected.length}

                                order={order}
                                orderBy={sort_key}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                //rowCount={rows.length}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            //order={"desc"}
                            />
                            <TableBody>
                                {listing && listing?.length ? (
                                    listing?.map((row, index) => {
                                        let username = row?.platforms?.map((v) => v?.username)?.filter((v) => v);
                                        let niches = row?.profileNiche ? JSON?.parse(row?.profileNiche)?.map((v) => v.option)?.toString() || "N/A" : "N/A";
                                        const cellTooltipIndex = index;
                                        const isOpen = openTooltipIndexes.includes(cellTooltipIndex);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row?.id}
                                                className={"tableRow"}
                                                style={{ userSelect: "text" }}
                                            >
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction={"row"} >
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={row.picture}
                                                            sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                                                        />
                                                        <Link

                                                            className="text-decoration-none text-capitalize"
                                                            style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px', marginLeft: '8px', position: "relative", top: 5 }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open(`/userManagement/details/${row.id}`)
                                                            }}
                                                        >
                                                            {row?.name || "N/A"}
                                                        </Link>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">

                                                    {username?.length > 0 ? username?.toString() : "N/A"}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {niches != null && niches.length > 20 ? (
                                                        <div >
                                                            <Tooltip
                                                                ref={ref => tooltipRefs.current[cellTooltipIndex] = ref}
                                                                title={niches}
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                arrow={true}
                                                                open={isOpen}
                                                                disableFocusListener
                                                                disableTouchListener
                                                                disableInteractive
                                                            >
                                                                <span
                                                                    className="showhandCursor"
                                                                    // onMouseEnter={() => handleTooltipToggle(cellTooltipIndex)}
                                                                    // onMouseLeave={() => setOpenTooltipIndexes([])}
                                                                    onClick={() => handleTooltipToggle(cellTooltipIndex)}
                                                                >
                                                                    {niches.slice(0, 20)}
                                                                </span>
                                                            </Tooltip>
                                                            {!isOpen && <Tooltip title={niches} arrow={true}>
                                                                <span className="showhandCursor">...</span>
                                                            </Tooltip>}
                                                        </div>
                                                    ) : (
                                                        <span>{niches}</span>
                                                    )}

                                                </TableCell >
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.length > 0 ? getFixedValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.reduce((a, b) => Math?.max(a, b))) : "N/A"}
                                                    {/* {getFixedValue(maxValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v))) || "N/A"} */}
                                                    {/* {row?.followers ? row?.followers + " (" + row?.influencerType + ")" : "N/A"} */}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.state?.trim() !== "null" && row?.state !== '' ? row?.state : "N/A"}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.city?.trim() !== "null" && row?.state !== '' ? row?.city : "N/A"}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.createdDate ? getFormatedDate(row?.createdDate?.toLocaleString(), "MM/DD/YYYY HH:mm:ss") : "N/A"}
                                                </TableCell>

                                                <TableCell align="left" className={`single-line `}>
                                                    <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="actBtnGrps">
                                                        {row.status !== PENDING ? <><span className="showhandCursor" onClick={() => {
                                                            window.open(`/editInfluencer/${row.id}`)
                                                            // navigate(`/editInfluencer/${row.id}`);
                                                        }}>
                                                            Edit
                                                        </span>
                                                            <span className="viewIcon" onClick={() => {
                                                                window.open(`/userManagement/details/${row.id}`)
                                                                //navigate(`/userManagement/details/${row.id}`);
                                                            }}>
                                                                View
                                                            </span>

                                                            <span className="viewIcon" style={{ color: "red" }} onClick={() => {
                                                                setDeleteOpen(row.id)
                                                            }}>
                                                                Delete
                                                            </span></> : <span className="viewIcon" onClick={() => {
                                                                window.open(`/userManagement/details/${row.id}`)
                                                                //navigate(`/userManagement/details/${row.id}`);
                                                            }}>
                                                            Verfiy
                                                        </span>}


                                                    </div>
                                                </TableCell>
                                            </TableRow >
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={11}>
                                        <TableCell colSpan={11} style={{ textAlign: 'center', height: "250px" }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            // sx={{ minHeight: 300 }}
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody >
                        </Table >
                    </TableContainer >
                    <hr />
                    <Stack
                        sx={{
                            background: 'transparent',
                        }}
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                            <Box className="d-flex align-items-center rowPerPage" gap={0}>
                                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                            </Box>
                            <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                                    {listing && listing.length > 0
                                        ? page === 0
                                            ? `0 - ${listing.length}`
                                            : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                            } `
                                        : "0 - 0"}{" "}
                                    &nbsp;of {totalCount} items
                                </Typography>
                                <Pagination

                                    count={Math.ceil(totalPages / limit) || 0}
                                    variant="outlined"
                                    onChange={handleChange}
                                    defaultPage={1} siblingCount={0} boundaryCount={1}
                                />
                            </Box>
                        </Box>
                    </Stack >
                </Box >
            </Stack >
            <CommonModel
                open={deleteOpen}
                handleDialogClose={() => setDeleteOpen(false)}
                dialogTitle={"Delete Creator"}
                className={"btnDanger"}
                dialogPara={`Are you sure you want to delete this creator? `}
                nvYes={"Delete"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(deleteCreator(deleteOpen));
                    setDeleteOpen(false);
                }}
            />
        </>
    );
}
