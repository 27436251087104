import { StepIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { dateForamt } from '../../utils/common';
import { CAMPAIGN_TYPES } from '../../utils/constant';



export default function VerticalLinearStepper() {
    const formik = useFormikContext();
    const formData = formik.values;
    let stepsWithoutBarter = [
        {
            label: 'Step-1',
            description: `Concept Script has been send till ${formData.conceptScriptDate ? dateForamt(formData.conceptScriptDate) : ""}`,

        },
        {
            label: 'Step-2',
            description: `Once Script has been approved the you need to submit content till  ${formData.contentSubmissionDate ? dateForamt(formData.contentSubmissionDate) : ""}`,

        },
        {
            label: 'Step-3',
            description: `Once Script has been approved the you need to publish content and share us the content url's till ${formData.contentPublishDate ? dateForamt(formData.contentPublishDate) : ""}`,

        },
    ];

    let stepsWithBarter = [
        {
            label: 'Step-1',
            description: `Product shiped has been send till ${formData.productShipmentDate ? dateForamt(formData.productShipmentDate) : ""}`,

        },
        {
            label: 'Step-2',
            description:
                `Once Concept Script has been approved the you need to submit Product Shipment till  ${formData.conceptScriptDate ? dateForamt(formData.conceptScriptDate) : ""}`,

        },
        {
            label: 'Step-3',
            description: `Once Script has been approved the you need to submit content till  ${formData.contentSubmissionDate ? dateForamt(formData.contentSubmissionDate) : ""}`,

        },
        {
            label: 'Step-4',
            description: `Once Script has been approved the you need to publish content and share us the content url's till ${formData.contentPublishDate ? dateForamt(formData.contentPublishDate) : ""}`,
        },
    ];
    let steps = stepsWithoutBarter;
    if (formData.type === CAMPAIGN_TYPES.BARTER) {
        steps = stepsWithBarter;
    }


    console.log("formData", formData)
    return (
        <Box>
            <Stepper className='campPreview' activeStep={3} orientation="vertical">
                {steps.map((step, index) => (
                    <Step expanded={true} key={step.label} >
                        <StepLabel StepIconComponent={(props) => (
                            <StepIcon
                                {...props}
                                icon={props.icon}
                                active={false}
                                completed={false}
                            />
                        )} >
                            {step.label}
                        </StepLabel>
                        <StepContent completed={true}  >
                            <Typography className='fs-6' variant="body2">{step.description}</Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

        </Box>
    );
}
