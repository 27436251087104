import { CloseSharp } from "@mui/icons-material";
import VerifiedIcon from '@mui/icons-material/Verified';
import { FormLabel, Grid, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bidAcceptedOrRejected, bidNegotiation } from '../../pages/campaigns/action';
import Schema from "../../schema";
import { formatPrice } from "../../utils/common";
import { NEGOTIATED, RUPEES_SYMBOL, SUBMITTED } from '../../utils/constant';
import NumberInput from "../inputs/normalInput/number";
import CustomTextArea from '../inputs/textarea';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function BidNegotiate(props) {
    const { setBidOpen, bidOpen, setOpenmodel, negotation } = props;
    const handleClose = () => setBidOpen(false);

    const dispatch = useDispatch();

    const [details, setDetails] = useState();
    const {
        campaignInfluencersList,
        influencersBidDetails
    } = useSelector((state) => state.campaignSlice);

    useEffect(() => {
        if (influencersBidDetails?.attempts?.length > 0) setDetails(influencersBidDetails?.attempts[0]);
    }, [influencersBidDetails]);
    console.log("influencersBidDetails", influencersBidDetails);


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={bidOpen}
                maxWidth={"lg"}
            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        Negotiate {details?.bidName}
                    </Typography>
                    <div className="cursorPointer" onClick={handleClose}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                <DialogContent className='p-4'>
                    <Stack direction={"row"} gap={6} mb={2}>
                        <Typography variant='subtitle1'><span className="label">Original Budget:</span> {RUPEES_SYMBOL}{formatPrice(influencersBidDetails?.budget)}</Typography>
                        {/* <Typography variant='subtitle1'><span className="label text-capitalize">Bid:</span> {RUPEES_SYMBOL}{details?.amount || 0}</Typography> */}
                        <Typography variant='subtitle1'><span className="label text-capitalize">Per-creator budget : </span> {RUPEES_SYMBOL}{influencersBidDetails?.budgetPerInfluencer || 0}</Typography>
                        {parseFloat(influencersBidDetails?.advancePayment) > 0 &&
                            <Typography variant='subtitle1'><span className="label text-capitalize">Advance Payment:</span> {RUPEES_SYMBOL}{formatPrice(influencersBidDetails?.advancePayment) || 0}</Typography>
                        }
                    </Stack>

                    <Formik
                        initialValues={{ amount: influencersBidDetails?.amount, description: "", advancePayment: influencersBidDetails?.advancePayment }}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validationSchema={Schema.BidNegotiate}
                        onSubmit={(values, { setSubmitting }) => {

                            if (parseInt(influencersBidDetails?.amount) !== parseInt(values.amount) || parseInt(influencersBidDetails?.advancePayment) !== parseInt(values.advancePayment)) {
                                let postData = {
                                    bidName: "Your Bid -" + (negotation + 1),//details?.bidName,
                                    influencerId: influencersBidDetails?.influencer?.id,
                                    status: SUBMITTED,
                                    amount: values.amount,
                                    description: values.description,
                                    advancePayment: values.advancePayment || 0,
                                    campaignId: influencersBidDetails.campaignId,
                                    isAmountAccept: parseInt(influencersBidDetails?.amount) === parseInt(values.amount) ? 1 : 0,
                                    isAdAmountAccept: parseInt(influencersBidDetails?.advancePayment) === parseInt(values.advancePayment) ? 1 : 0,
                                }
                                dispatch(bidNegotiation(postData, influencersBidDetails.id));
                            } else {
                                dispatch(bidAcceptedOrRejected({ "status": NEGOTIATED, influencerId: influencersBidDetails?.influencer?.id }, details?.id, influencersBidDetails.campaignId));
                            }
                            setBidOpen(false);
                            setOpenmodel(false)
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>Negotiating Amount</FormLabel>
                                            <NumberInput
                                                name={"amount"}
                                                type={"number"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.amount}
                                                placeholder={"Enter Negotiating Amount"}
                                                onChange={handleChange}
                                                //onBlur={handleBlur}
                                                error={Boolean(touched.amount) && errors.amount}
                                                helperText={touched.amount && errors.amount}

                                            />
                                        </Grid>

                                        {parseFloat(influencersBidDetails?.advancePayment) > 0 && <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel>Advance Payment</FormLabel>
                                            <NumberInput
                                                name={"advancePayment"}
                                                isDisabled={influencersBidDetails?.isAdAmountAccept == 1}
                                                type={"number"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.advancePayment}
                                                placeholder={"Enter Advance payment"}
                                                onChange={handleChange}
                                                //onBlur={handleBlur}
                                                error={Boolean(touched.advancePayment) && errors.advancePayment}
                                                helperText={touched.advancePayment && errors.advancePayment}
                                                endAdornment={influencersBidDetails?.isAdAmountAccept == 1 ? <VerifiedIcon fontSize="small" sx={{ color: "#22C55E" }} /> : ""}
                                            />
                                        </Grid>
                                        }
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel>Description</FormLabel>
                                            <CustomTextArea
                                                name={"description"}
                                                type={"text"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.description}
                                                onChange={handleChange}
                                                placeholder="Enter your description"
                                                multiline={true}
                                            />

                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        {/* <button className='btnSecondary' onClick={handleClose}>Cancel</button> */}
                                        <button className='btnPrimary' type="submit">
                                            {(parseInt(influencersBidDetails?.amount) !== parseInt(values.amount) || parseInt(influencersBidDetails?.advancePayment || 0) !== parseInt(values.advancePayment || 0)) ? "Send" : "Accept"}
                                        </button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik >

                </DialogContent>

            </BootstrapDialog>

        </>
    );
}
