import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
export default function TimePickerComponent({ values, handleChange }) {
    //const [value, setValue] = React.useState < Dayjs | null > (dayjs('2022-04-17T15:30'));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                    className='timeScroll'
                    minutesStep={30}
                    sx={{ width: "100%" }}
                    label=""
                    ampm={false}
                    borderColor="red"
                    width="100%"
                    value={dayjs(values)}
                    onChange={(newValue) => {
                        console.log("time", dayjs(newValue).hour(newValue.hour()).minute(newValue.minute()))
                        handleChange(newValue)
                    }}
                    slots={{
                        openPickerIcon: () => (
                            <InputAdornment position="end" sx={{ fontSize: "10px !important" }}>
                                <AccessTimeIcon sx={{ fontSize: "10px !important" }} fontSize='10px !important' />
                            </InputAdornment>
                        ),
                    }}
                />

            </DemoContainer>
        </LocalizationProvider>
    );
}