import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { api } from "../../utils/api";
import { endpoints } from "../../utils/endpoints";
import { updateWallet } from "./walletSlice";

export const getCampaignListing = () => {
  return (dispatch, getState) => {

    api.getApiCall(
      endpoints.brands,
      "",
      (respData) => {
        dispatch(updateWallet({ brandsDropdown: respData.data.result.data, }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getExploreCampaignList = () => {
  return (dispatch, getState) => {
    const { exploreLimit, explorePage, exploreSearch } = getState().walletSlice;

    api.getApiCall(
      endpoints.exploreCampaign,
      `?limit=${exploreLimit}&offset=${explorePage * exploreLimit}&search=${exploreSearch}`,
      (respData) => {
        dispatch(updateWallet({ listing: respData.data.result.data, exploreCampaignCount: respData.data.result.count, exploreTotalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getExploreCampaignDetails = (id) => {
  return (dispatch, getState) => {
    const { limit, page, search, startDate, endDate } = getState().walletSlice;
    let param = "";
    if (search) {
      param += `&search=${search}`;
    }
    if (startDate) {
      param += `&startDate=${startDate}`;
    }
    if (endDate) {
      param += `&endDate=${endDate}`;
    }
    api.getApiCall(
      endpoints.wallatCampaign,
      `?limit=${limit}&offset=${page * limit}&campaignId=${id}${param}`,
      (respData) => {
        dispatch(updateWallet({ campaignData: respData.data.result.campaignData, exploreCampaignDetails: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getExploreCampaignBlockedAmount = (id) => {
  return (dispatch, getState) => {
    const { searchBlocked } = getState().walletSlice;
    let param = "";
    if (searchBlocked) {
      param += `&search=${searchBlocked}`;
    }

    api.getApiCall(
      endpoints.getBlockedAmountByCampaignId,
      `?campaignId=${id}${param}`,
      (respData) => {
        dispatch(updateWallet({ blockedList: respData.data.result }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getDepositHistory = () => {
  return (dispatch, getState) => {
    const { limit, page, startDate, endDate, companyIds } = getState().walletSlice;
    api.getApiCall(
      endpoints.depositHistory,
      `?limit=${limit}&offset=${page * limit}&startDate=${startDate}&endDate=${endDate}&companyIds=${JSON.stringify(companyIds?.map((v) => v.id))}`,
      (respData) => {
        dispatch(updateWallet({ depositHistory: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};



export const getAllCompanyforDropdown = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getAllCompanies,
      ``,
      (respData) => {
        dispatch(updateWallet({ companyListing: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getCompanyDetails = (id, navigate) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getCompanyById + `/${id}`,
      ``,
      (respData) => {
        respData.data.result.comingFrom = "profile";
        navigate("/company/profile/" + id, { state: respData.data.result })
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getWalletDetails = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getWalletDetails,
      ``,
      (respData) => {
        dispatch(updateWallet({ walletDetails: respData.data.result.walletData }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getWalletEstimatedPayout = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.estimatedPayout,
      ``,
      (respData) => {
        dispatch(updateWallet({ estimatedPayouts: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};









export const updateUserStatus = (postData) => {

  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateUserStatus,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        dispatch(updateCommonData({
          message: "Status successfully updated",
          title: "Success",
          img: "/success.png",
          status: true
        }));
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data?.msg || "Api error",
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
        //Alert(2, data?.msg);
      }
    );
  };
};


