import { AddCircle } from "@mui/icons-material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useCreateNewCampaignMutation } from "../redux/api";

import { useNavigate } from "react-router-dom";
import HeadingSection from "../components/headingSection";
import CustomizedSnackbars from "../components/snackbar";
import { createCampaignValidationSchema } from "../utils/schema";

const CreateNewCampaign = () => {
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });
  const navigate = useNavigate();
  const option = {
    campaignType: ["promotion", "social"],
    paymentType: ["googlePay", "paytm"],
    postedOn: ["Instagram", "Facebook"],
    gender: ["Male", "Female", "Others"],
    ageGroup: ["18+", "25+"],
    followers: ["10k", "100k"],
  };

  const defaultValue = {
    campaignName: "",
    campaignType: "",
    paymentType: "",
    brandName: "",
    duration: "",
    productLink: "",
    campaignDetails: "",
    websiteLink: "",
    postedOn: "",
    gender: "",
    ageGroup: "",
    followers: "",
    hashtag: "",
    deliverables: "",
  };

  const [createNewCampaign, { isLoading, data: userData }] =
    useCreateNewCampaignMutation();
  //   const [formData, setFormData] = useState(defaultValue);
  //   const handleChange = (e) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   };

  //   const handleSubmit = async () => {
  //     await createNewCampaign(formData).then(
  //       (res) => res.data.status == "SUCCESS" && navigate("/")
  //     );
  //   };


  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: createCampaignValidationSchema,
    onSubmit: async (values) => {

      await createNewCampaign(values).then((res) => {
        if (res.data.status == "SUCCESS") {
          setSnackbar({
            message: res.data.message,
            severity: "success",
            open: true,
          });
          setTimeout(() => { }, 1500);
        } else {
          setSnackbar({
            message: res.data.message,
            severity: "error",
            open: true,
          });
        }
      });
    },
  });
  return (
    <>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack
        direction={"row"}
        marginBottom={"15px"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HeadingSection heading={"Create New Campaign"} backBtn={false} />

        <Button
          variant="contained"
          type="submit"
          color="primary"
        >
          Create Campaign
        </Button>
      </Stack>

      <Stack
        padding={"24px 24px 24px 24px"}
        gap={4}
        sx={{
          background: "rgba(255, 255, 255, 1)",
          marginBottom: "70px",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h5">Basic Details</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <Stack direction={"row"} gap={3} flexGrow={1}>
              <FormControl fullWidth>
                <FormLabel>Campaign Name</FormLabel>
                <OutlinedInput
                  value={formik.values.campaignName}
                  name="campaignName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.campaignName &&
                    Boolean(formik.errors.campaignName)
                  }
                  helperText={
                    formik.touched.campaignName && formik.errors.campaignName
                  }
                />
                {formik.touched.campaignName && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.campaignName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth>
                <FormLabel>Campaign Type</FormLabel>
                <Select
                  value={formik.values.campaignType}
                  fullWidth
                  name="campaignType"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.campaignType &&
                    Boolean(formik.errors.campaignType)
                  }
                  helperText={
                    formik.touched.campaignType && formik.errors.campaignType
                  }
                >
                  {option.campaignType.map((campaignType, index) => (
                    <MenuItem key={index} value={campaignType}>
                      {campaignType}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.campaignType && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.campaignType}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Brand Name</FormLabel>
                <OutlinedInput
                  value={formik.values.brandName}
                  name="brandName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.brandName && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.brandName}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack direction={"row"} gap={3} flexGrow={1}>
              <FormControl fullWidth>
                <FormLabel>Payment Type</FormLabel>
                <Select
                  value={formik.values.paymentType}
                  fullWidth
                  name="paymentType"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  displayEmpty
                >
                  {option.paymentType.map((paymentType, index) => (
                    <MenuItem key={index} value={paymentType}>
                      {paymentType}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.paymentType && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.paymentType}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Duration</FormLabel>
                <OutlinedInput
                  value={formik.values.duration}
                  onBlur={formik.handleBlur}
                  name="duration"
                  onChange={formik.handleChange}
                />
                {formik.touched.duration && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.duration}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Product Link</FormLabel>
                <OutlinedInput
                  value={formik.values.productLink}
                  onBlur={formik.handleBlur}
                  name="productLink"
                  onChange={formik.handleChange}
                />
                {formik.touched.productLink && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.productLink}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction={"row"} gap={3}>
              <FormControl fullWidth>
                <FormLabel>Campaign Details</FormLabel>
                <OutlinedInput
                  multiline
                  rows={4}
                  value={formik.campaignDetails}
                  onBlur={formik.handleBlur}
                  name="campaignDetails"
                  onChange={formik.handleChange}
                  sx={{ marginBottom: "35px" }}
                />
                {formik.touched.campaignDetails && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.campaignDetails}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Website Link</FormLabel>
                <OutlinedInput
                  onBlur={formik.handleBlur}
                  value={formik.values.websiteLink}
                  name="websiteLink"
                  onChange={formik.handleChange}
                />
                {formik.touched.websiteLink && (
                  <FormHelperText variant="standard" error>
                    {formik.errors.websiteLink}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth></FormControl>
            </Stack>
          </Stack>

          <Divider
            orientation="horizontal"
            sx={{ background: "rgba(221, 221, 221, 0.7) ", height: "1px" }}
          ></Divider>

          <Stack gap={2} marginTop={"35px"}>
            <Typography variant="h5">Other Details</Typography>

            <Stack direction={"row"}>
              <Stack direction={"row"} gap={3} flexGrow={1}>
                <FormControl fullWidth>
                  <FormLabel>Posted On</FormLabel>
                  <Select
                    placeholder="Select (Instagram/FB/Twitter)"
                    value={formik.values.postedOn}
                    onBlur={formik.handleBlur}
                    fullWidth
                    name="postedOn"
                    onChange={formik.handleChange}
                    displayEmpty
                  >
                    {option.postedOn.map((postedOn, index) => (
                      <MenuItem key={index} value={postedOn}>
                        {postedOn}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.postedOn && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.postedOn}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel>Deliverables</FormLabel>
                  <OutlinedInput
                    value={formik.values.deliverable}
                    onBlur={formik.handleBlur}
                    name="deliverables"
                    onChange={formik.handleChange}
                  />
                  {formik.touched.deliverables && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.deliverables}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>Hashtag</FormLabel>
                  <OutlinedInput
                    value={formik.values.hashtag}
                    onBlur={formik.handleBlur}
                    name="hashtag"
                    onChange={formik.handleChange}
                  />
                  {formik.touched.hashtag && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.hashtag}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Stack direction={"row"} gap={3} flexGrow={1}>
                <FormControl fullWidth>
                  <FormLabel>Gender</FormLabel>
                  <Select
                    placeholder="Select"
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                    fullWidth
                    name="gender"
                    onChange={formik.handleChange}
                    displayEmpty
                  >
                    {option.gender.map((genderOption, index) => (
                      <MenuItem key={index} value={genderOption}>
                        {genderOption}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.gender && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.gender}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel>Age Group</FormLabel>
                  <Select
                    onBlur={formik.handleBlur}
                    placeholder="Select"
                    value={formik.values.ageGroup}
                    fullWidth
                    name="ageGroup"
                    onChange={formik.handleChange}
                    displayEmpty
                  >
                    {option.ageGroup.map((ageGroup, index) => (
                      <MenuItem key={index} value={ageGroup}>
                        {ageGroup}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.ageGroup && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.ageGroup}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>Followers</FormLabel>
                  <Select
                    onBlur={formik.handleBlur}
                    placeholder="Select"
                    value={formik.values.followers}
                    fullWidth
                    name="followers"
                    onChange={formik.handleChange}
                    displayEmpty
                  >
                    {option.followers.map((followers, index) => (
                      <MenuItem key={index} value={followers}>
                        {followers}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.followers && (
                    <FormHelperText variant="standard" error>
                      {formik.errors.followers}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Stack>
          </Stack>

          <Divider
            orientation="horizontal"
            sx={{
              background: "rgba(221, 221, 221, 0.7) ",
              height: "1px",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          />
          <Stack justifyContent="space-between" direction={"row"}>
            <Typography marginBottom={"15px"} variant="h5">
              Examples & Ref. Images
            </Typography>
            <AddCircle sx={{ color: "rgba(0, 119, 255, 1)" }} />
          </Stack>
          <Stack marginBottom={"30px"} direction={"row"} gap={0.85}>
            <Button
              onClick={""}
              variant="contained"
              color="primary"
              startIcon={<InsertPhotoIcon />}
            >
              Add Image
            </Button>
            <Button
              onClick={""}
              variant="contained"
              color="primary"
              startIcon={<VideoCameraBackOutlinedIcon />}
            >
              Add Video
            </Button>
          </Stack>

          <Divider
            orientation="horizontal"
            sx={{ background: "rgba(221, 221, 221, 0.7) ", height: "1px" }}
          ></Divider>

          <Stack alignItems={"end"} paddingTop={"15px"}>
            <Button type="submit" variant="contained" color="primary">
              Create Campaign
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default CreateNewCampaign;
