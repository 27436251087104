import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import {
    Box,
    Chip,
    Paper,
    Stack,
    Tooltip,
    Typography,
    tooltipClasses
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useEffect, useRef, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import DetailsButtons from "../../../components/buttons";
import CommonModel from "../../../components/dialog/Common";
import MilestoneHistory from '../../../components/dialog/MilestoneHistory';
import ScriptListing from '../../../components/dialog/ScriptListing';
import { getUserType } from '../../../utils/common';
import { BASE_URL, CAMPAIGN_TYPES, COMPANY, COMPLETED, PENDING, SOCKET_EVENT, UNPUBLISHED } from "../../../utils/constant";
import { endpoints } from "../../../utils/endpoints";
import { approveReleasePayment, getCampaignInfluencersListNew, removeInfluencersfromCampaign } from "../action";
import { updateCampaign } from '../campaignSlice';
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));

function EnhancedTableHead(props) {
    const { campaignInfluencersList, type } = props;

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Influencer Name",
            type: "",
        },
        {
            id: "productStatus",
            numeric: false,
            disablePadding: true,
            label: "Product Status",
            type: "",
        },
        {
            id: "conceptScript",
            numeric: false,
            disablePadding: true,
            label: "Concept Script",
            type: "",
        },
        {
            id: "conceptReview",
            numeric: false,
            disablePadding: true,
            label: "Content Review",
            type: "",
        },

        {
            id: "finalPublish",
            numeric: false,
            disablePadding: true,
            label: "Final Publish",
            type: "",
        },
        {
            id: "paymentStatus",
            numeric: false,
            disablePadding: true,
            label: "Payment Status",
            type: "",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Invoice Status",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Actions",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.filter((v) => {
                    // Filter out "Product Status" column if the type is not "barter"
                    if ((campaignInfluencersList?.shipmentDetails == 0 || campaignInfluencersList?.shipmentDetails == null) && v.id === "productStatus") {
                        return false;
                    }
                    if (type == CAMPAIGN_TYPES.BARTER && ['paymentStatus', 'status'].includes(v.id)) {
                        return false
                    }
                    if (type == CAMPAIGN_TYPES.PERFORMANCE && ['paymentStatus'].includes(v.id)) {
                        return false
                    }
                    if (type == CAMPAIGN_TYPES.AFFILIATE && ['conceptReview', 'conceptScript', 'finalPublish'].includes(v.id)) {
                        return false
                    }
                    return true;
                }).map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}

                        >
                            <TableSortLabel
                                IconComponent={() => null}
                            >
                                {headCell.label} {type !== CAMPAIGN_TYPES.AFFILIATE && ['productStatus', "conceptScript", "finalPublish", "conceptReview", "name"].includes(headCell.id) && <BootstrapTooltip arrow title={headCell.id == "name" ? "Influencer Name in red means that the individual has been removed by you from this campaign." : "A 'Pending' status in red means that the due date has passed and the required action isn't completed yet."} placement="top"><InfoIcon className="blueColor" fontSize='small' color="secondary" /></BootstrapTooltip>}

                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}



export default function ApprovedInfluencers() {


    const [openModal, setOpenmodel] = useState(false);

    const [influencer, setInfluencer] = useState({});

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);

    const [approve, setApprove] = useState({ status: false, msg: "", title: "", data: {} });

    const [milestoneData, setMilestoneData] = useState({});


    const state = useLocation();

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        campaignInfluencersList, listing
    } = useSelector((state) => state.campaignSlice);

    const socket = useRef(); // Store the socket in a ref to persist across renders

    useEffect(() => {
        // Initialize the socket only once when the component mounts

        socket.current = io(BASE_URL + endpoints.ims, {
            path: '/stream',
            transports: ['websocket']
        });

        // Clean up the socket connection when the component unmounts
        return () => {
            socket.current.disconnect();
        };
    }, []); // Empty dependency array ensures it only runs once

    useEffect(() => {
        dispatch(getCampaignInfluencersListNew(id));
    }, [id]);

    useEffect(() => {
        if (socket.current) {
            // Register socket event listener
            socket.current.on(SOCKET_EVENT.CONTENT_POST, (data) => {
                console.log("socket data", data);
                if (id === data?.id) {
                    dispatch(getCampaignInfluencersListNew(id, data?.campaignContentData));
                }
            });

            // Clean up the event listener when the component unmounts or the id changes
            return () => {
                socket.current.off(SOCKET_EVENT.CONTENT_POST);
            };
        }
    }, [id]); // Only re-run when id changes


    // const socket = io(BASE_URL + endpoints.ims, {
    //     path: '/stream',
    //     transports: ['websocket']
    // });
    // useEffect(() => {
    //     dispatch(getCampaignInfluencersListNew(id));
    // }, []);


    // useEffect(() => {
    //     socket.on(SOCKET_EVENT.CONTENT_POST, (data) => {
    //         console.log("socket data", data)
    //         console.log("socket data id", id)
    //         if (id == data?.id)
    //             dispatch(getCampaignInfluencersListNew(id, data?.campaignContentData));
    //     });
    // }, [socket]);


    return (
        <>
            <Stack className='mainTitleBtn d-flex flex-wrap flex-row justify-content-between gap-3' mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography variant="h1" className="page-title capitalize-first-letter">
                        {state?.state?.name || campaignInfluencersList?.name}
                    </Typography>
                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        // onClick={() => navigate("/campaign-management")}
                        onClick={() => {
                            if (getUserType() == COMPANY) {
                                navigate("/my-campaigns")
                            } else {
                                navigate("/campaign-management")
                            }
                        }
                        }
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} >
                            Back
                        </Typography>
                    </Stack>

                </Stack>
                <DetailsButtons />
            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >

                <Box className="search_bar">
                </Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                type={campaignInfluencersList?.type}
                                campaignInfluencersList={campaignInfluencersList}
                                order={"asc"}
                                orderBy={"name"}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={() => { }}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            //order={"desc"}
                            />
                            <TableBody>

                                {campaignInfluencersList?.campaignContent?.length > 0 ? (
                                    campaignInfluencersList?.campaignContent?.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={- 1
                                                    }
                                                    key={row?.id}


                                                >
                                                    <TableCell component="th" scope="row" padding="none" sx={{ border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }}  >
                                                        <span style={{ color: row.isDeleted !== 0 ? "red" : "" }} className='text-capitalize'  >{row?.influencer?.name || "N/A"}</span>

                                                        {row.isDeleted !== 0 && <Typography variant="caption" gutterBottom sx={{ display: 'block' }} whiteSpace={"nowrap"}>(Removed from Campaign)</Typography>}

                                                    </TableCell>
                                                    {campaignInfluencersList?.shipmentDetails == 1 &&
                                                        <TableCell sx={{ color: row?.productStatus === PENDING && new Date() > new Date(row?.productShipmentDate) ? "#EF3F3F !important" : "", border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }} component="th" scope="row" padding="none" className="single-line capitalize-first-letter">
                                                            {row?.productStatus || "N/A"}
                                                        </TableCell>}
                                                    {campaignInfluencersList?.type !== CAMPAIGN_TYPES.AFFILIATE && <>
                                                        <TableCell sx={{ color: row?.scriptStatus === PENDING && new Date() > new Date(row?.conceptScriptDate) ? "#EF3F3F !important" : "", border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }} component="th" scope="row" padding="none" className="single-line capitalize-first-letter">
                                                            {row?.scriptStatus || "N/A"}
                                                        </TableCell>
                                                        <TableCell sx={{ color: row?.postStatus === PENDING && new Date() > new Date(row?.contentSubmissionDate) ? "#EF3F3F !important" : "", border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }} component="th" scope="row" padding="none" className="single-line capitalize-first-letter">
                                                            {row?.postStatus || "N/A"}
                                                        </TableCell>
                                                        <TableCell sx={{ color: row?.publishStatus === PENDING && new Date() > new Date(row?.contentPublishDate) ? "#EF3F3F !important" : "", border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }} align="left" className="single-line capitalize-first-letter">
                                                            {row.publishStatus}
                                                        </TableCell>
                                                    </>}
                                                    {campaignInfluencersList?.type !== CAMPAIGN_TYPES.BARTER && <>


                                                        {CAMPAIGN_TYPES.PERFORMANCE !== campaignInfluencersList?.type && <TableCell sx={{ border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }} align="left" className="single-line capitalize-first-letter">
                                                            {row?.paymentStatus || "-"}
                                                        </TableCell>}

                                                        <TableCell align="left" sx={{ border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }}>
                                                            <button sx={{ color: row?.invoiceStatus === PENDING ? "#EF3F3F !important" : "", border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }} className={row.invoiceStatus?.toLowerCase() + " capitalize-first-letter"}>{row.invoiceStatus}</button>

                                                        </TableCell></>
                                                    }

                                                    <TableCell sx={{ border: row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING ? "none !important" : "" }}>
                                                        <div className="actBtnGrps">


                                                            {campaignInfluencersList?.type !== CAMPAIGN_TYPES.AFFILIATE &&
                                                                <Chip size='small' onClick={() => {
                                                                    setInfluencer(row.influencer);
                                                                    dispatch(updateCampaign({ scriptDetails: row.campaignContentJourney, journeyId: row.id, address: row?.address, productDetails: { productStatus: row.productStatus, influencerid: row.influencer?.id, campaignId: campaignInfluencersList.id, postStatus: row.postStatus } }))
                                                                    // setScriptDetails(row.campaignContentJourney)
                                                                    setOpenmodel(true);
                                                                }} variant='primary' color="primary" label="Content" sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'none', // Keep the background color same on hover
                                                                    },
                                                                    '& .MuiChip-label': {
                                                                        color: 'inherit', // Keep the text color same on hover
                                                                    },
                                                                }} />

                                                            }
                                                            {[CAMPAIGN_TYPES.PAID, CAMPAIGN_TYPES.PERFORMANCE, CAMPAIGN_TYPES.AFFILIATE].includes(campaignInfluencersList?.type) && <>
                                                                <Chip size='small' onClick={() => {
                                                                    setHistoryOpen(true);
                                                                    setMilestoneData({
                                                                        milestones: row.milestones, paymentRequest: row.paymentRequest, paymentStatus: row?.paymentStatus, name: row?.influencer?.name, postData: {
                                                                            influencerId: row.influencer.id,
                                                                            campaignId: id,
                                                                            ccId: row.id
                                                                        }
                                                                    });
                                                                }} variant='default' sx={{
                                                                    bgcolor: "#6C7692", color: "white", '&:hover': {
                                                                        backgroundColor: '#41517e', // Keep the background color same on hover
                                                                    },
                                                                    '& .MuiChip-label': {
                                                                        color: 'inherit', // Keep the text color same on hover
                                                                    },
                                                                }} label="Milestone" />


                                                                <Chip icon={<GetAppIcon />} disabled={row?.invoices?.filter((v) => v.location != null)?.length == 0} size='small' onClick={() => {
                                                                    row.invoices.forEach((item, i) => {
                                                                        if (item.location) {
                                                                            setTimeout(() => {
                                                                                window.open(item.location, "_blank");
                                                                            }, 500);

                                                                        }

                                                                    })

                                                                }} variant='primary' color='info' sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'none', // Keep the background color same on hover
                                                                    },
                                                                    '& .MuiChip-label': {
                                                                        color: 'inherit', // Keep the text color same on hover
                                                                    },
                                                                }} label="Invoice" />
                                                            </>}
                                                            <LeaderboardIcon cursor="pointer" size="small" color='info' onClick={() => navigate("/campaign/postcampaign/" + campaignInfluencersList?.id + "/" + row?.influencer?.id, { state: { name: campaignInfluencersList.name, infname: row?.influencer.name, type: campaignInfluencersList.type } })} />

                                                            {(row.isDeleted == 0 && campaignInfluencersList?.type !== CAMPAIGN_TYPES.AFFILIATE &&
                                                                (
                                                                    (campaignInfluencersList?.shipmentDetails == 1 && row?.productStatus == PENDING && new Date() > new Date(row.productShipmentDate)) ||
                                                                    (row.scriptStatus === PENDING && new Date() > new Date(campaignInfluencersList.conceptScriptDate)) ||
                                                                    (row.postStatus === PENDING && new Date() > new Date(campaignInfluencersList.contentSubmissionDate)) ||
                                                                    (row.publishStatus === PENDING && new Date() > new Date(campaignInfluencersList.contentPublishDate))
                                                                )) && (
                                                                    campaignInfluencersList.status !== COMPLETED && campaignInfluencersList.status !== UNPUBLISHED && <DeleteOutlineOutlinedIcon
                                                                        size="small"
                                                                        onClick={() => {
                                                                            if (getUserType() == COMPANY) {
                                                                                setDeleteOpen(row.id)
                                                                            }

                                                                        }}
                                                                        color={getUserType() === COMPANY ? 'error' : "disabled"}
                                                                        sx={{ cursor: "pointer" }}
                                                                    />
                                                                )
                                                            }


                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    row.paymentRequest.payment_request_json.length > 0 && row.paymentRequest.payment_request_json[0].status === PENDING && <TableRow>
                                                        <TableCell colSpan={7}  >
                                                            <Stack p={1.5} borderRadius={1} sx={{ bgcolor: "#F2F8FF !important" }} direction={"row"} gap={2}>

                                                                <Typography variant="body2" color="textSecondary">
                                                                    Payment release request for the final milestone by the Influencer
                                                                </Typography>
                                                                <Chip size='small' onClick={() => {
                                                                    setApprove({
                                                                        status: true, msg: "Are you sure you want to approve this?", title: "Approve", data: {
                                                                            influencerId: row.influencer.id,
                                                                            campaignId: id,
                                                                            reqId: row.paymentRequest.payment_request_json.length > 0 ? row.paymentRequest.payment_request_json[0].id : 0,
                                                                            status: "APPROVED"
                                                                        }
                                                                    })
                                                                }} color='success' variant='outlined' label="Approve" />
                                                                <Chip size='small' onClick={() => {
                                                                    setApprove({
                                                                        status: true, msg: "Are you sure you want to reject this?", title: "Reject", data: {
                                                                            influencerId: row.influencer.id,
                                                                            campaignId: id,
                                                                            reqId: row.paymentRequest.payment_request_json.length > 0 ? row.paymentRequest.payment_request_json[0].id : 0,
                                                                            status: "REJECTED"
                                                                        }
                                                                    })
                                                                }} color='error' variant='outlined' label="Reject" />
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </>
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>

                    </TableContainer>
                </Paper>
            </Box >
            <MilestoneHistory setHistoryOpen={setHistoryOpen} historyOpen={historyOpen} campaignInfluencersList={campaignInfluencersList} name="Rohit Pathak" milestoneData={milestoneData} />
            <CommonModel
                open={deleteOpen}
                handleDialogClose={() => setDeleteOpen(false)}
                dialogTitle={"Remove influencer"}
                className={"btnDanger"}
                dialogPara={`Do you want to remove this influencer from this campaign? `}
                nvYes={"Remove"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(removeInfluencersfromCampaign(id, deleteOpen));
                    setDeleteOpen(false);
                }}
            />
            <CommonModel
                open={approve.status}
                handleDialogClose={() => setApprove((prev) => ({ ...prev, status: false }))}
                dialogTitle={approve.title}
                className={approve.title == "Reject" ? "btnDanger" : ""}
                dialogPara={approve.msg}
                nvYes={"Yes"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(approveReleasePayment(approve.data));
                    setApprove((prev) => ({ ...prev, status: false }));
                }}
            />
            {openModal && <ScriptListing campaignId={id} influencer={influencer} openModal={openModal} setOpenmodel={setOpenmodel} />}

        </>
    );
}
