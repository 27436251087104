import {
  FormLabel,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import AvatarComponent from "../components/avatar";
import NormalInput from "../components/inputs/normalInput/index";
import CustomizedSnackbars from "../components/snackbar";
import { useGetAdminQuery } from "../redux/api";
import { ROUTES } from "../utils/routes";
import { adminUserChangePasswordValidationSchema } from "../utils/schema";
import { changeAdminPassword } from './campaigns/action';


const AdminChangePassword = () => {
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });
  const defaultValue = { email: "", name: "", roleId: "", password: "", newPassword: "" };
  const [formData, setFormData] = useState(defaultValue);
  const { id } = useParams();
  const { data, isLoading: userLoading } = useGetAdminQuery(id);
  const [value, setValue] = useState(defaultValue);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.status == "SUCCESS") {
      setValue({
        email: data.result[0].email,
        name: data.result[0].adminName,
        roleId: data.result[0].roleId,
        id: data.result[0].id,
      });
    }
  }, [userLoading]);


  return (
    <>
      <Box>
        <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
        <Stack direction="row" alignItems="center" spacing={3} mb={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              minHeight: '42px'
            }}
          >
            <Typography variant="h1" className="page-title">
              Change Passowrd
            </Typography>
          </Stack>
        </Stack>
        <Box
          p={3}
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',
          }}
        >
          <Formik
            initialValues={value}
            enableReinitialize={true}
            validateOnChange={true}
            validationSchema={adminUserChangePasswordValidationSchema}
            onSubmit={(values, { setSubmitting }) => {

              delete values['newPassword']
              dispatch(changeAdminPassword(values));
              navigate(ROUTES.ADMIN_USERS)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              isValid,
              dirty,
            }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                >

                  <Stack gap={3} justifyContent={"center"}>
                    <Stack direction={"row"} alignItems={"center"} gap={1.25}>
                      <AvatarComponent
                        width={28}
                        height={28}
                        sx={{
                          color: '#000',
                        }}
                      />
                      <Typography variant="h4" className="subtitle1">Change Your Passowrd</Typography>
                    </Stack>
                    <div className="row flex-column gap-3">
                      <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">
                        <FormLabel required>Password</FormLabel>
                        <NormalInput
                          name={"password"}
                          type={"password"}
                          focused={false}
                          label={("")}
                          sizeval="medium"
                          values={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.password) && errors.password}
                          helperText={touched.password && errors.password}
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">
                        <FormLabel required>Confirm Password</FormLabel>
                        <NormalInput
                          name={"newPassword"}
                          type={"password"}
                          focused={false}
                          label={("")}
                          sizeval="medium"
                          values={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.newPassword) && errors.newPassword}
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12 gap-2 d-flex justify-content-end">
                        <button className="btnSecondary" onClick={() => navigate(-1)}>Cancel</button>
                        <button className="btnPrimary" type="submit">Update Password</button>
                      </div>
                    </div>
                  </Stack>
                </Form>
              );
            }}
          </Formik >
        </Box>
      </Box>
    </>
  );
};

export default AdminChangePassword;
