import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { getFormatedDate } from '../../utils/common';
const RequestedPaymentHistory = (props) => {
    const { open, setOpen, history } = props;
    const handleClose = () => setOpen(false);
    console.log("history", history)

    return (
        <>
            <Dialog maxWidth={"sm"} fullWidth open={open} onClose={handleClose}>
                <DialogContent className='p-0'>
                    <Grid container >
                        <Grid item p={3} xs={12} lg={12} md={12} sm={12}>
                            <Typography variant='h4' gutterBottom>
                                “Influencer Requested Payment” History
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date & Time</TableCell>
                                            <TableCell >Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {history != null && history?.length > 0 ? history?.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell  >{row.createdDate ? getFormatedDate(row.createdDate, "DD-MM-YYYY HH:mm:ss") : "N/A"}</TableCell>
                                                <TableCell  > <button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button></TableCell>
                                            </TableRow>
                                        )) : <TableRow><TableCell colSpan={4} align='center'  >No History Found</TableCell></TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>



                </DialogContent>
                <DialogActions className='px-4 pt-3 pb-4'>
                    <button
                        className='btnSecondary'
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </button>
                </DialogActions>

            </Dialog >

        </>
    );
};

export default RequestedPaymentHistory;