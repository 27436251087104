import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function TabComponent(props) {
  const { tabSectionData } = props;
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: "100%"}}>
      <TabContext value={value}>
        <Box className="tablayout">
          <TabList  onChange={handleChange} aria-label="lab API tabs example">
            {tabSectionData.map((item, index) => (
              <Tab
                key={index + 1}
                label={<h6 className="tabHead">{item.heading}</h6>}
                value={index + 1}
                
              />
            ))}
          </TabList>
        </Box>
        <Box sx={{ borderRadius: "10px", height: "68vh", background: "white" }}>
          {tabSectionData.map((item, index) => (
            <TabPanel key={index + 1} value={index + 1}>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
}
