import { useEffect, useState } from "react";
import { BASE_URL } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";

const Privacy = () => {
    const [data, setData] = useState("");  // Initialize with an empty string

    const getPrivacy = () => {
        const url = BASE_URL.replace("/v1/", "/");
        fetch(url + endpoints.privacyPolicy)
            .then((res) => res.text())  // Assuming the response is in text format
            .then((res) => setData(res))  // Set the response data
            .catch((err) => console.log("Error fetching data", err));
    };

    useEffect(() => {
        getPrivacy();
    }, []);

    return (
        <div style={{ padding: 5 }}>
            {/* Ensure data is valid HTML string */}
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    );
};

export default Privacy;
