import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommonData } from '../../redux/commonSlice';
import { getCompanyid } from '../../utils/common';



export default function Alert() {
    const { message, title, img, status, getPlanButton } = useSelector((state) => state.commonSlice)

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(updateCommonData({
            status: false,
            getPlanButton: false
        }));
    };
    useEffect(() => {
        let id = setTimeout(() => {
            dispatch(updateCommonData({
                status: false,
                getPlanButton: false
            }));
        }, 20000);
        return () => {
            clearTimeout(id);
        }
    }, [status])

    const handleClick = () => {
        window.open("/subscription/active/details/" + getCompanyid(), "_blank")
        // window.location.href = ;
    }
    return (
        <>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={status}
                fullWidth
                maxWidth="xs"
                className='modalToast'
                style={{
                    borderRadius: '16px !important'
                }}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className='smallModal' style={{ padding: '40px' }}>
                    <Typography variant="p" component="div" className="text-center" mt={2} mb={2}><img src={img} alt="success" style={{ width: '70px', height: '70px' }} /></Typography>
                    {title && <Typography variant="h1" component="h1" className="text-center" mb={0.5} style={{ color: '#000', fontSize: '28px' }}>{title}</Typography>}
                    <Typography variant='p' component="p" className="text-center mb-0" style={{ color: '#000', fontSize: '16px', marginTop: 4 }} gutterBottom><span dangerouslySetInnerHTML={{ __html: message }}></span></Typography>
                </DialogContent>
                {getPlanButton && <DialogActions style={{ justifyContent: 'center', }} >
                    <Button sx={{
                        borderRadius: 1, background: "#0077FF ", color: "white", mb: 6, '&:hover': {
                            bgcolor: '#0077FF',
                        },
                    }} onClick={handleClick} startIcon={<img src="/btn.png" style={{ width: "20px" }} alt={"btn"} />} >
                        Get Plan
                    </Button>

                </DialogActions>}
            </Dialog >
        </>
    );
}