import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));
const CustomToolTip = ({ text }) => {
    return <>
        &nbsp;<BootstrapTooltip arrow title={text} placement="top">
            <InfoIcon fontSize='small' color="secondary" />
        </BootstrapTooltip></>
}
export default CustomToolTip