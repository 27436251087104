import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logoIcon from '../assets/images/logoIcon.svg';
import menWithMobile from '../assets/images/onboarding/login_img.png';
import Carousel from 'react-bootstrap/Carousel';

const CreatePassword = () => {
    return (
        <div className='loginPage wrap'>
            <Container fluid className=''>
                <Row className='flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column-reverse flex-column-reverse'>
                    <Col className='p-0' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='bgDiv'>
                            <div className='lftSide d-flex align-items-center justify-content-center text-center'>
                                <div className='slideContainer'>
                                    <Image src={menWithMobile} className='img-fluid' alt="Men with mobile" />
                                    <div className='carousel1 mx-auto mt-5'>
                                        <Carousel controls={false}>
                                            <Carousel.Item>

                                                <Carousel.Caption className='py-0 position-static text-capitalize'>
                                                    <h3>Ready to unlock the gateway to influence?</h3>
                                                    <p>Step into our digital realm and ignite your brand's journey to the stars!</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                            <Carousel.Item>

                                                <Carousel.Caption className='py-0 position-static text-capitalize'>
                                                    <h3>Ready to unlock the gateway to influence?</h3>
                                                    <p>Step into our digital realm and ignite your brand's journey to the stars!</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                            <Carousel.Item>

                                                <Carousel.Caption className='py-0 position-static text-capitalize'>
                                                    <h3>Ready to unlock the gateway to influence?</h3>
                                                    <p>Step into our digital realm and ignite your brand's journey to the stars!</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='p-0'>
                        <div className='formSide d-flex align-items-center justify-content-center'>
                            <div className='fromContainer mx-auto'>
                                <div className='logoIcon text-center mb-4'>
                                    <Image src={logoIcon} alt="Logo Icon" />
                                </div>
                                <h1 className='text-center mb-4'>Update your password</h1>
                                <Form className='frmp pt-3'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='d-block mb-1'>Create New Password</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email address" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='d-block mb-1'>Confirm Password</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email address" />
                                    </Form.Group>
                                    <Button variant="primary w-100 mt-2" type="submit">
                                        Update
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreatePassword