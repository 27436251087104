import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Dialog, DialogContent, Grid, MenuItem, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { contentScriptAcceptOrReject, updateProductStatus } from '../../pages/campaigns/action';
import { getUserType } from '../../utils/common';
import { ADMIN, BID_ACCEPT, CAMPAIGN_TYPES, COMPANY, DELIVERED, LIVE, PENDING, PRODUCT_STATUS, REVIEW, SHIPPED } from '../../utils/constant';
import { ROUTES } from '../../utils/routes';
import NormalInput from '../inputs/normalInput';
import BasicSelect from '../select';
import CommonModel from './Common';
import FileHistory from './FileHistory';

const ScriptListing = (props) => {
    const { openModal, setOpenmodel, influencer, campaignId } = props;
    const handleClose = () => setOpenmodel(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);

    const [history, setHistory] = useState([]);
    const [historyOpen, setHistoryOpen] = useState(false);



    const [status, setStatus] = useState({ text: "", status: "", description: "", type: "script" });
    const {
        scriptDetails, campaignInfluencersList, address, productDetails, linkJourney
    } = useSelector((state) => state.campaignSlice)

    const handleStatus = (e, status) => {
        dispatch(updateProductStatus({ influencerId: productDetails.influencerid, campaignId: productDetails.campaignId, status: status }));
        setOpenmodel(false);
    }



    console.log("campaignInfluencersList", productDetails.postStatus)
    return (
        <>
            <Dialog maxWidth={"lg"} fullWidth open={openModal} onClose={handleClose}>
                <DialogContent className='p-0'>
                    <Stack direction={"row"} gap={3} px={3} py={2.5} className='align-items-center'>
                        <Typography className='text-capitalie' variant='h4' component="h4" style={{ textTransform: "capitalize" }}>{influencer?.name}</Typography>
                        <a
                            style={{ textDecoration: "none", fontSize: '14px', fontWeight: '500' }}
                            href={`${ROUTES.USER_DETAILS}${influencer.id}`} rel="noreferrer" target='_blank'>
                            View Profile Details</a>
                        <div className='d-flex align-items-center' style={{ gap: '5px' }}>
                            <FacebookOutlinedIcon size="small" color='primary' />
                            <InstagramIcon size="small" color='error' />
                        </div>

                    </Stack>
                    <hr />
                    {[CAMPAIGN_TYPES.BARTER, CAMPAIGN_TYPES.PAID].includes(campaignInfluencersList.type) && campaignInfluencersList?.shipmentDetails == 1 &&
                        <Box className="apprvdInflu" px={3} py={2} sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }} gap={5}>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Address: </span>  {address?.location || "N/A"}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">City:</span>   {address?.city || "N/A"}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">State:</span> {address?.state || "N/A"}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Zip Code:</span>  {address?.zipCode || "N/A"}
                            </Typography>
                        </Box>
                    }
                    <hr />

                    {campaignInfluencersList.status == LIVE && [CAMPAIGN_TYPES.BARTER, CAMPAIGN_TYPES.PAID].includes(campaignInfluencersList.type) && campaignInfluencersList?.shipmentDetails == 1 && getUserType() === COMPANY && <>
                        <Grid container>
                            <Grid item xs={12} lg={12} md={12} sm={12} px={3} py={2} className='d-flex align-items-center justify-content-start w-100' gap={3}>
                                <Typography className='fs-6' variant="h5" component="h5">Product Shipment Status:</Typography>
                                <Typography variant="contained" component="p">
                                    {productDetails.productStatus === DELIVERED || productDetails.productStatus === PENDING ? <button className={productDetails.productStatus?.toLowerCase() + " capitalize-first-letter"}>{productDetails.productStatus}</button> :
                                        <BasicSelect
                                            required={false}
                                            name={"brandId"}
                                            label={("")}
                                            values={productDetails.productStatus}
                                            handleChange={(e) => {

                                            }}
                                            options={PRODUCT_STATUS.map((item) => (
                                                <MenuItem value={item} key={item} onClick={(e) => handleStatus(e, item)} disabled={item !== SHIPPED}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                            style={{ flex: 1 }} // Make the BasicSelect fill the remaining space
                                        />}
                                </Typography>
                            </Grid>
                            <hr />
                        </Grid>
                    </>
                    }

                    {[CAMPAIGN_TYPES.BARTER, CAMPAIGN_TYPES.PAID].includes(campaignInfluencersList.type) && campaignInfluencersList?.shipmentDetails == 1 && getUserType() === ADMIN && <>
                        <Grid container>
                            <Grid item xs={12} lg={12} md={12} sm={12} px={3} py={2} className='d-flex align-items-center justify-content-start w-100' gap={3}>
                                <Typography className='fs-6' variant="h5" component="h5">Product Shipment Status:</Typography>
                                <Typography variant="contained" component="p">
                                    <button className={productDetails.productStatus?.toLowerCase() + " capitalize-first-letter"}>{productDetails.productStatus}</button>
                                </Typography>
                            </Grid>
                            <hr />
                        </Grid>
                    </>
                    }



                    <Formik
                        initialValues={{ scripts: scriptDetails }}
                        enableReinitialize={true}
                        validateOnChange={true}
                        // validationSchema={Schema.ScriptDescription}
                        onSubmit={(values, { setSubmitting }) => {

                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >

                                    <FieldArray
                                        name={`scripts`}
                                        render={(array) => {
                                            let headingShown = false;

                                            return Object.entries(values?.scripts)?.map(([key, value], i) => {
                                                //let headingShown = false;
                                                var socialKey = key.toLowerCase();

                                                return value?.map((v, index) => {
                                                    return (
                                                        <>
                                                            {v.fileName !== '' && <Grid className='appInfluPop appInfluPop1' container key={index}>
                                                                <React.Fragment>
                                                                    <Grid item xs={12} lg={12} md={12} sm={12} pr={4} pl={4} pt={1}>
                                                                        <Grid container className='justify-content-between' spacing={3}>

                                                                            {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false &&
                                                                                <>
                                                                                    <Grid item xs={12} lg={3} md={3} sm={3}>
                                                                                        Content Review
                                                                                    </Grid>
                                                                                    <Grid item xs={12} lg={2} md={2} sm={2}>
                                                                                        Caption
                                                                                    </Grid>
                                                                                    <Grid item xs={12} lg={2} md={2} sm={2}>
                                                                                        Status
                                                                                    </Grid>
                                                                                    <Grid item xs={12} lg={2.5} md={2.5} sm={2.5}>
                                                                                        Description
                                                                                    </Grid>
                                                                                    <Grid item xs={12} lg={2.5} md={2.5} sm={2.5}>
                                                                                        Actions
                                                                                    </Grid>

                                                                                </>}


                                                                            <Grid item xs={12} lg={3} md={3} sm={3}>
                                                                                <Typography mb={2.5} component="h5" className='fs-6'>
                                                                                    {socialKey === "script" ? "Content Script" : ""}
                                                                                    {/* {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false && "Content Review"} */}


                                                                                </Typography>

                                                                                <Typography variant='body2' component="p" sx={{
                                                                                    display: 'inline-block',
                                                                                    maxWidth: '100%', // Adjust the maxWidth as needed
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    <a href={v.location} rel="noreferrer" target='_blank'
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            gap: '5px',
                                                                                        }}
                                                                                    >
                                                                                        {socialKey === "script" && <DescriptionOutlinedIcon size="small" color='default' />}
                                                                                        {socialKey === "instagram" && <InstagramIcon size="small" color='error' />}
                                                                                        {socialKey === "youtube" && <YouTubeIcon size="small" color='error' />}
                                                                                        {socialKey === "facebook" && <FacebookIcon size="small" color='default' />}

                                                                                        {v?.fileName && v?.fileName?.length > 20 ? v?.fileName?.slice(0, 20) + "..." : v?.fileName}
                                                                                    </a>



                                                                                </Typography>


                                                                            </Grid>
                                                                            <Grid item xs={12} lg={2} md={2} sm={2}>
                                                                                <Typography mb={2.5} component="h5" className='fs-6'>

                                                                                    {socialKey === "script" ? "Caption" : ""}
                                                                                    {/* {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false && "Caption"} */}
                                                                                </Typography>
                                                                                <Typography variant='body2' component="p">
                                                                                    {v.caption || "N/A"}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={2} md={2} sm={2}>
                                                                                <Typography mb={2.5} component="h5" className='fs-6'>
                                                                                    {socialKey === "script" ? "Status" : ""}
                                                                                    {/* {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false && "Status"} */}

                                                                                </Typography>
                                                                                <button className={v.status?.toLowerCase() + " capitalize-first-letter"}>{v.status}</button>
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={2.5} md={2.5} sm={2.5}>
                                                                                <Typography mb={2.5} component="h4" className='fs-6'>
                                                                                    {socialKey === "script" ? "Description" : ""}
                                                                                    {/* {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false && "Description"} */}
                                                                                    {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false ? headingShown = true : ""}
                                                                                </Typography>
                                                                                <NormalInput
                                                                                    name={`scripts[${key}][${index}].description`}
                                                                                    type={"text"}
                                                                                    focused={false}
                                                                                    label={("")}
                                                                                    sizeval="medium"
                                                                                    placeholder="Enter your description"
                                                                                    values={values.scripts[key][index].description}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    isDisabled={v.status !== REVIEW}
                                                                                    //error={Boolean(touched.description) && errors.description}
                                                                                    helperText={v.description === "" && v.status == REVIEW && getUserType() === COMPANY ? <p className="error" id="outlined-multiline-static-helper-text">Description is required</p> : ""}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={2.5} md={2.5} sm={2.5}>
                                                                                <Typography mb={2.5} component="h4" className='fs-6'>
                                                                                    {socialKey === "script" ? "Actions" : ""}
                                                                                    {/* {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false && "Description"} */}
                                                                                    {(socialKey === "youtube" || socialKey === "instagram") && headingShown === false ? headingShown = true : ""}
                                                                                </Typography>

                                                                                <Stack direction={"row"} gap={1} sx={{ position: "relative", alignItems: 'center', gap: '12px', justifyContent: 'space-between', minHeight: '42px' }}>
                                                                                    {getUserType() === COMPANY && v.status === REVIEW && campaignInfluencersList.status == LIVE && (
                                                                                        <>
                                                                                            {/* // <Stack direction="row" sx={{ position: "relative", gap: '8px', alignItems: 'center', display: 'flex' }}> */}
                                                                                            <span className="viewIcon" onClick={() => {
                                                                                                setStatus({ text: "approve", status: "ACCEPTED", description: v.description, type: "script" });
                                                                                                setOpen(true);
                                                                                                setId(v.id);
                                                                                            }}>Approve</span>
                                                                                            <span className="viewIcon" style={{ color: "#FF0000" }} onClick={() => {
                                                                                                if (v.description) {
                                                                                                    setStatus({ text: "reject", status: "REJECTED", description: v.description, type: "script" });
                                                                                                    setOpen(true);
                                                                                                    setId(v.id);
                                                                                                }
                                                                                            }}>Reject</span>

                                                                                        </>

                                                                                    )}
                                                                                    <Link
                                                                                        style={{
                                                                                            fontSize: '13px',
                                                                                            position: 'unset',
                                                                                            top: socialKey === "script" ? "54px" : '32px',
                                                                                            cursor: v.attempts.length === 0 ? 'not-allowed' : 'pointer',
                                                                                            whiteSpace: "nowrap",
                                                                                            color: "gray",

                                                                                        }}
                                                                                        className={v.attempts.length === 0 ? "cursorDisabled" : ""}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            if (v.attempts.length > 0) {
                                                                                                setHistoryOpen(true);
                                                                                                setHistory(v.attempts);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        View History
                                                                                    </Link>
                                                                                </Stack>
                                                                            </Grid>

                                                                            <Grid item xs={12} md={12} lg={12} sm={12}>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    {socialKey === "script" && <hr />}
                                                                    {socialKey !== "script" && !headingShown && (headingShown = true)}

                                                                </React.Fragment >
                                                            </Grid>}
                                                            <></>
                                                        </>

                                                    );

                                                })

                                            })
                                        }

                                        }
                                    />
                                </Form>
                            );
                        }}
                    </Formik>


                    <hr />
                    {productDetails?.postStatus === BID_ACCEPT && scriptDetails?.Script?.length > 0 &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} ml={1} md={6} sm={12}>

                                <Typography p={3} className='text-capitalie' variant='h5' component="h5" style={{ textTransform: "capitalize" }}>   Publish Links </Typography>
                            </Grid>
                        </Grid>}
                    <Formik
                        initialValues={{ links: scriptDetails }}
                        enableReinitialize={true}
                        validateOnChange={true}
                        key={scriptDetails?.Script?.length > 0}
                        // validationSchema={Schema.ScriptDescription}
                        onSubmit={(values, { setSubmitting }) => {

                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            console.log("values?.links", values?.links)
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >
                                    <FieldArray
                                        name={`links`}
                                        key={scriptDetails?.Script?.length > 0}
                                        render={(array) => {
                                            return Object.entries(values?.links)?.map(([key, value], i) => {
                                                //let headingShown = false;
                                                var socialKey = key.toLowerCase();

                                                return value?.map((v, index) => {
                                                    return (
                                                        <>
                                                            {/* productDetails?.postStatus === BID_ACCEPT && */}
                                                            {v.publishLink && socialKey !== "script" && <Grid className='appInfluPop appInfluPop1' container key={index}>
                                                                <React.Fragment>
                                                                    <Grid item xs={12} lg={12} md={12} sm={12} pr={4} pl={4} pt={1}>
                                                                        <Grid container className='justify-content-between' spacing={3}>
                                                                            <Grid item xs={12} lg={5} md={5} sm={5}>
                                                                                <Typography variant='body2' component="p" sx={{
                                                                                    display: 'inline-block',
                                                                                    maxWidth: '100%', // Adjust the maxWidth as needed
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    <a href={v.publishLink} rel="noreferrer" target='_blank'
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            gap: '5px',
                                                                                        }}
                                                                                    >
                                                                                        {socialKey === "script" && <DescriptionOutlinedIcon size="small" color='default' />}
                                                                                        {socialKey === "instagram" && <InstagramIcon size="small" color='error' />}
                                                                                        {socialKey === "youtube" && <YouTubeIcon size="small" color='error' />}
                                                                                        {socialKey === "facebook" && <FacebookIcon size="small" color='default' />}

                                                                                        {v?.publishLink && v?.publishLink?.length > 20 ? v?.publishLink?.slice(0, 20) + "..." : v?.publishLink}
                                                                                    </a>



                                                                                </Typography>


                                                                            </Grid>

                                                                            <Grid item xs={12} lg={2} md={2} sm={2}>

                                                                                <button className={v.linkStatus?.toLowerCase() + " capitalize-first-letter"}>{v.linkStatus}</button>
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={2.5} md={2.5} sm={2.5}>

                                                                                <NormalInput
                                                                                    name={`links[${key}][${index}].linkDescription`}
                                                                                    type={"text"}
                                                                                    focused={false}
                                                                                    label={("")}
                                                                                    sizeval="medium"
                                                                                    placeholder="Enter your description"
                                                                                    values={values.links[key][index].linkDescription}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    isDisabled={v.linkStatus !== REVIEW}
                                                                                    //error={Boolean(touched.description) && errors.description}
                                                                                    helperText={v.linkDescription === "" && v.linkStatus === REVIEW && getUserType() === COMPANY ? <p className="error" id="outlined-multiline-static-helper-text">Description is required</p> : ""}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={2.5} md={2.5} sm={2.5}>

                                                                                <Stack direction={"row"} gap={1} sx={{ position: "relative", alignItems: 'center', gap: '12px', justifyContent: 'space-between', minHeight: '42px' }}>
                                                                                    {getUserType() === COMPANY && v.linkStatus === REVIEW && campaignInfluencersList.status == LIVE && (
                                                                                        <>
                                                                                            {/* // <Stack direction="row" sx={{ position: "relative", gap: '8px', alignItems: 'center', display: 'flex' }}> */}
                                                                                            <span className="viewIcon" onClick={() => {
                                                                                                setStatus({ text: "approve", status: "ACCEPTED", description: v.linkDescription, type: "link" });
                                                                                                setOpen(true);
                                                                                                setId(v.id);
                                                                                            }}>Approve</span>
                                                                                            <span className="viewIcon" style={{ color: "#FF0000" }} onClick={() => {
                                                                                                if (v.linkDescription) {
                                                                                                    setStatus({ text: "reject", status: "REJECTED", description: v.linkDescription, type: "link" });
                                                                                                    setOpen(true);
                                                                                                    setId(v.id);
                                                                                                }
                                                                                            }}>Reject</span>

                                                                                        </>

                                                                                    )}
                                                                                    <Link
                                                                                        style={{
                                                                                            fontSize: '13px',
                                                                                            position: 'unset',
                                                                                            top: socialKey === "script" ? "54px" : '32px',
                                                                                            cursor: v.attempts.length === 0 ? 'not-allowed' : 'pointer',
                                                                                            whiteSpace: "nowrap",
                                                                                            color: "gray",

                                                                                        }}
                                                                                        className={v.attempts.length === 0 ? "cursorDisabled" : ""}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            if (v.attempts.length > 0) {
                                                                                                setHistoryOpen(true);
                                                                                                setHistory(v.linkAttempts);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        View History
                                                                                    </Link>
                                                                                </Stack>
                                                                            </Grid>

                                                                            <Grid item xs={12} md={12} lg={12} sm={12}>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </React.Fragment >
                                                            </Grid>}
                                                            <></>
                                                        </>

                                                    );

                                                })

                                            })
                                        }

                                        }
                                    />
                                </Form>
                            );
                        }}
                    </Formik>

                    {scriptDetails?.Script == null &&
                        <Grid item xs={12} lg={12} md={12} sm={12} p={2} textAlign={"center"}>
                            No data found!
                        </Grid>
                    }
                    {false && <Grid item xs={12} lg={12} md={12} sm={12} p={4}>
                        <Grid container className='justify-content-between' spacing={3}>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Typography mb={1.5} component="h5" className='fs-6'>
                                    Publish Link
                                </Typography>
                                {Object.entries(scriptDetails)?.map(([key, value], i) => {
                                    var socialKey = key.toLowerCase();

                                    return value?.map((v, index) => {
                                        return v.publishLink && <>

                                            <Typography variant='contained' component="p" mt={1} mb={1}>
                                                {socialKey === "instagram" && <InstagramIcon size="small" color='error' />}
                                                {socialKey === "youtube" && <YouTubeIcon size="small" color='error' />}
                                                {socialKey === "facebook" && <FacebookIcon size="small" color='info' />}
                                                <a href={v.publishLink} rel="noreferrer" target='_blank' style={{ position: "relative", left: 8 }}>
                                                    {v.publishLink}
                                                </a>
                                            </Typography >
                                        </>
                                    })
                                })}
                            </Grid>
                        </Grid>
                    </Grid>}



                </DialogContent >
                <DialogActions className='px-4 pt-3 pb-4'>
                    <button
                        className='btnSecondary'
                        onClick={() => setOpenmodel(false)}
                    >
                        Close
                    </button>
                </DialogActions>

            </Dialog >
            <FileHistory setHistoryOpen={setHistoryOpen} historyOpen={historyOpen} data={history} />
            <CommonModel
                open={open}
                handleDialogClose={() => setOpen(false)}
                dialogTitle={status?.type === "script" ? "Approved/Rejected Content script" : "Approved/Rejected link"}
                dialogPara={`Are you sure you want to ${status?.text} this ${status?.type === "script" ? "script" : "link"}? `}
                nvYes={"Submit"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(contentScriptAcceptOrReject({ status: status?.status, description: status?.description }, id, campaignId, status?.type));
                    setOpen(false);
                }}
            />
        </>
    );
};

export default ScriptListing;