import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useRef, useState } from "react";

//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import { getFixedValue } from "../../../utils/constant";
import { deleteCreator, removeGroupMember } from "../../users/action";
import { updateUser } from "../../users/userSlice";

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, listing } = props;
    const { selectedCheckbox, allRemoveCheckbox
    } = useSelector((state) => state.userSlice);
    const dispatch = useDispatch();
    const createSortHandler =
        (property) => (event) => {
            if (property === "date") {
                onRequestSort(event, property);
            }
            return;
        };
    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
            type: "",
        },
        {
            id: "username",
            numeric: false,
            disablePadding: true,
            label: "Username",
            type: "",
        },
        {
            id: "niches",
            numeric: false,
            disablePadding: true,
            label: "Niches",
            type: "",
        },
        {
            id: "followers",
            numeric: false,
            disablePadding: true,
            label: "Followers",
            type: "",
        },
        {
            id: "state",
            numeric: false,
            disablePadding: true,
            label: "State",
            type: "",
        },
        {
            id: "city",
            numeric: false,
            disablePadding: true,
            label: "City",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                <TableCell>
                    <Checkbox
                        //checked={selectedCheckbox.length === listing?.length}
                        checked={allRemoveCheckbox}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(updateUser({ selectedCheckbox: listing.map((v) => v.id), allRemoveCheckbox: true }))
                            } else {
                                dispatch(updateUser({ selectedCheckbox: [], allRemoveCheckbox: false }))
                            }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </TableCell>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy == headCell.id ? order : false}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                            // IconComponent={headCell.id === "date" ? ArrowDownwardIcon : null}
                            // onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


export default function SelecetedUsers() {

    const [deleteOpen, setDeleteOpen] = useState(0);
    const dispatch = useDispatch();
    const [openTooltipIndexes, setOpenTooltipIndexes] = useState([]);
    const tooltipRefs = useRef([]);
    const { id } = useParams();
    const handleTooltipToggle = (cellIndex) => {
        setOpenTooltipIndexes(openTooltipIndexes.includes(cellIndex) ? [] : [cellIndex]);
    };
    const handleDocumentClick = (event) => {
        const clickedOutsideAllTooltips = tooltipRefs.current.every(ref => ref && !ref.contains(event.target));
        if (clickedOutsideAllTooltips) {
            setOpenTooltipIndexes([]);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const {

        selectedAllUserCheckbox,
        selectedRows,
        selectedCheckbox
    } = useSelector((state) => state.userSlice);




    return (
        <>
            <Stack direction={"column"} gap={2}>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'none',
                    }}>
                    <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                        <div className='allFilter campMgmt'></div>
                        <div className='allFilter campMgmt'>
                            {selectedCheckbox.length > 1 &&
                                <Button variant="contained" color="error" onClick={() => {
                                    dispatch(removeGroupMember(id, [...new Set([...selectedAllUserCheckbox, ...selectedRows.map((v) => v.id)])]));
                                    dispatch(updateUser({ selectedRows: [], selectedCheckbox: [], allRemoveCheckbox: false }));

                                }}>Remove Groups</Button>}
                        </div>
                    </div>

                    <TableContainer className="campListTables" sx={{ maxHeight: '60vh' }} stickyHeader >
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                listing={selectedRows}
                                order={"test"}
                                orderBy={"test"}
                                onRequestSort={() => { }}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            />
                            {console.log("selectedRows", selectedRows)}
                            <TableBody>
                                {selectedRows?.length > 0 ? (
                                    selectedRows?.map((row, index) => {
                                        let username = row?.platforms?.map((v) => v?.username)?.filter((v) => v);
                                        let niches = row?.profileNiche ? JSON?.parse(row?.profileNiche)?.map((v) => v.option)?.toString() || "N/A" : "N/A";
                                        const cellTooltipIndex = index;
                                        const isOpen = openTooltipIndexes.includes(cellTooltipIndex);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={selectedCheckbox?.includes(row.id)}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={selectedCheckbox?.includes(row.id)}
                                            // sx={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedCheckbox?.includes(row.id)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                dispatch(updateUser({ selectedCheckbox: [...selectedCheckbox, row.id], selectedRows: [...(selectedRows || []), row] }))
                                                            } else {
                                                                dispatch(updateUser({ selectedCheckbox: selectedCheckbox.filter((v) => v !== row.id), selectedRows: selectedRows.filter((v) => v.id !== row.id) }))
                                                            }
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />

                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction={"row"} >
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={row.picture}
                                                            sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                                                        />
                                                        <Link

                                                            className="text-decoration-none text-capitalize"
                                                            style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px', marginLeft: '8px', position: "relative", top: 5 }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open(`/userManagement/details/${row.id}`)
                                                            }}
                                                        >
                                                            {row?.name || "N/A"}
                                                        </Link>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">

                                                    {username?.length > 0 ? username?.toString() : "N/A"}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {niches != null && niches.length > 20 ? (
                                                        <div >
                                                            <Tooltip
                                                                ref={ref => tooltipRefs.current[cellTooltipIndex] = ref}
                                                                title={niches}
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                arrow={true}
                                                                open={isOpen}
                                                                disableFocusListener
                                                                disableTouchListener
                                                                disableInteractive
                                                            >
                                                                <span
                                                                    className="showhandCursor"
                                                                    onClick={() => handleTooltipToggle(cellTooltipIndex)}
                                                                >
                                                                    {niches.slice(0, 20)}
                                                                </span>
                                                            </Tooltip>
                                                            {!isOpen && <Tooltip title={niches} arrow={true}>
                                                                <span className="showhandCursor">...</span>
                                                            </Tooltip>}
                                                        </div>
                                                    ) : (
                                                        <span>{niches}</span>
                                                    )}

                                                </TableCell >
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.length > 0 ? getFixedValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v)?.reduce((a, b) => Math?.max(a, b))) : "N/A"}
                                                    {/* {getFixedValue(maxValue(row?.platforms?.map((v) => parseInt(v?.followers))?.filter((v) => v))) || "N/A"} */}
                                                    {/* {row?.followers ? row?.followers + " (" + row?.influencerType + ")" : "N/A"} */}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.state?.trim() !== "null" && row?.state !== '' ? row?.state : "N/A"}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.city?.trim() !== "null" && row?.state !== '' ? row?.city : "N/A"}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Button variant="contained" onClick={() => {
                                                        dispatch(removeGroupMember(id, [row.id]));
                                                        dispatch(updateUser({ selectedRows: selectedRows.filter((v) => v.id !== row.id) }));

                                                    }} color="error" size="small"> Remove</Button>
                                                </TableCell>

                                            </TableRow >
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={11}>
                                        <TableCell colSpan={11} style={{ textAlign: 'center', height: "250px" }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            // sx={{ minHeight: 300 }}
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody >
                        </Table >
                    </TableContainer >
                </Box >
            </Stack >
            <CommonModel
                open={deleteOpen}
                handleDialogClose={() => setDeleteOpen(false)}
                dialogTitle={"Delete Creator"}
                className={"btnDanger"}
                dialogPara={`Are you sure you want to delete this creator? `}
                nvYes={"Delete"}
                nvCancel={"Cancel"}
                yesHandle={() => {
                    dispatch(deleteCreator(deleteOpen));
                    setDeleteOpen(false);
                }}
            />
        </>
    );
}
