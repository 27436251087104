import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Box, FormLabel, Grid, MenuItem, Paper, Stack, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import AutoSuggestion from "../../../components/autocomplete";
import NormalInput from "../../../components/inputs/normalInput";
import NumberInput from "../../../components/inputs/normalInput/number";
import BasicSelect from "../../../components/select";
import Schema from "../../../schema";
import { addNullInsteadofBlank, getDiscountedRate } from "../../../utils/common";
import { phonePreventText, SUBSCRIPTION_ENUM, SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPE } from "../../../utils/constant";
import { createSubscription, getActiveDefaultPlan, getAllCompanyforDropdown } from "../action";
import { updateSubscription } from "../subscriptionSlice";

const AddEditSubscriptions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const {
        companyListing, defaultActivePlan
    } = useSelector((state) => state.subscriptionSlice);
    const initialValues = {
        name: null,
        type: null,
        ordering: null,
        companyId: null,
        startDate: null,
        endDate: null,
        searchView: null,
        profileView: null,
        phoneNumber: null,
        email: null,
        uniqueUsersLogin: null,
        influencerApproved: null,
        paid: null,
        barter: null,
        affiliate: null,
        performance: null,
        monthlyRate: null,
        quarterlyRate: null,
        yearlyRate: null,
        totalDiscount: null,
        monthlyDiscountedRate: null,
        quarterlyDiscountedRate: null,
        yearlyDiscountedRate: null,
        typeOfProducts: "",
        planCost: ""

    }

    useEffect(() => {
        dispatch(updateSubscription({ activeFilters: { type: SUBSCRIPTION_ENUM.DEFUALT, status: SUBSCRIPTION_STATUS.LIVE } }));
        dispatch(getActiveDefaultPlan());
        dispatch(getAllCompanyforDropdown());
    }, [])
    const handleKeyDown = (event) => {
        // Prevent form submission when Enter key is pressed
        if (event.key === 'Enter' && event.target.type !== 'button') {
            event.preventDefault();
        }
    };
    return (
        <>
            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    {!state?.comingFrom ? <>
                        <Typography variant="h1" className="page-title">
                            {state?.id ? "Edit" : "Create"}  Plan
                        </Typography>

                        <Stack
                            className="backText"
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => { }}
                        >
                            <svg
                                width="16"
                                height="12"
                                viewBox="0 0 16 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                    stroke="#0077FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                    stroke="#0077FF"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <Typography variant="h5" color={"primary"} onClick={() => navigate("/subscriptions")}>
                                Back
                            </Typography>
                        </Stack></> : <Typography variant="h1" className="page-title">
                        Create Plan
                    </Typography>}
                </Stack>
            </Stack>
            <Formik
                initialValues={initialValues}
                validationSchema={Schema.Subscriptions}
                enableReinitialize
                validateOnChange
                onSubmit={(values, { resetForm }) => {
                    let infDescovery = [values.searchView, values.profileView, values.phoneNumber, values.email].filter((v) => v)?.length > 0 ? "Influencer Discovery" : ""
                    let campMgnt = [values.uniqueUsersLogin, values.influencerApproved, values.paid, values.barter, values.affiliate, values.performance].filter((v) => v)?.length > 0 ? "Campaign Management" : ""
                    values.typeOfProducts = infDescovery && campMgnt ? infDescovery + "," + campMgnt : infDescovery || campMgnt || ""
                    delete values?.updatedDate;

                    let data = addNullInsteadofBlank(values);
                    data.planCost = data.planCost ? data.planCost : 0;
                    dispatch(createSubscription(data, navigate));
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    isValid,
                    setFieldError,
                    dirty,
                }) => {
                    return (

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }} onKeyDown={handleKeyDown}>
                            <div className='card p-4'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={4} md={4} sm={4}>
                                        <FormLabel required>Plan Name</FormLabel>
                                        <NormalInput
                                            name={"name"}
                                            isRequired={false}
                                            type={"text"}
                                            focused={false}
                                            label={("")}
                                            sizeval="medium"
                                            values={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.name) && errors.name}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4} md={4} sm={4}>
                                        <FormLabel required>Type of Plan</FormLabel>
                                        <BasicSelect
                                            key={values.type}
                                            required={false}
                                            name={"type"}
                                            label={("")}
                                            values={values.type}
                                            defaultValue={values.type}
                                            handleChange={(e) => {
                                                handleChange(e);
                                                if ([SUBSCRIPTION_ENUM.CUSTOM, SUBSCRIPTION_ENUM.DEFUALT].includes(e.target.value)) {
                                                    setFieldValue("monthlyRate", null);
                                                    setFieldValue("quarterlyRate", null);
                                                    setFieldValue("yearlyRate", null);
                                                    setFieldValue("totalDiscount", null);
                                                }
                                            }}
                                            options={SUBSCRIPTION_TYPE.filter((v) => {
                                                if (v === SUBSCRIPTION_ENUM.DEFUALT && defaultActivePlan > 0) {
                                                    return false;
                                                }
                                                return true;
                                            }).map((item) => (
                                                <MenuItem value={item} key={item} disabled={false}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                            style={{ flex: 1 }}
                                        />
                                        {errors.type && touched.type && (
                                            <p className="error">
                                                {`${errors?.type}`}
                                            </p>
                                        )}
                                    </Grid>

                                    {values.type === SUBSCRIPTION_ENUM.STANDARD &&
                                        <Grid item xs={12} lg={4} md={4} sm={4}>
                                            <FormLabel required>Plan Indexing</FormLabel>
                                            <NumberInput
                                                name={"ordering"}
                                                isRequired={false}
                                                type={"number"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.ordering}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 4) {
                                                        handleChange(e);
                                                    } else {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.ordering) && errors.ordering}
                                                helperText={touched.ordering && errors.ordering}
                                            />
                                        </Grid>
                                    }
                                    {values.type === SUBSCRIPTION_ENUM.CUSTOM && <>
                                        <Grid item xs={12} lg={4} md={4} sm={4}>
                                            <FormLabel required>Plan Cost</FormLabel>
                                            <NumberInput
                                                startAdornmentText={<InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>}
                                                startAdornment={true}
                                                placeholder={"Please enter plan cost here.."}
                                                name={"planCost"}
                                                isRequired={false}
                                                type={"number"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.planCost}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 10) {
                                                        handleChange(e);
                                                    } else {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.planCost) && errors.planCost}
                                                helperText={touched.planCost && errors.planCost}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={4} sm={4}>
                                            <FormLabel required>Start Date</FormLabel>
                                            <NormalInput
                                                name={"startDate"}
                                                type={"date"}
                                                focused={false}
                                                isRequired={false}

                                                label={""}
                                                min={new Date().toISOString().split('T')[0]}
                                                sizeval="medium"
                                                defaultValue={values?.startDate}
                                                values={values?.startDate}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    if (values.endDate) {
                                                        const startDate = new Date(
                                                            e.target.value
                                                        );
                                                        const endDate = new Date(values.endDate);
                                                        if (!(endDate >= startDate)) {

                                                            handleChange({
                                                                target: {
                                                                    name: "endDate",
                                                                    value: e.target.value,
                                                                },
                                                            });
                                                        }
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    Boolean(touched.startDate) &&
                                                    errors.startDate
                                                }
                                                helperText={
                                                    touched.startDate && errors.startDate
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={4} sm={4} >
                                            <FormLabel required>End Date</FormLabel>
                                            <NormalInput
                                                name={"endDate"}
                                                type={"date"}
                                                focused={false}
                                                isRequired={false}
                                                isDisabled={!values.startDate}
                                                label={""}
                                                sizeval="medium"
                                                min={values?.startDate}
                                                values={values?.endDate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    Boolean(touched.endDate) && errors.endDate
                                                }
                                                helperText={touched.endDate && errors.endDate}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={4} sm={4}>
                                            <FormLabel required>Company Listing</FormLabel>
                                            <AutoSuggestion

                                                multiple={false}
                                                required={false}
                                                disableClearable={true}
                                                values={values?.companyId}
                                                className="auto-chips-cover"
                                                label={""}
                                                onChange={(e, newValue) => {

                                                    handleChange({
                                                        target: { name: "companyId", value: newValue.id },
                                                    });
                                                }}
                                                placeholder=""
                                                defaultValue={values?.companyId}
                                                id={`users-${values?.companyId}`}
                                                size="small"
                                                name="companyId"
                                                error={Boolean(touched.companyId) && errors.companyId}
                                                helperText={touched.companyId && errors.companyId}
                                                options={companyListing}
                                                isOptionEqualToValue={(option, value) => {
                                                    if (
                                                        value === "" ||
                                                        option === "" ||
                                                        value === option
                                                    )
                                                        return option;
                                                }}
                                                getOptionLabel={(option) =>
                                                    option.companyName || ""
                                                }
                                                renderOption={(props, option) => {
                                                    return (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                "& > img": { mr: 2, flexShrink: 0 },
                                                                fontFamily: "Poppins",
                                                            }}
                                                            {...props}
                                                        >
                                                            {option.companyName}
                                                        </Box>
                                                    );
                                                }}
                                            />

                                        </Grid>

                                    </>}
                                </Grid >
                            </div>

                            {values.type && <div className='card cretePlan p-4'>
                                <div className='row'>
                                    <div className='col-12 mb-4'>
                                        <Typography variant="h4" component="h4" sx={{ fontSize: '18px' }}>
                                            Product Selection
                                        </Typography>
                                    </div>
                                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                        <div className='borderBox'>
                                            <Typography variant="h4" component="h4" sx={{ fontSize: '15px' }}>
                                                Influencer Discovery
                                            </Typography>
                                            <Box className='numbBox' component="form" noValidate autoComplete="off">
                                                <div className='row g-3 align-items-center'>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                            Search View
                                                        </Typography>
                                                    </div>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <NumberInput
                                                            name={"searchView"}
                                                            isRequired={false}
                                                            type={"number"}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.searchView}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.searchView) && errors.searchView}
                                                            helperText={touched.searchView && errors.searchView}
                                                        />
                                                    </div>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                            Profile View
                                                        </Typography>
                                                    </div>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <NumberInput
                                                            name={"profileView"}
                                                            isRequired={false}
                                                            type={"number"}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.profileView}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.profileView) && errors.profileView}
                                                            helperText={touched.profileView && errors.profileView}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='cntCard'>
                                                    <Typography variant="h5" component="h5" sx={{ fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}>
                                                        Contact Details
                                                    </Typography>
                                                    <div className='row g-3 align-items-center'>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                                Phone Number
                                                            </Typography>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <NumberInput
                                                                name={"phoneNumber"}
                                                                isRequired={false}
                                                                type={"number"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.phoneNumber}
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 10) {
                                                                        handleChange(e);
                                                                    } else {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.phoneNumber) && errors.phoneNumber}
                                                                helperText={touched.phoneNumber && errors.phoneNumber}
                                                            />
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                                Email ID
                                                            </Typography>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <NumberInput
                                                                name={"email"}
                                                                isRequired={false}
                                                                type={"number"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.email) && errors.email}
                                                                helperText={touched.email && errors.email}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                                        <div className='borderBox'>
                                            <Typography variant="h4" component="h4" sx={{ fontSize: '15px' }}>
                                                Campaign Management
                                            </Typography>
                                            <Box className='numbBox' component="form" noValidate autoComplete="off">
                                                <div className='row g-3 align-items-center'>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                            Unique User Logins
                                                        </Typography>
                                                    </div>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <NumberInput
                                                            name={"uniqueUsersLogin"}
                                                            isRequired={false}
                                                            type={"number"}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.uniqueUsersLogin}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.uniqueUsersLogin) && errors.uniqueUsersLogin}
                                                            helperText={touched.uniqueUsersLogin && errors.uniqueUsersLogin}
                                                        />
                                                    </div>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                            Influencer Approved
                                                        </Typography>
                                                    </div>
                                                    <div className='col-md-6 col-sm-6 col-6'>
                                                        <NumberInput
                                                            name={"influencerApproved"}
                                                            isRequired={false}
                                                            type={"number"}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.influencerApproved}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.influencerApproved) && errors.influencerApproved}
                                                            helperText={touched.influencerApproved && errors.influencerApproved}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='cntCard'>
                                                    <Typography variant="h5" component="h5" sx={{ fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}>
                                                        Campaign type
                                                    </Typography>
                                                    <div className='row g-3 align-items-center'>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                                Paid
                                                            </Typography>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <NumberInput
                                                                name={"paid"}
                                                                isRequired={false}
                                                                type={"number"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.paid}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.paid) && errors.paid}
                                                                helperText={touched.paid && errors.paid}
                                                            />
                                                        </div>

                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                                Barter
                                                            </Typography>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <NumberInput
                                                                name={"barter"}
                                                                isRequired={false}
                                                                type={"text"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.barter}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.barter) && errors.barter}
                                                                helperText={touched.barter && errors.barter}
                                                            />
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                                Affiliate
                                                            </Typography>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <NumberInput
                                                                name={"affiliate"}
                                                                isRequired={false}
                                                                type={"number"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.affiliate}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.affiliate) && errors.affiliate}
                                                                helperText={touched.affiliate && errors.affiliate}
                                                            />
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                                Performance
                                                            </Typography>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-6'>
                                                            <NumberInput
                                                                name={"performance"}
                                                                isRequired={false}
                                                                type={"number"}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.performance}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={Boolean(touched.performance) && errors.performance}
                                                                helperText={touched.performance && errors.performance}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            }


                            {values.type === SUBSCRIPTION_ENUM.STANDARD &&
                                <>
                                    <div className='card p-4 box-shadow-none'>
                                        <div className='row'>
                                            <div className='col-lg-8 col-md-9 col-sm-12 col-12'>
                                                <div className='row align-items-center justify-content-between'>
                                                    <div className='col-4'>
                                                        <Typography variant="h4" component="h4" sx={{ fontSize: '18px' }}>
                                                            Plan Cost
                                                        </Typography>
                                                    </div>
                                                    <div className='col-8 text-end'>
                                                        <NumberInput
                                                            className="ttldiscount"
                                                            name={"totalDiscount"}
                                                            isRequired={false}
                                                            startAdornment={true}
                                                            startAdornmentText=" Discount (%)"
                                                            type={"number"}
                                                            focused={false}

                                                            label={("")}
                                                            // placeholder={"Please enter discount here.."}
                                                            sizeval="medium"
                                                            values={values.totalDiscount}
                                                            onKeyDown={(e) => {
                                                                phonePreventText.includes(e.key) &&
                                                                    e.preventDefault()
                                                            }
                                                            }
                                                            onChange={(e) => {

                                                                if (e.target.value <= 100 && e.target.value !== "0") {
                                                                    handleChange(e);
                                                                    setFieldValue("monthlyDiscountedRate", e.target.value > 0 ? String(getDiscountedRate(parseFloat(e.target.value), parseFloat(values.monthlyRate || 0))) : "")
                                                                    setFieldValue("quarterlyDiscountedRate", e.target.value > 0 ? String(getDiscountedRate(parseFloat(e.target.value), parseFloat(values.quarterlyRate || 0))) : "")
                                                                    setFieldValue("yearlyDiscountedRate", e.target.value > 0 ? String(getDiscountedRate(parseFloat(e.target.value), parseFloat(values.yearlyRate || 0))) : "")

                                                                } else {
                                                                    e.preventDefault();
                                                                }

                                                            }}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.totalDiscount) && errors.totalDiscount}
                                                            helperText={touched.totalDiscount && errors.totalDiscount}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='discountTable mt-3'>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}></TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Monthly (₹)</TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Quarterly (₹)</TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Yearly (₹)</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow
                                                                >
                                                                    <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Actual Rates
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                        <NumberInput
                                                                            name={"monthlyRate"}
                                                                            isRequired={false}
                                                                            type={"number"}
                                                                            focused={false}
                                                                            label={("")}
                                                                            onKeyDown={(e) =>
                                                                                phonePreventText.includes(e.key) &&
                                                                                e.preventDefault()
                                                                            }
                                                                            sizeval="medium"
                                                                            values={values.monthlyRate}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                setFieldValue("monthlyDiscountedRate", String(getDiscountedRate(parseFloat(values.totalDiscount), parseFloat(e.target.value))))
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            error={Boolean(touched.monthlyRate) && errors.monthlyRate}
                                                                            helperText={touched.monthlyRate && errors.monthlyRate}
                                                                        /></TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><NumberInput
                                                                        name={"quarterlyRate"}
                                                                        isRequired={false}
                                                                        type={"number"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        onKeyDown={(e) =>
                                                                            phonePreventText.includes(e.key) &&
                                                                            e.preventDefault()
                                                                        }
                                                                        sizeval="medium"
                                                                        values={values.quarterlyRate}
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            setFieldValue("quarterlyDiscountedRate", String(getDiscountedRate(parseFloat(values.totalDiscount), parseFloat(e.target.value))))
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={Boolean(touched.quarterlyRate) && errors.quarterlyRate}
                                                                        helperText={touched.quarterlyRate && errors.quarterlyRate}
                                                                    /></TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><NumberInput
                                                                        name={"yearlyRate"}
                                                                        isRequired={false}
                                                                        type={"number"}
                                                                        onKeyDown={(e) =>
                                                                            phonePreventText.includes(e.key) &&
                                                                            e.preventDefault()
                                                                        }
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={values.yearlyRate}
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            setFieldValue("yearlyDiscountedRate", String(getDiscountedRate(parseFloat(values.totalDiscount), parseFloat(e.target.value))))
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={Boolean(touched.yearlyRate) && errors.yearlyRate}
                                                                        helperText={touched.yearlyRate && errors.yearlyRate}
                                                                    /></TableCell>
                                                                </TableRow>

                                                                <TableRow
                                                                >
                                                                    <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Discounted Rates
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><NormalInput
                                                                        name={"monthlyDiscountedRate"}
                                                                        isDisabled={true}
                                                                        readOnly={true}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={values.monthlyDiscountedRate}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={Boolean(touched.monthlyDiscountedRate) && errors.monthlyDiscountedRate}
                                                                        helperText={touched.monthlyDiscountedRate && errors.monthlyDiscountedRate}
                                                                    /></TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><NormalInput
                                                                        name={"quarterlyDiscountedRate"}
                                                                        isDisabled={true}
                                                                        readOnly={true}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={values.quarterlyDiscountedRate}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={Boolean(touched.quarterlyDiscountedRate) && errors.quarterlyDiscountedRate}
                                                                        helperText={touched.quarterlyDiscountedRate && errors.quarterlyDiscountedRate}
                                                                    /></TableCell>
                                                                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><NormalInput
                                                                        name={"yearlyDiscountedRate"}
                                                                        isDisabled={true}
                                                                        readOnly={true}
                                                                        type={"text"}
                                                                        focused={false}
                                                                        label={("")}
                                                                        sizeval="medium"
                                                                        values={values.yearlyDiscountedRate}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={Boolean(touched.yearlyDiscountedRate) && errors.yearlyDiscountedRate}
                                                                        helperText={touched.yearlyDiscountedRate && errors.yearlyDiscountedRate}
                                                                    /></TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className='card p-4'>
                                <div className='row'>
                                    <div className='col-12 d-flex align-items-center justify-content-end text-end'>
                                        <button className="btnSecondary" onClick={(e) => navigate("/subscriptions")}>Cancel</button>
                                        <button
                                            className="btnPrimary ms-2"
                                            type="submit"
                                        //disabled={!(isValid && dirty)}
                                        >
                                            Create Plan
                                        </button>
                                    </div>
                                </div>
                            </div>




                        </Form >
                    );
                }}
            </Formik>
        </>

    );

}
export default AddEditSubscriptions;