import { Edit } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { updateCommonData } from "../../redux/commonSlice";
import { MAX_FILE_SIZE } from "../../utils/constant";


function ImageUpload(props) {
    const { handleFile, label, fileObject, fileUrl, name, previewImg } = props;
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file && file.size > MAX_FILE_SIZE) {
            dispatch(updateCommonData({
                message: "File size exceeds 2 MB. Please upload a smaller file.",
                title: "Failed",
                img: "/failed.png",
                status: true
            }));
            event.target.value = '';
            return false;
        }

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                previewImg(reader.result);
            };
            reader.readAsDataURL(file);
        }
        handleFile(file);
    };

    useEffect(() => {
        if (fileObject?.name !== undefined) {

            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(fileObject);
        }
        if (fileUrl !== "") {
            setImage(fileUrl);
        }
    }, [fileObject, fileUrl]);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <Typography mb={2} variant="h6">
                {label}
            </Typography>
            <Box
                component={IconButton}
                disableRipple
                sx={{
                    width: { xs: "100%", lg: "80%" },
                    aspectRatio: "1/1",
                    position: "relative",
                    borderRadius: "10px",
                    padding: '0px',
                    background: theme.palette.grey[100],
                    border: `1px dashed ${theme.palette.grey[300]}`,
                }}
            >
                {image ? (
                    <>
                        <input
                            type="file"
                            accept="image/*"
                            id="image"
                            name={name}
                            ref={fileInputRef}
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                        />
                        <label
                            htmlFor="image-upload"
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <img
                                src={image}
                                alt="Uploaded"
                                style={{ width: "100%", height: "auto" }}
                            />
                            <IconButton
                                disableRipple
                                disableFocusRipple
                                onClick={handleClick}
                                sx={{
                                    position: "absolute",
                                    bottom: "20px",
                                    right: "-20px",
                                    color: "white",
                                    background: "#0077FF",
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </label>
                    </>
                ) : (
                    <React.Fragment>
                        <input
                            type="file"
                            name={name}
                            ref={fileInputRef}
                            accept="image/jpeg, image/png, image/jpg"
                            id="image-upload"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                        />
                        <label
                            htmlFor="image-upload"
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{ cursor: "pointer" }}
                                component={"svg"}
                                width="50"
                                height="38"
                                viewBox="0 0 45 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M41.5385 0H3.46154C2.54348 0 1.66303 0.31607 1.01386 0.87868C0.364697 1.44129 0 2.20435 0 3V30C0 30.7957 0.364697 31.5587 1.01386 32.1213C1.66303 32.6839 2.54348 33 3.46154 33H41.5385C42.4565 33 43.337 32.6839 43.9861 32.1213C44.6353 31.5587 45 30.7957 45 30V3C45 2.20435 44.6353 1.44129 43.9861 0.87868C43.337 0.31607 42.4565 0 41.5385 0ZM41.5385 3V22.2656L35.8983 17.3794C35.5769 17.1007 35.1952 16.8797 34.7752 16.7289C34.3552 16.5781 33.905 16.5005 33.4504 16.5005C32.9957 16.5005 32.5455 16.5781 32.1255 16.7289C31.7055 16.8797 31.3238 17.1007 31.0024 17.3794L26.6755 21.1294L17.1563 12.8794C16.5072 12.3172 15.627 12.0014 14.7094 12.0014C13.7917 12.0014 12.9116 12.3172 12.2625 12.8794L3.46154 20.5069V3H41.5385ZM3.46154 24.75L14.7115 15L32.0192 30H3.46154V24.75ZM41.5385 30H36.9151L29.1267 23.25L33.4536 19.5L41.5385 26.5088V30ZM25.9615 11.25C25.9615 10.805 26.1138 10.37 26.3991 9.99997C26.6843 9.62996 27.0898 9.34157 27.5642 9.17127C28.0386 9.00097 28.5606 8.95642 29.0642 9.04323C29.5678 9.13005 30.0304 9.34434 30.3934 9.65901C30.7565 9.97368 31.0038 10.3746 31.104 10.811C31.2041 11.2475 31.1527 11.6999 30.9562 12.111C30.7597 12.5222 30.427 12.8736 30 13.1208C29.5731 13.368 29.0712 13.5 28.5577 13.5C27.8691 13.5 27.2088 13.2629 26.7219 12.841C26.2351 12.419 25.9615 11.8467 25.9615 11.25Z"
                                    fill="#E9E9E9"
                                />
                            </Box>
                            <IconButton
                                onClick={handleClick}
                                disableRipple
                                disableFocusRipple
                                sx={{
                                    position: "absolute",
                                    bottom: "20px",
                                    right: "-20px",
                                    color: "white",
                                    background: "#0077FF",
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </label>
                    </React.Fragment>
                )}
            </Box>
        </>
    );
}

export default ImageUpload;
