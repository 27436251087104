import {
  AddCircle
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Pagination,
  Stack,
  TablePagination,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import * as React from "react";

import { visuallyHidden } from "@mui/utils";
//import SelectPerPage from "../../components/select/paginationSelect";
import useDebounce from "../../hooks/use.debounce";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

import PaginationSelect from "../../components/select/pagination";
import { getAllBrandsforListing } from "./action";
import { updateBrand } from "./brandSlice";





function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  const headCells = [
    {
      id: "logo",
      numeric: false,
      disablePadding: true,
      label: "Brand Name",
      type: "",
    },
    {
      id: "product_name",
      numeric: false,
      disablePadding: true,
      label: "Product Name",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Action",
      type: "",
    },
  ];





  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className="hideActionSort">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={() => null}

              //onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}



export default function BrandsForCompany(props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const dispatch = useDispatch();
  const {
    limit,
    page,
    search,
    sort_key,
    totalCount,
    totalPages,
    listing,
  } = useSelector((state) => state.brandSlice);
  const debouncedSearch = useDebounce(search, 500);




  const handleChangePerPage = (event, value) => {
    dispatch(updateBrand({ limit: event.target.value, page: 0 }));
    dispatch(getAllBrandsforListing());
  };

  const handleChange = (event, value) => {
    dispatch(updateBrand({ page: value - 1 }));
    dispatch(getAllBrandsforListing());
  };


  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateBrand({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );
    dispatch(getAllBrandsforListing());
  };

  React.useEffect(() => {
    dispatch(getAllBrandsforListing());

  }, [debouncedSearch]);

  const handleSearch = (event, value) => {
    dispatch(updateBrand({ search: event.target.value }));
  };

  const handleSearchClear = () => {
    dispatch(updateBrand({ search: "" }));
    dispatch(getAllBrandsforListing());
  };



  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1">Brand Management</Typography>
          <button
            className="btnPrimary"
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => navigate("/company-brands/add")}
          >
            Create Brand
          </button>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',
          }}>
          <TableContainer>
            <Table

              aria-labelledby="tableTitle"
            //size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                // numSelected={selected.length}

                order={order}
                orderBy={sort_key}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                //rowCount={rows.length}
                numSelected={0}
                onSelectAllClick={function (

                ) {
                  throw new Error("Function not implemented.");
                }}
              //order={"desc"}
              />
              <TableBody>
                {listing && listing?.length ? (
                  listing?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        className={"tableRow"}
                        style={{ userSelect: "text" }}
                      >

                        <TableCell component="th" scope="row" className="d-flex align-items-center" padding="none">
                          <Avatar
                            alt={row?.brandName}
                            src={row.brandImage ? row?.brandImage : "/profile.jpg"}
                            sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                          />
                          <span style={{ marginLeft: "8px" }}>{row?.brandName || "N/A"}</span>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row?.product?.map((v) => v.name)?.toString()}

                        </TableCell>


                        <TableCell>
                          <Stack direction={"row"} gap={0.5}>
                            <div>
                              <span className="editIcon"
                                style={{ color: "#0077FF" }}
                                onClick={() => navigate(`${ROUTES.BRANDS_FOR_COMPANY}/${row.id}`, { state: row })}
                              >
                                Edit
                              </span>
                            </div>
                            {/* <ModeEditIcon color="secondary" size="small" onClick={() => navigate(`${ROUTES.BRANDS_FOR_COMPANY}/${row.id}`, { state: row })} /> */}
                            {/* <RemoveRedEyeIcon color="info" size="small" /> */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        alignItems={"center"}
                        component="h3"
                      >
                        No Data found!
                      </Typography>

                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <hr />
          <Stack
          sx={{
            background: 'transparent',
          }}
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
              <Box className="d-flex align-items-center rowPerPage" gap={0}>
                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
              </Box>
              <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                <Typography variant="subtitle2">
                  {listing && listing.length > 0
                    ? page === 0
                      ? `0 - ${listing.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <Pagination
                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </Stack>
          {false && <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            style={{ padding: '16px 24px' }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              sx={{
                "& .MuiTablePagination-selectLabel": {
                  mb: 0.5,
                },
                "& .MuiTablePagination-displayedRows": {
                  mb: 0.5,
                },
                border: 0,

              }}
              count={1}
              rowsPerPage={5}
              page={0}
              onPageChange={() => { }}
              onRowsPerPageChange={() => { }}
            />
          </Stack>}
        </Box>
      </Stack>
    </>
  );
}
