import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import CopyToClipboardButton from "../copy/CopyToClipboardButton";

export default function UserDetailsTooltip({ open, setOpen, text, setOpen1 }) {

    const [open2, setOpen2] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
        //  setOpen1(false);
    };

    const handleClick = () => {

    }

    return (
        <>
            <Box className='phoneTooltip'>
                <Tooltip

                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="left-end"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'transparent', // Override tooltip background color

                            },
                        },
                    }}
                    title={
                        <Box
                            className="influDetCnt"
                            sx={{
                                background: 'rgba(252, 229, 186, 25%) !important',
                                color: "black !important",
                                p: 0,
                                position: 'relative',
                                minWidth: '200px',
                                padding: '10px',
                                height: '42px',
                                border: '1px solid #FCE5BA',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '20px',
                                width: 'fit-content'
                            }}
                        >
                            <Typography variant='subtitle1' sx={{ mt: 2 }}>{text}</Typography>
                            <div className='cpyCls'>
                                {text && <ContentCopyIcon onClick={() => {

                                    setOpen2(true);
                                }} />}
                                <CloseIcon onClick={handleTooltipClose} fontSize="small" sx={{ fontSize: "6px !important" }} />
                            </div>

                        </Box>
                    }
                >

                </Tooltip>

            </Box>
            {open2 && <CopyToClipboardButton text={text} open3={open2} setOpen2={setOpen2} />}
        </>
    );
}
