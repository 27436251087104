import {
    Search
} from "@mui/icons-material";
import { Box, Button, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Form, Formik } from 'formik';
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../components/inputs/searchInput";
import { AGE_GROUP, citiesInIndia } from "../../../utils/constant";
import { addNewCampaign, getInfluencerProfilesSearch } from "../action";
import { updateCampaign } from "../campaignSlice";
const Filter = ({ setfilterState }) => {

    const { niches, influencerType } = useSelector((state) => state.commonSlice);
    const { advanceFilter } = useSelector((state) => state.campaignSlice);
    //const navigate = useNavigate();

    const [audienceLocation, setAudienceLocation] = useState([]);

    const dispatch = useDispatch();

    const initialValue = {
        niches: [],
        creatorLocation: [],
        influencerType: [],
        audienceLocation: [],
        creatorGender: "male",
        audienceGender: "male",
        nichesSearch: "",
        creatorLocationSearch: "",
        audienceLocationSearch: [],
        age: []
    }

    const [values, setValues] = useState(initialValue);
    useEffect(() => {
        setAudienceLocation(citiesInIndia);
    }, [niches]);



    return <Grid container  >


        <Formik
            initialValues={values}
            enableReinitialize={true}
            //validateOnChange={true}
            // validationSchema={Schema.CampaignInfo}
            onSubmit={(values, { setSubmitting }) => {

                // const filterData = encodedURI(removeEmptyValues(values));
                let audienceData = { age: values.age, audienceGender: values.audienceGender, audienceLocation: values.audienceLocation };

                let audiencePostdata = { audience: JSON.stringify({ ageGroup: values.age, audioPrefGender: values.audienceGender, creatorLocation: values.audienceLocation }) };

                dispatch(updateCampaign({ advanceFilter: { ...advanceFilter, ...audienceData } }));
                dispatch(addNewCampaign(audiencePostdata, "test"));
                dispatch(getInfluencerProfilesSearch());
                setValues(values);
                setfilterState(false);
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                dirty,
            }) => {
                return (
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}>
                        <Grid container bgcolor={"#E2EAF3"}>
                            <Grid item xs={12} lg={12} md={12} sm={12} pl={4} mt={2} mb={2}>
                                <Typography variant="h4" component="h4" >Audience Filter</Typography>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12} mr={2} bgRight={1} pl={2}  >
                                <Grid item xs={12} lg={12} md={12} sm={12} mt={2} border={"1px solid #EEEEEE"} bgcolor={"#FFFFFF"} p={1}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Audience Location
                                    </Typography>
                                    <SearchInput
                                        className="inputSearch p-0"
                                        value={values.audienceLocationSearch}
                                        placeholder={"Search here..."}
                                        handleSearch={(e) => {
                                            if (e.target.value) {
                                                setAudienceLocation(citiesInIndia?.filter((v) => v.toLowerCase().includes(e.target.value)));
                                            } else {
                                                setAudienceLocation(citiesInIndia);
                                            }
                                            handleChange({
                                                target: {
                                                    name: "audienceLocationSearch",
                                                    value: e.target.value
                                                }
                                            })
                                        }}
                                        clearSearch={() => { }}
                                        startAdornment={
                                            <InputAdornment className="overIcon " position="start"
                                                sx={{
                                                    maxHeight: 'none',
                                                    height: '38px',
                                                    minWidth: '38px',
                                                    marginRight: '0px'
                                                }}
                                            >
                                                <Search />
                                            </InputAdornment>
                                        }
                                    />
                                    <Box
                                        style={{
                                            maxHeight: 150,
                                            overflowY: 'auto',
                                            overflowX: 'hidden',

                                        }}
                                    >
                                        <FormGroup sx={{ gap: 1, mt: 2 }}>
                                            {audienceLocation?.map((v, i) => <FormControlLabel key={i} control={<Checkbox checked={values.audienceLocation.includes(v)} onChange={handleChange} name="audienceLocation" value={v} />} label={v} />)}
                                        </FormGroup>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12} border={"1px solid #EEEEEE"} bgcolor={"#FFFFFF"} mt={2} p={1}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Audience Gender
                                    </Typography>
                                    <Stack direction="row" gap={2}>
                                        <Button variant="contained" size="small" color={values.audienceGender === "male" ? 'info' : 'secondary'} onClick={() => {
                                            handleChange({
                                                target: {
                                                    name: "audienceGender",
                                                    value: "male"
                                                }
                                            })
                                        }}>
                                            Male
                                        </Button>

                                        <Button variant="contained" size="small" color={values.audienceGender === "female" ? 'info' : 'secondary'} onClick={() => {
                                            handleChange({
                                                target: {
                                                    name: "audienceGender",
                                                    value: "female"
                                                }
                                            })
                                        }}>
                                            Female
                                        </Button>
                                        <Button variant="contained" size="small" color={values.audienceGender === "LGBTQ" ? 'info' : 'secondary'} onClick={() => {
                                            handleChange({
                                                target: {
                                                    name: "audienceGender",
                                                    value: "LGBTQ"
                                                }
                                            })
                                        }}>
                                            LGBTQ+
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12} mt={2} border={"1px solid #EEEEEE"} bgcolor={"#FFFFFF"} p={1}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Audience age range
                                    </Typography>
                                    <FormGroup >
                                        {AGE_GROUP.map((v, i) => {
                                            return <FormControlLabel key={i} control={<Checkbox name="age" value={v} checked={values.age.includes(v)} onChange={handleChange} />} label={v} />
                                        })}
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12} mt={4}>
                                    <Stack direction="row" gap={4}>
                                        <button className='btnSecondary' onClick={(e) => {

                                            let audienceData = { age: [], audienceGender: "", audienceLocation: [] }
                                            dispatch(updateCampaign({ advanceFilter: { ...advanceFilter, ...audienceData } }));
                                            setValues(initialValue);
                                            setfilterState(false);
                                        }}>
                                            Clear Filter
                                        </button>
                                        <button className='btnPrimary' type="submit">
                                            Apply Filter
                                        </button>

                                    </Stack>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Form >
                );
            }}
        </Formik >

    </Grid>
}
export default memo(Filter);