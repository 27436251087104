import { colors, createTheme } from "@mui/material";
import GilmerHeavy from "../assets/fonts/Gilmer-Heavy.woff";
import GilmerMedium from "../assets/fonts/Gilmer-Medium.woff";
import GilmerRegular from "../assets/fonts/Gilmer-Regular.woff";
import GilmerThin from "../assets/fonts/Gilmer-Thin.woff";
import GilmerBold from "../assets/fonts/Gilmer-Bold.woff";
import GilmerLight from "../assets/fonts/Gilmer-Light.woff";

// Define your custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#0077FF",
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.50)",
    },
    warning: {
      light: "rgba(253, 126, 20, 0.18)",
      main: "rgba(253, 126, 20, 1)",
    },
    body:{
      fontFamily:'Inter !important'
    }
  },
  typography: {
    h1:{
      fontSize:'24px',
      fontWeight:'600',
      color:'#000',
      margin:'0px',
    },
    h4: {
      color:'#000',
      fontSize:'20px',
      fontWeight:'600',
      fontFamily:'Gilmer',
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    
  },
  components: {
    MuiTableCell:{
      styleOverrides: {
        root: {
          letterSpacing:'0px',
        }
      }
    },

    MuiFormLabel:{
      styleOverrides: {
        root: {
          color:'#6C7692',
          fontSize:'14px',
          display:'block',
          fontWeight:400,
          marginBottom:'1px',
          lineHeight:'normal'
        },
      },
    },
    MuiCheckbox:{
      styleOverrides: {
        root: {
          padding:'0px',
        },
      },
    },
    MuiFormHelperText:{
      styleOverrides: {
        root: {
          fontSize:'13px',
          fontWeight: '500',
          letterSpacing:'0px',
          marginTop:'0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "32px",
          textTransform: "none",
          padding: "12px 16px",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: 1,
          borderWidth: "2px",
          "&:focus": {
            borderWidth: "2px",
          },
          "&:hover": {
            borderWidth: "2px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          fontSize:'13px',
          color:'#6C7692',
          borderRadius: "8px",
          borderColor: "#aaa",
          fontWeight:'normal',
          fill:'none',
          "& .MuiOutlinedInput-input, .MuiSelect-select": {
            lineHeight:'45px',
            height:'auto',
            padding:'0px 10px',
            color:'#6C7692'
          },
        },
      },
    },
  },
});

export default theme;
