import { Box, Chip, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoSuggestion from "../../../components/autocomplete";
import InviteInfulencerModel from "../../../components/dialog";
import ManualSearchModel from "../../../components/dialog/ManualSearch";
import BasicSelect from "../../../components/select";
import { getUserType } from "../../../utils/common";
import { ADMIN, COMPANY, INFLUENCER_NICHE, PLATEFORMS, USER_STATUS } from "../../../utils/constant";
import { getInfluencerProfilesSearch } from "../action";
import { updateCampaign } from "../campaignSlice";
import Steppers from './Stepper';

const useStyles = makeStyles({
    autocompleteListbox: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '15px !important',
        gap: '8px',
    },
    autocompleteOption: {
        padding: '0px !important',
        background: 'transparent !important',
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '5px',
        },
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});


const SearchInfluencer = () => {
    const [openModal, setOpenmodel] = useState(false);
    const [open, setOpen] = useState(false);
    const [influencerNiche, setInfluencerNiche] = useState([]);
    const [plateforms, setPlateform] = useState([]);
    const { paramMasterList } = useSelector((state) => state.commonSlice);
    const { formData, step, nicha, userListing } = useSelector((state) => state.campaignSlice);

    const [value, setValue] = useState({ plateform: "All", influencers: [] });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (paramMasterList?.length > 0) {
            let para = paramMasterList;
            let data = JSON.parse(para?.find((v) => v.parameterkey === INFLUENCER_NICHE)?.value);
            //setInfluencerNiche(data?.map((v) => v.option).splice(0, 5));
            setInfluencerNiche(data?.map((v) => v.option));
            dispatch(updateCampaign({ nicha: data?.filter((v) => ["Art & Creativity", "Parenting & Family", "Travel & Adventure", "Sports & Fitness", "Education & Learning"].includes(v.option)).map((v) => v.option) }));
            let palateformData = JSON.parse(para?.find((v) => v.parameterkey === PLATEFORMS)?.value);
            setPlateform([...['All'], ...palateformData]);
        }

        if (formData?.plateform !== undefined) {
            setValue((prev) => ({ ...prev, influencers: [] }));
        }
        if (formData?.type === undefined && getUserType() === ADMIN) {
            navigate("/campaigns");
        }
        if (formData?.type === undefined && getUserType() === COMPANY) {
            navigate("/my-campaigns");
        }
    }, [formData, paramMasterList])


    const classes = useStyles();
    return <>
        {/*  */}

        <Box display={open ? "none" : ""} >
            <Stack direction={"column"} gap={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">Create New Campaign</Typography>
                </Stack>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'hidden',
                    }}
                >
                    <Grid container>
                        <Grid className='formLevels' item p={5} xs={12} lg={12} md={12} sm={12}>
                            <Steppers step={4} />
                        </Grid>
                        <hr />
                        <Grid container xs={12} lg={12} md={12} sm={12} px={5} py={3}>
                            <Grid item xs={12} lg={12} md={12} sm={12} >
                                <Grid container rowGap={4} className='d-flex align-items-center justify-content-end'>
                                    <Grid item xs={6} lg={6} md={6} sm={6}>
                                        <Typography variant="h4" component="h4">Influencer’s Profile Screener</Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex justify-content-end'>
                                        <Stack direction={"row"} gap={2}>
                                            {/* <Button variant="contained" sx={{ bgcolor: "gray" }} onClick={() => {
                                                setOpen(true);
                                            }}>Manual Search</Button> */}

                                            <button className="btnPrimary" onClick={() => setOpenmodel(true)}>Invite Manually</button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Formik
                                    initialValues={value}
                                    enableReinitialize={true}
                                    validateOnChange={true}
                                    //validationSchema={Schema.Step4}
                                    onSubmit={(values, { setSubmitting }) => {
                                        let jsonString = JSON.stringify({ platform: values.plateform, search: values?.influencers?.toString(), status: USER_STATUS.APPROVED });// campaignId: formData.campaignId

                                        if (values.plateform === "All") {
                                            jsonString = JSON.stringify({ search: values?.influencers?.toString(), status: USER_STATUS.APPROVED });// campaignId: formData.campaignId
                                        }
                                        const encodedString = encodeURIComponent(jsonString);

                                        dispatch(updateCampaign({ formData: { ...formData, ...values }, influencersFilter: encodedString, step: 7, sugType: "suggested", listType: 1 }));
                                        dispatch(getInfluencerProfilesSearch());
                                        navigate("/campaign/profile-screener")
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched,
                                        values,
                                        setFieldValue,
                                        isValid,
                                        dirty,
                                    }) => {

                                        return (
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit(e);
                                                }}
                                            >
                                                <Grid className="campSerchInflu searcPage radius10" container px={5} mt={2} py={2}>
                                                    <Grid item xs={12} lg={12} md={12} sm={12} py={4} sx={{ alignItems: "center" }}  >
                                                        <Typography variant="h4" align="center" component="h4" mb={4}>Search from the Largest Database of Influencers</Typography>
                                                        <Grid className="mx-auto" item xs={12} xl={8} lg={8} md={12} sm={12}>
                                                            <Stack className="searchBlockInflu" direction="row">
                                                                <Box width="18%">
                                                                    <BasicSelect
                                                                        className="searchPlatform"
                                                                        required={false}
                                                                        defaultValue={values.plateform}
                                                                        name="plateform"
                                                                        label=""
                                                                        values={values.plateform}
                                                                        handleChange={handleChange}
                                                                        options={plateforms.map((item) => (
                                                                            <MenuItem value={item} key={item}>
                                                                                {item}
                                                                            </MenuItem>
                                                                        ))}
                                                                        style={{}}
                                                                    />
                                                                </Box>
                                                                <Box className="searchText" style={{ width: 'calc(100% - 235px)' }}>
                                                                    <AutoSuggestion
                                                                        multiple={true}
                                                                        disableClearable={true}
                                                                        values={""}
                                                                        //classes={{ listbox: classes.autocompleteListbox, option: classes.autocompleteOption }}
                                                                        freeSolo={true}
                                                                        className="auto-chips-cover"
                                                                        label={""}
                                                                        onChange={(_e, newValue) => {
                                                                            handleChange({
                                                                                target: { name: "influencers", value: newValue },
                                                                            });
                                                                        }}
                                                                        onInputChange={(e) => {
                                                                            //setInfluencerNiche(() => nicha)
                                                                            if (e.target.value) {
                                                                                dispatch(updateCampaign({ nicha: influencerNiche }))
                                                                            } else {
                                                                                dispatch(updateCampaign({ nicha: influencerNiche?.filter((v) => ["Art & Creativity", "Parenting & Family", "Travel & Adventure", "Sports & Fitness", "Education & Learning"].includes(v)) }))
                                                                            }

                                                                        }}
                                                                        defaultValue={values?.influencers}
                                                                        key={step + values?.influencers?.length}
                                                                        limitTags={2}
                                                                        id="multiple-limit-tags"
                                                                        size="small"
                                                                        name="search"
                                                                        placeholder="Search influencers by niche like Health, Beauty, Tech."
                                                                        options={nicha}
                                                                        isOptionEqualToValue={(option, value) => {
                                                                            if (
                                                                                value === "" ||
                                                                                option === "" ||
                                                                                value === option
                                                                            )
                                                                                return option;
                                                                        }}
                                                                        getOptionLabel={(option) =>
                                                                            option || "no_option_found"
                                                                        }

                                                                        renderOption={(props, option) => {

                                                                            return (
                                                                                < >
                                                                                    {props.id === "search-option-0" && <Grid item xs={12} lg={12} md={12} sm={12} mb={2}>
                                                                                        <Typography component="h4" variant="h4" display="block" p={2} pt={0}>
                                                                                            Search Suggestion
                                                                                        </Typography>
                                                                                        <hr />
                                                                                    </Grid>}

                                                                                    <Grid sx={{ display: "flex", flexWrap: 'wrap' }} {...props}>
                                                                                        <Chip

                                                                                            // avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                                                                            label={option}
                                                                                            color="default"
                                                                                        />


                                                                                    </Grid>

                                                                                </>
                                                                            );
                                                                        }}
                                                                    // renderTags={(value, getTagProps) => (
                                                                    //     <Grid>
                                                                    //         {value.slice(0, 2).map((option, index) => (
                                                                    //             <Grid item key={option}>
                                                                    //                 <Chip
                                                                    //                     label={option}
                                                                    //                     {...getTagProps({ index })}
                                                                    //                     avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                                                    //                 />
                                                                    //             </Grid>
                                                                    //         ))}
                                                                    //         {value.length > 2 && (
                                                                    //             <Grid item>
                                                                    //                 <Typography>+ {value.length - 2} more</Typography>
                                                                    //             </Grid>
                                                                    //         )}
                                                                    //     </Grid>
                                                                    // )}
                                                                    />

                                                                </Box>
                                                                <Box>
                                                                    <button
                                                                        className="searchBtn"
                                                                        type="submit"
                                                                    >
                                                                        {/* <SearchIcon /> */}Generate ✨

                                                                    </button>
                                                                </Box>
                                                            </Stack>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                            </Grid>

                        </Grid>

                        <Grid item p={4} xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">
                            <Stack direction={"row"} spacing={1}>

                                <button
                                    className="btnSecondary"
                                    onClick={() => {
                                        dispatch(updateCampaign({ step: 4 }));
                                        navigate("/campaign/profile-screener")
                                    }}

                                >
                                    Back
                                </button>
                                {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { }}
                        >
                            Review Campaign & Create
                        </Button> */}
                            </Stack>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12}>
                        <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
                        <ManualSearchModel openModal={open} setOpenmodel={setOpen} />
                    </Grid>
                </Box>
            </Stack>


        </Box >
    </>
}
export default SearchInfluencer;