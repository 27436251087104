import { TextField } from "@mui/material";
import React from "react";

export default function NumberInput({
    label,
    placeholder,
    id,
    name,
    type,
    onBlur,
    values,
    onChange,
    style,
    inpImg,
    className,
    helperText,
    isRequired,
    multiline,
    rows,
    error,
    touched,
    focused,
    sizeval,
    isDisabled,
    inputProps,
    onKeyDown,
    variant = "outlined",
    min,
    max,
    readOnly = false,
    startAdornment = false,
    startAdornmentText = "",
    endAdornment = false
}) {
    return (
        <TextField
            autoFocus={focused}
            variant={variant}
            autoComplete="off"
            onBlur={onBlur}
            id="outlined-number"
            value={values !== undefined && values !== null ? values : ""}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            helperText={helperText}
            error={error}
            name={name}
            required={isRequired}
            //InputProps={{ inputProps: { min: "0", max: "10", step: "1" } }}
            type={type}
            InputProps={{
                inputProps: { min: min, max: max, step: "1" },
                readOnly: readOnly,
                startAdornment: startAdornment ? startAdornmentText : null,
                endAdornment: endAdornment ? endAdornment : ""
            }}
            label={label}
            size={sizeval}
            fullWidth
            multiline={false}
            rows={"1"}
            disabled={isDisabled ? isDisabled : false}
            inputProps={inputProps}
            className={className}
            sx={style}
        />
    );
}
