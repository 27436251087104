import { Chip, Dialog, DialogActions, DialogContent, FormLabel, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { getInfluencerProfilesList, inviteAdditionalInfluencer } from '../../pages/campaigns/action';
import Schema from '../../schema';
import AutoSuggestion from '../autocomplete';
const Invite = (props) => {
    const { inviteModal, setInviteModal } = props;
    const { id } = useParams();
    const handleClose = () => setInviteModal(false);
    const dispatch = useDispatch();
    const { influencersList } = useSelector((state) => state.campaignSlice);
    let initialValues = {
        influencer_ids: [],
    }

    useEffect(() => {
        if (inviteModal)
            dispatch(getInfluencerProfilesList(id));
    }, [inviteModal]);
    return (
        <>
            <Dialog open={inviteModal} fullWidth onClose={handleClose}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={Schema.InviteMoreInfluencer}
                    onSubmit={(values, { setSubmitting }) => {
                        let ids = values.influencer_ids.map((v) => v.id);
                        dispatch(inviteAdditionalInfluencer({ id: id, invitedInfluencers: ids }));
                        setInviteModal(false);
                        //inviteAdditionalInfluencer
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        dirty,
                    }) => {

                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                }}
                            >

                                <Typography variant='h4' component="h4" px={3} py={2.5}>Invite Influencer</Typography>
                                <hr />
                                <DialogContent px={3} py={2.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>Influencers List</FormLabel>
                                            <AutoSuggestion
                                                multiple={true}
                                                disableClearable={true}
                                                values={values?.influencer_ids}
                                                //classes={{ listbox: classes.autocompleteListbox, option: classes.autocompleteOption }}
                                                freeSolo={true}
                                                className="auto-chips-cover"
                                                label={""}
                                                onChange={(_e, newValue) => {
                                                    handleChange({
                                                        target: { name: "influencer_ids", value: newValue },
                                                    });
                                                }}
                                                defaultValue={values?.influencer_ids}
                                                id="multiple-limit-tags"
                                                size="small"
                                                name="search"
                                                placeholder="Search influencers."
                                                options={influencersList}
                                                isOptionEqualToValue={(option, value) => {
                                                    if (
                                                        value === "" ||
                                                        option === "" ||
                                                        value === option
                                                    )
                                                        return option;
                                                }}
                                                getOptionLabel={(option) =>
                                                    option?.name || "no_option_found"
                                                }
                                                renderOption={(props, option) => {
                                                    return (
                                                        <>
                                                            <Grid sx={{ display: "flex", flexWrap: 'wrap' }} {...props}>
                                                                <Chip
                                                                    // avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                                                    label={option.name}
                                                                    color="default"
                                                                />
                                                            </Grid>
                                                        </>
                                                    );
                                                }}

                                            />
                                            {
                                                errors.influencer_ids && touched.influencer_ids && (
                                                    <p
                                                        className="error m-0"
                                                    >
                                                        {`${errors?.influencer_ids}`}
                                                    </p>
                                                )
                                            }
                                        </Grid>

                                    </Grid>
                                </DialogContent>
                                <hr />
                                <DialogActions style={{ padding: '24px !important' }}><br />
                                    <button onClick={handleClose} className='btnSecondary'>Cancel</button>
                                    <button type="submit" className='btnPrimary'>Send Invite</button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik >
            </Dialog >
        </>
    );
};

export default Invite;
