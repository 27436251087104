import { TextField } from "@mui/material";
import React from "react";


export default function CustomTextArea({
  label,
  placeholder,
  id,
  name,
  type,
  onBlur,
  values,
  onChange,
  style,
  inpImg,
  className,
  helperText,
  isRequired,
  multiline,
  rows,
  error,
  touched,
  focused,
  sizeval,
  isDisabled,
  inputProps,
  onKeyDown,
  min = "0",
  max = "0",
  minHeight = 90
}) {
  return (
    <TextField
      autoFocus={focused}
      variant="outlined"
      autoComplete="off"
      onBlur={onBlur}
      id="outlined-multiline-static"
      value={values ? values : ""}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
      name={name}
      required={isRequired}
      InputProps={{
        style: {
          marginTop: 0,
          marginLeft: 0,
          paddingTop: 0,
          paddingLeft: 0,

        },
      }}
      type={type}
      label={label}
      size={sizeval}
      fullWidth
      multiline={multiline}
      rows={3}
      disabled={isDisabled ? isDisabled : false}
      inputProps={inputProps}
      className={className}

    />
  );
}
