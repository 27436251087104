import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/constant";
export const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", localStorage.getItem("token"));
      }
      //headers.set("authorization", localStorage.getItem("token"));

      return headers;
    },
  }),
  tagTypes: ["Influencer", "role", "admin", "status", "myCampaign"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({ url: "/company/login", method: "POST", body: data }),
    }),
    getRoles: builder.query({
      query: (data) => ({
        url: "/roles",
        method: "GET",
        params: data,
      }),
      providesTags: ["role"],
    }),
    signup: builder.mutation({
      query: (data) => ({ url: "/brand/account", method: "POST", body: data }),
    }),
    addRolesAndPermissions: builder.mutation({
      query: (data) => ({
        url: "/roles",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["role"],
    }),
    addAdminUsers: builder.mutation({
      query: (data) => ({
        url: "/admin/addAdmin",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["admin"],
    }),
    getAdminUsers: builder.query({
      query: (params) => ({
        url: "/admin/getAdminUsers",
        method: "GET",
        params,
      }),
      providesTags: ["admin"],
    }),
    getAdminUserById: builder.query({
      query: (id) => ({
        url: `/admin/getAdminUser/${id}`,
        method: "GET",
      }),
      providesTags: ["admin"],
    }),
    getUserManagement: builder.query({
      query: (data) => ({
        url: "/admin/getAllUsers",
        method: "get",
        params: data,
      }),
      providesTags: ["Influencer", "status"],
    }),
    createInfluencer: builder.mutation({
      query: (data) => ({
        url: "/admin/createUser",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Influencer"],
    }),
    getUser: builder.query({
      query: (id) => `/admin/getUser/${id}`,
      providesTags: ["Influencer", "status"],
    }),
    getUserDetails: builder.query({
      query: (id) => `/admin/getUserDetails/${id}`,
      providesTags: ["Influencer", "status"],
    }),
    getRoleById: builder.query({
      query: (id) => `/roles/${id}`,
      providesTags: ["role"],
    }),
    getAdmin: builder.query({
      query: (id) => ({ url: `/admin/getAdminUser/${id}` }),
      providesTags: ["admin"],
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: "/admin/updateUser/",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Influencer"],
    }),
    updateAdmin: builder.mutation({
      query: (data) => ({
        url: "/admin/updateAdmin/",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["admin"],
    }),
    updateUserStatus: builder.mutation({
      query: (data) => ({
        url: "/admin/updateUserStatus",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["status"],
    }),
    getParamMaster: builder.query({
      query: () => ({
        url: "/config/parameter-master",
        method: "GET",
      }),
      providesTags: [""],
    }),
    updateRoles: builder.mutation({
      query: (data) => ({
        url: `/role/${data.id}`,
        method: "PATCH",
        body: data.formData,
      }),
      invalidatesTags: ["role"],
    }),
    deleteInfluencer: builder.mutation({
      query: (id) => ({
        url: `/admin/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Influencer"],
    }),
    createNewCampaign: builder.mutation({
      query: (data) => ({
        url: "/brand/createCampaign",
        method: "POST",
        body: data,
      }),
    }),
    getAllMyCampaigns: builder.query({
      query: (data) => ({
        url: "/getAllCampaign",
        method: "GET",
        params: data,
      }),
      providesTags: ["myCampaign"],
    }),
    createPushNotification: builder.mutation({
      query: (data) => ({
        url: "/save-notification-token/",
        method: "POST",
        body: data,
      }),
    }),
    getAllBrands: builder.query({
      query: (data) => ({
        url: "/brands",
        method: "GET",
        params: data,
      }),
      providesTags: ["brand"],
    }),
  }),
});
export const {
  useLoginMutation,
  useSignupMutation,
  useGetRolesQuery,
  useAddRolesAndPermissionsMutation,
  useGetAdminUsersQuery,
  useAddAdminUsersMutation,
  useGetUserManagementMutation,
  useCreateInfluencerMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetAdminQuery,
  useUpdateAdminMutation,
  useUpdateUserStatusMutation,
  useGetRoleByIdQuery,
  useGetParamMasterQuery,
  useGetUserManagementQuery,
  useUpdateRolesMutation,
  useDeleteInfluencerMutation,
  useGetAdminUserByIdQuery,
  useCreateNewCampaignMutation,
  useGetAllMyCampaignsQuery,
  useGetAllBrandsQuery,
  useGetUserDetailsQuery
} = api;
