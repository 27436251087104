import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MessageIcon from '@mui/icons-material/Message';
import { Box, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getDateformatUsingSeconds, getFixedValue } from '../../utils/constant';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        slidesToSlide: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Posts = ({ details, flag }) => {
    const [tab, setTab] = useState("topMostPost")

    return (
        <>
            <Stack direction="row" mt={3} mb={3} gap={4}>
                <Typography variant='h5' className='text-uppercase' sx={{ width: "70%" }}>
                    Contents &emsp;{details?.content?.topMostPost?.length > 0 && flag && <a href={`/`} onClick={(e) => {
                        e.preventDefault();
                        window.open(`/creator-content/${details.handleRecordId}/${details.userId}`, "_blank")
                    }} style={{ fontSize: 14, textDecoration: "none" }}> View All</a>}
                </Typography>
                <Box sx={{ width: "30%", display: 'flex', justifyContent: 'flex-end' }}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant='subtitle2' onClick={() => setTab("topMostPost")} sx={{ cursor: "pointer" }} className={`${tab === "topMostPost" ? "content-tab" : ""} showHandCursor content-active-tab`}>
                            Top Posts
                        </Typography>
                        <Typography variant='subtitle2' onClick={() => setTab("topRecentPost")} sx={{ cursor: "pointer" }} className={`${tab === "topRecentPost" ? "content-tab" : ""} showHandCursor content-active-tab`}>
                            Recent Posts
                        </Typography>
                    </Stack>
                </Box>
            </Stack>

            {details?.content?.[tab]?.length > 0 ? <Carousel responsive={responsive} itemClass="carousel-item-padding-40-px">
                {
                    details?.content?.[tab]?.map((v) => (
                        <div
                            key={v.id}
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                if (v.shortcode) {
                                    window.open(v.shortcode)
                                } else {
                                    return
                                }

                            }}
                        >
                            <div className="brandBlocks">
                                <Card sx={{ maxWidth: '100%', cursor: 'pointer' }}>
                                    <CardMedia
                                        component="img"
                                        alt="img"
                                        height="140"
                                        image={v.thumbnailSrc}
                                    />
                                    <CardContent
                                        className="wishDate w-100"
                                        style={{
                                            padding: '0px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            component="p"
                                            className="mb-0"
                                            style={{ fontSize: '14px', color: '#6C7692' }}
                                        >
                                            <FavoriteBorderOutlinedIcon /> {getFixedValue(v.previewLikeCount)} &nbsp;
                                            <MessageIcon fontSize="small" /> {getFixedValue(v.commentCount)}
                                        </Typography>
                                        <Typography
                                            component="p"
                                            className="mb-0"
                                            style={{ color: '#6C7692', fontSize: '14px' }}
                                        >
                                            {getDateformatUsingSeconds(v.timestamp)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    ))
                }
            </Carousel> : <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                <div className="brandBlocks brandBlImg">No Data Found</div>
            </div>}



        </>
    );
};

export default Posts;
