import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  Avatar,
  Box,
  CssBaseline,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import userProf from "../assets/images/userProf.png";
import { resetCampaign } from "../pages/campaigns/campaignSlice";
import { getCompanyDetails } from "../pages/company/action";
import { useGetUserDetailsQuery } from "../redux/api";
import { setSidebar } from "../redux/reducer";
import { getCompanyid, getUserType, isMobile, setCompanyName, setCompanyid } from "../utils/common";
import { COMPANY } from "../utils/constant";
import { ROUTES } from "../utils/routes";

const MenuItemAppbar = styled(MenuItem)(({ theme }) => ({
  width: "180px",
  padding: "10px 25px",
  fontWeight: 600,
  color: "#6C7692",
  "profileAppbar": {
    color: theme.palette.warning.main
  },
  "&:hover, &.active": {
    background: theme.palette.warning.light,
  },
}));

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const states = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.commonSlice);

  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [anchorEl, setSideba] = React.useState(false);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const sidebarRef = useRef(null);
  let userData = localStorage.getItem('user')
  if (userData) userData = JSON.parse(userData)

  const { data, isSuccess } = useGetUserDetailsQuery(userData?.id);

  const [adminData, setadminData] = React.useState({
    name: "",
    image: "",
    type: ""
  });


  useEffect(() => {
    if (data?.status === "SUCCESS") {

      setadminData({
        name: data.result?.adminName ? data.result?.adminName : data.result?.companyName,
        image: data.result?.logo ? data.result?.logo : userProf,
        type: data.result?.userType ? data.result?.userType : '',

      });
      setCompanyName(data.result?.companyName);
      setCompanyid(data?.result?.companyId || data.result?.id)
    }
  }, [isSuccess]);


  const handleToggleSidebar = (e) => {

    e.preventDefault();
    dispatch(setSidebar());
  };

  const handleClickOutside = (event) => {
    // alert(isMobile())
    // if (isMobile())
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && states.sidebar) {
      dispatch(setSidebar());
    }
  };

  useEffect(() => {

    if (isMobile()) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);


  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: states.sidebarWidth,
          transition: `"all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}"`,
        }}
      >
        <div className={`mainheader ${!states.sidebar ? 'activeHeader' : ''}`} color={"background"} >
          <Toolbar>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width={"100%"}
            >
              <span ref={sidebarRef}>
                <MenuIcon className="menuIcon cursorPointer" onClick={handleToggleSidebar} />
              </span>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={3}
                sx={{ cursor: "pointer" }}
                onClick={handleClick}              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <div className="avtarImg">
                    <Avatar src={userInfo?.photoUrl ? userInfo?.photoUrl : "/"} alt={getUserType() === COMPANY ? userInfo?.companyName?.toUpperCase() || userInfo?.contactPersonName : userInfo?.adminName?.toUpperCase()} />
                  </div>
                  <Stack className="userNme">
                    <h5>{getUserType() === COMPANY ? userInfo?.companyName || userInfo?.contactPersonName : userInfo?.adminName}</h5>
                    <label>{adminData?.type}</label>
                  </Stack>
                  <ExpandMoreOutlinedIcon sx={{ color: '#000' }} disableRipple />
                </Stack>
              </Stack>
              <Menu
                id="basic-menu"
                className="dropmenu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // sx={{ right: "24px" }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {getUserType() == COMPANY && <>
                  <MenuItemAppbar className="dropsItem" onClick={() => {
                    handleClose()
                    dispatch(getCompanyDetails(getCompanyid(), navigate))
                  }}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <PersonOutlineOutlinedIcon />

                      <Typography variant="body2" fontWeight={600}>Profile</Typography>
                    </Stack>
                  </MenuItemAppbar>
                  <MenuItemAppbar className="dropsItem" onClick={() => {
                    handleClose()
                    navigate(ROUTES.SUBSCRIPTION_FOR_COMPANY)
                  }}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <AttachMoneyOutlinedIcon />

                      <Typography variant="body2" fontWeight={600}>View Subscription</Typography>
                    </Stack>
                  </MenuItemAppbar>
                  <MenuItemAppbar className="dropsItem" onClick={() => {
                    handleClose()
                    navigate(ROUTES.COMPANY_USERS)
                  }}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <ManageAccountsOutlinedIcon />

                      <Typography variant="body2" fontWeight={600}>Manage Users</Typography>
                    </Stack>
                  </MenuItemAppbar>
                </>
                }
                <MenuItemAppbar className="dropsItem" onClick={() => {
                  handleClose();
                  localStorage.clear(); navigate(ROUTES.LOGIN)
                  dispatch(resetCampaign());
                }}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <LogoutOutlinedIcon />
                    <Typography variant="body2" fontWeight={600}>Logout</Typography>
                  </Stack>
                </MenuItemAppbar>
              </Menu>
            </Stack>
          </Toolbar>
        </div>
      </Box>
    </>
  );
};

export default Header;
