import { FormLabel, Grid, TextField } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Formik } from 'formik';
import React from 'react';
import Image from 'react-bootstrap/Image';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logoIcon from '../../assets/images/logoIcon.svg';
import Alert from "../../components/dialog/Alert";
import NumberInput from "../../components/inputs/normalInput/number";
import Toaster from '../../components/toaster/toaster';
import Schema from '../../schema';
import { maskPhoneNumber, obfuscateEmail } from "../../utils/common";
import { otpVerify } from "../users/action";

const TwoFactorAuth = () => {

    const initialValues = {
        otp: null,
    }
    const { state } = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    let email = state?.userData?.twoFactorAuthType?.toLowerCase() == "email" ? obfuscateEmail(state?.userData?.email) : "";
    let phone = state?.userData?.twoFactorAuthType?.toLowerCase() == "sms" ? maskPhoneNumber(state?.userData?.phoneNumber) : "";

    return (
        <> <Toaster />
            <div className='loginPage signupbg wrap'>
                <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-start">
                            <div className="leftText">
                                <div className="maintagline">
                                    <span>Register to get</span>More with Inflyx
                                </div>
                                <ul className="reg_pointer">
                                    <li>🧠 Intelligent Matchmaking</li>
                                    <li>🕓 Historical Insights</li>
                                    <li>📝 Post Campaign Analysis</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center justify-content-start">
                            <div className="formSide w-100 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
                                <div className="formcvr">
                                    <div className="logoIcon text-center mb-3">
                                        <Image src={logoIcon} alt="Logo Icon" />
                                    </div>
                                    <h1 className="text-center mb-5">
                                        Verification
                                    </h1>


                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={Schema.otpVerfication}
                                        enableReinitialize={true}
                                        onSubmit={(values, { resetForm }) => {
                                            dispatch(otpVerify(values, navigate));
                                            //resetForm()
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            touched,
                                            values,
                                            setFieldValue,
                                            isValid,
                                            setFieldError,
                                            dirty,
                                        }) => {

                                            return (
                                                <Form>
                                                    <Grid className="frm" item xs={12} lg={6} md={6} sm={6}>
                                                        <p>We have sent you a verification code on {state?.userData?.twoFactorAuthType?.toLowerCase() == "both" ? obfuscateEmail(state?.userData?.email) + " and " + maskPhoneNumber(state?.userData?.phoneNumber) : state?.userData?.twoFactorAuthType?.toLowerCase() === "email" ? email : phone}</p>
                                                        < FormLabel > Verification Code</FormLabel>
                                                        <TextField
                                                            id="outlined-number"
                                                            label=""
                                                            value={values.otp}
                                                            placeholder="Enter 6 digit OTP"
                                                            type="text"
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                // Ensure the value contains only digits and is at most 6 characters long
                                                                if (/^\d{0,6}$/.test(newValue)) {
                                                                    handleChange(e);
                                                                }
                                                            }}
                                                            name="otp"
                                                            sx={{ width: "100%" }}
                                                            inputMode="numeric"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                maxLength: 6, // Enforce 6 character limit
                                                                style: { MozAppearance: 'textfield' }, // Hide the spin buttons in Firefox
                                                            }}
                                                            error={Boolean(touched.otp) && errors.otp}
                                                            helperText={touched.otp && errors.otp}
                                                        />
                                                        {false && <NumberInput
                                                            name={"otp"}
                                                            type={"number"}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.otp}
                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 6) {
                                                                    handleChange(e);
                                                                } else {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.otp) && errors.otp}
                                                            helperText={touched.otp && errors.otp}
                                                        />}
                                                    </Grid>
                                                    <button className="btnPrimary mt-3 w-100" type="submit">
                                                        Continue
                                                    </button>
                                                    <center><a href="/login" onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate("/login")
                                                    }} style={{ position: "relative", top: "10px" }} >
                                                        Back
                                                    </a></center>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Alert />
        </>
    )
}

export default TwoFactorAuth