import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGetRoleByIdQuery } from "./redux/api";
import { setPermission } from "./redux/reducer";

export const PrivateRoute = ({ element, pageType, requiredPermissions = null }) => {
  const dispatch = useDispatch();
  const storageref = useRef(localStorage.getItem("token"));
  const storagerefUser = useRef(localStorage.getItem("user"));
  let roleId, permissions;
  if (storagerefUser.current) {
    roleId = JSON.parse(storagerefUser.current)?.roleId;
  }
  // api call -----------------------------------------------------------------
  const { data } = useGetRoleByIdQuery(roleId);

  if (data) {
    permissions = data.result[0]?.permissions;
  }
  if (permissions) {
    dispatch(setPermission(permissions));
  }

  const hasPermission = () => {
    return permissions && permissions[pageType][requiredPermissions] == 1;
  };
  // if (permissions) { //storageref
  if (storageref.current) {
    return requiredPermissions ? (
      hasPermission() ? (
        element
      ) : (
        <></>
        // <Unauthorize />
      )
    ) : (
      element
    );
  } else {
    return <Navigate to="/login" />;
  }
  // }
};
