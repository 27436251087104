import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, FormLabel, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { talkToExperts } from '../../pages/subscriptions/action';
import Schema from '../../schema';
import NormalInput from '../inputs/normalInput';
import NumberInput from '../inputs/normalInput/number';
import CustomTextArea from '../inputs/textarea';
import CommonModel from './Common';

const TalkToExpert = ({ open, handleClose, type }) => {
    const dispatch = useDispatch();
    const [open1, setOpen1] = useState(false);
    const [msg, setMsg] = useState("");
    let initialValues = {
        fullName: '',
        workEmail: '',
        companyName: '',
        phoneNo: '',
        message: '',
        type: type
    }

    return (
        <>
            <Dialog sx={{ borderRadius: '12px', padding: '40px' }} open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <div className='talkCover'>
                    <div className='popHead'>
                        <div>
                            <h4>Talk to Our <span>Experts</span></h4>
                            <p className='mb-0'>Fill the form below and our team will connect you within 48 hours!</p>
                        </div>
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <div className='popBdy'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={Schema.TalkToExpert}
                            enableReinitialize
                            validateOnChange
                            onSubmit={(values, { resetForm }) => {
                                //talkToExperts
                                values.phoneNo = String(values.phoneNo)
                                dispatch(talkToExperts(values, setOpen1, setMsg));
                                handleClose();
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue,
                                isValid,
                                setFieldError,
                                dirty,
                            }) => {
                                return (

                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}>
                                        <Grid container spacing={2} mt={2}>
                                            <Grid item xs={12} lg={6} md={6} sm={12}>
                                                <FormLabel required>Full Name</FormLabel>
                                                <NormalInput
                                                    className="talkinput"
                                                    name={"fullName"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.fullName}

                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.fullName) && errors.fullName}
                                                    helperText={touched.fullName && errors.fullName}

                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6} sm={12}>
                                                <FormLabel required>Work Email</FormLabel>
                                                <NormalInput
                                                    className="talkinput"
                                                    name={"workEmail"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.workEmail}

                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.workEmail) && errors.workEmail}
                                                    helperText={touched.workEmail && errors.workEmail}

                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6} sm={12}>
                                                <FormLabel >Company Name</FormLabel>
                                                <NormalInput
                                                    className="talkinput"
                                                    name={"companyName"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.companyName}

                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.companyName) && errors.companyName}
                                                    helperText={touched.companyName && errors.companyName}

                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6} sm={12}>
                                                <FormLabel required>Phone No</FormLabel>
                                                <NumberInput
                                                    className="talkinput"
                                                    name={"phoneNo"}
                                                    type={"number"}
                                                    focused={false}
                                                    label={("")}

                                                    sizeval="medium"
                                                    values={values.phoneNo}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 10) {
                                                            handleChange(e);
                                                        } else {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.phoneNo) && errors.phoneNo}
                                                    helperText={touched.phoneNo && errors.phoneNo}
                                                />

                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel>Tell us how we can help you?</FormLabel>
                                                <CustomTextArea
                                                    className="talkinput"
                                                    name={"message"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.message}
                                                    onChange={handleChange}

                                                    multiline={true}

                                                />

                                            </Grid>
                                        </Grid>
                                        <DialogActions>
                                            <button className='btnPrimary w-100 mt-4' type="submit">
                                                Submit
                                            </button>
                                        </DialogActions>
                                    </Form >
                                );
                            }}
                        </Formik >
                    </div>
                </div>
            </Dialog>
            <CommonModel
                open={open1}
                handleDialogClose={() => setOpen1(false)}
                dialogTitle={""}
                dialogPara={<span dangerouslySetInnerHTML={{ __html: msg }}></span>}
                nvYes={""}
                nvCancel={"Close"}
                yesHandle={() => {
                    setOpen1(false);
                }}
            />
            {/* <Alert /> */}
        </>
    );
};

export default TalkToExpert;
