import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCompanyQandA } from '../../pages/company/action';
import { COMPANY_QUE_FLOW } from '../../utils/common';
import CommonModel from './Common';


export default function CompanyQandAModals() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [questions, setQuestions] = useState(COMPANY_QUE_FLOW[0])
    const [value, setValue] = useState();
    const [indexing, setIndexing] = useState(1);
    const [postValue, setPostValues] = useState({ showCompanyFlow: false });
    const [brandModalOpen, setBrandModalOpen] = useState(false);
    return (
        <>
            {indexing !== COMPANY_QUE_FLOW.length + 1 && <Dialog
                open={open}
                //onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                className='questionPops'
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    {questions?.question}
                </DialogTitle>
                <div className='quesSection'>
                    <div className='questionPointers'>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                            >
                                {questions?.answer?.map((v, i) => <FormControlLabel className='quesPoint' key={i} checked={value === v} name={v} onChange={(e) => {
                                    setQuestions(COMPANY_QUE_FLOW.find((v, index) => index === indexing));
                                    setIndexing((prev) => prev + 1);
                                    setValue(e.target.value);
                                    setPostValues((prev) => {
                                        const updatedPostValues = { ...prev, [questions.name]: e.target.value };
                                        if (indexing === COMPANY_QUE_FLOW.length) {
                                            dispatch(updateCompanyQandA(updatedPostValues, setOpen));
                                            setBrandModalOpen(true)
                                        }
                                        return updatedPostValues;
                                    });
                                }} value={v} control={<Radio />} label={v} />)}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className='questionImg'>
                        <img src={questions?.image} alt={"company question images"} />
                    </div>
                </div>
                <Typography className='totalcount mb-0' component={"span"}>
                    {indexing} out of {COMPANY_QUE_FLOW.length}
                </Typography>
            </Dialog>}
            <CommonModel
                open={brandModalOpen}
                handleDialogClose={() => setBrandModalOpen(false)}
                dialogTitle={"Profile creation successfull"}
                dialogPara={`No brands are linked to your company yet. Please create brand(s).`}
                nvCancel={""}
                yesHandle={() => setBrandModalOpen(false)}
            />
        </>
    );
}