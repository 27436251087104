import { CloseSharp } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function NotificationType(props) {
    const { open, setOpen, ids } = props;
    const navigate = useNavigate();
    return (
        <>
            <BootstrapDialog
                onClose={() => setOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={open}
                size="md"
                fullWidth
            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        Choose Notification type?
                    </Typography>
                    <div className="cursorPointer" onClick={() => setOpen(false)}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                <DialogContent >
                    <Grid container>

                        <Grid item xs={12} lg={6} md={6} sm={12} onClick={() => navigate("/notification/add", { state: { type: "push", groupIds: ids } })} >
                            <Box p={2} m={1} display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                textAlign="center" sx={{ cursor: "pointer", borderRadius: "16px", border: "1px solid #AAAAAA" }} >
                                <img src={"/push.png"} alt={"push notification"} />
                                <Typography pt={2} gutterBottom>
                                    Push Notification
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6} sm={12} onClick={() => navigate("/notification/add", { state: { type: "email", groupIds: ids } })}>
                            <Box border={1} p={2} m={1} sx={{ cursor: "pointer", borderRadius: "16px", border: "1px solid #AAAAAA" }} display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                textAlign="center">
                                <img src={"/emailN.png"} alt={"push notification"} />
                                <Typography pt={2} gutterBottom>
                                    Email Notification
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                </DialogContent>
            </BootstrapDialog >

        </>
    );
}
