import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import { api } from "./api";
import { firebaseApi } from "./firebaseApi";
import rootReducer from "./root.reducer";
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['campaignSlice', 'commonSlice', 'dashboardSlice']
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // {
  //   companySlice: companySlice.reducer,
  //   brandSlice: brandSlice.reducer,
  //   campaignSlice: campaignSlice.reducer,
  //   commonSlice: commonSlice.reducer,
  //   userSlice: userSlice.reducer,
  //   privacySlice: privacySlice.reducer,
  //   globalLoaderSlice: globalLoaderSlice.reducer,
  //   auditSlice: auditSlice.reducer,
  //   dashboardSlice: dashboardSlice.reducer,
  //   [appSlice.name]: appReducer,
  //   [api.reducerPath]: api.reducer,
  //   [firebaseApi.reducerPath]: firebaseApi.reducer,
  // },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    api.middleware,
    firebaseApi.middleware,
    thunk//, logger
  ],
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);