import { Box, FormLabel, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import FileUpload from "../../../components/inputs/file";
import NormalInput from "../../../components/inputs/normalInput";
import CustomTextArea from "../../../components/inputs/textarea";
import BasicSelect from "../../../components/select";
import Schema from "../../../schema";
import { GROUP_TYPE, GROUP_TYPE_ENUM } from "../../../utils/constant";
import { createGroup } from "../action";

const AddGroup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();


    const initialValues = {
        name: state?.name || "",
        type: state?.type || "",
        shortDescription: state?.shortDescription || "",
        image: "",
        description: state?.description || "",
        id: state?.id || ""
    }
    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >

                    <Typography variant="h1" className="page-title">
                        {state?.id ? "Edit" : "Create"}  New Group
                    </Typography>

                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => { }}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} onClick={() => navigate("/notification/group")}>
                            Back
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={Schema.groupAdd}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        dispatch(createGroup(values, navigate));
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        setFieldError,
                        dirty,
                    }) => {
                        console.log("errors", errors)
                        return (
                            <Form>
                                <Grid container spacing={3} p={3} ml={1} bgcolor={"white"}>
                                    <Grid item xs={12} lg={4} md={4} sm={12}>
                                        <Grid container spacing={2}  >
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel required focused={false}>Group Name</FormLabel>
                                                <NormalInput
                                                    name={"name"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.name) && errors.name}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel required focused={false}>Group Type</FormLabel>
                                                <BasicSelect
                                                    required={false}
                                                    name={`type`}
                                                    label={("")}
                                                    values={values.type}
                                                    handleChange={handleChange}
                                                    options={GROUP_TYPE.map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                    handleBlur={handleBlur}
                                                />
                                                {errors.type && touched.type && (
                                                    <p
                                                        className="error"
                                                    >
                                                        {`${errors?.type}`}
                                                    </p>
                                                )}
                                            </Grid>
                                            {values.type === GROUP_TYPE_ENUM.COMMUNITY && <>
                                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                                    <FormLabel required focused={false}>Community Image</FormLabel>
                                                    <FileUpload onChange={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: "image",
                                                                value: e.target.files[0]
                                                            }
                                                        })
                                                    }} fileName={values?.image?.name} accept="image/*" />
                                                    {errors.image && touched.image && (
                                                        <p
                                                            className="error"

                                                        >
                                                            {`${errors?.image}`}
                                                        </p>
                                                    )}
                                                </Grid>

                                            </>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}>
                                        <Grid container spacing={2} pr={4}  >
                                            {values.type === GROUP_TYPE_ENUM.COMMUNITY && <>
                                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                                    <FormLabel focused={false}>Short Description</FormLabel>
                                                    <NormalInput
                                                        name={"shortDescription"}
                                                        type={"text"}
                                                        focused={false}
                                                        label={("")}
                                                        sizeval="medium"
                                                        values={values.shortDescription}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.shortDescription) && errors.shortDescription}
                                                        helperText={touched.shortDescription && errors.shortDescription}
                                                    />
                                                </Grid></>
                                            }
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel>Group Description</FormLabel>
                                                <CustomTextArea
                                                    name={"description"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.description}
                                                    onChange={handleChange}
                                                    placeholder=" "
                                                    multiline={true}
                                                    style={{ m: 0, p: 0, height: '90px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} pr={4} lg={12} md={12} sm={12} className="pt-0" container justifyContent="flex-end">
                                            <Stack direction={"row"} spacing={1} mt={2}>
                                                {/* <button className="btnSecondary" onClick={() => navigate("/notification")}>Cancel</button> */}
                                                <button
                                                    className="btnPrimary"
                                                    type="submit"
                                                // disabled={!(isValid && dirty)}
                                                >
                                                    {state?.id ? "Update" : "Create"} Group
                                                </button>
                                            </Stack>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Box >
        </Box >

    );

}
export default AddGroup;