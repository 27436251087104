import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { Box, Button, Checkbox, FormLabel, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { Form, Formik } from "formik";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Editor from 'react-simple-wysiwyg';
import FileUpload from "../../../components/inputs/file";
import NormalInput from "../../../components/inputs/normalInput";
import CustomTextArea from "../../../components/inputs/textarea";
import TimePickerComponent from "../../../components/time/index";
import Schema from "../../../schema";
import { formatDate } from '../../../utils/common';
import { DAYS, NOTIFICATION_FERQUENCY, NOTIFICATION_FERQUENCY_ENUM } from '../../../utils/constant';
import { addNotification } from '../action';
import GroupTable from '../history/groupTable';
const NotificationAdd = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [val, setValue] = useState({
        type: state?.type || "push",
        title: "",
        image: "",
        message: "",
        frequency: "oneTime",
        startDate: "",
        endDate: "",
        neverEnd: [],
        time: null,
        repeat: "",
        weekDays: [],
        monthlyDate: [],
        groupIds: JSON.stringify(state?.groupIds || [])
    })







    return <Box>
        <Stack direction={"row"} alignItems={"center"} mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" className="page-title">
                    {!id ? "Send" : "Edit"} Notification

                </Typography>

                <Stack
                    className="backText"
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/notification/group")}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"} >
                        Back
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Formik
                initialValues={val}
                validationSchema={Schema.NotificationAdd(state?.type)}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(values, { resetForm }) => {
                    var time = values.time;
                    if (typeof values.time == "object") {
                        const hours = values.time.hour();
                        const min = values.time.minute();
                        time = hours + ":" + min;
                    }
                    const updatedvalues = {
                        ...values,
                        // time: values.time && values.time != null ? getFormatedDate(values.time, "hh:mm") : "",
                        time: time,
                        neverEnd: values.neverEnd.length > 0 ? "true" : "false",
                        monthlyDate: JSON.stringify(values.monthlyDate),
                        weekDays: JSON.stringify(values.weekDays)
                    }
                    dispatch(addNotification(updatedvalues, navigate));
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    isValid,
                    setFieldError,
                    dirty,
                }) => {
                    if (values.time !== null && typeof values.time !== "object") {
                        const [hour, minute] = values?.time?.split(':');
                        if (hour && minute) {
                            values.time = dayjs().hour(Number(hour)).minute(Number(minute));
                        }
                    }
                    console.log("values", errors, values);
                    return (
                        <Form>
                            <Grid container spacing={2} >
                                <Grid item xs={12} lg={6} md={6} sm={12} rowGap={3} >
                                    <Grid container rowGap={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>{state?.type == "email" ? "Subject" : "Title"}</FormLabel>
                                            <NormalInput
                                                name={"title"}
                                                type={"text"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.title) && errors.title}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Grid>

                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel>Attached Image</FormLabel>
                                            <FileUpload onChange={(e) => {
                                                handleChange({
                                                    target: {
                                                        name: "image",
                                                        value: e.target.files[0]
                                                    }
                                                })
                                            }} fileName={values?.image?.name} accept="image/*" />
                                            <small style={{ fontSize: '11px', fontWeight: '400', color: 'rgb(108, 118, 146)', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 1920x1080, 1080x907 and 600x336. Image formats like JPEG and PNG</small>
                                            {errors.image && touched.image && (
                                                <p
                                                    className="error"

                                                >
                                                    {`${errors?.image}`}
                                                </p>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>{state?.type == "email" ? "Body" : "Message"}</FormLabel>
                                            {state?.type !== "push" ? <Editor value={values.message} onChange={(e) => {
                                                handleChange({
                                                    target: {
                                                        name: "message",
                                                        value: e.target.value
                                                    }
                                                })
                                            }} /> :
                                                <CustomTextArea
                                                    name={"message"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.message}
                                                    onChange={handleChange}
                                                    placeholder=" "
                                                    multiline={true}
                                                    style={{ m: 0, p: 0, height: '90px' }}
                                                />}
                                            {errors.message && touched.message && (
                                                <p className="error" >
                                                    {`${errors?.message}`}
                                                </p>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6} sm={12} mt={1}>
                                    <Grid width={"100%"} ml={1} mt={1} container bgcolor={"#F7F7F7"} borderRadius={2} p={1} spacing={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Typography gutterBottom>
                                                Schedule Date & Time
                                            </Typography>
                                            <hr></hr>
                                        </Grid>

                                        <Grid item xs={12} lg={6} md={6} sm={12}  >
                                            <Box p={1} sx={{ cursor: "pointer", color: values.frequency === "oneTime" ? "#0077FF" : "" }} onClick={() => {
                                                handleChange({
                                                    target: {
                                                        name: "frequency",
                                                        value: "oneTime"
                                                    }
                                                })
                                                handleChange({
                                                    target: {
                                                        name: "endDate",
                                                        value: ""
                                                    }
                                                })
                                                handleChange({
                                                    target: {
                                                        name: "neverEnd",
                                                        value: []
                                                    }
                                                })
                                                handleChange({
                                                    target: {
                                                        name: "repeat",
                                                        value: ""
                                                    }
                                                })
                                                handleChange({
                                                    target: {
                                                        name: "time",
                                                        value: null
                                                    }
                                                })
                                                handleChange({
                                                    target: {
                                                        name: "weekDays",
                                                        value: []
                                                    }
                                                })
                                                handleChange({
                                                    target: {
                                                        name: "monthlyDate",
                                                        value: []
                                                    }
                                                })
                                            }} border={"1px solid #AAAAAA80"} justifyContent={"center"} display="flex" gap={1}>
                                                <AccessTimeIcon fontSize="small" />
                                                <Typography>
                                                    One-Time
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} lg={6} md={6} sm={12} >
                                            <Box p={1} sx={{ cursor: "pointer", color: values.frequency === "recurring" ? "#0077FF" : "" }} onClick={() => {
                                                handleChange({
                                                    target: {
                                                        name: "frequency",
                                                        value: "recurring"
                                                    }
                                                })
                                            }} border={"1px solid #AAAAAA80"} justifyContent={"center"} display="flex" gap={1}>
                                                <EventRepeatIcon fontSize="small" />
                                                <Typography>
                                                    Recurring
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {values.frequency === "oneTime" ? <>
                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                <FormLabel required>Date</FormLabel>
                                                <NormalInput
                                                    name={"startDate"}
                                                    type={"date"}
                                                    focused={false}
                                                    isRequired={false}
                                                    label={""}
                                                    sizeval="medium"
                                                    min={formatDate(new Date())}
                                                    values={values?.startDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(touched.startDate) && errors.startDate
                                                    }
                                                    helperText={touched.startDate && errors.startDate}
                                                />

                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                <FormLabel required>Start Time</FormLabel>
                                                {/* <NormalInput
                                                    name={"time"}
                                                    type={"time"}
                                                    focused={false}
                                                    isRequired={false}
                                                    label={""}
                                                    sizeval="medium"

                                                    values={values?.time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(touched.time) && errors.time
                                                    }
                                                    helperText={touched.time && errors.time}
                                                /> */}
                                                <TimePickerComponent values={values?.time} editMode={values.time} handleChange={(value) => {
                                                    handleChange({
                                                        target: {
                                                            name: "time",
                                                            value: value
                                                        }
                                                    })
                                                }}

                                                />
                                                {errors.time && touched.time && (
                                                    <p className="error" >
                                                        {`${errors?.time}`}
                                                    </p>
                                                )}

                                            </Grid></> : <>
                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                <FormLabel required>Start Date</FormLabel>
                                                <NormalInput
                                                    name={"startDate"}
                                                    type={"date"}
                                                    focused={false}
                                                    isRequired={false}
                                                    label={""}
                                                    sizeval="medium"
                                                    min={formatDate(new Date())}
                                                    values={values?.startDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(touched.startDate) && errors.startDate
                                                    }
                                                    helperText={touched.startDate && errors.startDate}
                                                />

                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                <Stack direction={"row"} gap={0.5} justifyContent="space-between"
                                                    alignItems="center"
                                                    width="100%">
                                                    <FormLabel required={!values.neverEnd.length > 0}>End Date</FormLabel>
                                                    <Box display={"flex"} gap={0.5} alignContent={"right"} justifyContent="flex-end">
                                                        <Checkbox
                                                            size='small'
                                                            sx={{ fontSize: "10px !important" }}
                                                            value={"neverEnd"}
                                                            checked={values.neverEnd.length > 0}
                                                            name='neverEnd'
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setFieldValue("endDate", "");
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        /><FormLabel size="small" sx={{ fontSize: 12 }} justifyContent="right">Never Ends</FormLabel>
                                                    </Box>
                                                    {console.log("values.neverEnd", values.neverEnd)}
                                                </Stack>

                                                <NormalInput
                                                    name={"endDate"}
                                                    type={"date"}
                                                    focused={false}
                                                    isRequired={false}
                                                    label={""}
                                                    isDisabled={values.neverEnd.length > 0 || !values.startDate}
                                                    sizeval="medium"
                                                    min={values?.startDate}
                                                    values={values?.endDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(touched.endDate) && errors.endDate
                                                    }
                                                    helperText={touched.endDate && errors.endDate}
                                                />

                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                <FormLabel required>Repeat</FormLabel>

                                                <Select
                                                    value={values.repeat}
                                                    sx={{ width: "100%" }}
                                                    name="repeat"
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    defaultValue={""}
                                                >
                                                    <MenuItem value="" disabled hidden>
                                                        Select
                                                    </MenuItem>
                                                    {NOTIFICATION_FERQUENCY.map((row) => (
                                                        <MenuItem key={row} value={row}>
                                                            {row}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.repeat && touched.repeat && (
                                                    <p className="error" >
                                                        {`${errors?.repeat}`}
                                                    </p>
                                                )}

                                            </Grid>

                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                <FormLabel required>Start Time</FormLabel>
                                                {/* <NormalInput
                                                        name={"time"}
                                                        type={"time"}
                                                        focused={false}
                                                        isRequired={false}
                                                        label={""}
                                                        sizeval="medium"

                                                        values={values?.time}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            Boolean(touched.time) && errors.time
                                                        }
                                                        helperText={touched.time && errors.time}
                                                    /> */}
                                                <TimePickerComponent values={values?.time} handleChange={(value) => {
                                                    handleChange({
                                                        target: {
                                                            name: "time",
                                                            value: value
                                                        }
                                                    })
                                                }} />
                                                {errors.time && touched.time && (
                                                    <p className="error" >
                                                        {`${errors?.time}`}
                                                    </p>
                                                )}

                                            </Grid>
                                            {values.repeat === NOTIFICATION_FERQUENCY_ENUM.WEEKLY &&
                                                <Grid item xs={12} lg={6} md={6} sm={12} >
                                                    <FormLabel required>Select Day</FormLabel>
                                                    <Stack direction="row" rowGap={2} columnGap={2} mt={1}>
                                                        {DAYS.map((day, index) => (
                                                            <Button
                                                                key={index}
                                                                size="small"
                                                                color={values.weekDays.includes(day.value) ? "success" : "primary"}
                                                                variant={values.weekDays.includes(day.value) ? '' : 'outlined'}
                                                                onClick={() => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "weekDays",
                                                                            value: values.weekDays.includes(day.value) ? values.weekDays.filter((v) => v !== day.value) : [day.value]
                                                                        }
                                                                    })
                                                                }}
                                                                sx={{
                                                                    minWidth: 30,
                                                                    height: 30,
                                                                    borderRadius: 1,
                                                                    padding: 0, // Ensure no extra padding is added
                                                                    boxSizing: 'border-box', // Consistent sizing with borders
                                                                    backgroundColor: values.weekDays.includes(day.value) ? 'primary.main' : '#FFFFFF',
                                                                    color: values.weekDays.includes(day.value) ? 'white' : '#00000099',
                                                                    border: values.weekDays.includes(day.value) ? '1px solid primary.main' : '1px solid #DDDDDD',
                                                                    '&:hover': {
                                                                        backgroundColor: values.weekDays.includes(day.value) ? 'primary.main' : 'inherit', // Prevent background color change on hover
                                                                    },
                                                                }}
                                                            >
                                                                {day.title}
                                                            </Button>
                                                        ))}
                                                    </Stack>
                                                    {errors.weekDays && touched.weekDays && (
                                                        <p className="error" >
                                                            {`${errors?.weekDays}`}
                                                        </p>
                                                    )}
                                                </Grid>}

                                            {values.repeat === NOTIFICATION_FERQUENCY_ENUM.MONTHLY &&
                                                <Grid item xs={12} lg={12} md={12} sm={12} >
                                                    <FormLabel required>Select Date</FormLabel>
                                                    <Stack direction="row" rowGap={2} columnGap={2} mt={1} flexWrap="wrap">
                                                        {Array.from({ length: 31 }, (_, index) => index).map((day) => (
                                                            <Button
                                                                key={day}
                                                                size="small"
                                                                color={values.monthlyDate.includes(day) ? "success" : "primary"}
                                                                variant={values.monthlyDate.includes(day) ? '' : 'outlined'}
                                                                onClick={() => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "monthlyDate",
                                                                            value: values.monthlyDate.includes(day) ? values.monthlyDate.filter((v) => v !== day) : [day]
                                                                        }
                                                                    })
                                                                }}
                                                                sx={{
                                                                    minWidth: 30,
                                                                    height: 30,
                                                                    borderRadius: 1,
                                                                    padding: 0, // Ensure no extra padding is added
                                                                    boxSizing: 'border-box', // Consistent sizing with borders
                                                                    backgroundColor: values.monthlyDate.includes(day) ? 'primary.main' : '#FFFFFF',
                                                                    color: values.monthlyDate.includes(day) ? 'white' : '#00000099',
                                                                    border: values.monthlyDate.includes(day) ? '1px solid primary.main' : '1px solid #DDDDDD',
                                                                    '&:hover': {
                                                                        backgroundColor: values.monthlyDate.includes(day) ? 'primary.main' : 'inherit', // Prevent background color change on hover
                                                                    },
                                                                }}
                                                            >
                                                                {day + 1}
                                                            </Button>
                                                        ))}
                                                    </Stack>
                                                    {errors.monthlyDate && touched.monthlyDate && (
                                                        <p className="error" >
                                                            {`${errors?.monthlyDate}`}
                                                        </p>
                                                    )}
                                                </Grid>}
                                            <Grid item xs={12} lg={6} md={6} sm={12} ></Grid>
                                        </>}
                                        <Grid item xs={12} lg={12} md={12} sm={12} mb={4}>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12} mt={2} className="pt-0" container justifyContent="flex-end">
                                    <Stack direction={"row"} spacing={1}>
                                        <button className="btnSecondary" onClick={() => navigate("/notification/history")}>Cancel</button>
                                        <button
                                            className="btnPrimary"
                                            type="submit"
                                        // disabled={!(isValid && dirty)}
                                        >
                                            Submit
                                        </button>
                                    </Stack>
                                </Grid>


                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <br></br>
            <hr></hr>
            <br></br>
            {id && <GroupTable setValue={setValue} />}
        </Box>
    </Box >
}
export default NotificationAdd;