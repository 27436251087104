
import InfoIcon from '@mui/icons-material/Info';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch } from 'react-redux';
import BrandPosts from './brandPosts';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        slidesToSlide: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "16px"
    },
}));
const Brands = ({ brands, handleId, userId }) => {
    const [filterState, setfilterState] = useState(false);
    const dispatch = useDispatch();
    const [brandName, setBrandName] = useState({ id: "", name: "", postCount: 0, brandId: "" });
    return (
        <>
            <h4 className='text-uppercase'><SellOutlinedIcon /> Brands</h4>
            <h6 className='text-uppercase'> BRAND MENTIONS&nbsp;<BootstrapTooltip arrow title="This section provides you with details
about the brands that this influencer
mentioned in the posts.
Here you can see how many times a
particular brand is mentioned and the
respective posts as well." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip>
                &emsp;{brands?.length > 0 && handleId > 0 && <a href={`/`} onClick={(e) => {
                    e.preventDefault();
                    window.open(`/user-brands/${handleId}/${userId}`, "_blank")
                }} style={{ fontSize: 14, textDecoration: "none" }}> View All</a>}
            </h6>
            <div className='secItem' style={{ marginTop: 30 }}>
                {/* <h4>Content <Link className='ms-3' style={{ fontSize: "14px" }} to="#">View All</Link></h4> */}

                <div className='row g-3'>
                    {brands?.length > 0 ? <Carousel responsive={responsive} itemClass="carousel-item-padding-40-px">
                        {brands?.map((v) => {
                            return <div key={v.id} style={{ marginLeft: "15px" }} >
                                <div className='brandBlocks'>
                                    <Card sx={{ maxWidth: '100%' }}>
                                        <div className='imgCver'>
                                            <CardMedia
                                                component="img"
                                                alt="green iguana"
                                                height="120"
                                                image={v.image}
                                            />
                                        </div>
                                        <CardContent style={{ textAlign: 'center' }}>
                                            <Typography variant="h5" component="h5" className='fs-5' style={{ fontWeight: '600' }}>{v.Name}</Typography>
                                            <Typography component="p" className='mb-0 fs-6' style={{ color: '#6C7692' }}>{v.socialHandleName}</Typography>
                                        </CardContent>
                                        <Stack className='postCount'>
                                            <a href='/' onClick={(e) => {
                                                e.preventDefault();
                                                //dispatch(updateUser({ brandsPosts: [] }))
                                                setBrandName({ id: v?.handleId || handleId || "", name: v.Name, postCount: v.media_count, brandId: v.brandId })
                                                setfilterState(true)
                                                // dispatch(getBrandPosts(v.brandId, id))
                                            }}>
                                                {v.media_count} Posts
                                            </a>
                                        </Stack>
                                    </Card>
                                </div>
                            </div>

                        })}
                    </Carousel> : <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                        <div className="brandBlocks brandBlImg">No Data Found</div>
                    </div>}
                </div>
            </div>
            {brandName.id && <BrandPosts filterState={filterState} setfilterState={setfilterState} brandName={brandName} />}
        </>
    )

}
export default Brands;