
import { Backdrop, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BASE_URL } from '../../utils/constant';

const UserDeleted = () => {
    const [phone, setPhone] = useState();
    const [otp, setOtp] = useState('');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        const url = BASE_URL;
        setLoading(true);
        if (data?.status === "SUCCESS") {
            fetch(url + "auth/phoneOTPVerify", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": data?.result?.tmpJwtToken
                },
                body: JSON.stringify({
                    "otp": otp,
                    "deviceId": "123456",
                    "firebaseToken": "1232134"
                })
            }).then(response => {
                return response.json();
            }).then(data => {
                if (data?.status === "SUCCESS" && data?.result?.newUser) {
                    alert("user does not exist!");
                }
                else {
                    fetch(url + "admin/user/" + data?.result?.userId, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            "authorization": data?.result?.jwtToken
                        }
                    }).then(response => {
                        return response.json();
                    }).then(data => {
                        setData(null);
                        setPhone(null);
                        setOtp(null);
                        alert("User successfully deleted!");

                    })
                }
            }).catch(error => {
                setError(error);
                setLoading(false);
            });
        } else {
            //if (!phone) return alert("please provide a phone number")
            fetch(url + "auth/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "phoneCountryCode": "+91",
                    "phoneNumber": phone
                })
            }).then(response => {
                return response.json();
            }).then(data => {
                setData(data);
                setLoading(false);
            }).catch(error => {
                setError(error);
                setLoading(false);
            });
        }




    };
    console.log("data", data)
    return (
        <><Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={error}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    padding: 2,
                }}
            >
                <Grid
                    container
                    spacing={2}
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ maxWidth: 400 }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" gutterBottom>
                            Delete User
                        </Typography>
                    </Grid>
                    {data == null && <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Phone Number"
                            variant="outlined"

                            max={12}
                            min={10}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </Grid>}
                    {data != null && <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="OTP"
                            variant="outlined"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box></>
    );
};

export default UserDeleted;
