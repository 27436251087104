import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import HeadingSection from "../components/headingSection";
import { AddCircle, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ROUTES } from "../utils/routes";
import { useNavigate } from "react-router-dom";

const EditCampaign = () => {
  const navigate = useNavigate();

  const details = [];
  return (
    <>
      <Stack
        direction={"row"}
        marginBottom={"6vh"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HeadingSection heading={"Create New Campaign"} backBtn={false} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          
          onClick={() => navigate(ROUTES.CREATE_CAMPAIGN)}
        >
          Create Campaign
        </Button>
      </Stack>

      <Stack
        direction={"column"}
        marginBottom={"15px"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
      <Box
        marginBottom={"23px"}
        sx={{ background: "rgba(255, 255, 255, 1)" }}
        width={"100%"}
        padding={"24px 24px 24px 24px"}
      >
        <Stack direction={{md:"row", sm: 'column'}} sx>
          <Typography variant="h6">Basic Details</Typography>
          <IconButton sx={{ padding: "3px 5px 6px 6px" }}>
            <Edit sx={{ color: "rgba(0, 119, 255, 1)" }} />
          </IconButton>
        </Stack>
        <Stack direction={{md:"row", sm: 'column'}}>
          <Stack direction={"row"} gap={6}>
            <Stack gap={2}>
              <Typography>Campaign Name</Typography>
              <Typography>Brand Name</Typography>
              <Typography>Duration</Typography>
              <Typography>Product Link</Typography>
            </Stack>
            <Stack gap={2}>
              <Typography> Apple Mac-book</Typography>
              <Typography> Apple Technologies</Typography>
              <Typography>30 Nov - 31 Dec</Typography>
              <Typography component={Link}>
                https://www.xyz.com/product/apple-mac/16inch
              </Typography>
            </Stack>
          </Stack>

          <Stack direction={"row"} gap={6}>
            <Stack gap={2}>
              <Typography>Website</Typography>
              <Typography>Campaign Type</Typography>
              <Typography>Campaign Details</Typography>
            </Stack>
            <Stack gap={2}>
              <Typography component={Link}> https://www.xyz.com</Typography>
              <Typography> Apple Technologies</Typography>
              <Typography>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque udantium, totam rem aperiam
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{ background: "rgba(255, 255, 255, 1)" }}
        padding={"24px 24px 24px 24px"}
        width={"100%"}
      >
        <Typography variant="h6">Other Details</Typography>
        <Stack direction={"row"} >
          <Stack direction={"row"} gap={6}>
            <Stack gap={2}>
              <Typography>Posted on</Typography>
              <Typography>Deliverables</Typography>
              <Typography>Duration</Typography>
            </Stack>
            <Stack gap={2}>
              <Typography> Instagram</Typography>
              <Typography>
                Story Post - 2 Images Reels Post - 1 Viedo (30 Secs)
              </Typography>
              <Typography>#macbookDec</Typography>
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={6}>
            <Stack gap={2} padding={"0px 0px 0px 300px"}>
              <Typography>Gender</Typography>
              <Typography>Age Group</Typography>
              <Typography>Followers</Typography>
            </Stack>
            <Stack gap={2}>
              <Typography>Any</Typography>
              <Typography>20-45</Typography>
              <Typography>50K, 100K, 300K, 1M</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>

</Stack>
   
    </>
  );
};
export default EditCampaign;
