import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsButtons from '../../../components/buttons';
import CommonModel from "../../../components/dialog/Common";
import { CustomModal } from "../../../components/modal";
import { getUserType } from '../../../utils/common';
import { COMPANY, COMPLETED, LIVE, UNPUBLISHED } from '../../../utils/constant';
import { getCampaignById, getPostAffiliateInsights, getPostCampaign, getPostCampaignBreakDown, updateCampaignStatus } from '../action';
import { updateCampaign } from "../campaignSlice";
import PostCampaign from "./postCampaignAnalysis";

const PartialCampaignDetails = () => {

    const { id } = useParams()
    const [openNew, setOpennew] = useState(false);
    const [open, setOpen] = useState(false);
    const [unpublishOpen, setOpenUnpublish] = useState(false);
    const [modal, setModal] = useState({
        heading: "",
        submitButton: "",
        isError: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { formData } = useSelector((state) => state.campaignSlice);

    useEffect(() => {
        dispatch(updateCampaign({ postAffiliateInsights: [] }));
        dispatch(getCampaignById(id));
        dispatch(getPostCampaignBreakDown(id));
        if (formData?.type !== "affiliate") {
            dispatch(getPostCampaign(id));
        } else {
            dispatch(getPostAffiliateInsights(id));
        }
    }, [])

    const handleSubmit = (data) => {
        const payload = {
            campaignId: id,
            description: data.description,
            status: data.status
        }

        dispatch(updateCampaignStatus(payload))
        navigate("/campaign-management")
    }

    return <>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={3} mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" component="h1">View Campaign Details </Typography>
                <Stack
                    className="backText"
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        if (getUserType() !== COMPANY) {
                            navigate("/campaign-management");
                        } else {
                            navigate("/my-campaigns");
                        }
                    }}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"}>
                        Back
                    </Typography>
                </Stack>

            </Stack>
            <div style={{ display: "flex", gap: 10 }}>
                {formData.status === "PENDING" && getUserType() !== COMPANY &&
                    <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }} >
                        <button
                            className='btnPrimary'
                            onClick={() => {
                                setOpen(true);
                                setModal({
                                    heading: "Approve Campaign",
                                    submitButton: "Approve",
                                });
                            }}
                        >
                            Approve
                        </button>
                        <button
                            className='btnDanger'
                            onClick={() => {
                                setOpen(true);
                                setModal({
                                    heading: "Reject Campaign",
                                    submitButton: "Reject",
                                    creating: false,
                                });
                            }}
                        >
                            Reject
                        </button>
                    </Stack>
                }

                {formData.status === LIVE && getUserType() !== COMPANY && <Stack direction="row" spacing={1} >
                    <button
                        className='btnPrimary'
                        onClick={() => setOpenUnpublish(true)}
                        style={{ height: 41, marginTop: 3 }}
                    >
                        UNPUBLISH
                    </button>
                </Stack>}
                {[LIVE, COMPLETED, UNPUBLISHED].includes(formData.status) && <DetailsButtons />}
            </div>
        </Stack>
        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Grid container>
                <CustomModal
                    open={open}
                    onClose={() => setOpen(!open)}
                    modal={modal}
                    approveFn={handleSubmit}
                />

                <Grid container rowGap={1} >

                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label ">Campaign Name : </span>  <span className=" text-capitalize">{formData?.name}</span>
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4} sm={4} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Brand Name : </span>   {formData?.brandName || "-"}
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={2} lg={2} md={2} sm={2} className='d-flex align-items-end justify-content-end' gap={2}>
                        <Typography onClick={() => navigate("/campaign/details/" + id)} variant="subtitle1" gutterBottom color={"#0077FF"} sx={{ textDecoration: "underline" }} className="showhandCursor">
                            More Info+
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Campaign Duration: </span>  {moment(formData?.startDate).format("DD MMM")}{" - "}{moment(formData?.endDate).format("DD MMM")}
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Platform: </span> {formData?.deliverables?.length > 0 ? formData?.deliverables[0].plateform : 'N/A'}
                            </Typography>
                        </Box>
                    </Grid>
                    {formData?.products?.map((v, i) => {
                        return <> <Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label ">Product Name : </span>  <span className=" text-capitalize">{v?.name}  </span>
                                </Typography>

                            </Box>
                        </Grid><Grid item xs={6} lg={6} md={6} sm={6} className='d-flex align-items-start justify-content-start' gap={2}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <span className="label ">Product Link : <a href={v?.link} style={{ wordBreak: "break-all" }} target='_blank' rel="noreferrer" >{v.link}</a></span>
                                    </Typography>

                                </Box>
                            </Grid></>
                    })}

                </Grid >

            </Grid >

        </Box >
        <Box
            mt={4}
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '12px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            <Grid container spacing={2}  >
                {/* Thumbnail and Description */}

                <Grid className="thumbnailImg" item xs={12} md={4} lg={4} sm={12} >
                    <Typography variant="h5" mb={1}>Thumbnail</Typography>
                    <img src={formData?.bannerImage?.toString()} alt="Thumbnail" width="100%" />
                </Grid>
                <Grid item xs={12} md={8} lg={8} sm={12}>
                    <Typography variant="h5" mb={1}>Description</Typography>
                    <Typography variant="body1" paragraph>

                        {formData?.brief ? <span dangerouslySetInnerHTML={{ __html: formData?.brief }} ></span> : "N/A"}
                    </Typography>

                </Grid>
            </Grid>
        </Box>
        <CommonModel
            open={unpublishOpen}
            handleDialogClose={() => setOpenUnpublish(false)}
            dialogTitle={"Campaign unpublish"}
            dialogPara={`Are you sure you want to unpublish this campaign? `}
            nvYes={"Yes"}
            nvCancel={"Cancel"}
            yesHandle={() => {
                dispatch(updateCampaignStatus({ campaignId: String(formData?.id), status: UNPUBLISHED }));
                setOpennew(false);
                navigate("/campaigns")
            }}
        />
        <PostCampaign />
    </>
}
export default PartialCampaignDetails;