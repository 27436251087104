
export const endpoints = {
    paramMaster: "config/parameter-master",
    addCampaign: "campaign/createCampaign",
    createCampaignBudget: "campaign/createCampaignBudget",
    createCampaignDate: "campaign/createCampaignDate",
    getAllInfluencers: "admin/getAllUsers",
    getAllCampaign: "getAllCampaign",
    campaign: "campaign/",
    deleteCreator: "admin/user",
    addCampaignToInfluencer: "Influencer/invite",
    addCompany: "/company/create",
    brands: "brands",
    getaudittrail: "getaudittrail",
    company: "company/getAllCompany",
    getAllSubscription: "subscription-plan/allPlans",
    myCampaigns: "/brand/getMyCampaign",
    getCampaignInfluencerEndpoint: "/campaign/",
    bidAcceptedOrRejectedEndpoint: "Influencer/attempt/",
    movedToApprovedEndpoint: "Influencer/attempts",
    bidNegotiatedEndpoint: "Influencer/apply",
    campaignContentJourney: "campaignContentJourney/",
    campaignContentJourneyLink: "campaignContentJourneylink/",
    presignedurl: "largeUpload",
    updateCompany: "company",
    updateAdmin: 'admin/updateAdmin/',
    createBrand: 'brand/create',
    getBrandById: 'brand',
    updateBrand: 'brand',
    getUserAndCampainInsights: 'getUserAndCampainInsights',
    updateCampaignStatus: 'admin/updateCampaignStatus',
    getCompanyById: '/company',
    updateUserStatus: "admin/updateAdmin/",
    campaignContentProductStatus: "campaignContentProductStatus",
    getAllInfluencersSuggestions: "Influencer/search",
    getUserSocialDetails: "Influencer/socialByInfluencerId/",
    userDetails: "admin/getUser/",
    getInvitedInfluencers: "getInvitedInfluencers/",
    forgotPassword: "company/forgotPassword",
    resetPassword: "company/resetPassword",
    ims: "ims",
    policy: "terms-condition",
    dashboardInfluencerList: "admin/getSearchUsers",
    updateCompanyQandA: "company-flow",
    otpVerification: "company/login/otp-verify",
    getContents: "social-media/post/",
    getAllBrands: "social-media/brand/",
    getBrandPosts: "social-media/brand-post",
    addSubscription: "subscription-plan/create",
    subscriptionDetails: "/subscription-plan",
    subscriptionStatusUpdate: "subscription-plan/update",
    getAllCompanies: "company-profile/get-all-companies",
    getuserDetails: "admin/getUserDetails/",
    getAllActivePlans: "company-profile/get-active-plans",
    subscriptionPayment: "company/createDeposit",
    getWalletDetails: "transaction/getWalletDetails",
    companyUser: "company/users",
    depositHistory: "transaction/getTransactionHistory",
    estimatedPayout: "company/campaignInfo",
    exploreCampaign: "company/getCampaigns",
    getActivePlan: "company-profile/get-active-plans-info",
    getPlanHistory: "company-profile/get-plans-history",
    getEmail: "Influencer/socialEmail",
    getPhone: "Influencer/socialPhone",
    customPayment: "company/createDepositLink",
    talktoExperts: "company/talkToExpert",
    wallatCampaign: "company/getWithdrawals",
    downloadCSV: "admin/companyDownload",
    adminUserList: "admin/getAdminUsers",
    adminCompanyCreate: "company/admin-create",
    getStateAndCity: "Influencer/getStateAndCity",
    checkUserCompany: "company-pre/preCheckLimit",
    cancelRecurringSubscription: "company-mandate/cancelMandate",
    groupList: "group/list",
    groupNotificationCreate: "group/create",
    groupNotificationUpdate: "group/update",
    groupDetails: "group",
    removeFromcampaign: "campaignContent/",
    getKyc: "kyc",
    markAsInProcess: "campaignContent/markAsInProcess",
    approvedReleasePayment: "campaignContent/paymentRequestStatus",
    addGroupMember: "groupmember/add",
    removeGroupMember: "groupmember/remove",
    notificationAdd: "notification/create",
    notificationList: "notification/list",
    notificationDelete: "notification",
    notificationRemove: "notification/groupAddOrRemove",
    eventList: "notification/getPushNotificationContents",
    updateNotification: "notification/updateNotification",
    emailEvent: "notification/getEmailNotificationContents",
    updateEmailNotification: "notification/updateEmailNotification",
    postcampaign: "campaign/postInsights/",
    postInsightsBreakDown: "campaign/postInsightsBreakDown/",
    groupmemberSelected: "groupmember/selected",
    getBlockedAmountByCampaignId: "company/getBlockedAmountByCampaignId",
    postAffiliateInsights: "campaign/postAffiliateInsights",
    privacyPolicy: "privacy-policy",
    terms: "terms-conditions"
}