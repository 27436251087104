import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import facebook from "../assets/images/icons/facebook.svg";
import instagram from "../assets/images/icons/instagram.svg";
import linkedin from "../assets/images/icons/linkedin.png";
import youtube from "../assets/images/icons/youtube.svg";
import { CustomModal } from "../components/modal";
import CustomizedSnackbars from "../components/snackbar";
import TabComponent from "../components/tabs";
import {
  useGetUserQuery,
  useUpdateUserStatusMutation
} from "../redux/api";
import { ROUTES } from "../utils/routes";

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState({
    heading: "",
    submitButton: "",
    isError: false,
  });
  const [userData, setUserData] = React.useState();
  const { isSuccess, isLoading, data } = useGetUserQuery(id);
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });

  const images = {
    instagram: instagram,
    facebook: facebook,
    youtube: youtube,
    linkedin: linkedin,
  };

  const [updateUser] = useUpdateUserStatusMutation();

  React.useEffect(() => {
    if (data?.status == "SUCCESS") {
      setUserData(data.result);
    }
  }, [isLoading]);

  const handleClick = () => {
    navigate(-1);
  };
  const tabSectionData = [
    {
      heading: "Influencer's Details",
      content: ` <Box
      height={200}
      width={300}
      display="flex"
      alignItems="center"
      gap={4}
      p={1}
      >
      <div style="display: flex; flex-direction: column;">
      <h4 class="title2 mb-4">Details</h4>
      <label>
      <b>Average Likes:</b> 7.5K
      </label>
      <label>
      <b>Average Comments:</b> 2.3K
      </label>
      <label>
      <b>Engagement Rate:</b> 0.51%
      </label>
      </div>
      </Box>`,
    },
    {
      heading: "Brands",
      content: "Coming soon .......",
    },
    {
      heading: "Instagram",
      content: "Coming soon .......",
    },
    {
      heading: "Facebook",
      content: "Coming soon .......",
    },
    {
      heading: "Youtube",
      content: "Coming soon .......",
    },
  ];
  const details = {
    name: userData?.name?.capitalize(),
    followers: userData?.followers,
    username: userData?.platform?.[0]?.name,
    mobile: `${userData?.phoneCountryCode || "+91"}-${userData?.phoneNumber}`,
    location: (userData?.location && userData?.location.join(", ")) || "",
    influencerType: userData?.influencerType,
    niche: userData?.profileNiche.map((item) => item.option).join(", "),
    status: userData?.status,
    imageUrl: userData?.picture,
    platformUrls: userData?.platform || [],
  };

  const handleSubmit = (updateData) => {
    updateUser({
      userId: [+id],
      status: updateData.status,
      description: updateData.description,
    }).then((res) => {
      if (res.data.status == "SUCCESS") {
        setSnackbar({
          open: true,
          message: res.data.message,
          severity: "success",
        });
      } else {
        setSnackbar({
          open: true,
          message: res.data.message,
          severity: "error",
        });
      }
      setTimeout(() => {
        navigate(ROUTES.USER_MANAGEMENT);
      }, 1500);
    });
  };

  const approveFunction = () => {
    setOpen(true);
    setModal({
      heading: "Approve User",
      submitButton: "Approve",
    });
  };
  const rejectFunction = () => {
    setOpen(true);
    setModal({
      heading: "Reject User",
      submitButton: "Reject",
      creating: false,
    });
  };

  return (
    <>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Box>
        <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              minHeight: '42px'
            }}
          >
            <Typography variant="h1" className="page-title">
              {details.name}
            </Typography>
            <Stack
              className="backText"
              direction={"row"}
              gap={1}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                  stroke="#0077FF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                  stroke="#0077FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Typography variant="h5" color={"primary"}>
                Back
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <CustomModal
          open={open}
          onClose={() => setOpen(!open)}
          modal={modal}
          approveFn={handleSubmit}
        />
        <Stack direction={"row"} gap={2}>
          <Box width={"25%"} sx={{ overflow: "scroll" }}>
            <Card>
              <Box className="profPage">
                {/* <AvatarComponent image={key.picture} /> */}
                <CardMedia
                  component="img"
                  alt="User Prof"
                  image={details.imageUrl ? details.imageUrl : "/profile1.jpg"}
                />
                {details?.status != "PENDING" && (
                  <Stack direction={"row"} justifyContent={"center"} gap={1.25}>
                    <div
                      className={
                        details.status == "APPROVED"
                          ? "apprStatus"
                          : "errorStatus"
                      }
                    >
                      {" "}
                      {details.status}
                    </div>
                  </Stack>
                )}
              </Box>
              <CardContent className="nameStatus text-center p-0">
                <Typography variant="h5" component="h5" gutterBottom>
                  {details?.name}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {details.followers} Followers
                </Typography>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  m={2}
                  gap={2}
                >
                  {details?.platformUrls && details?.platformUrls.length
                    ? details?.platformUrls.map((item, index) => (
                      <img
                        src={images[item.platformType]}
                        alt={item.platformType}
                        width="20"
                        height="20"
                      />
                    ))
                    : ""}
                </Stack>
                {details?.status == "PENDING" && (
                  <Stack direction={"row"} justifyContent={"center"} gap={1.25}>
                    <Button
                      startIcon={<CheckSharpIcon />}
                      variant="contained"
                      onClick={() => approveFunction()}
                    >
                      Approve
                    </Button>
                    <Button
                      startIcon={<CloseSharpIcon />}
                      color="error"
                      variant="contained"
                      onClick={() => rejectFunction()}
                    >
                      Reject
                    </Button>
                  </Stack>
                )}

                <List className="shortDesc d-flex flex-column mt-5 py-4 px-4">

                  <ListItem className="p-0">
                    <ListItemText
                      className="m-0"
                      primary={
                        <div>
                          <label>Mobile:</label>
                          <span>{details.mobile}</span>
                        </div>
                      }
                    />
                  </ListItem>
                  <ListItem className="p-0">
                    <ListItemText
                      className="m-0"
                      primary={
                        <div>
                          <label>Location:</label>
                          <span>{details.location}</span>
                        </div>
                      }
                    />
                  </ListItem>
                  <ListItem className="p-0">
                    <ListItemText
                      className="m-0"
                      primary={
                        <div>
                          <label>Influencer Type:</label>
                          <span>{details.influencerType}</span>
                        </div>
                      }
                    />
                  </ListItem>
                  <ListItem className="p-0">
                    <ListItemText
                      className="m-0"
                      primary={
                        <div>
                          <label>Influencer Niche:</label>
                          <span>{details.niche}</span>
                        </div>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Box>
          <Box width={"75%"}>
            <TabComponent tabSectionData={tabSectionData} />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
export default UserDetail;
