import { error } from "jquery";
import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { getUserInfoNew } from "../../layout/action";
import { updateCommonData } from "../../redux/commonSlice";
import { Alert } from "../../utils/Alert";
import { api } from "../../utils/api";
import { downloadFile, encodedURI, getCompanyid, getUserInfo, removeEmptyValues, setCompanyFlowStatus } from "../../utils/common";
import { endpoints } from "../../utils/endpoints";
import { updateCompany } from "./companySlice";

export const getAllCompany = () => {
  return (dispatch, getState) => {

    api.getApiCall(
      endpoints.brands,
      "",
      (respData) => {
        dispatch(updateCompany({ brandsDropdown: respData.data.result.data, }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllCompanyforListing = () => {
  return (dispatch, getState) => {
    const { limit, page, filters } = getState().companySlice;
    const filterData = encodedURI(removeEmptyValues(filters));
    api.getApiCall(
      endpoints.company,
      `?limit=${limit}&offset=${page * limit}&filter=${filterData}`,
      (respData) => {
        dispatch(updateCompany({ listing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getAllCompanyUserforListing = () => {
  return (dispatch, getState) => {
    const { limit, page, filters } = getState().companySlice;
    api.getApiCall(
      endpoints.companyUser + `/${getUserInfo()}`,
      `?limit=${limit}&offset=${page * limit}&search=${filters.search ? filters.search : ""}`,
      (respData) => {
        dispatch(updateCompany({ listing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getAllCompanyforDropdown = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.company,
      `?limit=10000`,
      (respData) => {
        dispatch(updateCompany({ companyDrodown: respData.data.result.data }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getCompanyDetails = (id, navigate) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getCompanyById + `/${id}`,
      ``,
      (respData) => {
        respData.data.result.comingFrom = "profile";
        navigate("/company/profile/" + id, { state: respData.data.result })
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getCompanyCSVDowanload = () => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.downloadCSV,
      ``,
      (respData) => {
        downloadFile(respData.data)
      },
      (errors) => {
        dispatch(updateCommonData({
          message: error.message || error.msg || "getting error from api",
          title: "Error",
          img: "/failed.png",
          status: true
        }));
      }
    );
  };
};


export const createCompany = (values, navigate, type) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    const formData = new FormData();

    formData.append("contactPersonName", values.contactPersonName);
    formData.append("twoFactorAuthType", values.twoFactorAuthType === "Select" ? "" : values.twoFactorAuthType);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append("companyName", values.companyName);
    formData.append("logo", values.logo || "");
    formData.append("phoneCountryCode", "+91");
    formData.append("socialMedia", JSON.stringify({ instagram: values.instagramInfo, youtube: values.youtubeInfo }));
    formData.append("brandBasics", JSON.stringify({ niche: values.niche?.map((v) => v?.option), description: values.description }));
    formData.append("targetAudience", JSON.stringify({ geoPreferences: values.geoPreferences, description: values.idealDescription }));
    formData.append("branding", JSON.stringify({ primaryColor: values.primaryColor, secondaryColor: values.secondaryColor, font: values.font }));
    formData.append("brandValueAndMessaging", JSON.stringify({ messages: values.messagesKey, toneOfVoice: values.toneOfVoice, additionalInformation: values.additionalInformation }));

    formData.append("keyMessages", values.keyMessages);
    formData.append("brandTone", values.brandTone);
    formData.append("influencersCampaign", values.influencersCampaign);
    formData.append("lastMonthInfluencers", values.lastMonthInfluencers);
    formData.append("support", values.support);

    if (values?.password)
      formData.append("password", values.password);


    if (values?.userId !== undefined) {
      formData.append("userId", values.userId);
    }

    if (values?.userId !== undefined) {
      api.patchApiCall(
        endpoints.updateCompany + `/${values?.userId}`,
        formData,
        (respData) => {
          dispatch(updateGlobalLoader(false));

          if (respData.data.status !== "SUCCESS") {
            dispatch(updateCommonData({
              message: respData.data.message,
              title: "Error",
              img: "/failed.png",
              status: true
            }));
            return
          } else {
            dispatch(updateCommonData({
              message: respData.data.message,
              title: "Success",
              img: "/success.png",
              status: true
            }));

          }
          if (values.comingFrom === "profile") {
            dispatch(getCompanyDetails(values?.id, navigate));
          } else {
            navigate("/companies");
          }


        },
        (error) => {
          dispatch(updateGlobalLoader(false));
          let { data } = error;
          dispatch(updateCommonData({
            message: "The email ID provided is already registered. Please try another.",
            title: "Error",
            img: "/failed.png",
            status: true
          }));
        }
      );
    } else {
      api.postApiCall(
        endpoints.adminCompanyCreate,
        formData,
        (respData) => {
          dispatch(updateGlobalLoader(false));
          if (respData.data.status === "SUCCESS") {
            dispatch(updateCommonData({
              message: respData.data.message,
              title: "Success",
              img: "/success.png",
              status: true
            }));
          } else {
            dispatch(updateCommonData({
              message: respData.data.message,
              title: "Failed",
              img: "/failed.png",
              status: true
            }));
            if (type !== 1) {
              navigate("/company/add");
              return
            }
          }

          if (type === 1) {
            navigate(`/login`);
          } else {

            navigate("/companies");
          }

        },
        (error) => {
          dispatch(updateGlobalLoader(false));
          let { data } = error;
          dispatch(updateCommonData({
            message: data.message,
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
      );
    }
  }
}


export const updateCompanyQandA = (values, hideModal) => {
  return (dispatch, getState) => {
    //dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateCompanyQandA + `/${getCompanyid()}`,
      values,
      (respData) => {
        // dispatch(updateGlobalLoader(false));
        hideModal(false);
        setCompanyFlowStatus(0);
      },
      (error) => {
        //dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );


  }
}

export const companySignup = (values, navigate) => {
  return (dispatch, getState) => {
    const formData = new FormData();

    formData.append("contactPersonName", values.contactPersonName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append("companyName", values.companyName || "");
    if (values.password) {
      formData.append("password", values.password);
    }
    formData.append("phoneCountryCode", values.phoneCountryCode);
    formData.append("socialMedia", JSON.stringify({}));
    formData.append("brandBasics", JSON.stringify({}));
    formData.append("targetAudience", JSON.stringify({}));
    formData.append("branding", JSON.stringify({}));
    formData.append("brandValueAndMessaging", JSON.stringify({}));

    if (values.companyId) {
      formData.append("companyId", values.companyId);
    }
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.addCompany,
      formData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status !== "SUCCESS") {

          dispatch(updateCommonData({
            message: respData.data.message,
            title: "",
            img: "/failed.png",
            status: true
          }));
          return;
        }
        if (values.companyId) {
          navigate(`/company/users`);
        } else {
          navigate(`/login`);
        }

      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }
}

export const companyUserAdd = (values, navigate) => {
  return (dispatch, getState) => {
    const formData = new FormData();
    formData.append("contactPersonName", values.contactPersonName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    if (values.password) {
      formData.append("password", values.password);
    }
    formData.append("phoneCountryCode", values.phoneCountryCode);
    if (values.companyId) {
      formData.append("companyId", values.companyId);
    }
    if (values.photoUrl) {
      formData.append("photoUrl", values.photoUrl);
    }
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.addCompany,
      formData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status !== "SUCCESS") {
          // dispatch(updateCompany({
          //   modalOpen: true,
          //   modalMessage: respData.data.message
          // // }))
          // dispatch(updateCommonData({
          //   message: respData.data.message,
          //   title: "Insufficient Credits",
          //   img: "/sub_exp.png",
          //   status: true,
          //   getPlanButton: true
          // }));
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "",
            img: "/failed.png",
            status: true,
            getPlanButton: false
          }));
          return;
        }
        if (values.companyId) {
          const userType = JSON.parse(localStorage?.getItem("user"));
          dispatch(getUserInfoNew(userType?.id));
          navigate(`/company/users`);
        } else {
          navigate(`/login`);
        }

      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));

      }
    );
  }
}

export const updateCompanyUser = (values, navigate) => {
  return (dispatch, getState) => {
    const formData = new FormData();

    formData.append("contactPersonName", values.contactPersonName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    if (values.password) {
      formData.append("password", values.password);
    }
    formData.append("phoneCountryCode", values.phoneCountryCode);
    formData.append("userId", values.userId);
    if (values.photoUrl) {
      formData.append("photoUrl", values.photoUrl);
    }
    dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateCompany + `/${values?.id}`,
      formData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status !== "SUCCESS") {
          dispatch(updateCompany({
            modalOpen: true,
            modalMessage: respData.data.message
          }))
          // dispatch(updateCommonData({
          //   message: respData.data.message | "The email ID provided is already registered. Please try another.",
          //   title: "Failed",
          //   img: "/failed.png",
          //   status: true
          // }));
          // return;
        }
        dispatch(updateCommonData({
          message: "User updated successfully",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        const userType = JSON.parse(localStorage.getItem("user"));
        dispatch(getUserInfoNew(userType?.id));

        if (values.comingFrom === "profile") {
          dispatch(getCompanyDetails(values?.id, navigate));
        } else {
          navigate("/company/users");
        }


      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Error",
          img: "/failed.png",
          status: true
        }));
      }
    );
  }
}


export const deleteCompany = (id) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.deleteApiCall(
      endpoints.updateCompany + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateCommonData({
          message: "Company Deleted successfully",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        dispatch(getAllCompanyforListing());
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );
  };
};



export const updateUserStatus = (postData) => {

  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.patchApiCall(
      endpoints.updateUserStatus,
      postData,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        dispatch(updateCommonData({
          message: "Status successfully updated",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        dispatch(getAllCompanyUserforListing());

      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data?.msg || "Api error",
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
        //Alert(2, data?.msg);
      }
    );
  };
};


export const forgotPassword = (email, navigate) => {

  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.forgotPassword,
      { email },
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "SUCCESS") {
          dispatch(updateCommonData({
            message: "Email sent successfully",
            title: "Success",
            img: "/success.png",
            status: true
          }));
          navigate("/login");
        } else {
          dispatch(updateCommonData({
            message: respData.data.result.message || "Api error",
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        Alert(2, data?.msg);
      }
    );
  };
};

export const resetPassword = (data, navigate) => {

  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.resetPassword,
      data,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status == "SUCCESS") {
          dispatch(updateCommonData({
            message: respData.data.message,
            title: "Success",
            img: "/success.png",
            status: true
          }));

          navigate("/login");
        } else {
          dispatch(updateCommonData({
            message: respData.data.result.message || "Api error",
            title: "Failed",
            img: "/failed.png",
            status: true
          }));

        }
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        Alert(2, data?.msg);
      }
    );
  };
};

export const checkUserCreateCredit = (navigate) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.getApiCall(
      endpoints.checkUserCompany,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        if (res.status === "FAILURE") {
          dispatch(updateCommonData({
            message: res.message,
            title: "Insufficient Credits",
            img: "/sub_exp.png",
            status: true,
            getPlanButton: true
          }));

        } else {
          navigate("/company/user/add");
        }
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};