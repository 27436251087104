import { CloseSharp } from "@mui/icons-material";
import { Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bidAcceptedOrRejected } from '../../pages/campaigns/action';
import { formatPrice } from "../../utils/common";
import { CAMPAIGN_TYPES, RUPEES_SYMBOL } from "../../utils/constant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function BidAcceptModal(props) {
    const { open, setOpen, bidDetails } = props;
    const handleClose = () => setOpen(false);


    const [details, setDetails] = useState();

    const dispatch = useDispatch();
    const {
        influencersBidDetails
    } = useSelector((state) => state.campaignSlice);


    useEffect(() => {
        if (influencersBidDetails?.attempts?.length > 0) setDetails(influencersBidDetails?.attempts[0]);
    }, [influencersBidDetails]);

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        {bidDetails.bidText} {details?.bidName}
                    </Typography>
                    <div className="cursorPointer" onClick={handleClose}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                {console.log("bidDetails", bidDetails)}
                <DialogContent className='p-4'>
                    {bidDetails.bidText !== "Reject" ? <>
                        <Typography variant='contained' component="p" gutterBottom>
                            Are you sure you want to accept the  <b className="capitalize-first-letter">{details?.bidName} Proposal</b> from the influencer {influencersBidDetails?.influencer?.name} with the following details?.
                            {/* Are you sure you want to  <span className="capitalize-first-letter">{bidDetails.bidText}</span> the <b className="capitalize-first-letter">{details?.bidName} Proposal</b> from the influencer {influencersBidDetails?.influencer?.name} of Rs. {details?.amount}/-? */}
                        </Typography>
                        <Typography mt={3} variant='subtitle' component="p" gutterBottom>
                            <b>Rebid for Campaign </b> <span style={{ paddingLeft: 100 }}>{RUPEES_SYMBOL}{formatPrice(details?.amount)}</span>
                        </Typography>
                        {influencersBidDetails?.type == CAMPAIGN_TYPES.PAID && parseInt(details?.advancePayment) > 0 &&
                            <Typography mt={2} variant='subtitle' component="p" gutterBottom>
                                <b>Rebid for Advance </b> <span style={{ paddingLeft: 110 }}>{RUPEES_SYMBOL}{formatPrice(details?.advancePayment)}</span>
                            </Typography>
                        }
                    </> : `Are you sure you want to  ${bidDetails.bidText} the ${details?.bidName} Proposal from the influencer ${influencersBidDetails?.influencer?.name} of Rs. ${details?.amount}/-?`
                    }

                </DialogContent>
                <DialogActions className='border-0 px-4 py-4'>
                    <button className='btnSecondary' onClick={handleClose}>Cancel</button>
                    <button
                        className={bidDetails.bidText === "Reject" ? "btnDanger" : 'btnPrimary'}
                        onClick={() => {
                            setOpen(false)

                            dispatch(bidAcceptedOrRejected({ "status": bidDetails.bidStatus, influencerId: influencersBidDetails?.influencer?.id }, details?.id, influencersBidDetails.campaignId));
                        }} color="primary" variant="contained"> {bidDetails.bidText}</button>
                </DialogActions>
            </BootstrapDialog>

        </>
    );
}
