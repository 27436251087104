import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from "react-redux";
import { inviteInfluencerByCampaignManualy } from '../../pages/campaigns/action';
import Schema from '../../schema';
import NormalInput from '../inputs/normalInput';
import CustomTextArea from '../inputs/textarea';
const InviteInfulencerModel = (props) => {
    const { openModal, setOpenmodel } = props;
    const handleClose = () => setOpenmodel(false);
    const dispatch = useDispatch();
    let initialValues = {
        email: "",
        message: ""
    }
    return (
        <>
            <Dialog open={openModal} onClose={handleClose}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={Schema.InviteInfluencer}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(inviteInfluencerByCampaignManualy(values, setOpenmodel));
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        dirty,
                    }) => {
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                }}
                            >
                                
                                <Typography variant='h4' component="h4" px={3} py={2.5}>Invite Influencer</Typography>
                                <hr/>
                                <DialogContent px={3} py={2.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>Email ID</FormLabel>
                                            <NormalInput
                                                name={"email"}
                                                type={"text"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.email}
                                                placeholder={"Email"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.email) && errors.email}
                                                helperText={touched.email && errors.email}

                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel>Message</FormLabel>
                                            <CustomTextArea
                                                name={"message"}
                                                type={"text"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.message}
                                                onChange={handleChange}
                                                placeholder="Enter Message"
                                                multiline={true}

                                            />

                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <hr/>
                                <DialogActions style={{padding:'24px !important'}}><br/>
                                    <button onClick={handleClose} className='btnSecondary'>Cancel</button>
                                    <button type="submit" className='btnPrimary'>Send Invite</button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik >
            </Dialog >
        </>
    );
};

export default InviteInfulencerModel;
