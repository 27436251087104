import { Box, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Editor from 'react-simple-wysiwyg';
import { addPrivacy, getPrivacy } from "./action";

const PrivacyAndPolicy = () => {
    const { data } = useSelector((state) => state.privacySlice);
    const dispatch = useDispatch();
    const initialValues = {
        privacyPolicy: data?.privacyPolicy || "",
        termsCondition: data?.termsCondition || ""
    }

    useEffect(() => {
        dispatch(getPrivacy());
    }, []);

    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography variant="h1" className="page-title">
                        Privacy and Policy
                    </Typography>
                </Stack>
            </Stack>

            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <Formik
                    initialValues={initialValues}
                    //validationSchema={Schema.Company}
                    enableReinitialize
                    onSubmit={(values, { resetForm }) => {
                        dispatch(addPrivacy(values));
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        setFieldError,
                        dirty,
                    }) => {
                        return (

                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}>

                                <Grid container spacing={3} p={3} bgcolor={"white"}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Privacy Policy
                                        </Typography>

                                        <Editor value={values.privacyPolicy} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "privacyPolicy",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Terms and conditions
                                        </Typography>
                                        <Editor value={values.termsCondition} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "termsCondition",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} lg={12} md={12} sm={12} p={3} className="pt-0" container justifyContent="flex-end">
                                    <Stack direction={"row"} spacing={1}>

                                        <button
                                            className="btnPrimary"
                                            type="submit"
                                        // disabled={!(isValid && dirty)}
                                        >
                                            Submit
                                        </button>
                                    </Stack>
                                </Grid>

                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>

    );

}
export default PrivacyAndPolicy;