import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
export default function PaginationSelect({ page = 10, handleChange }) {

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                {/* <InputLab id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                    // labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={page}
                    // label="Per Page"
                    onChange={handleChange}
                    // InputLabelProps={{shrink: false}}
                    className="selectRowPerpage"
                >
                    <MenuItem value={5} className="options">
                        5
                    </MenuItem>
                    <MenuItem value={10} className="options">
                        10
                    </MenuItem>
                    <MenuItem value={20} className="options">
                        20
                    </MenuItem>
                    <MenuItem value={30} className="options">
                        30
                    </MenuItem>
                    <MenuItem value={50} className="options">
                        50
                    </MenuItem>
                    <MenuItem value={100} className="options">
                        100
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
