import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Link from '@mui/material/Link';
import * as React from "react";
import Image from 'react-bootstrap/Image';
import socialIcons from '../../../src/assets/images/icons/instagram.svg';
import influencerProfImgs from '../../../src/assets/images/userProf.png';
import { usePushNotificationMutation } from '../../redux/firebaseApi';
import SearchData from '../dashboard/search';

import LinkIcon from '@mui/icons-material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, calories, fat, carbs, protein, link) {
  return { name, calories, fat, carbs, protein, link };
}

const rows = [
  createData('Rohan Geek', 'Gadgets', '1M', 'Alienware, HP', 'Instgram', <LinkIcon />),
  createData('Kaushik Sharma', 'Gadgets', '800K', 'Dell, Lenovo, Cooler Master', 'Facebook', <LinkIcon />),
  createData('Sameera Negi', 'Technology', '500K', 'Logitech', 'Linkedin', <LinkIcon />),
  createData('Tech-Rohit', 'Gadgets', '300K', 'Boat, Intex', 'Twitter', <LinkIcon />),
  createData('Sameer Arya', 'Gadgets', '307K', 'iBall, Onida', 'Instgram', <LinkIcon />),
];


const InfluencerSearch = () => {
  const [pushNotification] = usePushNotificationMutation();
  React.useEffect(() => {
    const token = localStorage.getItem("notificationToken");
    if (token) {
      const payload = {
        token,
        title: "Welcome",
        message: "This is demo notification",
      };
      pushNotification(payload)
        .then((res) => {
          console.log(res, "------res");
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
  }, []);
  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          display={'none'}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Dashboard
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',

          }}>
          <SearchData />
          <Stack className="communityBlock" px={5} py={5}>
            <Stack
              flexDirection={'row'}
              mb={3}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography className="fs-4" variant="h4" component="h4">Communities 👥</Typography>
              <Typography component="div">
                <Link className="text-decoration-none" href="#/" style={{ fontSize: '14px', fontWeight: '500' }}>View All</Link>
              </Typography>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} xl={3} lg={3} md={3} sm={12}>
                <Card
                  className="commItem"
                  borderRadius={12}
                  sx={{
                    height: '172px',
                    position: 'relative',
                    cursor: 'pointer',
                    borderRadius: '12px',
                    padding: '1rem'
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Typography className="fs-6 text-white" variant="h6" component="h6" style={{ fontWeight: 'normal' }} mb={1}>Entertainment</Typography>
                    <Typography className="text-white" variant="h4" component="h4">Dancers</Typography>
                    <Stack className="imgwplatform" sx={{ flexDirection: 'row' }}>
                      <Typography component="div" className="brandProfImg">
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                        <Image src={influencerProfImgs} className='img-fluid' alt="Influencer Profile" width={32} height={32} />
                      </Typography>
                      <Image src={socialIcons} className='img-fluid' alt="Social Icons" width={32} height={32} />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Stack>
          <Stack className="communityBlock" px={5} pb={0} pt={2}>
            <Stack
              flexDirection={'row'}
              mb={3}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography className="fs-4" variant="h4" component="h4">Suggested For you 🤝</Typography>
            </Stack>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Influencers</TableCell>
                  <TableCell align="left">Niche</TableCell>
                  <TableCell align="left">Followers</TableCell>
                  <TableCell align="left">Brands Endorsement</TableCell>
                  <TableCell align="left">Platform</TableCell>
                  <TableCell align="left">Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.calories}</TableCell>
                    <TableCell align="left">{row.fat}</TableCell>
                    <TableCell align="left">{row.carbs}</TableCell>
                    <TableCell align="left">{row.protein}</TableCell>
                    <TableCell align="left">{row.link}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
      </Stack>
    </>
  );
};

export default InfluencerSearch;