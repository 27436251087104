import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getFormatedDate } from '../../utils/common';


const DateRangePicker = (props) => {
    const { text, getSelectedDate, dateReset, style, startDate1, endDate1 } = props;
    const [startDate, setStartDate] = useState(startDate1 || null);
    const [endDate, setEndDate] = useState(endDate1 || null);
    const [showCalendar, setShowCalendar] = useState(false);

    const handleChange = (dates) => {
        const [start, end] = dates;
        getSelectedDate(dates);
        setStartDate(start);
        setEndDate(end);
        setShowCalendar(false);

    };

    useEffect(() => {
        if (typeof dateReset == "object" && Object?.keys(dateReset ?? {})?.length === 0) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [dateReset])

    const CustomInput = ({ value, onClick, text }) => (
        <TextField
            variant="outlined"
            fullWidth
            onClick={onClick}
            value={value || text}
            placeholder={text}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CalendarMonthIcon fontSize="small" />
                    </InputAdornment>
                ),
            }}
        />
    );
    return (
        <div>
            <DatePicker
                selected={startDate}
                style={style}
                onChange={handleChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline={showCalendar} // Show calendar based on state
                monthsShown={2}
                placeholderText={text}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput startDate={startDate} endDate={endDate} onClick={() => setShowCalendar(!showCalendar)} text={text} />}
            />

        </div>
    );
};

const CustomInput = ({ value, onClick, startDate, endDate, text }) => (
    <button className="custom-input" onClick={onClick}>
        {startDate && endDate ? `${getFormatedDate(startDate, 'DD/MM/yyyy')} - ${getFormatedDate(endDate, 'DD/MM/yyyy')}` : text}
    </button>
);

export default DateRangePicker;
