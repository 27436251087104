
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    globalLoader: false
};

export const globalLoaderSlice = createSlice({
    name: "globalLoader",
    initialState,
    reducers: {
        updateGlobalLoader: (state, action) => {
            state.globalLoader = action.payload;
        },

    },
});

// Action creators are generated for each case reducer function
export const { updateGlobalLoader } = globalLoaderSlice.actions;
export default globalLoaderSlice.reducer;
