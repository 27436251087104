import { Snackbar, SnackbarContent } from "@mui/material";
import { useEffect } from "react";

const CopyToClipboardButton = ({ text, open3, setOpen2, handleClick1 }) => {
    useEffect(() => {
        navigator.clipboard.writeText(text);
    }, [text, open3])

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom", horizontal: "center",
                }}
                autoHideDuration={1000}
                onClose={() => setOpen2(false)}
                open={open3}
            >
                <SnackbarContent
                    message="Copied to clipboard"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        width: '100%',
                    }}
                />
            </Snackbar >
        </>
    );
};

export default CopyToClipboardButton;
