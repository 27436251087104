import { api } from "../../utils/api";
import { encodedURI, removeEmptyValues } from "../../utils/common";
import { endpoints } from "../../utils/endpoints";
import { updateKyc } from "./kycSlice";



export const getListing = () => {
  return (dispatch, getState) => {
    const { limit, page, filters } = getState().kycSlice;
    const { userIds, ...result } = filters;
    const filterData = encodedURI(removeEmptyValues(result));
    api.getApiCall(
      endpoints.getKyc,
      `?limit=${limit}&offset=${page * limit}&filter=${filterData}`,
      (respData) => {
        dispatch(updateKyc({ listing: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count }));

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

