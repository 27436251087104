import {
    Search
} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Checkbox, FormControl, FormLabel, Grid, InputAdornment, List, ListItem, ListItemText, MenuItem, Select, Stack, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Editor from 'react-simple-wysiwyg';
import NormalInput from "../../../components/inputs/normalInput";
import SearchInput from "../../../components/inputs/searchInput";
import Schema from "../../../schema";
import { formatString } from "../../../utils/common";
import { eventListForEmail1, updateEmailEditor } from "../action";
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));
const EmailEditor = () => {

    const dispatch = useDispatch();
    const [events, setEvent] = useState([]);
    const [search, setSearch] = useState("");
    const { eventListForEmail } = useSelector((state) => state.notificationSlice)


    let initialVal = {
        id: 0,
        subject: "",
        content: "",
        image: "",
        isRecurring: [],
        selectedEvents: [],
        recurringDisplay: 1,
        day: "",
        dynamicFields: ""
    }
    const [val, setValue] = useState(initialVal);

    useEffect(() => {
        dispatch(eventListForEmail1(setEvent));
    }, [dispatch])

    return <>

        <Formik
            initialValues={val}
            validationSchema={Schema.EditorNotificationForEmail}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
                // let { isActive, isDeleted, createdAt, updatedAt, language, selectedEvents, comment, ...object } = values;
                dispatch(updateEmailEditor({
                    id: values.id,
                    subject: values.subject,
                    content: values?.content?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') || "",
                    image: values.image,
                    isRecurring: values.isRecurring.length > 0 ? 1 : "0",
                    day: values.day,
                    templateName: values.templateName,
                }));
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                setFieldError,
                dirty,
            }) => {
                console.log("isValid", errors, values)
                return (
                    <Form>
                        <Grid container>
                            <Grid item xs={12} lg={4} md={4} sm={12} mt={2}  >
                                <Typography variant="contained" component="h5" mb={2} gutterBottom>System Events List<span aria-hidden="true" class="MuiFormLabel-asterisk css-wgai2y-MuiFormLabel-asterisk">&thinsp;*</span></Typography>
                                <List dense={true} sx={{ maxHeight: "375px" }} className="multiSelect css-h4y409-MuiList-root1" >
                                    <Box p={1} borderBottom={"1px solid #AAAAAA59"}>
                                        <SearchInput
                                            className="inputSearch sContrl"
                                            value={search}
                                            style={{ width: "100%" }}
                                            placeholder={"Search here..."}
                                            handleSearch={(e) => {
                                                setSearch(e.target.value);
                                                const regex = new RegExp(`.*${e.target.value}.*`, "i");
                                                if (e.target.value) {
                                                    setEvent(eventListForEmail.filter(item => regex.test(item.templateName)))
                                                } else {
                                                    setEvent(eventListForEmail);
                                                }
                                            }}

                                            startAdornment={
                                                <InputAdornment className="overIcon" position="start"
                                                    sx={{
                                                        maxHeight: 'none',
                                                        height: '38px',
                                                        minWidth: '38px',
                                                        marginRight: '0px'
                                                    }}
                                                >
                                                    <Search />
                                                </InputAdornment>
                                            }
                                        />
                                    </Box>

                                    {events.map((v, i) => {
                                        return <ListItem key={i} sx={{ cursor: "pointer", borderBottom: '1px solid #AAAAAA59', background: values?.selectedEvents?.includes(v.id) ? "#AAAAAA59" : "" }} >
                                            <ListItemText
                                                name={"selectedEvents"}
                                                value={v.id}
                                                onClick={() => {

                                                    const selectedCategoryIndex = values.selectedEvents?.indexOf(v.id);
                                                    let selectEv = [];
                                                    if (selectedCategoryIndex === -1) {
                                                        handleChange({
                                                            target: {
                                                                name: "selectedEvents",
                                                                value: [v.id] //[...values?.selectedEvents, v]
                                                            }
                                                        });
                                                        selectEv = [v.id];
                                                        setValue((pre) => ({
                                                            ...v,
                                                            day: v.day ? v.day : "",
                                                            image: v.image ? v.image : "",
                                                            isRecurring: v.isRecurring > 0 ? ['isRecurring'] : [],
                                                            selectedEvents: selectEv
                                                        }));
                                                    } else {
                                                        const updatedselectedEvents = values?.selectedEvents ? [...values?.selectedEvents] : [];
                                                        updatedselectedEvents.splice(selectedCategoryIndex, 1);
                                                        handleChange({
                                                            target: {
                                                                name: "selectedEvents",
                                                                value: []
                                                            }
                                                        });
                                                        selectEv = []
                                                        setValue(initialVal);
                                                    }

                                                }}
                                                primary={formatString(v.templateName)}
                                                secondary={''}
                                            />

                                        </ListItem>
                                    })}

                                </List>

                                <p></p>
                                {errors.selectedEvents && touched.selectedEvents && (
                                    <p
                                        className="error"
                                    >
                                        {`${errors?.selectedEvents}`}
                                    </p>
                                )}

                            </Grid>
                            <Grid item xs={12} lg={8} md={8} sm={12}   >
                                <Grid container spacing={2} p={3} ml={1} bgcolor={"white"}>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <FormLabel required focused={false}>Subject</FormLabel>
                                        <NormalInput
                                            name={"subject"}
                                            type={"text"}
                                            isDisabled={false}
                                            focused={false}
                                            label={("")}
                                            placeholder={"subject"}
                                            sizeval="medium"
                                            values={values.subject}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.subject) && errors.subject}
                                            helperText={touched.subject && errors.subject}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <FormLabel required>Body</FormLabel>
                                        <Editor value={values.content} onChange={(e) => {
                                            handleChange({
                                                target: {
                                                    name: "content",
                                                    value: e.target.value
                                                }
                                            })
                                        }} />
                                        {errors.content && touched.content && (
                                            <p
                                                className="error"
                                            >
                                                {`${errors?.content}`}
                                            </p>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <FormLabel >Dynamic Fields
                                            <BootstrapTooltip arrow title={"Please copy the Dynamic Field(s) along with the full syntax as given below and insert in your content body as needed."} placement="top"><InfoIcon className="blueColor" fontSize='small' color="secondary" /></BootstrapTooltip></FormLabel>
                                        <Typography>
                                            {values.dynamicFields || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <FormLabel>Insert Image<BootstrapTooltip arrow title={"Please copy the syntax tag '<%= insertImage %>' from the Dynamic Fields and insert in your mail body wherever needed to show the image in the output."} placement="top"><InfoIcon className="blueColor" fontSize='small' color="secondary" /></BootstrapTooltip></FormLabel>
                                        <div
                                            style={{
                                                border: '1px solid #aaa',
                                                borderRadius: '8px',
                                                padding: '6px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <Button
                                                    component="label"

                                                    variant="contained"
                                                    tabIndex={-1}
                                                    style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.3)", color: " rgba(0, 0, 0, 1)", fontSize: '15px', fontWeight: '500', lineHeight: '17.2px', boxShadow: 'none' }}
                                                >
                                                    Choose File
                                                </Button>
                                                <input
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        cursor: 'pointer',
                                                    }}
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: "image",
                                                                value: e.target.files[0]
                                                            }
                                                        })
                                                    }}
                                                />

                                                {values.image?.name && <p className="m-0">{values.image?.name}</p>}
                                            </div>
                                        </div>
                                        <small style={{ fontSize: '11px', fontWeight: '400', color: 'rgb(108, 118, 146)', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 1920x1080, 1080x907 and 600x336. Image formats like JPEG and PNG</small>
                                        {/* <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 180x180 and 300x300.</small> */}

                                        {errors.logo && touched.logo && (
                                            <p
                                                className="error m-0"
                                            >
                                                {`${errors?.logo}`}
                                            </p>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} lg={6} md={6} sm={6}>
                                        <Box sx={{

                                        }}>
                                            {typeof values?.image == "object" && values.image !== null && <img src={URL.createObjectURL(values?.image)} style={{ width: "100%", height: "100%", borderRadius: 10 }} alt="notification" />}
                                            {typeof values?.image !== "object" && values.image !== "" && <img src={values?.image} style={{ width: "100%", height: "100%", borderRadius: 10 }} alt="notification" />}

                                        </Box>
                                    </Grid>


                                    {values.recurringDisplay == 1 &&
                                        <Grid container borderRadius={2} spacing={2} pr={2} pb={3} ml={2} mt={2} bgcolor={"#F7F7F7"}>
                                            <Grid item xs={12} lg={12} md={12} sm={12} >
                                                <Typography variant="h5" mb={2} gutterBottom>
                                                    <Checkbox
                                                        size='small'
                                                        sx={{ fontSize: "10px !important" }}
                                                        value={"isRecurring"}
                                                        checked={values.isRecurring.length > 0}
                                                        name='isRecurring'
                                                        onChange={(e) => {
                                                            handleChange({
                                                                target: {
                                                                    name: "day",
                                                                    value: ""
                                                                }
                                                            })
                                                            handleChange(e);
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    /> Recurring Notification
                                                </Typography>

                                                <hr></hr>
                                            </Grid>
                                            {values.isRecurring.length > 0 &&
                                                <>
                                                    <Grid item xs={12} lg={12} md={12} sm={12} >
                                                        <Stack direction={"row"} gap={2}>
                                                            <FormLabel sx={{ position: "relative", top: 20 }} required={false} >Every</FormLabel>
                                                            <FormControl sx={{ m: 1, minWidth: 50 }} size="small">
                                                                <Select
                                                                    value={values.day}
                                                                    sx={{ maxHeight: 40, borderRadius: 1 }}
                                                                    size="small"
                                                                    name="day"
                                                                    onChange={handleChange}
                                                                    displayEmpty
                                                                    defaultValue={""}
                                                                >
                                                                    <MenuItem value="" disabled hidden>
                                                                        Select day
                                                                    </MenuItem>
                                                                    {[...Array.from({ length: 7 }, (_, i) => i + 1), ...[15, 30]].map((row) => (
                                                                        <MenuItem key={row} value={row}>
                                                                            {row}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <FormLabel sx={{ position: "relative", top: 20 }} required={false}>Day(s)</FormLabel>
                                                        </Stack>

                                                        {errors.day && touched.day && (
                                                            <p className="error" >
                                                                {`${errors?.day}`}
                                                            </p>
                                                        )}
                                                    </Grid>

                                                </>

                                            }



                                        </Grid>
                                    }
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <hr></hr>
                                    </Grid>

                                    <Grid item xs={12} lg={12} md={12} sm={12} className="pt-0" container justifyContent="flex-end">
                                        <Stack direction={"row"} spacing={1} pt={4}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                disabled={!(isValid && dirty)}
                                            >
                                                Update
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>

    </>

}
export default EmailEditor