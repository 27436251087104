import {
  Search
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import useDebounce from "../../../hooks/use.debounce";
import { getFormatedDate } from "../../../utils/common";
import { resetSubscription } from "../../subscriptions/subscriptionSlice";
import { deleteGroup, getAllGroups } from "../action";
import { updateNotification } from "../notificationSlice";
import NotificationType from "./notificationType";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isSticky, listing, setIds, ids } = props;
  const createSortHandler =
    (property) => (event) => {
      if (["noofUsers"].includes(property)) {
        onRequestSort(event, property);
      } else {
        return;
      }
    };


  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Group Name",
      type: "",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Group type",
      type: "",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Group Description",
      type: "",
    },
    {
      id: "noofUsers",
      numeric: false,
      disablePadding: true,
      label: "No. of Users",
      type: "",
    },
    {
      id: "last",
      numeric: false,
      disablePadding: true,
      label: "Last Updated",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className={`hideActionSort ${isSticky} `}>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={listing.length == ids.length}
            onChange={(e) => {
              if (e.target.checked) {
                setIds(listing.map((v) => v.id))
              } else {
                setIds([]);
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                //  IconComponent={null}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}


export default function GroupNotification(props) {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("noofUsers");
  const [open, setOpen] = useState(0);
  const [ids, setIds] = useState([]);
  const [sticky, setSticky] = useState("");
  const [open1, setOpen1] = useState(false);
  const dispatch = useDispatch();
  const {
    limit,
    page,
    sortKey,
    totalCount,
    totalPages,
    filters,
    listing,
  } = useSelector((state) => state.notificationSlice);
  const debouncedSearch = useDebounce(filters.name, 500);
  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateNotification({
        sortType: isAsc ? "asc" : "desc",
        sortKey: property,
      }),
    );
    dispatch(getAllGroups())
  };

  useEffect(() => {
    if (debouncedSearch || filters.name == "")
      dispatch(getAllGroups())
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(getAllGroups())
    return () => dispatch(resetSubscription())
  }, []);

  const handleSearch = (event) => {
    //if (event.target.value)
    dispatch(updateNotification({ filters: { ...filters, name: event.target.value } }));
  };

  const handleSearchClear = () => {
    dispatch(updateNotification({ filters: { ...filters, name: "" } }));
    dispatch(getAllGroups());
  };
  const handleChangePerPage = (event, value) => {
    dispatch(updateNotification({ limit: event.target.value, page: 0 }));
    dispatch(getAllGroups());
  };

  const handleChange = (event, value) => {
    dispatch(updateNotification({ page: value - 1 }));
    dispatch(getAllGroups());
  };

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1"> Notification Groups</Typography>
          <Stack direction={"row"} gap={2}>
            <Button className="btnPrimary" variant="contained"
              disabled={ids.length == 0}
              onClick={() => setOpen1(true)}
            >
              Send Notification
            </Button>
            <Button className="btnPrimary" variant="contained"
              // disabled={!states?.permissions?.subscriptions?.create}
              onClick={() => navigate("/notification/group/add")}
            >
              Create Group
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            // overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            {/* <div className='allFilter cmpyMgmt '>
              <div className="d-flex align-items-center gap-2" style={{ marginRight: 0 }}>

              </div>
            </div> */}
            <SearchInput
              className="inputSearch sContrl p-0"
              value={filters.name}
              placeholder={"Search here..."}
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
              startAdornment={
                <InputAdornment className="overIcon" position="start"
                  sx={{
                    maxHeight: 'none',
                    height: '38px',
                    minWidth: '38px',
                    marginRight: '0px'
                  }}
                >
                  <Search />
                </InputAdornment>
              }
            />

          </div>

          
            <TableContainer>
              <Table
                stickyHeader aria-label="sticky table"
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  isSticky={sticky}
                  order={order}
                  orderBy={sortKey}
                  listing={listing}
                  setIds={setIds}
                  ids={ids}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  //rowCount={rows.length}
                  numSelected={0}
                  onSelectAllClick={function (

                  ) {
                    throw new Error("Function not implemented.");
                  }}
                //order={"desc"}
                />
                <TableBody>
                  {listing && listing?.length ? (
                    listing?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.id}
                          className={"tableRow"}
                          style={{ userSelect: "text" }}
                        >
                          <TableCell>
                            <Checkbox
                              // disabled={rowsSelected.includes(row.id)}
                              checked={ids.includes(row.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setIds((prev) => ([...prev, row.id]))
                                } else {
                                  setIds(ids.filter((v) => v !== row.id))
                                }
                              }}
                              // sx={{ cursor: rowsSelected.includes(row.id) ? 'not-allowed' : "" }}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />

                          </TableCell>

                          <TableCell component="td" scope="row">
                            {row?.name || "N/A"}

                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row?.type || "N/A"}

                          </TableCell>

                          <TableCell align="left">
                            {row?.description || "N/A"}

                          </TableCell>
                          <TableCell align="left">
                            {row.noOfUsers || 0}
                          </TableCell>
                          <TableCell align="left">
                            {getFormatedDate(row.updatedDate, "YYYY-MM-DD H:m:s")}
                          </TableCell>

                          <TableCell sx={{ cursor: "pointer" }}>
                            <Stack direction={"row"} gap={2}>
                              <div className="actBtnGrps">
                                {states?.permissions?.groups?.edit == 1 || true && <span className="editIcon" onClick={() => navigate(`/notification/group/${row.id}/group-edit`, { state: row })}>Edit</span>}
                                <span className="viewIcon" onClick={() => navigate("/notification/group/details/" + row.id)}>View</span>
                                <span className="viewIcon" style={{ color: "red" }} onClick={() => {
                                  setOpen(row.id)
                                }}>
                                  Delete
                                </span>
                              </div>

                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow colSpan={10}>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                        <Typography
                          color="inherit"
                          variant="subtitle1"
                          alignItems={"center"}
                          component="h3"
                        >
                          No Data found!
                        </Typography>

                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </TableContainer>
            <hr />
            <Stack
              sx={{
                background: 'transparent',
              }}
              direction="row"
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                <Box className="d-flex align-items-center rowPerPage" gap={0}>
                  <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                  <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                </Box>
                <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                  <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>
                    {listing && listing.length > 0
                      ? page === 0
                        ? `0 - ${listing.length}`
                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                        } `
                      : "0 - 0"}{" "}
                    &nbsp;of {totalCount} items
                  </Typography>
                  <Pagination
                    count={Math.ceil(totalPages / limit) || 0}
                    variant="outlined"
                    onChange={handleChange}
                    defaultPage={1} siblingCount={0} boundaryCount={1}
                  />
                </Box>
              </Box>
            </Stack >
          
          <CommonModel
            open={Boolean(open)}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={"Delete Group"}
            dialogPara={`Are you sure, you want to delete this group?`}
            nvYes={"Yes"}
            className={"btnDanger"}
            nvCancel={"Cancel"}
            yesHandle={() => {
              setIds(ids.filter((v) => v !== open))
              dispatch(deleteGroup(open))
              setOpen(false);
            }}
          />
        </Box>

        <NotificationType
          setOpen={setOpen1}
          open={open1}
          ids={ids}
        />


      </Stack>

    </>
  );
}
