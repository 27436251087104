import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useRef } from 'react';
import { getLink } from '../../utils/common';
import { SOCIAL_PLATEFORM, getFixedValue } from '../../utils/constant';
import Brands from './Brands';
import Posts from './Posts';
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "16px"
    },
}));
const smoothScrollTo = (target, duration) => {
    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const ease = easeInOutQuad(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, ease);
        if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
};

const ProfileSummary = ({ details, userDetails }) => {
    const step1Ref = useRef(null);
    const step2Ref = useRef(null);
    const step3Ref = useRef(null);
    const step4Ref = useRef(null);

    const scrollToStep = (stepRef) => {
        smoothScrollTo(stepRef.current, 800); // Adjust duration as needed
    };

    const getIndex = details.findIndex((v) => v?.content?.topMostPost?.length > 0);

    return (
        <div className='tabInnLayout'>
            <div className='row' style={{ padding: '0px 12px' }}>
                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 p-0 borderRight'>
                    <div className='scrollableTabs'>
                        <div className='sumOptions p-4 d-flex flex-column'>
                            <a href='#' onClick={() => scrollToStep(step1Ref)}><LayersOutlinedIcon />Platforms</a>
                            {/* <a href='#' onClick={() => scrollToStep(step2Ref)}><PhotoSizeSelectActualOutlinedIcon /> Content</a> */}
                            <a href='#' onClick={() => scrollToStep(step3Ref)}><ImageOutlinedIcon />Content</a>
                            <a href='#' onClick={() => scrollToStep(step4Ref)}><SellOutlinedIcon />Brands</a>
                        </div>
                    </div>
                </div>
                <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 p-0'>
                    <div className='sumdetPage'>

                        {/* Plateform section start */}
                        <div className='followItems p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3' id='step1' ref={step1Ref}>

                            {details?.map((v) => {
                                return <div className='item bgOne'>
                                    <h4 className='d-flex align-items-center' style={{ gap: '7px' }}>
                                        {SOCIAL_PLATEFORM.INSTAGRAM === v.platformType && <InstagramIcon color="error" />}
                                        {SOCIAL_PLATEFORM.FB === v.platformType && <FacebookOutlinedIcon color="primary" />}
                                        {SOCIAL_PLATEFORM.YOUTUBE === v.platformType && <YouTubeIcon color="error" />}
                                        {/* <Image src={v.platformType === SOCIAL_PLATEFORM.INSTAGRAM ? InstaImg : FaceImg} circle className='img-fluid mr-3' style={{ width: '22px', height: '22px', borderRadius: '0px' }} /> */}
                                        <a href="/" onClick={(e) => { e.preventDefault(); window.open(getLink(v.platformType, v.handleName), "_blank"); }} target='_blank'>{v.handleName}</a>

                                    </h4>
                                    <ul>
                                        <li>
                                            <label>{SOCIAL_PLATEFORM.YOUTUBE === v.platformType ? "Subscribers" : "Followers"}</label>
                                            <span>{getFixedValue(v.followers)}</span>
                                        </li>
                                        <li>
                                            <label>{SOCIAL_PLATEFORM.YOUTUBE === v.platformType ? "Total Videos" : "Avg. Likes"} </label>
                                            <span>{SOCIAL_PLATEFORM.YOUTUBE === v.platformType ? v?.content?.post?.length : getFixedValue(v?.Engagement?.posts?.avg_likes) || "N/A"}</span>
                                        </li>

                                        {SOCIAL_PLATEFORM.YOUTUBE === v.platformType && <li>
                                            <label>{"Avg. Likes"} </label>
                                            <span>{getFixedValue(v?.Engagement?.posts?.avg_likes)}</span>
                                        </li>}
                                        <li>
                                            <label>Avg. Comments</label>
                                            <span>{getFixedValue(v?.Engagement?.posts?.avg_comments) || "N/A"}</span>
                                        </li>
                                        <li>
                                            <label>{SOCIAL_PLATEFORM.INSTAGRAM !== v.platformType ? "Avg. Views" : "Avg. Reel Views"}</label>
                                            <span>{getFixedValue(v?.Engagement?.posts?.avg_views) || "N/A"}</span>
                                        </li>
                                        {SOCIAL_PLATEFORM.INSTAGRAM === v.platformType &&
                                            <li>
                                                <label>Engagement Rate &nbsp;<BootstrapTooltip arrow title="Engagement rate shows the percent of the audience who engages with the content posted by this influencer.

 It is calculated as average engagement (likes + comments) divided by follower count, and then multiply by 100 to give you a percentage." placement="top"><InfoIcon fontSize='small' color="secondary" /></BootstrapTooltip></label>
                                                <span>{v?.overview?.engagement_rate?.toFixed(2) + "%" || "N/A"}</span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            })}

                            {/* <div className='item bgTwo'>
                                <h4 className='d-flex align-items-center' style={{ gap: '7px' }}>
                                    <Image src={FaceImg} circle className='img-fluid mr-3' style={{ width: '22px', height: '22px', borderRadius: '0px' }} />
                                    @page1records
                                </h4>
                                <ul>
                                    <li>
                                        <label>Subscribers</label>
                                        <span>N/A</span>
                                    </li>
                                    <li>
                                        <label>Total Videos</label>
                                        <span>N/A</span>
                                    </li>
                                    <li>
                                        <label>Avg. Likes</label>
                                        <span>N/A</span>
                                    </li>
                                    <li>
                                        <label>Avg. Comments</label>
                                        <span>N/A</span>
                                    </li>
                                    <li>
                                        <label>Avg. Views</label>
                                        <span>N/A</span>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        {/* Plateform section End */}
                        <hr />
                        {/* Content section start */}
                        {/* <div className='newSec p-5' id='step2' ref={step2Ref}>
                            <h4 style={{ gap: '7px' }} className='text-uppercase d-flex align-items-center'><PhotoSizeSelectActualOutlinedIcon /> Content</h4>
                            <div className='secItem'>
                                <h4>Instagram Price</h4>
                                <div className='followItems'>
                                    <div className='item'>
                                        <ul>
                                            <li>
                                                <label>Reels</label>
                                                <span>N/A</span>
                                            </li>
                                            <li>
                                                <label>Posts</label>
                                                <span>N/A</span>
                                            </li>
                                            <li>
                                                <label>Story</label>
                                                <span>N/A</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='secItem'>
                                <h4>Youtube Price</h4>
                                <div className='followItems'>
                                    <div className='item'>
                                        <ul>
                                            <li>
                                                <label>Total Videos</label>
                                                <span>N/A</span>
                                            </li>
                                            <li>
                                                <label>Total Shorts</label>
                                                <span>N/A</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* Content section End */}



                        {/* Price section start */}
                        {false && <div className='newSec  p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3' id='step3' ref={step3Ref}>
                            <h4 style={{ gap: '7px' }} className='text-uppercase d-flex align-items-center'><CurrencyRupeeOutlinedIcon /> Prices</h4>
                            <div className='secItem mb-3'>
                                <div className='followItems'>
                                    <div className='item bgOne'>
                                        <Typography variant='h4' component="h4">Instagram Price</Typography>
                                        <ul>
                                            {userDetails?.rateCard?.instagram?.length > 0 ? userDetails?.rateCard?.instagram?.map((v) => <li>
                                                <label>{v.type}</label>
                                                <span>{v.price}</span>
                                            </li>) : <span>No Data Found!</span>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='secItem mb-3'>
                                <div className='followItems'>

                                    <div className='item bgTwo'>
                                        <Typography variant='h4' component="h4">Facebook Price</Typography>
                                        <ul>
                                            {userDetails?.rateCard?.facebook?.length > 0 ? userDetails?.rateCard?.facebook?.map((v) => <li>
                                                <label>{v.type}</label>
                                                <span>{v.price}</span>
                                            </li>) : <span>No Data Found!</span>}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className='secItem'>
                                <div className='followItems'>

                                    <div className='item bgTwo'>
                                        <Typography variant='h4' component="h4">Youtube Price</Typography>
                                        <ul>
                                            {userDetails?.rateCard?.youtube?.length > 0 ? userDetails?.rateCard?.youtube?.map((v) => <li>
                                                <label>{v.type}</label>
                                                <span>{v.price}</span>
                                            </li>) : <span>No Data Found!</span>}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>}
                        {/* Price section End */}
                        {/* Content start */}
                        <div className='newSec p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3' id='step4' style={{ position: "relative", top: "-20px" }} ref={step3Ref}>
                            {/* <h4 className='text-uppercase'><SellOutlinedIcon /> Content</h4> */}
                            <Posts details={details[getIndex]} flag={false} />
                        </div>

                        {/* Content End */}
                        <hr></hr>
                        {/* Brands section start */}
                        <div className='newSec  p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3' id='step4' ref={step4Ref}>


                            <Brands brands={details?.map((v) => v?.Brands || [])?.flat().filter((v, i, self) => i == self.findIndex((vv) => v.Name === vv.Name))} handleId={0} userId={0} />
                        </div>
                        {/* Brands section end */}
                        <hr />


                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileSummary;
