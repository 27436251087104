import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { groupDetails } from "../action";
import InfluencersList from "./influencersList";
const GroupDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { details } = useSelector((state) => state.notificationSlice);
    useEffect(() => {
        dispatch(groupDetails(params?.id));
    }, [params, dispatch])

    return (
        <>

            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography variant="h1" className="page-title">
                        View Notification Group
                    </Typography>
                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => { }}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} onClick={() => navigate("/notification/group")}>
                            Back
                        </Typography>

                    </Stack>

                </Stack>

            </Stack>
            <Box
                p={3}
                mb={2}
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <Grid container >

                    <Grid sm={12} lg={2} md={2} xs={12}>
                        <div className='d-flex flex-wrap gap-4 mb-4'>
                            <Avatar src={details.image} alt="" style={{ width: "110px", height: "110px", padding: '5px', borderRadius: "10px", border: '1px solid rgba(170, 170, 170, 0.3)' }} />
                        </div>
                    </Grid>
                    <Grid sm={12} lg={10} md={10} xs={12}>

                        <Stack direction={"row"} gap={15}>
                            <Typography variant="subtitle1" component="p" gutterBottom>
                                <span className="label ">Group Name : </span>  <span className=" text-capitalize">{details?.name}</span>
                            </Typography>
                            <Typography variant="subtitle1" component="p" gutterBottom>
                                <span className="label">&emsp;Group Type : </span>   {details?.type}
                            </Typography>
                        </Stack>
                        <br></br>
                        <Stack direction={"row"} gap={15}>
                            {details?.shortDescription &&
                                <Typography variant="subtitle1" component="p" gutterBottom>
                                    <span className="label ">Short Description : </span>  <span className=" text-capitalize">{details?.shortDescription}</span>
                                </Typography>}
                            <Typography variant="subtitle1" component="p">
                                <span className="label">Description: </span> {details?.description}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            <div className="card p-4">
                <InfluencersList details={details} />
            </div>
        </>
    );
}
export default GroupDetails;