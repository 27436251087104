import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  termsCondition: "",
  privacyPolicy: "",
  data: {}
};

export const privacySlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {
    updatePrivacy: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetPrivacy: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePrivacy, resetPrivacy } =
  privacySlice.actions;

export default privacySlice.reducer;
