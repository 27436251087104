import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { api } from "../../utils/api";
import { endpoints } from "../../utils/endpoints";
import { updatePrivacy } from "./privacySlice";




export const addPrivacy = (values) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.postApiCall(
      endpoints.policy,
      values,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateCommonData({
          message: "Data successfully updated",
          title: "Success",
          img: "/success.png",
          status: true
        }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getPrivacy = () => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.getApiCall(
      endpoints.policy,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updatePrivacy({
          data: respData.data.result
        }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};