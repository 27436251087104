import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemText, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Form, Formik } from "formik";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AutoSuggestion from "../../../components/autocomplete";
import SearchedInfluencer from '../../../components/card/SearchedInfluencer';
import InviteInfulencerModel from "../../../components/dialog";
import ManualSearchModel from "../../../components/dialog/ManualSearch";
import Drawer from '../../../components/drawer';
import NormalInput from '../../../components/inputs/normalInput';
import { FOLLOWERS_ARRAY, GENDER, citiesInIndia } from "../../../utils/constant";
import { getInfluencerProfilesSearch, invitedInfluencerBySearch } from '../action';
import { updateCampaign } from "../campaignSlice";
import Filter from '../create/filter';

const SendMoreInfluencer = () => {
    const [openModal, setOpenmodel] = useState(false);
    const [open, setOpen] = useState(false);
    const [accordian, setAccordian] = useState(true);
    const [filterState, setfilterState] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { formData, searchedInfluencers, searchedInfluencersCheckboxIds, searchedInfluencersInvitedIds, invitedInfluencers, advanceFilter } = useSelector((state) => state.campaignSlice);
    const { niches } = useSelector((state) => state.commonSlice);


    let initialvalues = {
        campaignApplyingPreferences: formData?.campaignApplyingPreferences || [],
        creatorLocation: formData?.creatorLocation || [],
        gender: formData?.gender || "Male",
        selectedCategories: formData?.selectedCategories || [],
        influncerType: formData?.influncerType || [],
        invitationCheckbox: searchedInfluencersCheckboxIds?.length || searchedInfluencersInvitedIds?.length || 0
    }
    useEffect(() => {
        dispatch(updateCampaign({ campaignId: id, formData: { campaignId: id } }));
    }, [id, dispatch])

    return <>
        <Stack direction={"column"} gap={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}

            >
                <Typography className="page-title" variant="h1">Profile Screener</Typography>
                <Stack
                    className='backText'
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/my-campaigns/influencer/" + id)}
                >
                    <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                            stroke="#0077FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <Typography variant="h5" color={"primary"}>
                        Back
                    </Typography>

                </Stack>
            </Stack>

            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >


                <Grid container >
                    <Grid item pl={5} mb={6} pb={2} xs={8} lg={8} md={8} sm={8} >
                        <Formik
                            initialValues={{ search: "" }}
                            enableReinitialize={true}
                            validateOnChange={true}
                            //validationSchema={Schema.Step4}
                            onSubmit={(values, { setSubmitting }) => {
                                setAccordian(false)
                                dispatch(updateCampaign({ filters: { ...values } }));
                                dispatch(getInfluencerProfilesSearch());
                                //navigate("/campaign/profile-screener")
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue,
                                isValid,
                                dirty,
                            }) => {

                                return (
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }}
                                    >
                                        <Grid container >
                                            <Grid item xs={12} lg={12} md={12} sm={12}   >
                                                <Stack className="searchBlockInflu" direction="row">
                                                    <Box className="searchText" style={{ width: 'calc(100% - 135px)' }}>
                                                        <NormalInput
                                                            name={"search"}
                                                            type={"text"}
                                                            isRequired={false}
                                                            focused={false}
                                                            label={("")}
                                                            sizeval="medium"
                                                            values={values.search}
                                                            placeholder={"Search any influencer here manually"}
                                                            onChange={handleChange}

                                                        />
                                                    </Box>
                                                    <Box>
                                                        <button
                                                            className="searchBtn"
                                                            type="submit"
                                                        >
                                                            Search

                                                        </button>
                                                    </Box>
                                                </Stack>


                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid item mt={6} xs={4} lg={4} md={4} sm={4} >
                        <Stack direction="row" pr={4} justifyContent="flex-end" alignContent={"right"} flexDirection={"right"}>
                            <button
                                className="btnPrimary"
                                type="submit"
                                onClick={() => setOpenmodel(true)}
                            >
                                Invite Manually

                            </button>
                        </Stack>
                    </Grid>

                    <Grid item xs={9} lg={9} md={9} sm={9} p={3}> <Typography variant="h4" component="h4">Influencer’s Profile Screener</Typography></Grid>

                </Grid>
                <hr />
                <Formik
                    initialValues={initialvalues}
                    enableReinitialize={true}
                    validateOnChange={true}
                    //validationSchema={Schema.ProfilerScreener}
                    onSubmit={(values, { setSubmitting }) => {

                        let filters = {
                            gender: values?.gender,
                            location: values?.creatorLocation,
                            influencerType: values?.influncerType?.map((v) => v?.split(" ")[0]),
                            categories: values?.selectedCategories,
                        }
                        const filterData = filters;//encodedURI(removeEmptyValues(filters));
                        dispatch(
                            updateCampaign({
                                advanceFilter: filterData
                            })
                        );
                        dispatch(getInfluencerProfilesSearch());
                        //dispatch(addCampaignInfo(values, endpoints.addCampaign));
                        // navigate("/campaign/profile-screener");
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        dirty,
                    }) => {
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <Grid container rowGap={2} p={2} >


                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Accordion sx={{ bgcolor: "#f7f7f7" }} key={accordian} defaultExpanded={accordian}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant="h4" component="h6">
                                                    Creator Preferences
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                                <Grid container rowGap={2} >
                                                    <Grid item xs={6} lg={6} md={6} sm={6}>
                                                        <FormControl>
                                                            <Typography mb={2} className="fs-6" variant="h6" component="h6">Creator Gender</Typography>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="gender"
                                                                value={values.gender}
                                                                onChange={handleChange}
                                                            >
                                                                {GENDER.map((v, i) => {
                                                                    return <FormControlLabel key={i} value={v} control={<Radio />} label={v} />
                                                                })}

                                                            </RadioGroup>
                                                        </FormControl>

                                                        <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Creator Location  <span className="impAstric">*</span></Typography>

                                                        <AutoSuggestion
                                                            multiple={true}
                                                            disableClearable={true}
                                                            values={values?.creatorLocation}
                                                            className="auto-chips-cover"
                                                            label={""}
                                                            onChange={(_e, newValue) => {
                                                                handleChange({
                                                                    target: { name: "creatorLocation", value: newValue },
                                                                });
                                                            }}
                                                            placeholder="Ex. Mumbai, Delhi"
                                                            // /key={formData.mode}
                                                            defaultValue={values?.creatorLocation || []}
                                                            id={`users-${values?.creatorLocation?.length}`}
                                                            onModalClose={() => { }}
                                                            size="small"
                                                            name="creatorLocation"
                                                            error={Boolean(touched.creatorLocation) && errors.creatorLocation}
                                                            helperText={touched.creatorLocation && errors.creatorLocation}
                                                            options={citiesInIndia}
                                                            isOptionEqualToValue={(option, value) => {
                                                                if (
                                                                    value === "" ||
                                                                    option === "" ||
                                                                    value === option
                                                                )
                                                                    return option;
                                                            }}
                                                            getOptionLabel={(option) =>
                                                                option || "no_option_found"
                                                            }
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    <Box
                                                                        component="li"
                                                                        sx={{
                                                                            "& > img": { mr: 2, flexShrink: 0 },
                                                                            fontFamily: "Poppins",
                                                                        }}
                                                                        {...props}
                                                                    >
                                                                        {option}
                                                                    </Box>
                                                                );
                                                            }}
                                                        />
                                                        <Typography mb={2} mt={3} className="fs-6" variant="h6" component="h6">Creator Type <span className="impAstric">*</span></Typography>
                                                        <FormGroup style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)' }}>
                                                            {FOLLOWERS_ARRAY.map((v, i) => {
                                                                return <FormControlLabel style={{ marginBottom: '10px' }} key={i} control={<Checkbox name="influncerType" value={v.label} checked={values.influncerType?.includes(v.label)} onChange={handleChange} />} label={v.label} />
                                                            })}
                                                        </FormGroup>
                                                        {errors.influncerType && touched.influncerType && (
                                                            <p
                                                                className="error"
                                                            >
                                                                {`${errors?.influncerType}`}
                                                            </p>
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={6} lg={6} md={6} sm={6} pl={4}>
                                                        {/* <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Creator Category</Typography> */}
                                                        <Stack direction="row" spacing={6}>
                                                            <Box width={"40%"} >
                                                                <Typography className="fs-6 mb-1 d-block" variant="contained" component="body2" >Creator Category <span className="impAstric">*</span></Typography>
                                                                <List dense={true} className="multiSelect css-h4y409-MuiList-root1" >
                                                                    {niches?.length > 0 && niches?.map((v, i) => {

                                                                        return <ListItem key={i} sx={{ cursor: "pointer", borderBottom: '1px solid #AAAAAA59', background: values?.selectedCategories?.includes(v) ? "#AAAAAA59" : "" }} >
                                                                            <ListItemText
                                                                                name={"selectedCategories"}
                                                                                value={v}
                                                                                onClick={() => {
                                                                                    const selectedCategoryIndex = values.selectedCategories?.indexOf(v);
                                                                                    if (selectedCategoryIndex === -1) {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "selectedCategories",
                                                                                                value: [...values?.selectedCategories, v]
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        const updatedSelectedCategories = values?.selectedCategories ? [...values?.selectedCategories] : [];
                                                                                        updatedSelectedCategories.splice(selectedCategoryIndex, 1);
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "selectedCategories",
                                                                                                value: updatedSelectedCategories
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                primary={v}
                                                                                secondary={''}
                                                                            />

                                                                        </ListItem>
                                                                    })}

                                                                </List>

                                                                <p></p>
                                                                {errors.selectedCategories && touched.selectedCategories && (
                                                                    <p
                                                                        className="error"
                                                                    >
                                                                        {`${errors?.selectedCategories}`}
                                                                    </p>
                                                                )}
                                                            </Box>
                                                            <Box width={"40%"} >
                                                                <Typography variant="contained" component="body2" >Selected Categories <span className="impAstric">*</span></Typography>
                                                                <List dense={true} className="multiSelect css-h4y409-MuiList-root1" >
                                                                    {values?.selectedCategories?.map((v, i) => {
                                                                        return <ListItem key={i} sx={{ borderBottom: '1px solid #AAAAAA59', background: "#AAAAAA59" }} >
                                                                            <ListItemText
                                                                                primary={v}
                                                                                secondary={''}
                                                                            />

                                                                        </ListItem>
                                                                    })}



                                                                </List>
                                                            </Box>
                                                        </Stack>
                                                        <Box

                                                            display="flex"
                                                            justifyContent="right"
                                                            alignItems="right"
                                                        >

                                                            <button
                                                                className="btnPrimary"
                                                                type="submit"
                                                            >
                                                                Search
                                                            </button>

                                                        </Box>
                                                    </Grid>

                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>

                <Grid container xs={12} lg={12} md={12} sm={12} p={5}>
                    <Grid item xs={8} lg={8} md={8} sm={8} mb={2}>

                        <Grid container rowGap={4} className='d-flex align-items-center justify-content-end' >
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Typography variant="h4" component="h4">Influencer’s Profile Screener</Typography>
                            </Grid>
                        </Grid>
                        <Grid container rowGap={4} className='d-flex align-items-center justify-content-end'>
                            <Grid item xs={4} lg={4} md={4} sm={4}>
                                {searchedInfluencers?.length > 0 && (
                                    <Typography py={3} >
                                        <Checkbox
                                            size='small'
                                            checked={searchedInfluencersCheckboxIds?.length === searchedInfluencers?.length}
                                            onChange={(event) => {
                                                let ids = [];
                                                if (event.target.checked) {
                                                    ids = searchedInfluencers?.map((v) => v.id) || [];
                                                } else {
                                                    ids = [];
                                                }
                                                dispatch(updateCampaign({ searchedInfluencersCheckboxIds: ids }));
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />&nbsp;
                                        <Link href="#" className='text-decoration-none'>Invite all</Link>
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item xs={8} lg={8} md={8} sm={8} >
                                <Stack direction="row" gap={4} pl={6} justifyContent="flex-end" alignContent={"right"} flexDirection={"right"}>
                                    {searchedInfluencersCheckboxIds.length > 0 && searchedInfluencersCheckboxIds?.length == searchedInfluencers?.length && (
                                        <button className='btnPrimary' onClick={() => {
                                            dispatch(updateCampaign({ searchedInfluencersInvitedIds: searchedInfluencersCheckboxIds }));
                                            dispatch(invitedInfluencerBySearch());
                                        }}>
                                            Send Invitations
                                        </button>
                                    )}
                                    {searchedInfluencers?.length > 0 && <button
                                        className="btnSecondary"
                                        onClick={() => setfilterState(true)}
                                    >
                                        Audience Filter <FilterAltIcon color="info" />

                                    </button>}
                                </Stack>
                            </Grid>


                        </Grid>
                        {(searchedInfluencers?.length === 0) && <Typography variant="h5" flexDirection={"right"}>{"We couldn't find any influencers that match your search..."}</Typography>}


                        <Grid container>
                            <Grid item xs={12} lg={12} md={12} sm={12}>

                                <SearchedInfluencer />

                                {/* {((formData?.campaignApplyingPreferences?.length > 0 ||
                                        invitedInfluencers?.length > 0 ||
                                        searchedInfluencersInvitedIds?.length > 0)) ? (
                                        <></>
                                    ) : <p
                                        className="error"
                                    >
                                        {formData?.mode != "edit" && "Please invite at least one Influencer or Set the campaign to 'Apply by Everyone'"}
                                    </p>} */}
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4} sm={4} >

                    </Grid>


                </Grid>

                <hr />



                <Grid item xs={12} lg={12} md={12} sm={12}>
                    <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
                    <ManualSearchModel openModal={open} setOpenmodel={setOpen} />
                </Grid>
            </Box>
        </Stack >

        <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
        <Drawer
            state={filterState}
            setState={setfilterState}
            className="filterDrawer"
            width="25%"
        >
            <Filter setfilterState={setfilterState} />
        </Drawer>
    </>
}
export default SendMoreInfluencer;