import AddCircleIcon from "@mui/icons-material/AddCircle";
import ImageIcon from '@mui/icons-material/Image';
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import moment from "moment";
import fifth from '../assets/images/companyQuestions/fifth.png';
import fourth from '../assets/images/companyQuestions/fourth.png';
import third from '../assets/images/companyQuestions/third.png';
import Alert from "../components/dialog/Alert";
import { CAMPAIGN_TYPES } from "./constant";
export const getAuthTokenFromLS = () => {
    return localStorage.getItem("token");
}

export const addNullInsteadofBlank = (obj) => {
    const newObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value == "" || !value) {
                newObj[key] = null
            } else
                newObj[key] = value;
        }

    }
    return newObj;
}

export const uniqueArrayByKey = (arr, key) => {
    const seen = new Set();
    return arr.filter(item => {
        const keyValue = item[key];
        if (seen.has(keyValue)) {
            return false;
        }
        seen.add(keyValue);
        return true;
    });
};

export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeEmptyValues = (obj) => {
    const newObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (Array.isArray(value) && value.length === 0) {
                continue; // Skip empty arrays
            } else if (typeof value === 'string' && value.trim() === '') {
                continue; // Skip empty strings
            }
            newObj[key] = value;
        }
    }
    return newObj;
}
export const hasNoBlankValues = (values) => {
    for (const key of values) {
        const value = values[key];

        if (Array.isArray(value)) {
            if (value.length === 0) return false;
        } else if (value === null || value === undefined) {
            return false;
        } else if (typeof value === 'object') {
            if (Object.keys(value).length === 0) return false;
        } else if (typeof value === 'string') {
            if (value.trim() === '') return false;
        }
    }

    return true;
};
export const removeEmptyValuesFromFormData = (formData) => {
    const newFormData = new FormData();
    formData.forEach((value, key) => {
        if (value === undefined || value === null) {
            return; // Skip undefined or null values
        } else if (typeof value === 'string' && value.trim() === '') {
            return; // Skip empty strings
        } else if (typeof value === 'object' && !(value instanceof File) && Object.keys(value).length === 0) {
            return; // Skip empty objects, except for files
        }
        newFormData.append(key, value);
    });
    return newFormData;
}

// Example usage
const exampleObj = {
    a: 'value',
    b: '',
    c: [],
    d: null,
    e: undefined,
    f: {},
    g: [1, 2, 3],
    h: '   ',
    i: { nested: 'value' }
};

// Output


export const encodedURI = (data) => {
    const jsonString = JSON.stringify(data);
    return encodeURIComponent(jsonString);
}

export const formatDate = (date, format = 'YYYY-MM-DD') => {
    return date ? new Date(date).toISOString().slice(0, 10) : null;
}
export const dateForamt = (customDate) => {
    if (!customDate || customDate === "") return "N/A";
    const date = new Date(customDate);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
}

export const removeHtmltags = (data) => data?.replace(/(<([^>]+)>)/gi, "");
export const getFormatedDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date).format(format);
}
export const setCompanyName = (data) => {
    localStorage.setItem("companyName", data);
}
export const setSearchdata = (data) => {
    localStorage.setItem("searchData", data);
}
export const getSearchdata = () => {
    return localStorage.getItem("searchData") != "null" || localStorage.getItem("searchData") != "" ? JSON.parse(localStorage.getItem("searchData")) : {};
}
export const getCompanyName = () => {
    return localStorage.getItem("companyName");
}
export const getBrandsCount = () => {
    return parseInt(localStorage.getItem("brandCount")) || 0;
}
export const getUserDetails = () => {
    return localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
}

export const setCompanyid = (data) => {
    localStorage.setItem("companyid", data);
}
export const getCompanyid = () => {
    return localStorage.getItem("companyid");
}
export const setCompanyFlowStatus = (data) => {
    localStorage.setItem("showCompanyFlow", data);
}
export const getCompanyFlowStatus = () => {
    return localStorage.getItem("showCompanyFlow");
}

export const getUserInfo = () => {
    return localStorage.getItem("companyid");
}

export const getUserType = () => {
    return localStorage.getItem("type");
}
export const isValidURL = (string) => {
    return string?.files && string?.files?.length > 0;
};
export const getMaximumKey = (data) => {
    let maxValue = 0
    let maxKey = "";
    data.forEach(obj => {

        const key = Object.keys(obj)[0];
        const value = obj[key];

        if (value > maxValue) {
            maxValue = value;
            maxKey = key;
        }
    });
    return maxKey;
}

export const getTop10Records = (data) => {

    const top10Records = Object?.entries(data.reduce((acc, obj) => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        acc[key] = (acc[key] || 0) + value;
        return acc;
    }, {}))
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(([key, value]) => ({ [key]: value }));

    return top10Records;

}

export const sumOfAll = (data) => {
    const v = data.reduce((accumulator, currentValue) => {
        const key = Object.keys(currentValue)[0];
        return accumulator + currentValue[key];
    }, 0);
    return v;
}

export const UrlRestrictionsArray = [
    {
        urlKey: "dashboard",
        pKey: "dashboard"
    },
    {
        urlKey: "companies",
        pKey: "company"
    },
    {
        urlKey: "campaigns",
        pKey: "campaign"
    },
    {
        urlKey: "brands",
        pKey: "brand"
    },
    {
        urlKey: "adminUsers",
        pKey: "adminUsers"
    },
    {
        urlKey: "roles",
        pKey: "roleAndRights"
    },
    {
        urlKey: "audit-trails",
        pKey: "auditTrail"
    },
    {
        urlKey: "userManagement",
        pKey: "users"
    },
    {
        urlKey: "subscription",
        pKey: "subscription"
    },
]

export const ALERT_MESSAGE = (status, message) => {
    <Alert status={status} msg={message} />
}
export const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for mobile user agents
    return /android|iphone|ipad|ipod|opera mini|iemobile|mobile|blackberry|bb10|webos|windows phone|zune/.test(userAgent.toLowerCase());
};

export const DELIVERABLES_DATA = [
    {
        facebook: [
            {
                name: "Image",
                title: "facebook",
                icon: <ImageIcon />,
                dropDownvalue: ["Single", "Carrousel"],//
            },
            {
                name: "Video",
                title: "facebook",
                icon: <PlayCircleIcon />,
                dropDownvalue: ["Post", "Reel"],
            },
            {
                name: "Stories",
                title: "facebook",
                icon: <AddCircleIcon />,
                dropDownvalue: ["Video", "Image"], //
            },
        ],
    },
    {
        instagram: [
            {
                name: "Image",
                title: "instagram",
                icon: <ImageIcon />,
                dropDownvalue: ["Single", "Carrousel"], //, 
            },
            {
                name: "Video",
                title: "instagram",
                icon: <PlayCircleIcon />,
                dropDownvalue: ["Post", "Reel"],
            },
            {
                name: "Stories",
                title: "instagram",
                icon: <AddCircleIcon />,
                dropDownvalue: ["Video", "Image",], //
            },
        ],
    },
    {
        youtube: [
            {
                name: "Video",
                title: "youtube",
                icon: <PlayCircleIcon />,
                dropDownvalue: ["Long Form Video", "Short"],
            },
            {
                name: "Image",
                title: "image",
                icon: <ImageIcon />,
                dropDownvalue: ["Community Post"],
            }
        ],
    },
];

export const SELECTED_DELI = [
    {
        "qty": 0,
        "post": "",
        "type": "image"
    },
    {
        "qty": 0,
        "post": "",
        "type": "video"
    },
    {
        "qty": 0,
        "post": "",
        "type": "stories"
    }
]

export const SELECTED_DELI_FOR_YOUTUBE = [
    {
        "qty": 0,
        "post": "",
        "type": "video"
    },
    {
        "qty": 0,
        "post": "",
        "type": "image"
    }
]
export const COMPANY_QUE_FLOW = [
    // {
    //     question: "Key Messages or Brand Talking Points",
    //     image: first,
    //     name: "keyMessages",
    //     answer: [
    //         "Discovering Influencers",
    //         "Making Plans List",
    //         "Running Campaigns",
    //         "Insights & Aanalytics"
    //     ]
    // },
    // {
    //     question: "Brand Tone of Voice & Communication Style",
    //     image: second,
    //     name: "brandTone",
    //     answer: [
    //         "Discovering Influencers1",
    //         "Making Plans List1",
    //         "Running Campaigns1",
    //         "Insights & Aanalytics1"
    //     ]
    // },
    {
        question: "How do you currently run Influencer campaigns?",
        image: third,
        name: "influencersCampaign",
        answer: [
            "In-House Team",
            "Through Agencies",
            "Through Influencer Platform",
            "Haven’t run campaigns yet"
        ]
    },
    {
        question: "How many Influencers did you work last month?",
        image: fourth,
        name: "lastMonthInfluencers",
        answer: [
            "0-10",
            "10-25",
            "25-50",
            "50+",
            "Nill"
        ]
    },
    {
        question: "In what ways can Inflyx provide support and assistance?",
        image: fifth,
        name: "support",
        answer: [
            "Discovering Influencers",
            "Making Plans List",
            "Running Campaigns",
            "Insights & Aanalytics"
        ]
    },
]
export const obfuscateEmail = (email) => {
    if (!email) return;
    const [localPart, domain] = email?.split('@');
    const obfuscatedLocalPart = localPart?.substring(0, 2) + '*****';
    return `${obfuscatedLocalPart}@${domain}`;
}

export const maskPhoneNumber = (phoneNumber) => {
    if (phoneNumber && phoneNumber?.length < 4) {
        return phoneNumber;
    }
    const maskedSection = '*'.repeat(phoneNumber?.length - 4);
    const visibleSection = phoneNumber?.slice(-4);
    return `${maskedSection}${visibleSection}`;
}
export const maskCardNumber = (cardNumber) => {
    // Replace all but the last 4 digits with X's
    return cardNumber ? cardNumber?.slice(-4)?.padStart(cardNumber.length, 'X') : "N/A";
}

export const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};
export const isEmptyObject = (obj) => {
    let jsonObject = obj !== undefined ? JSON.parse(obj) : {};
    return typeof jsonObject === "object" && jsonObject !== null && Object.keys(jsonObject).length > 0 ? jsonObject.base64 : false
};

export const getLink = (platform, handle) => {
    switch (platform) {
        case "youtube":
            return "https://www.youtube.com/" + handle

        case "instagram":
            return "https://www.instagram.com/" + handle
        default:
        // code block
    }
}


export const getRedirectlink = (module, id, user_id) => {
    switch (module) {
        case "campaign":
            return "/campaign/details/" + id
        case "influencer_profile":
            return "/userManagement/details/" + id
        case "subscription_plan":
            return "/subscription/details/" + id
        case "role":
            return "/roles/view/" + id
        case "user":
            return "/adminUsers"
        case "admin_profile":
            return "/adminUsers"
        case "company_profile":
            return "/company/details/" + id + "?user_id=" + user_id
        default:
            return "/"
    }
}

export const getGender = (gender) => {
    let g = gender?.toLowerCase();
    switch (g) {
        case "f":
            return "Female"

        case "m":
            return "Male"
        default:
            return g;
        // code block
    }
}
export const formatAgeString = (ageString) => {
    const numericPart = ageString?.replace(/[a-zA-Z]/g, '') || "N/A";
    return numericPart;
}

export const maxValue = (data) => {
    const maxObject = data?.reduce((max, obj) => {
        return (obj?.value > max?.value) ? obj : max;
    }, data[0]);
    return maxObject;
}
export const getDiscountedRate = (price, total) => {
    let rate = (price * total) / 100;
    return rate > 0 ? total - rate?.toFixed(2) : "";
}

export const formatPrice = (number) => {
    return parseFloat(number) > 0 ? parseFloat(number)?.toLocaleString() : number;
}

export const getFormatedNumber = (number) => {
    return number ? parseInt(number) : 0;
}
export const downloadFile = (response) => {
    try {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Company.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.log("error in downloading");
    }
};

export const getCostPrice = (costper, price) => {
    switch (costper) {
        case "costPerShare":
            return price.costPerShare
        case "costPerLinkClick":
            return price.costPerLinkClick
        case "costPerView":
            return price.costPerView
        case "costPerEngagement":
            return price.costPerEngagement
        default:
    }

}

export const getValuesChecked = (values) => {
    console.log("values", values)
    const val = values.filter((v, i) => v);
    return val.length;
}

export const getDetailsValuesChecked = (values) => {
    let valuesChecked = [values.name, values?.brandId, values?.startDate, values?.endDate, values?.bannerImage, values?.brief, values?.budgetPerInfluencer, values?.noOfCreators];

    if ([CAMPAIGN_TYPES.PERFORMANCE].includes(values.type)) {
        valuesChecked = [...valuesChecked, values.costPer, getCostPrice(values.costPer, values), values.kpiPerCreator, values.applyBydate]
    }
    if (values.type !== CAMPAIGN_TYPES.BARTER) {
        valuesChecked = [...valuesChecked, values?.budget];
    }
    if ([CAMPAIGN_TYPES.AFFILIATE].includes(values.type)) {
        valuesChecked = [...valuesChecked, values.eventProgramPrice, values.kpiPerCreator, values.applyBydate]
    } else {
        valuesChecked = [...valuesChecked, values?.contentSubmissionDate, values?.contentPublishDate, values.conceptScriptDate];
    }
    valuesChecked = valuesChecked.filter((v, i) => {
        return v === "" || v == null;
    })

    let valuesChecked2 = values.products?.filter((v) => v.name !== '' && v.link !== '');

    return valuesChecked.length === 0 && valuesChecked2.length > 0 ? true : false
}

export const camelCaseToTitleCase = (str) => {
    return str
        .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
        .replace(/^./, function (match) { return match.toUpperCase(); }) // Capitalize the first letter of the string
        .replace(/(\s[a-z])/g, function (match) { return match.toUpperCase(); }); // Capitalize the first letter after a space
}
export const parseJSONorString = (input) => {
    try {
        // Attempt to parse the input as JSON
        const parsed = JSON.parse(input);

        // If parsed is an object, return it; otherwise, it's a string
        if (typeof parsed === 'object' && parsed !== null) {
            return parsed;
        } else {
            return input; // It's not a JSON object but still valid JSON (e.g., a number, boolean)
        }
    } catch (e) {
        // If parsing fails, it means input is a plain string
        return input;
    }
}

export const getToFixed = (data) => data?.toFixed(2);

export const formatString = (input) => {
    return input?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/^./, str => str.toUpperCase());
}