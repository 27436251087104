import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInfluencerProfilesSuggestions } from '../../pages/campaigns/action';
import { updateCampaign } from '../../pages/campaigns/campaignSlice';
import NormalInput from '../inputs/textarea';
const ManualSearchModel = (props) => {
    const { openModal, setOpenmodel } = props;
    const handleClose = () => setOpenmodel(false);
    const dispatch = useDispatch();
    const [value, setValue] = useState({ platform: "", handle: "" });
    return (
        <>
            <Dialog open={openModal} onClose={handleClose}>
                <DialogTitle>Mannual Search</DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={2}>

                            <Grid item xs={12} lg={12} md={12} sm={12}>

                                <Typography variant='contained' component="p">Choose a platform</Typography>
                                <Stack direction={"row"} gap={2} mt={1}>
                                    <Button onClick={() => setValue((prev) => ({ ...prev, platform: "instagram" }))} variant={value.platform === "instagram" ? "contained" : "outlined"} color='primary' startIcon={<InstagramIcon size="small" />} sx={{ borderRadius: "10px" }} >Instagram</Button>
                                    <Button onClick={() => setValue((prev) => ({ ...prev, platform: "youtube" }))} variant={value.platform === "youtube" ? "contained" : "outlined"} startIcon={<YouTubeIcon size="small" />} sx={{ borderRadius: "10px" }}>Youtube</Button>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <FormLabel>Influencers</FormLabel>
                                <NormalInput
                                    name={"handle"}
                                    type={"text"}
                                    focused={false}
                                    label={("")}
                                    sizeval="medium"
                                    values={value.handle}
                                    onChange={(e) => setValue((prev) => ({ ...prev, handle: e.target.value }))}
                                    placeholder="Please enter Instagram profiles in any of the below formats.
                                    Full profile link, https://www.instagram.com/johndoe ,Only handle, `johndoe` or `@johndoe` "
                                    multiline={true}
                                    style={{ m: 0, p: 0 }}
                                />

                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={() => {
                        const jsonString = JSON.stringify({ platform: value.platform });
                        const encodedString = encodeURIComponent(jsonString);
                        dispatch(updateCampaign({ influencersFilter: encodedString }));
                        dispatch(getInfluencerProfilesSuggestions());
                        handleClose()
                    }} color="primary" variant="contained">Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ManualSearchModel;
