import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FIREBASE_BASE_URL } from "../utils/constant";
export const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const firebaseApi = createApi({
  reducerPath: "firebaseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: FIREBASE_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    pushNotification: builder.mutation({
      query: (data) => ({
        url: "/push-notification",
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const { usePushNotificationMutation } = firebaseApi;
