import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getInfluencerListFordashboard } from '../../pages/dashboard/action';
import { updateDashboard } from '../../pages/dashboard/dashboardSlice';
import { setSearchdata } from '../../utils/common';
const filter = createFilterOptions();

export default function CustomAuto({ handleChange, values, type }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { filters } = useSelector((state) => state.dashboardSlice)
    const { niches } = useSelector((state) => state.commonSlice);

    let industries = niches?.map((v) => {
        return { title: v };
    });

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const searchValue = event.target.value.trim(); // Get trimmed search value

            if (searchValue) {
                let data = { filters: { platform: values.platform || "Instagram", name: searchValue } };
                dispatch(updateDashboard(data));
                setSearchdata(JSON.stringify({ platform: values.platform || "Instagram", name: searchValue }));
                dispatch(getInfluencerListFordashboard());
                if (type == "dashboard") {
                    navigate("/dashboard/listing");
                }
                return false;
            }
        }
    };
    console.log("1234567", values.categories)
    return (
        <React.Fragment>
            <Autocomplete
                multiple={true}
                value={values.categories}
                onChange={(event, newValue) => {
                    if (newValue && newValue.length && newValue[newValue.length - 1].inputValue) {
                        filters = { ...filters };
                        delete filters.categories;
                        let name = newValue?.length > 0 ? newValue?.find((v) => v?.inputValue)?.inputValue : "";
                        let data = { filters: { platform: values.platform || "Instagram", name: name } }
                        dispatch(updateDashboard(data));
                        dispatch(getInfluencerListFordashboard());
                        if (type == "dashboard") {
                            setSearchdata(JSON.stringify({ platform: values.platform || "Instagram", name: name }));
                            navigate("/dashboard/listing");
                        }
                    } else {
                        handleChange(event, newValue);

                    }
                }}
                limitTags={2}
                key={values?.categories?.length}
                defaultValue={values.categories}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: `Influencers with the name "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}

                id="free-solo-dialog-demo"
                options={industries.filter((v) => !values.categories.map((v) => v?.title).includes(v?.title))}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option?.inputValue) {
                        return option?.inputValue;
                    }
                    return option?.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                clearIcon={<ClearIcon fontSize="small" onClick={() => {
                    setSearchdata(JSON.stringify({ platform: filters.platform }));
                    dispatch(updateDashboard({ filters: { platform: filters.platform } }));
                    dispatch(getInfluencerListFordashboard());
                }} />}
                freeSolo
                renderInput={(params) => <TextField {...params} label="" onKeyDown={handleEnterKeyPress} placeholder={values?.categories?.length > 0 ? "Add More.." : "Categories, Name"} />}
            />

        </React.Fragment>
    );
}
