import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const DetailsButtons = () => {
    const location = useLocation();
    const { id } = useParams()
    const navigate = useNavigate();
    const {
        campaignInfluencersList, formData
    } = useSelector((state) => state.campaignSlice)

    return <Stack direction="row" className="viewDetTabs mt-0 campaignDetails">
        <button className={location?.pathname?.split("/")?.includes("campaign") ? "active" : ""}
            onClick={() => {
                navigate("/campaign/partial/details/" + id)
                // if (getUserType() === "admin") {
                //     navigate("/campaign/details/" + id);
                // } else {
                //     navigate("/campaign/partial/details/" + id)
                // }
            }}
        >
            Details
        </button>
        <button className={location?.pathname?.split("/")?.includes("influencer") ? "active" : ""}
            onClick={() => navigate("/my-campaigns/influencer/" + id, { state: { campaignName: formData.name } })}
        >
            Influencer’s Applications{campaignInfluencersList?.length > 0 && location?.pathname?.split("/")?.includes("influencer") ? <span> {campaignInfluencersList?.length}</span> : ''}
        </button>
        <button className={location?.pathname?.split("/")?.includes("negotiated-influencers") ? "active" : ""}
            onClick={() => navigate("/my-campaigns/negotiated-influencers/" + id, { state: { campaignName: formData.name, type: formData.type } })}
        >
            Negotiated Influencers {campaignInfluencersList?.length > 0 && location?.pathname?.split("/")?.includes("negotiated-influencers") ? <span> {campaignInfluencersList?.length}</span> : ''}
        </button>

        <button className={location?.pathname?.split("/")?.includes("approved-influencer") ? "active" : ""}
            onClick={() => navigate(`/my-campaigns/approved-influencer/${id}`)}
        >
            Approved Influencers{campaignInfluencersList?.campaignContent?.length > 0 && location?.pathname?.split("/")?.includes("approved-influencer") ? <span> {campaignInfluencersList?.campaignContent?.length} </span> : ''}
        </button>

    </Stack >
}

export default DetailsButtons;