import { Box } from "@mui/material";
import * as React from "react";

export default function FooterComponent() {
  return (
    <Box
      className="footer"
      sx={{
        position: 'absolute',
        display: "none",
        bottom: '0',
        left: '0',
        width: '100%',
        height: '50px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0px 1.5rem 0px',
        background: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <p className="m-0" style={{ fontSize: '14px', color: '#6C7692', fontWeight: '400' }}>Copyright &#169; 2024. All Rights Reversed.</p>
      <div style={{ fontSize: '12px', color: '#6C7692', fontWeight: '400' }}>Version 0.1.0</div>
    </Box>
  );
}
