import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getParamMasterList } from '../../../layout/action';
import { getAuthTokenFromLS, getCompanyid } from '../../../utils/common';
import { BASE_URL } from '../../../utils/constant';
import { endpoints } from '../../../utils/endpoints';
import { getAllActivePlans } from '../action';

const SubscriptionsCompany = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getParamMasterList());
        dispatch(getAllActivePlans());
    }, [dispatch]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(BASE_URL + endpoints.getAllActivePlans + "/" + getCompanyid(), {
                    headers: {
                        "Authorization": getAuthTokenFromLS()
                    }
                });
                const data = await response.json();
                const activePlanCount1 = data.result.plan;
                const customPlan = data.result.currentPlan;
                const count = activePlanCount1?.filter((v) => v.isActive == 1);
                console.log("customPlan", customPlan)
                if (id === undefined || !id) {
                    if (count.length === 0 && customPlan?.type !== 'Custom') {
                        navigate("/subscription/active/details/" + getCompanyid())
                    } else {
                        navigate("/subscription/active")

                    }
                }
                console.log(data); // Do something with the data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        //
        fetchData();
    }, []);

    return <p>.</p>

}

export default SubscriptionsCompany