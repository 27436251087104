import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, CardMedia, Checkbox, Chip, Grid, Pagination, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInfluencerProfilesSearch, invitedInfluencerBySearch } from '../../pages/campaigns/action';
import { updateCampaign } from '../../pages/campaigns/campaignSlice';
import { ROUTES } from '../../utils/routes';
import PaginationSelect from '../select/pagination';

const SearchedInfluencer = () => {
    const dispatch = useDispatch();
    const { searchedInfluencers, searchedInfluencersInvitedIds, searchedInfluencersCheckboxIds, invitedInfluencers, influencerslimit, influencersPage, totalInfluencersCount } = useSelector((state) => state.campaignSlice);

    useEffect(() => {
        dispatch(updateCampaign({ influencerslimit: 10, influencersPage: 0 }));
        //dispatch(getInfluencerProfilesSearch());
    }, [])

    const handleChangePerPage = (event, value) => {
        dispatch(updateCampaign({ influencerslimit: event.target.value, influencersPage: 0 }));
        dispatch(getInfluencerProfilesSearch());
    };

    const handleChange = (event, value) => {
        dispatch(updateCampaign({ influencersPage: value - 1 }));
        dispatch(getInfluencerProfilesSearch());
    };
    return (
        <>
            {searchedInfluencers?.length > 0 && searchedInfluencers?.filter((v) => !invitedInfluencers?.includes(v.id))?.map((v, i) => {
                let object = v.platformUrls ? Object?.keys(JSON.parse(v?.platformUrls ?? {})) : [];
                return (
                    <Box key={i}
                        p={3}
                        mb={2}
                        sx={{
                            backgroundColor: '#f7f7f7',
                            transition: 'all .5s ease-in-out',
                            position: 'relative',
                            border: '1px solid rgba(170, 170, 170, 0.2)',
                            borderRadius: '10px',
                            overflow: 'hidden',
                        }}>
                        <Grid container>
                            {/* Left side: Image */}
                            <Grid item xs={3} lg={3} md={3} sm={3} className='d-flex align-items-start' gap={1.5}>
                                <Checkbox
                                    size='small'
                                    checked={searchedInfluencersCheckboxIds.includes(v.id)}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            dispatch(updateCampaign({ searchedInfluencersCheckboxIds: [...searchedInfluencersCheckboxIds, v.id], listType: 1 }));
                                        } else {
                                            dispatch(updateCampaign({ searchedInfluencersCheckboxIds: searchedInfluencersCheckboxIds.filter((d) => d !== v.id) }));
                                        }
                                    }}
                                    key={i}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <CardMedia
                                    component="img"
                                    image={v.picture ? v.picture : "/profile1.jpg"} // Replace with the actual image URL
                                    alt={v.name}
                                    style={{ borderRadius: '10px', width: '140px', height: "140px", border: '1px solid rgba(170, 170, 170, 0.5)' }}
                                />
                            </Grid>
                            {/* Right side: Data */}
                            <Grid item lg={9} md={9} sm={9}>
                                <Box>
                                    <Typography className="text-capitalize d-flex align-items-center" variant="h4" component="h4" gap={2}>
                                        {v.name}
                                        <Typography className="d-flex align-items-center" gap={0.5}>
                                            {object && object.includes("Facebook") && <FacebookOutlinedIcon size="small" color='primary' />}
                                            {object && object.includes("Instagram") && <InstagramIcon size="small" color='error' />}
                                            {object && object.includes("Youtube") && <YouTubeIcon size="small" color='error' />}
                                        </Typography>
                                    </Typography>
                                    <Grid container p={0}>
                                        <Grid item xs={8} lg={8} md={8} sm={8}>
                                            <Grid item xs={12} lg={12} md={12} sm={12} py={2} gap={1} className='d-flex flex-wrap'>
                                                {v.profileNiche && JSON.parse(v.profileNiche)?.map((v) => <Chip label={v.option} key={v.option} color="primary" size="small" />)}
                                            </Grid>
                                            <Stack className='screenDetails' direction="row" mb={1} spacing={4} rowGap={1}>
                                                <Typography variant="h4" className='fs-5'>
                                                    <label>Followers: </label>
                                                    <span>{v.platforms != null && v?.platforms?.length > 0 ? v.platforms[0]?.followers || "N/A" : "N/A"}</span>
                                                </Typography>
                                                <Typography variant="h4" className='fs-5'>
                                                    <label>Location: </label>
                                                    <span>{v?.city + " " + v?.state || "N/A"}</span>
                                                </Typography>
                                            </Stack>
                                            <Stack className='screenDetails' direction="row" spacing={1}>
                                                <Typography variant="h4" className='fs-5'>
                                                    <label>Brands Endorse: </label>
                                                    <span>N/A</span>
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4} lg={4} md={4} sm={4}>
                                            <Stack spacing={1}>
                                                <button className='btnPrimary' onClick={() => window.open(`${ROUTES.USER_DETAILS}${v.id}`, "_blank")} target='_blank'>View Details</button>
                                                <button className='btnPrimary' onClick={() => {
                                                    dispatch(updateCampaign({ invitedInfluencers: [v.id], searchedInfluencersInvitedIds: [...searchedInfluencersInvitedIds, v.id] }));
                                                    dispatch(invitedInfluencerBySearch());

                                                }}>
                                                    Send Invitation
                                                </button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )
            })}
            {searchedInfluencers?.filter((v) => !invitedInfluencers?.includes(v.id))?.length > 0 &&
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} >
                        <Stack
                            sx={{
                                background: 'transparent',
                            }}
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                                <Box className="d-flex align-items-center" gap={2}>
                                    Rows per page: <PaginationSelect handleChange={handleChangePerPage} page={influencerslimit} />
                                </Box>
                                <Box className="d-flex flex-wrap align-items-center" gap={2}>
                                    {/* <Typography variant="subtitle2">
                                    {searchedInfluencers && false && searchedInfluencers.length > 0
                                        ? page === 0
                                            ? `0 - ${searchedInfluencers.length}`
                                            : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + myCampaigns.length
                                            } `
                                        : "0 - 0"}{" "}
                                    &nbsp;of {totalCount} items
                                </Typography> */}
                                    <Pagination
                                        count={Math.ceil(totalInfluencersCount / influencerslimit) || 0}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            }
        </>
    );

};

export default SearchedInfluencer;
