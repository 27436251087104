import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import React from "react";

export default function NormalInput({
  label,
  placeholder,
  id,
  name,
  type,
  onBlur,
  values,
  onChange,
  style,
  inpImg,
  className,
  helperText,
  isRequired,
  multiline,
  rows,
  error,
  touched,
  focused,
  sizeval,
  isDisabled,
  inputProps,
  onKeyDown,
  min,
  max,
  isShrink = false,
  sx,
  readOnly = false,
  defaultValue,
  isEndAdornment = false,
  showPassword,
  handleViewPassword,
  variant = "outlined",
  startAdornment = false,
  startAdornmentText = "",
  step = 1
}) {
  return (
    <TextField
      autoFocus={focused}
      variant={variant}
      autoComplete="new-password"
      onBlur={onBlur}
      // id="outlined-number"
      value={values?.length > 0 ? values : ""}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
      defaultValue={defaultValue}
      name={name}
      required={isRequired}
      InputProps={{
        inputProps: { min: min, max: max, step: step },
        style: sx,
        readOnly: readOnly,
        startAdornment: startAdornment ? startAdornmentText : null,
        endAdornment: isEndAdornment ? (
          <InputAdornment position="end">
            <IconButton
              onClick={handleViewPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : null
      }}
      type={type}
      label={label}
      size={sizeval}
      fullWidth
      multiline={multiline}
      rows={"2"}
      disabled={isDisabled ? isDisabled : false}
      inputProps={inputProps}
      className={`${className}`}
      sx={style}
      InputLabelProps={{
        shrink: isShrink,
      }}
    />
  );
}
