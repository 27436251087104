import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Review = () => {

    const navigate = useNavigate();
    const { formData } = useSelector((state) => state.campaignSlice);
    useEffect(() => {
        setTimeout(() => {
            navigate("/campaign/details/" + formData.campaignId)
        }, 1500);
    }, []);

    return <><Grid item mb={2} xs={12} lg={12} md={12} sm={12}><Typography variant="h4" component="h4">Create New Campaign</Typography></Grid><Grid container rowGap={1} className="bg-white" textAlign={"center"} >

        <Grid item xs={12} mt="15%" mb="15%" lg={12} md={12} sm={12}>
            <Grid item mb={2} xs={12} lg={12} md={12} sm={12}>
                <img src="/review.png" width={"5%"} alt="review" />
            </Grid>
            <Grid item xs={12} mb={1} lg={12} md={12} sm={12}>
                <Typography variant="h4" component="h4">Campaign  Preview</Typography>
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12}>
                <Typography variant="body" >Your campaign is send for the review and will update you in next 48hrs</Typography>
            </Grid>
        </Grid>
    </Grid></>
}
export default Review;