import { toast } from "react-toastify";

const commonError = "OOPS! Something went wrong!";

const toastList = new Set();
const MAXIMUM_TOAST = 25;

export const Alert = (type, message) => {

    switch (type) {
        case 1: {
            if (toastList.size < MAXIMUM_TOAST) {
                const id = toast.success(message, {
                    onClose: () => toastList.delete(id),
                });
                toastList.add(id);
            }
            break;
        }
        case 2: {
            if (toastList.size < MAXIMUM_TOAST) {
                const id = toast.error(message, {
                    onClose: () => toastList.delete(id),
                });
                toastList.add(id);
            }
            break;
        }
        case 3: {
            if (toastList.size < MAXIMUM_TOAST) {
                const id = toast.info(message, {
                    onClose: () => toastList.delete(id),
                });
                toastList.add(id);
            }
            break;
        }
        default: {
            if (toastList.size < MAXIMUM_TOAST) {
                const id = toast.info(commonError, {
                    onClose: () => toastList.delete(id),
                });
                toastList.add(id);
            }
            break;
        }
    }
};
