import { TextField } from '@mui/material';

export default function SearchInput({ handleSearch, value, clearSearch, placeholder, className, id, name, startAdornment, style }) {
	return (
		<TextField
			value={value}
			size="small"
			variant="outlined"
			name={name}
			id={id}
			sx={style}
			className={`searchInput ${className}`}
			placeholder={placeholder ? placeholder : 'Search'}
			onChange={handleSearch}
			InputProps={{
				startAdornment: (
					startAdornment
				)
			}}

		/>
	);
}
