import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paramMasterList: [],
    plateforms: [],
    niches: [],
    message: "",
    title: "",
    img: "",
    status: false,
    getPlanButton: false,
    socket: {},
    influencerType: [],
    userDetails: {},
    userInfo: {},
    fixedSubscriptions: [],
    triggerTable: []
};

export const commonSlice = createSlice({
    name: "commonSlice",
    initialState,
    reducers: {
        updateCommonData: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetCommonData: (state, action) => {
            return initialState;
        },
    },
});

// Action creators are generated for each case reducer function
export const { updateCommonData, resetCommonData } = commonSlice.actions;

export default commonSlice.reducer;
