import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function AutoSuggestion(props) {
    const {
        name,
        label,
        onBlur,
        id,
        onChange,
        disabled,
        value,
        options,
        getOptionLabel,
        error,
        renderOption,
        helperText,
        isOptionEqualToValue,
        defaultValue,
        disableClearable = false,
        onInputChange,
        required = false,
        placeholder,
        multiple = false,
        renderTags,
        classes,
        limitTags,
        freeSolo,
        filterOptions,
        clearIcon,
        handleClear,
        getOptionDisabled
    } = props;

    return (
        <Autocomplete
            limitTags={limitTags}
            multiple={multiple}
            freeSolo={freeSolo}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            blurOnSelect={true}
            disableClearable={disableClearable}
            size='small'
            // getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            classes={classes}
            onBlur={onBlur}
            id={id}
            sx={{
                '& .MuiAutocomplete-inputRoot': {
                    height: 'auto', // Adjust this value as needed
                },
            }}
            forcePopupIcon
            options={options}
            renderTags={renderTags}
            autoHighlight
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onInputChange={onInputChange}
            clearIcon={clearIcon ? <ClearIcon fontSize="small" onClick={handleClear} /> : null}
            renderInput={(params) => (
                <TextField
                    freeSolo={freeSolo}
                    required={required}
                    name={name}
                    value={value}
                    defaultValue={value}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        form: {
                            autocomplete: 'off',
                        },
                        style: { height: "31px" },
                    }}
                />
            )}
        />
    );
}

export default AutoSuggestion;
