import {
  Search
} from "@mui/icons-material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  Avatar,
  Box,
  Button,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import DateRangePickerComponent from "../../components/date/DateRangePickerComponent";

import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import CommonModel from "../../components/dialog/Common";
import NormalInput from "../../components/inputs/normalInput";
import SearchInput from "../../components/inputs/searchInput";
import PaginationSelect from "../../components/select/pagination";
import useDebounce from "../../hooks/use.debounce";
import { getFormatedDate } from "../../utils/common";
import { BASE_URL, PLAN_STATUS, SOCKET_EVENT } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";
import { getAllBrandForDropDown } from "../brands/action";
import { getAllSubscriptions } from "../subscriptions/action";
import { updateSubscription } from "../subscriptions/subscriptionSlice";
import { deleteCompany, getAllCompanyforListing, getCompanyCSVDowanload } from "./action";
import { resetCompany, updateCompany } from "./companySlice";
function EnhancedTableHead(props) {
  const { order, orderBy } = props;


  const headCells = [

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Company Name",
      type: "",
    },
    {
      id: "contact_person",
      numeric: false,
      disablePadding: true,
      label: "Contact Person",
      type: "",
    },
    {
      id: "comp_runing",
      numeric: false,
      disablePadding: true,
      label: "Campaigns Running",
      type: "",
    },
    {
      id: "last_comp_date",
      numeric: false,
      disablePadding: true,
      label: "Last Campaign Date",
      type: "",
    },

    {
      id: "subs_plan",
      numeric: false,
      disablePadding: true,
      label: "Subscription Plan",
      type: "",
    },
    {
      id: "plan_status",
      numeric: false,
      disablePadding: true,
      label: "Plan Status",
      type: "",
    },
    {
      id: "ren_date",
      numeric: false,
      disablePadding: true,
      label: "Renewal Date",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Action",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className="hideActionSort">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={() => null}
              //onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}


const socket = io(BASE_URL + endpoints.ims, {
  path: '/stream',
  transports: ['websocket']
});
export default function Companies(props) {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [open, setOpen] = useState(false);

  const [id, setId] = useState(0);
  const tableRef = useRef();
  const dispatch = useDispatch();
  const {
    limit,
    page,
    sort_key,
    totalCount,
    totalPages,
    filters,
    listing,
  } = useSelector((state) => state.companySlice);
  const {
    listing: planList,
  } = useSelector((state) => state.subscriptionSlice);
  const debouncedSearch = useDebounce(filters.search, 500);

  const handleChangePerPage = (event, value) => {
    dispatch(updateCompany({ limit: event.target.value, page: 0 }));
    dispatch(getAllCompanyforListing());
  };
  useEffect(() => {
    const handleCampaignCreated = (data) => {
      return dispatch(getAllCompanyforListing());
    };
    socket.on(SOCKET_EVENT.COMPANY_CREATED, handleCampaignCreated);
    return () => {
      socket.off(SOCKET_EVENT.COMPANY_CREATED, handleCampaignCreated);
    };
  }, [socket]);



  const handleChange = (event, value) => {
    dispatch(updateCompany({ page: value - 1 }));
    dispatch(getAllCompanyforListing());
  };

  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateCompany({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );
    dispatch(getAllBrandForDropDown());
  };

  useEffect(() => {
    if (debouncedSearch || filters.search == "")
      dispatch(getAllCompanyforListing())
  }, [debouncedSearch]);



  useEffect(() => {
    dispatch(updateSubscription({ limit: 10000 }));
    dispatch(getAllCompanyforListing())
    dispatch(getAllSubscriptions());
    return () => dispatch(resetCompany())
  }, []);

  const handleSearch = (event) => {
    //if (event.target.value)
    dispatch(updateCompany({ filters: { ...filters, search: event.target.value }, page: 0 }));
  };

  const handleSearchClear = () => {
    dispatch(updateCompany({ filters: { ...filters, search: "" } }));
    dispatch(getAllCompanyforListing());
  };
  useEffect(() => {
    const tableContainer = tableRef.current;
    const handleScroll = () => {
      if (tableContainer) {
        const { scrollTop, clientHeight, scrollHeight } = tableContainer;
        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
          //dispatch(updateCompany({ limit: limit + 10, page: 0 }));
          //dispatch(getAllCompanyforListing())
        }
      }
    };

    tableContainer.addEventListener('scroll', handleScroll);

    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  });



  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1"> Company Management</Typography>
          <button className="btnPrimary"
            disabled={!states?.permissions?.company?.create}
            onClick={() => navigate("/company/add")}
          >
            Create Company
          </button>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            // overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            <SearchInput
              className="inputSearch sContrl p-0"
              value={filters.search}
              placeholder={"Search here..."}
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
              startAdornment={
                <InputAdornment className="overIcon" position="start"
                  sx={{
                    maxHeight: 'none',
                    height: '38px',
                    minWidth: '38px',
                    marginRight: '0px'
                  }}
                >
                  <Search />
                </InputAdornment>
              }
            />

            <div className='allFilter campMgmt'>
              {/* <DateRangePickerComponent text="Last Campaign Date" monthsShown={1} getSelectedDate={(dates) => {
                console.log("dates", dates)
                if (dates?.filter((v) => v)?.length === 2) {
                  dispatch(updateCompany({ filters: { ...filters, lastCamapignDate: getFormatedDate(dates[0]) } }));
                  dispatch(getAllCompanyforListing());
                }

              }} dateReset={filters} style={{ position: "relative", right: 101 }} /> */}
              <div className="d-flex align-items-center ">
                <Stack direction={"row"} gap={0.5}>
                  <Select
                    value={filters.planId || ''}
                    name="planId"
                    onChange={(e) => {
                      dispatch(updateCompany({ filters: { ...filters, planId: e.target.value } }));
                      dispatch(getAllCompanyforListing());
                    }}
                    displayEmpty
                    defaultValue={""}
                  >
                    <MenuItem value="" disabled hidden>
                      Current Plan
                    </MenuItem>
                    <MenuItem value=" " >
                      Current Plan (All)
                    </MenuItem>
                    {planList.map((row) => (
                      row.status !== "Draft" && <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {false && <Select
                    sx={{ width: "175px" }}
                    value={filters.planId || ''}
                    name="planId"
                    onChange={(e) => {
                      dispatch(updateCompany({ filters: { ...filters, planId: e.target.value } }));
                      dispatch(getAllCompanyforListing());
                    }}
                    displayEmpty
                    defaultValue={""}
                    renderValue={(selected) => {
                      const selectedItem = planList.find((row) => row.id === selected);
                      return (selected ? (selectedItem?.name?.trim() ? `Current Plan (${selectedItem.name})` : "Current Plan (All)") : "Current Plan")
                    }} // Custom rendering

                  >
                    <MenuItem value="" disabled hidden>
                      Current Plan
                    </MenuItem>
                    <MenuItem value=" " >
                      All
                    </MenuItem>
                    {planList.map((row) => (
                      row.status !== "Draft" && <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  }
                  <Select
                    value={filters.subscriptionStatus || ''}
                    name="planId"
                    onChange={(e) => {
                      dispatch(updateCompany({ filters: { ...filters, subscriptionStatus: e.target.value } }));
                      dispatch(getAllCompanyforListing());
                    }}
                    displayEmpty
                    defaultValue={""}
                  >
                    <MenuItem value="" disabled hidden>
                      Plan Status
                    </MenuItem>
                    <MenuItem value=" " >
                      Plan Status (All)
                    </MenuItem>

                    {PLAN_STATUS.map((row) => (
                      <MenuItem key={row} value={row}>
                        {row}
                      </MenuItem>
                    ))}
                  </Select>
                  <DateRangePickerComponent text="Last Campaign Date" monthsShown={1} getSelectedDate={(dates) => {

                    if (dates?.filter((v) => v)?.length === 2) {
                      dispatch(updateCompany({ filters: { ...filters, lastCampaignStartdate: getFormatedDate(dates[0]), lastCampaignEnddate: getFormatedDate(dates[1]) } }));
                      dispatch(getAllCompanyforListing());
                    }

                  }} dateReset={filters} style={{ position: "relative", right: 101 }} />


                  <DateRangePickerComponent text="Subscription Renewal Date" monthsShown={1} getSelectedDate={(dates) => {
                    console.log("dates", dates)
                    if (dates?.filter((v) => v)?.length === 2) {
                      dispatch(updateCompany({ filters: { ...filters, renewalCampaignStartdate: getFormatedDate(dates[0]), renewalCampaignEnddate: getFormatedDate(dates[1]) } }));
                      dispatch(getAllCompanyforListing());
                    }

                  }} dateReset={filters} style={{ position: "relative", right: 101 }} />

                  {false && <NormalInput
                    name={"lastCamapignDate"}
                    type={"date"}
                    focused={false}
                    isShrink={true}
                    label={<FormLabel>Last Campaign Date</FormLabel>}
                    placeholder="eeeeeee"
                    sizeval="medium"
                    values={filters.lastCamapignDate}
                    // min={filters.lastCamapignDate}
                    onChange={(e) => {
                      dispatch(updateCompany({ filters: { ...filters, lastCamapignDate: e.target.value } }));
                      dispatch(getAllCompanyforListing());
                    }}
                  />}

                  <Button className="downloadCSV" startIcon={<FileDownloadOutlinedIcon fontSize="small" />} onClick={() => dispatch(getCompanyCSVDowanload())}>Download CSV</Button>


                  <Tooltip title="Refresh listing">
                    <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', mt: 1.5 }}
                      onClick={() => {
                        dispatch(updateCompany({ filters: {} }))
                        dispatch(getAllCompanyforListing());
                      }} />
                  </Tooltip>
                </Stack>
              </div>
            </div>
          </div>


          <TableContainer className="tableHeightFix" stickyHeader ref={tableRef}>
            <Table
              stickyHeader aria-label="sticky table"
            >
              <EnhancedTableHead
                // numSelected={selected.length}

                order={order}
                orderBy={sort_key}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                //rowCount={rows.length}
                numSelected={0}
                onSelectAllClick={function (

                ) {
                  throw new Error("Function not implemented.");
                }}
              //order={"desc"}
              />
              <TableBody >
                {listing && listing?.length ? (
                  listing?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        className={"tableRow"}
                        style={{ userSelect: "text" }}
                      >

                        <TableCell component="th" scope="row" className="d-flex align-items-center" padding="none">
                          <Avatar
                            alt={row?.companyName}
                            src={row.logo}
                            sx={{ height: '35px', width: '35px', borderRadius: "50%" }}
                          />
                          <Link className="text-decoration-none" style={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0px', marginLeft: '8px' }} onClick={(e) => {
                            e.preventDefault();
                            navigate("/company/details/" + row.userId, { state: row })
                          }} >{row?.companyName || "N/A"}</Link>

                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row?.contactPersonName || "N/A"}
                        </TableCell>

                        <TableCell align="left">
                          {row?.campaignsRunning || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.lastCampaignDate ? getFormatedDate(row?.lastCampaignDate, "MM/DD/YYYY") : "N/A"}

                        </TableCell>
                        <TableCell align="left">
                          {row?.planName || "N/A"}
                        </TableCell>
                        <TableCell align="left">

                          {row?.subscriptionStatus ? <button className={row?.subscriptionStatus?.toLowerCase() + " capitalize-first-letter"}>{row?.subscriptionStatus}</button> : "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.renewalDate ? getFormatedDate(row?.renewalDate, "MM/DD/YYYY") : "N/A"}
                        </TableCell>
                        <TableCell sx={{ cursor: "pointer" }}>
                          <Stack direction={"row"}
                            sx={{
                              gap: '8px'
                            }}
                          >
                            <div className="actBtnGrps">
                              {states?.permissions?.company?.edit == 1 && <span className="editIcon" onClick={() => navigate(`/company/edit/${row.userId}`, { state: row })}>Edit</span>}
                              <span className="viewIcon" onClick={() => navigate("/company/details/" + row.userId, { state: row })}>View</span>
                            </div>
                            {/* <ModeEditIcon className="editIcon" size="small" onClick={() => navigate(`/company/edit/${row.id}`, { state: row })} />
                              <RemoveRedEyeIcon className="viewIcon" size="small" onClick={() => navigate("/company/details/" + row.id, { state: row })} /> */}
                            {/* <DeleteIcon size="small" color="error" onClick={() => {
                              setOpen(true);
                              setId(row.userId);
                            }} /> */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        alignItems={"center"}
                        component="h3"
                      >
                        No Data found!
                      </Typography>

                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </TableContainer>
          <hr />
          <Stack
            sx={{
              background: 'transparent',
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
              <Box className="d-flex align-items-center rowPerPage" gap={0}>
                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
              </Box>
              <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>

                  {listing && listing.length > 0
                    ? page === 0
                      ? `0 - ${listing.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <Pagination
                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                  defaultPage={1} siblingCount={0} boundaryCount={1}
                />
              </Box>
            </Box>

          </Stack>

          <CommonModel
            open={open}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={"Remove Company"}
            dialogPara={`Are you sure you want to remove this company? `}
            nvYes={"Submit"}
            nvCancel={"Cancel"}
            yesHandle={() => {
              dispatch(deleteCompany(id));
              setOpen(false);
            }}
          />
        </Box>




      </Stack>

    </>
  );
}
