import { updateGlobalLoader } from "../components/backdrop/backdropSlice";
import { updateCommonData } from "../redux/commonSlice";
import { api } from "../utils/api";
import { DELIVERABLES_POST, INFLUENCER_NICHE, INFLUENCER_TYPE, LANGUAGES, PLATEFORMS, SHOPIFY_EVENTS, SUBSCRIPTION_FIXED_PLAN_KEY, TRIGGER_TABLE } from "../utils/constant";
import { endpoints } from "../utils/endpoints";

export const getParamMasterList = () => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        const { user } = getState().app;
        api.getApiCall(
            endpoints.paramMaster,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                let data = respData.data.result;
                dispatch(
                    updateCommonData({
                        paramMasterList: respData.data.result,
                        plateforms: JSON.parse(data?.find((v) => v.parameterkey === PLATEFORMS)?.value),
                        deliverablesPlatform: JSON.parse(data?.find((v) => v.parameterkey === DELIVERABLES_POST)?.value),
                        niches: JSON.parse(data?.find((v) => v.parameterkey === INFLUENCER_NICHE)?.value)?.map((v) => v.option),
                        influencerType: JSON.parse(data?.find((v) => v.parameterkey === INFLUENCER_TYPE)?.value),
                        userDetails: JSON.parse(user || "{}"),
                        fixedSubscriptions: JSON.parse(data?.find((v) => v.parameterkey === SUBSCRIPTION_FIXED_PLAN_KEY)?.value),
                        triggerTable: JSON.parse(data?.find((v) => v.parameterkey === TRIGGER_TABLE)?.value),
                        shopifyEvents: JSON.parse(data?.find((v) => v.parameterkey === SHOPIFY_EVENTS)?.value),
                        languages: JSON.parse(data?.find((v) => v.parameterkey === LANGUAGES)?.value),
                    })
                );

            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                // dispatch(updateCommonData({
                //     message: "Something went to wrong",
                //     title: "502",
                //     img: "/failed.png",
                //     status: true
                // }));
            }
        );
    };
};
export const getUserInfoNew = (id) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.getuserDetails + id,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(updateCommonData({ userInfo: respData.data.result }));
            },
            (errors) => {
                console.log("error", errors);
            }
        );
    };
};