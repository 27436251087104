import { createSlice } from "@reduxjs/toolkit";
import { setCompanyFlowStatus } from "../utils/common";
import { api } from "./api";
const sidebarClose = '80px';
const sidebarOpen = '255px';

const initialState = {
  sidebar: true,
  sidebarWidth: sidebarOpen,
  token: localStorage.getItem('token') || null,
  user: localStorage.getItem('user') || null,
  permissions: {},
};
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSidebar: (state) => {
      state.sidebar = !state.sidebar;
      state.sidebar ? state.sidebarWidth = sidebarOpen : state.sidebarWidth = sidebarClose;
    },
    setPermission: (state, action) => {
      state.permissions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.result.userData;
        if (payload.status !== "FAILURE") {
          let otp = payload?.result?.otp;
          if (otp !== true) {
            localStorage.setItem("user", JSON.stringify({ id: state.user?.id, roleId: state.user?.roleId, userType: state.user?.userType, }))
            localStorage.setItem("token", payload.result.jwtToken)
            localStorage.setItem("type", state.user?.userType)
            setCompanyFlowStatus(state?.user?.showCompanyFlow || 0)
          } else {
            sessionStorage.setItem("jwttoken", payload.result.jwtToken)
          }
        }
      }
    )
  }
});
export default appSlice.reducer;

export const { setSidebar, setPermission } = appSlice.actions;
