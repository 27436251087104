import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Form, Formik } from "formik";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AutoSuggestion from "../../../components/autocomplete";
import SearchedInfluencer from '../../../components/card/SearchedInfluencer';
import InviteInfulencerModel from "../../../components/dialog";
import ManualSearchModel from "../../../components/dialog/ManualSearch";
import Drawer from '../../../components/drawer';
import NormalInput from '../../../components/inputs/normalInput';
import Schema from '../../../schema';
import { CREATOR_CATEGORY, FOLLOWERS_ARRAY, GENDER, citiesInIndia } from "../../../utils/constant";
import { addNewCampaign, getInfluencerProfilesSearch, invitedInfluencerBySearch } from '../action';
import { updateCampaign } from "../campaignSlice";
import Filter from './filter';

const ProfilerScreener = () => {
    const [openModal, setOpenmodel] = useState(false);
    const [open, setOpen] = useState(false);
    const [accordian, setAccordian] = useState(true);
    const [creator, setCreator] = useState({});
    const [filterState, setfilterState] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formData, searchedInfluencers, searchedInfluencersCheckboxIds, searchedInfluencersInvitedIds, invitedInfluencers, filters } = useSelector((state) => state.campaignSlice);
    const { niches } = useSelector((state) => state.commonSlice);
    useEffect(() => {
        window.onbeforeunload = () => true;

        return () => {
            window.onbeforeunload = null;
        };
    }, [formData])

    let initialvalues = {
        campaignApplyingPreferences: formData?.campaignApplyingPreferences || [],
        creatorLocation: formData?.creatorLocation || [],
        gender: formData?.gender || "Male",
        selectedCategories: formData?.selectedCategories || [],
        influncerType: formData?.influncerType || [],
        invitationCheckbox: searchedInfluencersCheckboxIds?.length || searchedInfluencersInvitedIds?.length || 0
    }


    return <>
        {/*  */}
        <Box display={open ? "none" : ""} >
            <Stack direction={"column"} gap={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">Profile Screener</Typography>
                </Stack>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'hidden',
                    }}
                >


                    <Grid container >
                        <Grid item pl={5} mb={6} pb={2} xs={8} lg={8} md={8} sm={8} >
                            <Formik
                                initialValues={{ search: "" }}
                                enableReinitialize={true}
                                validateOnChange={true}
                                //validationSchema={Schema.Step4}
                                onSubmit={(values, { setSubmitting }) => {

                                    setAccordian(false)
                                    dispatch(updateCampaign({ filters: { ...values, source: "campaign" }, advanceFilter: {} }));
                                    dispatch(getInfluencerProfilesSearch());
                                    navigate("/campaign/profile-screener")
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    setFieldValue,
                                    isValid,
                                    dirty,
                                }) => {

                                    return (
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit(e);
                                            }}
                                        >
                                            <Grid container >
                                                <Grid item xs={12} lg={12} md={12} sm={12}   >
                                                    <Stack className="searchBlockInflu" direction="row">
                                                        <Box className="searchText" style={{ width: 'calc(100% - 135px)' }}>
                                                            <NormalInput
                                                                name={"search"}
                                                                type={"text"}
                                                                isRequired={false}
                                                                focused={false}
                                                                label={("")}
                                                                sizeval="medium"
                                                                values={values.search}
                                                                placeholder={"Search any influencer here manually"}
                                                                onChange={handleChange}

                                                            />
                                                        </Box>
                                                        <Box>
                                                            <button
                                                                className="searchBtn"
                                                                type="submit"
                                                            >
                                                                Search

                                                            </button>
                                                        </Box>
                                                    </Stack>


                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                        <Grid item mt={6} xs={4} lg={4} md={4} sm={4} >
                            <Stack direction="row" pr={4} justifyContent="flex-end" alignContent={"right"} flexDirection={"right"}>
                                <button
                                    className="btnPrimary"
                                    type="submit"
                                    onClick={() => setOpenmodel(true)}
                                >
                                    Invite Manually

                                </button>
                            </Stack>
                        </Grid>

                        <Grid item xs={9} lg={9} md={9} sm={9} p={3}> <Typography variant="h4" component="h4">Influencer’s Profile Screener</Typography></Grid>
                        {false && <Grid item xs={3} lg={3} md={3} sm={3} p={2}>
                            <Stack direction="row" gap={4} pl={6} justifyContent="flex-end" alignContent={"right"} flexDirection={"right"}>

                                <button
                                    className="btnPrimary"
                                    type="submit"
                                    onClick={() => setOpenmodel(true)}
                                >
                                    Invite Manually

                                </button>
                            </Stack>
                        </Grid>}
                    </Grid>
                    <hr />
                    <Formik
                        initialValues={initialvalues}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validationSchema={Schema.ProfilerScreener}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log("values", values)
                            let filters = {
                                gender: values?.gender,
                                location: values?.creatorLocation,
                                influencerType: values?.influncerType?.map((v) => v?.split(" ")[0]),
                                categories: values?.selectedCategories,
                                //status: USER_STATUS.APPROVED
                            }
                            if (values?.gender == "Any") {
                                delete filters?.gender;
                            }
                            const filterData = filters;//encodedURI(removeEmptyValues(filters));
                            dispatch(
                                updateCampaign({
                                    formData: { ...formData, ...values },
                                    advanceFilter: filterData,
                                    filters: { source: "campaign" }
                                })
                            );
                            let creator = {
                                campaignApplyingPreferences: values.campaignApplyingPreferences.length > 0 ? 1 : 0,
                                creator: JSON.stringify({ gender: values.gender, influncerType: values.influncerType, selectedCategories: values.selectedCategories, creatorLocation: values.creatorLocation, campaignApplyingPreferences: values.campaignApplyingPreferences.length > 0 ? true : false }),
                            }
                            setCreator(values)
                            dispatch(addNewCampaign(creator, navigate));
                            dispatch(getInfluencerProfilesSearch());
                            //dispatch(addCampaignInfo(values, endpoints.addCampaign));
                            navigate("/campaign/profile-screener");
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    <Grid container rowGap={2} p={2} >
                                        <Grid item p={2} xs={12} lg={12} md={12} sm={12} className='sectionBgColor' >
                                            <Typography variant="h4" component="h6" >Campaign Applying Preferences</Typography>
                                            <FormControlLabel sx={{ mt: 2 }} control={<Checkbox name="campaignApplyingPreferences" checked={values?.campaignApplyingPreferences?.length > 0} value="campaignApplyingPreferences" onChange={(e) => {
                                                handleChange(e)
                                                if (e.target.checked) {
                                                    dispatch(updateCampaign({ formData: { ...formData, campaignApplyingPreferences: ['campaignApplyingPreferences'] } }));
                                                } else {
                                                    dispatch(updateCampaign({ formData: { ...formData, campaignApplyingPreferences: [] } }));
                                                }
                                            }} />} label="Apply by Everyone" />
                                        </Grid>

                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Accordion sx={{ bgcolor: "#f7f7f7" }} key={accordian} defaultExpanded={accordian}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                >
                                                    <Typography variant="h4" component="h6">
                                                        Creator Preferences
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>

                                                    <Grid container rowGap={2} >
                                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                                            <FormControl>
                                                                <Typography mb={2} className="fs-6" variant="h6" component="h6">Creator Gender</Typography>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="gender"
                                                                    value={values.gender}
                                                                    onChange={handleChange}
                                                                >
                                                                    {GENDER.map((v, i) => {
                                                                        return <FormControlLabel key={i} value={v} control={<Radio />} label={v} />
                                                                    })}

                                                                </RadioGroup>
                                                            </FormControl>

                                                            <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Creator Location <span className="impAstric">*</span></Typography>

                                                            <AutoSuggestion
                                                                multiple={true}
                                                                disableClearable={true}
                                                                values={values?.creatorLocation}
                                                                className="auto-chips-cover"
                                                                label={""}
                                                                onChange={(_e, newValue) => {
                                                                    handleChange({
                                                                        target: { name: "creatorLocation", value: newValue },
                                                                    });
                                                                }}
                                                                placeholder="Ex. Mumbai, Delhi"
                                                                // /key={formData.mode}
                                                                defaultValue={values?.creatorLocation || []}
                                                                id={`users-${values?.creatorLocation?.length}`}
                                                                onModalClose={() => { }}
                                                                size="small"
                                                                name="creatorLocation"
                                                                error={Boolean(touched.creatorLocation) && errors.creatorLocation}
                                                                helperText={touched.creatorLocation && errors.creatorLocation}
                                                                options={citiesInIndia}
                                                                isOptionEqualToValue={(option, value) => {
                                                                    if (
                                                                        value === "" ||
                                                                        option === "" ||
                                                                        value === option
                                                                    )
                                                                        return option;
                                                                }}
                                                                getOptionLabel={(option) =>
                                                                    option || "no_option_found"
                                                                }
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <Box
                                                                            component="li"
                                                                            sx={{
                                                                                "& > img": { mr: 2, flexShrink: 0 },
                                                                                fontFamily: "Poppins",
                                                                            }}
                                                                            {...props}
                                                                        >
                                                                            {option}
                                                                        </Box>
                                                                    );
                                                                }}
                                                            />
                                                            <Typography mb={2} mt={3} className="fs-6" variant="h6" component="h6">Creator Type <span className="impAstric">*</span></Typography>
                                                            <FormGroup style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)' }}>
                                                                {FOLLOWERS_ARRAY.map((v, i) => {
                                                                    return <FormControlLabel style={{ marginBottom: '10px' }} key={i} control={<Checkbox name="influncerType" value={v.value} checked={values.influncerType?.includes(v.value)} onChange={handleChange} />} label={v.label} />
                                                                })}
                                                            </FormGroup>
                                                            {errors.influncerType && touched.influncerType && (
                                                                <p
                                                                    className="error"
                                                                >
                                                                    {`${errors?.influncerType}`}
                                                                </p>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={6} lg={6} md={6} sm={6} pl={4}>
                                                            {/* <Typography mb={1} mt={3} className="fs-6" variant="h6" component="h6">Creator Category</Typography> */}
                                                            <Stack direction="row" spacing={6}>
                                                                <Box width={"40%"} >
                                                                    <Typography className="fs-6 mb-1 d-block" variant="contained" component="body2" >Creator Category <span className="impAstric">*</span></Typography>
                                                                    <List dense={true} className="multiSelect css-h4y409-MuiList-root1" >
                                                                        {niches?.length > 0 && niches?.map((v, i) => {

                                                                            return <ListItem key={i} sx={{ cursor: "pointer", borderBottom: '1px solid #AAAAAA59', background: values?.selectedCategories?.includes(v) ? "#AAAAAA59" : "" }} >
                                                                                <ListItemText
                                                                                    name={"selectedCategories"}
                                                                                    value={v}
                                                                                    onClick={() => {
                                                                                        const selectedCategoryIndex = values.selectedCategories?.indexOf(v);
                                                                                        if (selectedCategoryIndex === -1) {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: "selectedCategories",
                                                                                                    value: [...values?.selectedCategories, v]
                                                                                                }
                                                                                            });
                                                                                        } else {
                                                                                            const updatedSelectedCategories = values?.selectedCategories ? [...values?.selectedCategories] : [];
                                                                                            updatedSelectedCategories.splice(selectedCategoryIndex, 1);
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: "selectedCategories",
                                                                                                    value: updatedSelectedCategories
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    primary={v}
                                                                                    secondary={''}
                                                                                />

                                                                            </ListItem>
                                                                        })}

                                                                    </List>

                                                                    <p></p>
                                                                    {errors.selectedCategories && touched.selectedCategories && (
                                                                        <p
                                                                            className="error"
                                                                        >
                                                                            {`${errors?.selectedCategories}`}
                                                                        </p>
                                                                    )}
                                                                </Box>
                                                                <Box width={"40%"} >
                                                                    <Typography variant="contained" component="body2" >Selected Categories <span className="impAstric">*</span></Typography>
                                                                    <List dense={true} className="multiSelect css-h4y409-MuiList-root1" >
                                                                        {values?.selectedCategories?.map((v, i) => {
                                                                            return <ListItem key={i} sx={{ borderBottom: '1px solid #AAAAAA59', background: "#AAAAAA59" }} >
                                                                                <ListItemText
                                                                                    primary={v}
                                                                                    secondary={''}
                                                                                />

                                                                            </ListItem>
                                                                        })}



                                                                    </List>
                                                                </Box>
                                                            </Stack>
                                                            <Box

                                                                display="flex"
                                                                justifyContent="right"
                                                                alignItems="right"
                                                            >

                                                                <button
                                                                    className="btnPrimary"
                                                                    type="submit"
                                                                >
                                                                    Save
                                                                </button>

                                                            </Box>
                                                        </Grid>

                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>

                    <Grid container xs={12} lg={12} md={12} sm={12} p={5}>
                        <Grid item xs={8} lg={8} md={8} sm={8} mb={2}>

                            <Grid container rowGap={4} className='d-flex align-items-center justify-content-end' >
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant="h4" component="h4">Influencer’s Profile Screener</Typography>
                                </Grid>
                            </Grid>
                            <Grid container rowGap={4} className='d-flex align-items-center justify-content-end'>
                                <Grid item xs={12} lg={2} md={2} sm={12}>
                                    {searchedInfluencers?.length > 0 && (
                                        <Typography py={3} >
                                            <Checkbox
                                                size='small'
                                                checked={searchedInfluencersCheckboxIds?.length === searchedInfluencers?.length}
                                                onChange={(event) => {
                                                    let ids = [];
                                                    if (event.target.checked) {
                                                        ids = searchedInfluencers?.map((v) => v.id) || [];
                                                    } else {
                                                        ids = [];
                                                    }
                                                    dispatch(updateCampaign({ searchedInfluencersCheckboxIds: ids }));
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />&nbsp;
                                            <Link href="#" className='text-decoration-none'>Invite all</Link>
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} lg={10} md={10} sm={12} >
                                    <Stack direction="row" gap={4} pl={6} justifyContent="flex-end" alignContent={"right"} flexDirection={"right"}>

                                        <Select
                                            value={filters.creatorCategory || ''}
                                            name="creatorCategory"
                                            onChange={(e) => {
                                                dispatch(updateCampaign({ filters: { ...filters, creatorCategory: e.target.value, source: "campaign" } }));
                                                dispatch(getInfluencerProfilesSearch());
                                            }}
                                            displayEmpty
                                            defaultValue={""}
                                        >
                                            <MenuItem value="" disabled hidden>
                                                Creator Category
                                            </MenuItem>

                                            <MenuItem value="">
                                                All
                                            </MenuItem>

                                            {CREATOR_CATEGORY.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {searchedInfluencersCheckboxIds.length > 0 && searchedInfluencersCheckboxIds?.length == searchedInfluencers?.length && (
                                            <button className='btnPrimary' onClick={() => {
                                                dispatch(updateCampaign({ searchedInfluencersInvitedIds: searchedInfluencersCheckboxIds }));
                                                dispatch(invitedInfluencerBySearch());
                                            }}>
                                                Send Invitations
                                            </button>
                                        )}
                                        {searchedInfluencers?.length > 0 && <button
                                            className="btnSecondary"
                                            onClick={() => setfilterState(true)}
                                        >
                                            Audience Filter <FilterAltIcon color="info" />

                                        </button>}
                                    </Stack>
                                </Grid>


                            </Grid>
                            {(searchedInfluencers?.length === 0) && <Typography variant="h5" flexDirection={"right"}>{"We couldn't find any influencers that match your search..."}</Typography>}


                            <Grid container>
                                <Grid item xs={12} lg={12} md={12} sm={12}>

                                    <SearchedInfluencer />

                                    {((formData?.campaignApplyingPreferences?.length > 0 ||
                                        invitedInfluencers?.length > 0 ||
                                        searchedInfluencersInvitedIds?.length > 0)) ? (
                                        <></>
                                    ) : <p
                                        className="error"
                                    >
                                        {formData?.mode != "edit" && "Please invite at least one Influencer or Set the campaign to 'Apply by Everyone'"}
                                    </p>}
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4} sm={4} >

                        </Grid>


                    </Grid>

                    <hr />
                    <Grid item p={3} xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">

                        <Stack direction={"row"} spacing={1}>
                            <button className='btnSecondary'
                                onClick={() => {
                                    navigate("/campaign/info")
                                }}
                            >
                                Back
                            </button>
                            <button className={!(formData?.campaignApplyingPreferences?.length > 0 ||
                                invitedInfluencers?.length > 0 ||
                                searchedInfluencersInvitedIds?.length > 0 || formData?.mode === "edit") ? "btnDisabled" : 'btnPrimary'} disabled={!(formData?.campaignApplyingPreferences?.length > 0 ||
                                    invitedInfluencers?.length > 0 ||
                                    searchedInfluencersInvitedIds?.length > 0 || formData?.mode === "edit")} onClick={() => {
                                        if (formData?.campaignApplyingPreferences?.length > 0 ||
                                            invitedInfluencers?.length > 0 ||
                                            searchedInfluencersInvitedIds?.length > 0 || formData?.mode === "edit") {
                                            // let data = creator?.gender != undefined ? creator :
                                            let creatorData = {};
                                            if (creator?.gender !== undefined) {
                                                creatorData = { ...creator };
                                                creatorData.campaignApplyingPreferences = formData.campaignApplyingPreferences.length > 0 ? true : false;
                                                creatorData = {
                                                    campaignApplyingPreferences: formData.campaignApplyingPreferences?.length > 0 ? 1 : 0,
                                                    creator: JSON.stringify(creatorData)
                                                }
                                            } else {
                                                creatorData = {
                                                    campaignApplyingPreferences: formData.campaignApplyingPreferences?.length > 0 ? 1 : 0,
                                                    creator: JSON.stringify({ gender: "", influncerType: [], selectedCategories: [], creatorLocation: [], campaignApplyingPreferences: formData.campaignApplyingPreferences?.length > 0 ? true : false })
                                                }
                                            }


                                            dispatch(addNewCampaign(creatorData));
                                            //dispatch(updateCampaign({ searchedInfluencersInvitedIds: searchedInfluencersCheckboxIds }));
                                            //dispatch(invitedInfluencerBySearch());

                                            navigate("/campaign/review");
                                        } else {

                                        }
                                        // dispatch(addNewCampaign({ campaignApplyingPreferences: formData.campaignApplyingPreferences?.length > 0 ? 1 : 0 }, navigate));

                                    }}>Review Campaign & Create</button>

                        </Stack>
                    </Grid>


                    <Grid item xs={12} lg={12} md={12} sm={12}>
                        <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
                        <ManualSearchModel openModal={open} setOpenmodel={setOpen} />
                    </Grid>
                </Box>
            </Stack >
        </Box >
        <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
        <Drawer
            state={filterState}
            setState={setfilterState}
            className="filterDrawer"
            width="25%"
        >
            <Filter setfilterState={setfilterState} />
        </Drawer>
    </>
}
export default ProfilerScreener;