import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import { useLocation } from "react-router-dom"
import GlobalLoader from "../components/backdrop"
import Alert from '../components/dialog/Alert'
import FooterComponent from '../components/footer'
import Header from '../components/header'
import Sidebar from '../components/sidebar'
import Toaster from '../components/toaster/toaster'
import AccessDenied from '../pages/accessDenied'
import { UrlRestrictionsArray } from '../utils/common'
import { getParamMasterList } from './action'
const Layout = () => {
  const theme = useTheme()
  const dispatch = useDispatch();
  const toolbarHeight = theme.spacing(0);
  const states = useSelector(state => state.app)
  const location = useLocation();

  useEffect(() => {
    dispatch(getParamMasterList());
  }, []);




  const url = location?.pathname?.split("/")?.filter((v) => v !== '')[0];

  const permessions = UrlRestrictionsArray.find((v) => v.urlKey === url);
  if (states.permissions?.[permessions?.pKey]?.view == 0) {
    return <AccessDenied />
  }
  return (
    <>
      <Header />
      <Toaster />
      <GlobalLoader />
      <Sidebar />
      <Box className="bodyArea" ml={states.sidebarWidth} px={3} py={2} position={'relative'} bgcolor={'#f0f0f0'} minHeight={`calc(100vh - ${toolbarHeight})`} sx={{ paddingTop: '88px', transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`, }}>
        <div className='innerBody'>
          <div className='containerFluid'>
            <Outlet />
          </div>
        </div>
        <FooterComponent />
      </Box>
      <Alert />
    </>
  )
}

export default Layout