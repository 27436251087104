import { FormLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedSnackbars from "../components/snackbar";
import { useGetRoleByIdQuery, useUpdateRolesMutation } from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { ROUTES } from "../utils/routes";

const EditRole = () => {
  var { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isSuccess, isLoading } = useGetRoleByIdQuery(id);
  const [updateRoles] = useUpdateRolesMutation();
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });
  const [formData, setFormData] = React.useState({
    roleName: "",
    description: "",
    assignRights: {},
  });

  React.useEffect(() => {
    if (data?.status === "SUCCESS" && data.result.length > 0) {
      const roleData = data.result[0];
      setFormData({
        roleName: roleData.roleName,
        description: roleData.description,
        assignRights: roleData.permissions,
      });
    }
  }, [isSuccess]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (title, right) => {
    setFormData((prevData) => ({
      ...prevData,
      assignRights: {
        ...prevData.assignRights,
        [title]: {
          ...prevData.assignRights[title],
          [right]: prevData.assignRights[title][right] === 1 ? 0 : 1,
        },
      },
    }));
  };

  const saveRole = () => {
    let permissions = formData.assignRights;
    let newPermissionArr = [];
    newPermissionArr = Object.entries(permissions).map(([key, value]) => {
      return {
        type: key,
        rights: Object.entries(value)
          .filter(([key1, val]) => val === 1)  // Correctly referencing the value in the filter function
          .map(([right]) => right)
      };
    });
    // for (let key in permissions) {
    //   let obj = {};
    //   obj["type"] = key;
    //   obj["rights"] = [];

    //   for (let key1 in permissions[key]) {
    //     if (permissions[key][key1] === 1) {
    //       obj["rights"].push(key1);
    //     }
    //   }
    //   // if (obj.rights.length) {
    //   newPermissionArr.push(obj);
    //   // }
    // }
    // formData.assignRights = newPermissionArr;


    updateRoles({
      formData: { ...formData, assignRights: newPermissionArr },
      id,
    }).then((res) => {
      if (res.data.status == "SUCCESS") {
        dispatch(updateCommonData({
          message: "Role updated successfully",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        navigate(ROUTES.ROLES_AND_RIGHTS);
      } else {
        if (res.data.message) {
          dispatch(updateCommonData({
            message: res.data.message || "Api error",
            title: "Failed",
            img: "/failed.png",
            status: true
          }));
          // setSnackbar({
          //   message: res.data.message,
          //   severity: "error",
          //   open: true,
          // });
        }
      }
    });
  };

  return (
    <>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack direction="row" alignItems="center" spacing={3} mb={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Edit Role
          </Typography>
          <Stack
            className="backText"
            direction="row"
            gap={0.5}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography variant="h5" color="primary">
              Back
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}
      >
        <div className="p-4">
          <div className="row flex-column gap-3">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <FormLabel required>Role Name</FormLabel>
              <OutlinedInput
                className="w-100"
                type="text"
                name="roleName"
                value={formData.roleName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <FormLabel required>Role Description</FormLabel>
              <OutlinedInput
                className="w-100"
                multiline={true}
                style={{ padding: "0px", minHeight: "70px" }}
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" className="subtitle2 px-4 py-4" style={{ background: '#f5f5f5' }}>
            Assign Rights
          </Typography>
          <div className="scrollRoles">
            <div className="assRight">
              {Object.entries(formData.assignRights).map(([title, rights]) => (
                <RightsSection
                  key={title}
                  title={title}
                  rights={rights}
                  onChange={handleCheckboxChange}
                />
              ))}
            </div>
          </div>
        </div>
        <hr />
        <Stack direction="row" justifyContent="end" gap={1} p={3}>
          <button className="btnSecondary" onClick={() => navigate(-1)}>Cancel</button>
          <button type="submit" className="btnPrimary" onClick={saveRole}>Save Role</button>
        </Stack>
      </Box>
    </>
  );
};

const RightsSection = ({ title, rights, onChange }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1" style={{ minWidth: "200px" }}>
          {title.capitalize()}
        </Typography>
        <div style={{ display: "flex", gap: "30px" }}>
          {Object.entries(rights).map(([right, value]) => (
            <div key={right} className="form-check">
              <label className="form-check-label">{right.capitalize()}</label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={value === 1}
                onChange={() => onChange(title, right)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditRole;
