import { CloseSharp } from "@mui/icons-material";
import {
  Box,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Modal,
  Stack,
  TextareaAutosize,
  Typography
} from "@mui/material";
import React from "react";

export const CustomModal = ({
  creating = true,
  open,
  onClose,
  approveFn,
  modal = {},
}) => {
  const {
    heading,
    inputLabel = "Description",
    submitButton = "Approve",
  } = modal;
  const [inputValue, setInputVal] = React.useState("");
  const handleChange = (e) => {
    setInputVal(e.target.value);
  };
  const handleApprove = async (data) => {
    await approveFn(data);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 260, md: 400, lg: 550 },
    bgcolor: "background.paper",
    borderRadius: "10px",
    padding: "15px 25px",
    p: 2,
  };
  return (
    <>
      <CssBaseline />
      <Modal open={open} onClose={onClose}>
        <Box sx={style} className="p-0">
          <Stack
            className="px-3 py-3"
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant={"h4"}
              sx={{ color: creating == false && "error" }}
            >
              {heading}
            </Typography>
            <div className="cursorPointer" onClick={() => onClose()}>
              <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
            </div>
          </Stack>
          <hr />
          <Stack direction={"column"} className="px-3 py-3" gap={0.5}>
            <Typography variant="label" component="label">{inputLabel}</Typography>
            <TextareaAutosize
              minRows={4}
              placeholder="Enter your description"
              className="text-area"
              onChange={handleChange}
              value={inputValue}
              style={{
                borderRadius: "8px",
                padding: "12px",
                background: "#fff",
                border: "0px",
                borderWidth: "1px",
                borderStyle: 'solid',
                borderColor: "rgba(170, 170, 170, 0.5)",
              }}
            />
          </Stack>
          <Stack direction={"row"} className="px-3 py-3" justifyContent={"end"} spacing={1}>
            <button
              className="btnSecondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={submitButton != "Approve" ? "btnDanger" : "btnPrimary"}
              color={creating == false ? "error" : "primary"}
              onClick={() => {
                handleApprove({
                  status: submitButton != "Approve" ? "REJECTED" : "APPROVED",
                  description: inputValue,
                });
                onClose();
              }}
            >
              {submitButton}
            </button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};




export const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      p={0}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Typography className="px-4 py-3" variant="h4" component="h4">Status</Typography>
      <hr />
      <DialogContent className="px-4 py-3">
        <Typography component="p" style={{ fontSize: '14px' }}>{message || 'Are you sure ?'}</Typography>
      </DialogContent>
      <hr />
      <DialogActions className="px-3 pb-3 pt-3">
        <button onClick={onClose} className="btnSecondary">
          No
        </button>
        <button onClick={onConfirm} className="btnPrimary" autoFocus>
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

