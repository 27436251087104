import axios from "axios";
import { getAuthTokenFromLS } from "./common";
import { BASE_URL } from "./constant";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  headers: {
    "Authorization": getAuthTokenFromLS() || sessionStorage.getItem("jwttoken") || "",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {

    config.headers["Authorization"] = getAuthTokenFromLS() || sessionStorage.getItem("jwttoken") || "";

    return config;
  },
  (error) => {

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config) => {

    if (config?.data?.message === "Authorization Invalid" || config?.data?.message === "invalid token") {
      localStorage.clear();
      window.location.href = "/";
      return
    }
    return config;
  },
  (error) => {
    if (!error.response || error.response.status === 502) {
      // Clear local storage and redirect to the login page
      localStorage.clear();
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      return;
    }

    // Check if the authentication token is missing
    if (!getAuthTokenFromLS() || getAuthTokenFromLS() == undefined || getAuthTokenFromLS() == null) {
      // Clear local storage and redirect to the login page
      localStorage.clear();
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      return;
    }

    return Promise.reject(error);
  }
);
export default axiosInstance;