import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  nLimit: 10,
  page: 0,
  nPage: 0,
  nFilters: {},
  sortType: "desc",
  sortKey: "nextScheduleDate",
  search: "",
  totalCount: 0,
  totalPages: 0,
  brandsDropdown: [],
  companyListing: [],
  listing: [],
  filters: {},
  details: {},
  defaultActivePlan: 0,
  activeFilters: {},
  activePlans: [],
  // for listing
  selectedAllUserCheckbox: [],
  selectedUserCheckbox: [],
  selectedRows: [],
  selectedCheckbox: [],
  allcheckbox: false,
  allRemoveCheckbox: false,
  notificationListing: [],
  notificationDetails: {},
  eventListing: [],
  eventListForEmail: []
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateNotification: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetNotification: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateNotification, resetNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
